<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { deviceDetection } from "@pureadmin/utils";
import { GetSourceFromTheme } from "@/utils/themeSourceUtils";
const isMobile = deviceDetection();
const { t } = useI18n();
const boxImg = ref();
const boxImgPhone = ref();
onMounted(() => {
  const theme = useSystemConfigStoreHook().config.sysConfig.theme;
  const img = GetSourceFromTheme(theme).digitBg;
  const imgPhone = GetSourceFromTheme(theme).digitBgPhone;
  boxImg.value = img;
  boxImgPhone.value = imgPhone;
});
</script>

<template>
  <div class="relative w-full h-[95vh] text-[#f5f3f3]">
    <div
      :class="{ '!text-[2rem] pt-[5.6rem]': isMobile }"
      class="absolute text-[3.125rem] font-[OPPOSansB] pt-[3rem] w-full text-center"
    >
      {{ t("firstPage.fourScreen.interactive_digital_human") }}
    </div>
    <div
      class="absolute text-[1.25rem] font-[OPPOSansM] pt-[7rem] w-full text-center"
    >
      {{ t("future.one.professional") }}
    </div>
    <!-- 背景图 -->
    <div class="w-full h-full object-cover">
      <img
        class="w-full h-full object-cover"
        :src="isMobile ? boxImgPhone : boxImg"
      />
    </div>
  </div>
</template>
<style scoped></style>
