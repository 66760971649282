<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
const { t } = useI18n();
// 折扣码
const discountCode = ref();
const discountCodeDialogRef = ref();

const handleGetDiscountCode = () => {
  console.log("获取折扣码");
  discountCodeDialogRef.value.showDialog();
};

defineExpose({
  discountCode
});
</script>

<template>
  <div class="flex flex-col gap-2 mx-auto">
    <div
      class="ani-scale relative bg-[#F6F6F6] flex flex-col lg:flex-row lg:justify-between !h-[3.125rem] items-center mt-8 mb-6 lg:rounded-[5px] lg:h-[4.13rem]"
    >
      <!-- 金币 -->
      <!-- 用户头像 -->
      <!-- <img :src="icon" class="absolute w-[3.13rem] h-[2.31rem] left-[-2rem]" /> -->
      <div
        type="primary"
        @click="handleGetDiscountCode"
        class="custom cursor-pointer !font-normal font-[OPPOSansB] text-[1rem] lg:!text-[1.25rem] mt-[0.5rem] lg:mt-0 lg:mb-0 lg:ml-[1rem] lg:mr-[0.5rem]"
      >
        {{ t("dialog.discountCode.getDiscountCode") }}
      </div>
      <div class="flex flex-row justify-center lg:mr-[0.3rem]">
        <el-input
          v-model="discountCode"
          :placeholder="t('dialog.inputDiscountCode')"
          class="custom-input lg:!w-[18rem] !rounded-[5px]"
          maxlength="6"
          size="large"
        />
      </div>
    </div>
    <!-- applycode为true是合伙人申请，false是申请折扣码 -->
    <DiscountCodeDialog :applycode="false" ref="discountCodeDialogRef" />
  </div>
</template>

<style scoped>
.custom {
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  background: linear-gradient(
    212deg,
    var(--el-color-primary-stop) 25%,
    var(--el-color-primary) 76%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
/* 设置placeholder的文字大小*/
.custom-input ::placeholder {
  font-family: OPPOSansL;
  font-size: 15px;
}
/* 设置折扣码输入框无边框 */
::v-deep .custom-input .el-input__wrapper {
  box-shadow: none;
  height: 2.5rem;
}
</style>
<style scoped>
@media screen and (width <=1080px) {
  /* 设置折扣码输入框无边框 */
  ::v-deep .custom-input .el-input__wrapper {
    background-color: #f6f6f6;
    border-radius: 0 0;
    padding: 0.5rem 0.5rem;
  }
  :deep(.el-input__inner) {
    background-color: #fff !important;
    height: 2rem;
    width: 15rem;
  }
}
</style>
