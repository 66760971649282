import OperateMenuView from '@/views/operate/OperateMenu.vue';
import { RouterNameEnum } from '@/config';
import { operate } from "../enums";
export default {
  path: '/operate',
  component: OperateMenuView,
  meta: {
    title: '{"zh":["数字人操作"],"en":["Digital Human Operation"],"zh-tw":["數位人操作"]}',
    rank: operate,
    notFlat: true,
  },
  children: [
    {
      path: '/operate/menu-my-custom/index',
      name: RouterNameEnum.MenuMyCustom,
      component: () => import('@/views/operate/menu-my-custom/index.vue'),
      meta: {
        icon: "ri:account-circle-fill",
        title: '{"zh":["数字人操作"],"en":["Digital Human Operation"],"zh-tw":["數位人操作"]}',
        showLink: true,
      }
    },
    {
      path: '/operate/menu-work-management/index',
      name: RouterNameEnum.MenuWorkManagement,
      component: () => import('@/views/operate/menu-work-management/index.vue'),
      meta: {
        icon: "ri:folder-2-fill",
        title: '{"zh":["作品"],"en":["Work"],"zh-tw":["作品"]}',
        showLink: false
      },
    },
    {
      path: '/operate/agent-chat/index',
      name: RouterNameEnum.AgentChat,
      component: () => import('@/views/agent-chat/index.vue'),
      meta: {
        icon: "streamline:hand-held-tablet-writing-solid",
        label: '{"zh":["文案"],"en":["Copywriting"],"zh-tw":["文案"]}',
        showLink: false,
      }
    },
    {
      path: '/operate/menu-my-rights/index',
      name: RouterNameEnum.MenuMyRights,
      component: () => import('@/views/operate/menu-my-rights/index.vue'),
      meta: {
        icon: "ri:magic-line",
        title: '{"zh":["权益"],"en":["Assets"],"zh-tw":["權益"]}',
        showLink: false
      },
    }
  ]
} as RouteConfigsTable;