<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <div class="min-h-[95vh] flex items-center justify-center py-24">
    <div class="container mx-auto px-4">
      <!-- 标题区域 -->
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold text-gray-900 mb-4">
          {{ t("welcome.UseCasesLayout.digitalHumanApplicationScenarios") }}
        </h2>
        <p class="text-xl text-gray-600">
          {{ t("welcome.UseCasesLayout.realHumanAppearanceScenes") }}
        </p>
      </div>

      <!-- 卡片网格：4 列 -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <!-- 第1个卡片 -->
        <div
          class="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300"
        >
          <div class="aspect-video relative overflow-hidden">
            <img
              src="https://oss.iiii.com/userImg/1735202810765.png"
              :alt="t('welcome.UseCasesLayout.shortVideoAcquisition')"
              class="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-500"
            />
            <div
              class="absolute inset-0 bg-gradient-to-t from-[--el-color-primary] to-transparent opacity-75"
            ></div>
          </div>
          <div class="p-6">
            <h3 class="text-xl font-bold text-gray-900 mb-3">
              {{ t("welcome.UseCasesLayout.shortVideoAcquisition") }}
            </h3>
            <p class="text-gray-600">
              {{ t("welcome.UseCasesLayout.noProfessionalEquipment") }}
            </p>
          </div>
        </div>

        <!-- 第2个卡片 -->
        <div
          class="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300"
        >
          <div class="aspect-video relative overflow-hidden">
            <img
              src="https://oss.iiii.com/userImg/1735202707487.png"
              :alt="t('welcome.UseCasesLayout.personalIPCreation')"
              class="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-500"
            />
            <div
              class="absolute inset-0 bg-gradient-to-t from-[--el-color-primary] to-transparent opacity-75"
            ></div>
          </div>
          <div class="p-6">
            <h3 class="text-xl font-bold text-gray-900 mb-3">
              {{ t("welcome.UseCasesLayout.personalIPCreation") }}
            </h3>
            <p class="text-gray-600">
              {{ t("welcome.UseCasesLayout.breakTimeAndEffortLimits") }}
            </p>
          </div>
        </div>

        <!-- 第3个卡片 -->
        <div
          class="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300"
        >
          <div class="aspect-video relative overflow-hidden">
            <img
              src="https://oss.iiii.com/userImg/1735202675901.png"
              :alt="t('welcome.UseCasesLayout.brandPromotion')"
              class="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-500"
            />
            <div
              class="absolute inset-0 bg-gradient-to-t from-[--el-color-primary] to-transparent opacity-75"
            ></div>
          </div>
          <div class="p-6">
            <h3 class="text-xl font-bold text-gray-900 mb-3">
              {{ t("welcome.UseCasesLayout.brandPromotion") }}
            </h3>
            <p class="text-gray-600">
              {{ t("welcome.UseCasesLayout.customDigitalSpokesperson") }}
            </p>
          </div>
        </div>

        <!-- 第4个卡片 -->
        <div
          class="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300"
        >
          <div class="aspect-video relative overflow-hidden">
            <img
              src="https://oss.iiii.com/userImg/1735202778721.png"
              :alt="t('welcome.UseCasesLayout.courseProduction')"
              class="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-500"
            />
            <div
              class="absolute inset-0 bg-gradient-to-t from-[--el-color-primary] to-transparent opacity-75"
            ></div>
          </div>
          <div class="p-6">
            <h3 class="text-xl font-bold text-gray-900 mb-3">
              {{ t("welcome.UseCasesLayout.courseProduction") }}
            </h3>
            <p class="text-gray-600">
              {{ t("welcome.UseCasesLayout.quicklyTransformCourseContent") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 如果有需要额外样式可加在这里 */
</style>
