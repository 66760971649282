import {
  GlobalStateEnum,
  CutoutTypeEnum,
  NewsGroupEnum,
  PayBusinessTypeEnum,
  ScaleTypeEnum,
  DemoGroupEnum
} from "@/config/constantsEnum";
import { isAllEmpty } from "@pureadmin/utils";
import emojiRegex from "emoji-regex";
import { useAppStoreHook } from "@/store/modules/app";
import dayjs from "dayjs";
import { globalT } from "@/plugins/i18n";

export const formatUtils = {
  formatTime(seconds: number, lang: string) {
    if (lang === "zh" || lang === "zh-tw") {
      const minutes = Math.floor(seconds / 60); // 计算分钟数
      const remainingSeconds = seconds % 60; // 计算剩余的秒数

      // 格式化输出
      let result = minutes > 0 ? minutes + " 分 " : "";

      if (remainingSeconds > 0) {
        result += remainingSeconds + " 秒 ";
      }
      return result;
    } else {
      const minutes = Math.floor(seconds / 60); // 计算分钟数
      const remainingSeconds = seconds % 60; // 计算剩余的秒数

      // 格式化输出
      let result = minutes > 0 ? minutes + " min " : "";
      if (remainingSeconds > 0) {
        result += remainingSeconds + " seconds ";
      }
      return result;
    }
  },
  createRandom(min: number, max: number) {
    const Range = max - min;
    const Rand = Math.random();
    return min + Math.round(Rand * Range);
  },
  maskEmailOrPhone(input: string) {
    let maskedInput;

    // 如果输入是电子邮件
    if (input.includes("@")) {
      maskedInput = input.replace(/(.{2})(.*)(?=@)/, function (_, p1, p2) {
        return p1 + p2.replace(/./g, "*");
      });
    }
    // 如果输入是手机号
    else {
      maskedInput = input.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    }

    return maskedInput;
  },
  rgbToHex(rgb: any) {
    let a = rgb.split("(")[1].split(")")[0];
    a = a.split(",");
    const b = a.map(function (x) {
      x = parseInt(x).toString(16);
      return x.length == 1 ? "0" + x : x.toUpperCase(); // 修改在这里
    });
    return "#" + b.join("").toUpperCase(); // 这里也添加了toUpperCase()
  },
  formatDateString(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // JS months start at 0
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
  checkInviteCode(code: string): boolean {
    if (code === "") {
      return false;
    }
    // Combine the letter and number strings into a single string
    const validChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    // Create a regex that checks if all characters in the string are from validChars
    const regex = new RegExp("^[" + validChars + "]+$");

    // Return true if the string only contains valid characters, false otherwise
    return regex.test(code);
  },
  isEmpty(...obj: any[]): boolean {
    if (
      typeof obj == "undefined" ||
      obj == undefined ||
      obj == null ||
      obj.length == 0
    ) {
      return true;
    }
    let count = 0;
    for (count = 0; count < obj.length; count++) {
      const value = obj[count];
      if (
        typeof value == "undefined" ||
        obj == undefined ||
        value == null ||
        value == ""
      ) {
        return true;
      }
    }
    return false;
  },
  dealEmoji(text: string): string {
    if (this.isEmpty(text)) {
      return null;
    }
    text = text.replace(emojiRegex(), "");
    return text;
  },
  getTextLength(text: string) {
    if (this.isEmpty(text)) {
      return 0;
    }
    let count = 0;
    text = this.dealEmoji(text);
    const testList = text.split(" ");
    for (let i = 0; i < testList.length; i++) {
      const char = testList[i].trim();
      if (char === " ") {
        // skip spaces
        continue;
      }

      if (/^[a-zA-Z]*$/.test(char)) {
        // if it's an English character
        console.log(char);
        count += 1;
      } else {
        count += char.length;
      }
    }
    return count;
  },
  // 阿里云oss图片压缩处理
  ossScaleImage(url: string, scaleType?: ScaleTypeEnum) {
    if (!scaleType) {
      scaleType = ScaleTypeEnum.SCALE_DEFAULT;
    }
    if (isAllEmpty(url)) {
      return null;
    }
    let endScale = "style/scale512";
    if (scaleType == ScaleTypeEnum.SCALE_256) {
      endScale = "style/scale256";
    } else if (scaleType == ScaleTypeEnum.SCALE_720) {
      endScale = "style/scale720";
    } else if (scaleType == ScaleTypeEnum.SCALE_1080) {
      endScale = "style/scale1080";
    }
    let dealUrl = "" + url;
    if (dealUrl.includes("iiii.com")) {
      if (dealUrl.endsWith("&")) {
        dealUrl = dealUrl.substring(0, dealUrl.length - 1);
      }
      if (dealUrl.includes("?")) {
        return dealUrl + "&x-oss-process=" + endScale;
      }
      return dealUrl + "?x-oss-process=" + endScale;
    }
  },


  shallowEqual(obj1: Record<string, any>, obj2: Record<string, any>): boolean {
    if (obj1 == null || obj2 == null) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  },
  getFullUrl() {
    const protocol = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port;
    const fullUrl = port
      ? `${protocol}//${host}:${port}`
      : `${protocol}//${host}`;
    return fullUrl;
  },
  getHref() {
    return window.location.href;
  },
  formatEnableState(state: boolean) {
    if (state) {
      return "开启";
    } else {
      return "关闭";
    }
  },
  formatAgentState(state: GlobalStateEnum) {
    if (state === GlobalStateEnum.Success) {
      return "同意";
    } else if (state === GlobalStateEnum.Fail) {
      return "拒绝";
    } else if (state === GlobalStateEnum.Pending) {
      return "待审核";
    }
  },

  /**抠像类型 */
  getDigitalcutoutType(type: number) {
    if (type === CutoutTypeEnum.KEEP_BACKGROUND) {
      return globalT("digitalHumanPage.imgtype1");
    } else if (type === CutoutTypeEnum.TRANSPARENT_BACKGROUND) {
      return globalT("digitalHumanPage.imgtype2");
    } else if (type === CutoutTypeEnum.GREEN_SCREEN) {
      return globalT("digitalHumanPage.imgtype3");
    }
  },
  // 获取数字人视频相对应案例
  async getVideoList(reVideosMap, type) {
    const digitHumanList = reVideosMap.get(type);
    let urlAndPosterArray = [];
    if (digitHumanList?.length > 0) {
      urlAndPosterArray = digitHumanList.map(item => {
        return {
          url: item.url,
          poster: item.imageUrl
        };
      });
    }
    return urlAndPosterArray;
  },
  async getDigitalDemo(demoAdvertiseMap, type) {
    const digitHumanList = demoAdvertiseMap.get(type);
    let urlAndPosterArray = [];
    if (digitHumanList?.length > 0) {
      urlAndPosterArray = digitHumanList.map(item => {
        return {
          desc: item.description,
          img: item.imageUrl
        };
      });
    }
    return urlAndPosterArray;
  },
  /**获取数字人下拉列表和介绍信息 */
  async getDigitalHumanOption() {
    const demoAdvertiseList = await useAppStoreHook().getNews(
      NewsGroupEnum.DEMO_ADVERTISE
    );
    const arrXiaobing = await formatUtils.getDigitalDemo(
      demoAdvertiseList,
      PayBusinessTypeEnum.DIGITAL_SHORT
    );
    const arrnBusinessCard = await formatUtils.getDigitalDemo(
      demoAdvertiseList,
      PayBusinessTypeEnum.DIGITAL_INTERACTIVE
    );
    const arrdigitHumanLive = await formatUtils.getDigitalDemo(
      demoAdvertiseList,
      PayBusinessTypeEnum.DIGITAL_LIVE
    );
    const arrXbSound = await formatUtils.getDigitalDemo(
      demoAdvertiseList,
      PayBusinessTypeEnum.SOURCE_PACKAGE
    );
    const fastDigitalShort = await formatUtils.getDigitalDemo(
      demoAdvertiseList,
      PayBusinessTypeEnum.FAST_DIGITAL_SHORT
    );

    const digitalHumanOption = {};
    const reVideos = await useAppStoreHook().getNews(
      NewsGroupEnum.NEWS_VIDEO_DEMO
    );
    if (arrXiaobing?.length > 0) {
      digitalHumanOption[DemoGroupEnum.DIGITAL_SHORT] = {
        // 硅基数字人的详细文字描述
        desc: arrXiaobing[0].desc,
        img: arrXiaobing[0].img,
        //硅基数字人视频数组
        videoUrl: await formatUtils.getVideoList(
          reVideos,
          DemoGroupEnum.DIGITAL_SHORT
        )
      };
    }

    if (fastDigitalShort?.length > 0) {
      digitalHumanOption[DemoGroupEnum.FAST_DIGITAL_SHORT] = {
        // 硅基数字人的详细文字描述
        desc: fastDigitalShort[0].desc,
        img: fastDigitalShort[0].img,
        //硅基数字人视频数组
        videoUrl: await formatUtils.getVideoList(
          reVideos,
          DemoGroupEnum.FAST_DIGITAL_SHORT
        )
      };
    }

    if (arrnBusinessCard?.length > 0) {
      digitalHumanOption[DemoGroupEnum.DIGITAL_INTERACTIVE] = {
        // 数字名片的详细文字描述
        desc: arrnBusinessCard[0].desc,
        img: arrnBusinessCard[0].img,
        //数字名片的视频数组
        videoUrl: await formatUtils.getVideoList(
          reVideos,
          DemoGroupEnum.DIGITAL_INTERACTIVE
        )
      };
    }

    if (arrdigitHumanLive?.length > 0) {
      digitalHumanOption[DemoGroupEnum.DIGITAL_LIVE] = {
        // 直播数字人的详细文字描述
        desc: arrdigitHumanLive[0].desc,
        img: arrdigitHumanLive[0].img,
        //直播数字人的视频数组
        videoUrl: await formatUtils.getVideoList(
          reVideos,
          DemoGroupEnum.DIGITAL_LIVE
        )
      };
    }

    if (arrXbSound?.length > 0) {
      digitalHumanOption[DemoGroupEnum.SOURCE_PACKAGE] = {
        //声音定制的详细文字描述
        desc: arrXbSound[0].desc,
        img: arrXbSound[0].img,
        //声音定制视频数组
        videoUrl: await formatUtils.getVideoList(
          reVideos,
          DemoGroupEnum.SOURCE_PACKAGE
        )
      };
    }

    return digitalHumanOption;
  },

  //数字人界面联系我们介绍文字
  contactUs() {
    return [
      {
        desc:globalT("digitHuman.customizedPlans"),
      },
      {
        desc: globalT("digitHuman.digitApiDoc"),
        url: window.location.host.includes('snkxd-ai') ? null : "https://www.iiii.com/docs/api/digital-human.api.html"
      },
      {
        desc:globalT("digitHuman.websitePrivateDeployment")
      },

      {
        desc: globalT("digitHuman.customizedRequirements")
      }
    ];
  },
  //数字人界面联系我们介绍文字
  getDigitalHumanOptions() {
    return [
      {
        label: globalT("outstandingCase.twoScreen.Short_Video"),
        value: DemoGroupEnum.DIGITAL_SHORT
      },
      {
        label: globalT(
          "outstandingCase.twoScreen.Interactive_Digital_Human"
        ),
        value: DemoGroupEnum.DIGITAL_INTERACTIVE
      },
      {
        label: globalT("outstandingCase.twoScreen.Live_Digital_Human"),
        value: DemoGroupEnum.DIGITAL_LIVE
      },
      {
        label: globalT("outstandingCase.twoScreen.Voice_Customization"),
        value: DemoGroupEnum.SOURCE_PACKAGE
      },
      {
        label: globalT("outstandingCase.twoScreen.Fast_Digital_Human"),
        value: DemoGroupEnum.FAST_DIGITAL_SHORT
      }
    ];
  },

  formatDate(date: string): string {
    date = date.split(" ")[0];
    const today = dayjs();
    const targetDate = dayjs(date);
    let dateStr = targetDate.format("YYYY-MM-DD");
    if (targetDate.isSame(today, "day")) {
      dateStr = globalT("common.today");
    } else if (targetDate.isSame(today.subtract(1, "day"), "day")) {
      dateStr = globalT("common.yesterday");
    } else if (targetDate.isSame(today.subtract(2, "day"), "day")) {
      dateStr = globalT("common.dayBeforeYesterday");
    }
    return dateStr;
  },

  //声音克隆文字内容
  readWord() {
    const soundList = [
      [
        {
          info: `在历史的不同时期，科技进步都扮演了不同的角色。在农业时代，科技进步推动了种植业和畜牧业的发展，大幅提高了食品供应效率。在工业革命时代，蒸汽机、电力和机械化生产的发展，大大提高了生产效率和产量。而在信息化时代，计算机、互联网和移动通信技术的快速发展，则让人们享受到了前所未有的便利和效率。同时，科技进步其实也推动了人类向更高层次的文明迈进。从古代的人类文明开始，人们就一直在探索和开发新的技术和方法，希望能够解决问题和改善生活。如今，科技的进步让人类的生活更加美好和高效，同时也提出了新的挑战和问题。例如网络安全、隐私保护等，也日益成为了人们关注的焦点。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `为了让科技的发展更好地造福人类，我们需要持续关注和支持科技创新，积极探索和应用新的技术。同时也需要注意科技发展带来的风险和挑战，寻找解决问题的途径和方法。只有不断地创新和探索，才能让科技发展更加健康可持续，为人类的未来带来更多的可能性和希望。今天我们来深入探讨一下最新的创新技术和科技趋势，让您了解最前沿的科技进展。首先让我们来看看人工智能领域的最新进展。随着机器学习算法的不断发展，人工智能正逐渐变得更加智能化和自主化。最近，开发人员已经成功地将人工智能算法应用到各种行业中。从智能医疗设备到智能家居系统，再到自动驾驶汽车等等。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `除此之外，区块链技术也是近年来备受瞩目的一种新型技术。区块链技术可以用来创建一个安全、去中心化的数据交换系统，而不需要第三方机构来管理和监管数据。这种技术已经开始应用于加密货币交易，但是未来还有更广泛的应用前景，例如智能合约和去中心化应用程序等。最后，让我们来谈谈关于未来的一些科技趋势和创新。网友们都期待看到更多智能化和自主化技术的发展，例如自主驾驶汽车和智能家居系统。与此同时，人工智能技术将继续发挥越来越重要的作用。随着时间的推移，我们相信它将变得越来越强大和灵活。虚拟现实技术也将继续进化，打破时间和空间的限制，让我们的VR体验更加逼真。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `科技创新正在推动着我们的世界不断前进，为人们带来了更多的便利和机遇。近年来，随着人工智能、大数据、物联网、虚拟现实、区块链等技术的快速发展，我们的生活、工作方式正在发生着巨大的变化。这些技术也正在改变着我们的未来。人工智能是当前最炙手可热的技术之一，它已经在各个领域得到了广泛应用。人工智能技术通过模仿人类大脑的思维方式，使计算机具有了类似于人类的学习、推理、认知和创造能力。人工智能可以用来完成很多智能化的任务。比如图像和语音识别、自动驾驶、智能家居、智能客服等等。未来，人工智能将会更多地融入到我们的日常生活中，给我们带来更多的便利和创新。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `整体而言，从空间迁移的角度来说，机器人浪潮不会导致外来务工在城市间的流离失所。相反，机器人所释放的生产力能够创造出更多的工作岗位，与机器人在城市空间里实现人机共存。但从雇佣期限的角度来说，在机器人的冲击下，外来务工的雇佣合同在整体上呈现出短期化的趋势。这主要是因为，机器人渗透生产环节，在一定程度上重塑了传统技术与技能的匹配和粘性。劳动者基于企业的传统特定技能被削弱。也就是说，虽然外来务工能在相应的城市落脚，但难以长期稳定下来。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        }
      ],
      [
        {
          info: `近期，关于热门旅游目的地泰国的旅游安全问题受到热议。三月二十四日，澎湃新闻询问多家旅行社和在线旅游平台，均表示不存在“收到大量退单”的情况。可见的是，连日来，泰国官方频频发声。据泰国媒体三月二十一日报道，泰国总理府发言人透露，近期在中国社交平台上传播的有关“前往泰国旅游不安全”的消息不实。三月二十三日，泰王国驻华大使馆发布声明，泰国高度重视中国游客的旅游质量和安全。重申，自中华人民共和国允许中国游客出国旅游以来，泰国已做好接待中国游客到来的充分准备以及为中国游客增加了许多通信渠道，为游客求助当地警察通报紧急情况提供了便利与安全保障。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `王一凡，目前是西安科技大学高新学院-国际教育与人文学院的一名大四学生。他告诉记者，十一月底，他通过一个朋友转发到朋友圈的新闻，了解到陕西因为疫情多点散发，多地出现农产品流通不畅的情况。当时看到新闻，他就在想，现在年关将近了，蔬菜也不能一直保存下去，可能过段时间就都烂在地里了。他觉得如果有能力的话，应该去帮别人一把。为了更全面地了解农产品滞销的情况，王一凡专门下载了短视频APP，因为听说有很多菜农会在上面求助。但当时疫情防控，跨市运输蔬菜不太方便，他就找了一下西安本市的，先锁定了两个点，咸阳兴平县和西安高陵区。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `近日，有视频显示，安徽黄山风景区白云宾馆的大堂有多名游客滞留，并配文“黄山穷游之睡宾馆大堂”，引发网友热议。针对网友关注的该宾馆房价问题，黄山风景区工作人员解释说，因为所有建筑材料要运送上山，所有垃圾要运送下山，山上宾馆的住宿成本自然是比山下宾馆高出很多，但他们认为这个定价是合理合情的。该工作人员还表示，为确保游客的安全和舒适，不鼓励游客露宿山间，也不鼓励在宾馆大堂过夜。建议游客提前做好规划，合理安排行程。这种‘特种兵式旅游’现象，让管理者左右为难。目前已引起社会的关注和热议，我们也希望旅游业界的专家能给出更好的意见和建议。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `记者新近调查了解到，近年来出现一种网红医美医院，藏身于写字楼，甚至连门头招牌都没有，不打广告，也不借助美容美发等渠道揽客。而是凭借网红引流、图文种草，刻意规避广告监管。其营销人员为升单、拓客，肆意夸大医美效果，淡化手术风险，引发大量纠纷和投诉。天眼查显示，二零二二年江苏省相关部门监测到涉及这家医院的投诉有二十多起，远高于行业平均水平。院方坦承，他们的手术量大、投诉比较多，也常常被告上法庭，不过是患者对效果苛求所致。专业人士介绍，这类医美机构通常是通过网红种草引流，打擦边球规避医疗广告监管。专家建议，消费者要加强辨别、谨慎就诊，有关执法部门也需要创新监测手段、细化执法指引。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `记者从水利部长江委了解到，根据气象水文预报，预计未来一周，四月三日至四日，两湖水系及长江下游干流有中到大雨、局地暴雨的降水过程。五日至七日，长江流域有小雨、局地中雨。八日至九日，洞庭湖水系南部有中雨。甘肃省敦煌市气象台，四月三日十六时三十五分发布沙尘暴黄色预警信号。敦煌市已出现大风沙尘天气，预计今天下午至夜间，当地七里镇、阳关镇、黄渠镇、莫高镇、月牙泉、沙州镇、肃州镇、郭家堡等地将出现能见度小于一千米的沙尘暴天气。并伴有六至七级大风，阵风可达八至九级，请有关单位和人员做好防范准备。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        }
      ],
      [
        {
          info: `《小红帽》是《格林童话》故事中的一篇经典绘本故事。它讲述了一个快乐的小女孩-小红帽离开妈妈独自去外婆家送糕点的故事。小红帽在半路遇见了大灰狼，但是她并不认识它。忘记了妈妈的忠告，就轻易把外婆生病的事情告诉了大灰狼。结果大灰狼骗取了外婆的信任，迷惑了小红帽，把他们都吃掉了。幸亏遇到了经过的猎人，从而救出小红帽和外婆。小红帽没听妈妈的话，不仅离开了大路，还把外婆家的地址告诉了她完全不认识的大灰狼，结果惹来了一场大麻烦，险些丧命。这个故事告诉我们，在生活中，小朋友要遇事多动脑筋，学会保护自己。不要轻信陌生人的话、跟陌生人离开。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `天气冷得可怕，正在下雪，黑暗的夜幕开始垂下来了。这是这年最后的一夜，新年的前夕。在这样的寒冷和黑暗中，有一个瘦弱单薄，赤着脚的小女孩正在街上走着，手里的火柴一包也没有卖出去。她离开家的时候还穿着一双拖鞋，但那又有什么用呢？那是一双非常大、并不合脚的拖鞋。刚刚，当她匆忙地越过街道时，两辆马车飞奔过来，弄得小姑娘把鞋都跑掉了。有一只她怎么也找不到，还有一只又被一个淘气的小男孩儿捡到，拿着逃走了。小男孩儿还对他说，等他将来有孩子了，可以拿它做一个摇篮车。小女孩儿的小脚已经被冻得又红又肿了。她把许多火柴包在一个旧围裙里，手中还拿着一扎。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `她的一双小手几乎冻僵了。此时，哪怕一根小小的火柴对她也是有好处的。她犹豫了，要不要抽出一根来暖暖手！最后她抽出了一根，火柴燃起来了，冒出了火光！当她把手覆在上面的时候，瞬间变成了一朵温暖、光明的火焰，像是一根小小的蜡烛。这是一道美丽的小光！此刻小女孩儿觉得自己就像是坐在了一个真正的铁火炉边。它有着锃亮的黄铜圆把手和炉身，火烧得那么欢，那么暖，那么美！唉，这是怎么一回事儿？当小姑娘刚刚伸出一双脚，打算暖一暖脚的时候，火焰就忽然熄灭了！火炉也不见了。她坐在那儿，手中只有烧过了的火柴。于是，她又擦了一根，温暖的画面又出现了。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `有一个飞行员，他总说在大人世界找不到一个说话投机的人，因为大人都太讲实际了。六年前，他因飞机故障迫降在撒哈拉沙漠遇见了小王子。小王子来自另一个星球，陪伴他的只有一朵他非常喜爱的玫瑰花。但玫瑰花的虚荣心伤害了小王子，于是他离开了小行星，想寻找真正的朋友。他孤身一人去到各星球游历，见识到了愚蠢的国王、自负的人、酒鬼、商人、点灯人和地理学家 ，最终来到地球。小王子后来遇到一只小狐狸，并用耐心驯服了小狐狸，与它结成了亲密的朋友。在这过程中，他也愈加明白了人生的真谛，明白了真正朋友的含义。最终他在撒哈拉大沙漠找到了生命的泉水，并在蛇的帮助下离开了地球，重新回到了玫瑰花的身边。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `《花婆婆》之所以如此感人，是因为它具有饱满的诗性和美学价值，它是一部最动人的女性主义绘本。当鲁菲丝小姐成了一位风烛残年的老婆婆时，她告诉年轻的叙述者。许多年以前，当她还是一个名叫艾莉丝的小女孩的时候，她曾经答应过爷爷三件事。第一件事是去很远的地方旅行，第二件是住在海边，第三件是做一件让世界变得更美丽的事。前两件事不难，难的是第三件事。直到有一年的春天，她喜出望外地发现山坡上开满了一大片蓝色、紫色和粉红色的鲁冰花时，她知道什么是她要做的第三件事了。整个夏天，她的口袋里都装满了花种子，她把它们撒在了乡间的小路边、教堂后面……`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        }
      ],
      [
        {
          info: `喜欢吃荔枝但又怕燥热的人，在吃荔枝的同时，可多喝盐水。或用蜜枣一起煲水喝，可以预防上火。还可以把荔枝连皮浸入淡盐水中，放入冰柜里冰后食用。不仅不会上火，还可增加食欲。如果泡上一杯用干荔枝叶煎的荔枝茶，还可以解吃荔枝过多而产生的腹泻。荔枝属性温燥，阴虚火旺的人最好少吃，一次最好在零点二五公斤以内。如果正在长青春痘、生疮、伤风感冒或有急性炎症的朋友，则不适宜吃荔枝。此外选择合适的时段食用荔枝也很重要。由于荔枝性温，晚上吃荔枝容易影响睡眠质量，增加上火的风险。因此最好在早上或下午时段食用荔枝，同时避免空腹食用，以免刺激胃肠道。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `四大名著里面最被低估的是哪个？我觉得是西游记，不少人把它当成了一个玄幻冒险的故事。其实在清代，张潮就曾说西游记其实是一部悟书，更适合那些深陷迷雾需要醒悟的人。如果想真正读懂西游记，我们必须得明白，它其实和当时流行的王阳明心学密切相关。西游记的故事不是明朝才有，它的原型是唐太宗时期的《大唐西域记》。大约是宋末元初，出现了《大唐三藏取经诗话》，才有了猴行者的形象。所以西游记的故事，并不是吴承恩创作的，而是他改编的。原版中的主人公是玄奘，吴承恩改成了孙悟空。为一个原本是讲述求学冒险的故事，增加了明心见性、知行合一的内涵。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `所以我们平时练习的时候，也需要去体会这种放松自然的摆臂感觉。第二点呢就是落地，若你是跑个八百一千米的话，推荐大家用足中落地。或者是中前掌的部位去落地，不推荐脚后跟或者脚尖。那么相比落地的部位，更重要的是跑步落地时候的动作。落地点一定要尽可能的靠近自己身体重心投影的正下方。而且落地的时候，脚掌要微微带一点向后扒地的这种感觉。这个专业上叫做积极下压，是为了让我们的脚去配合地面的速度。如果我们的下压不够积极的话，脚的速度就会比地面的速度更慢一些，就很容易去产生刹车制动的这种现象。第三点，腿蹬离地面之后，就要尽快的迅速的拉起。方向是脚后跟向臀部的方向，时机差不多是脚后跟抬起之后。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `幸福的生活，藏在厨房烟火里。然而做饭时总觉得一团糟，做完饭心情更是炸裂。小编也是从坑里走出来，现如今的我对厨房清洁问题都是得心应手。为了拯救更多的厨房星人，小编决定放出武林秘籍，好东西是要一起分享的！收藏起来，相信你会用到的！两个厨房生活小妙招，招虽小但招招到位，一起来看看是不是？一是橙皮去水垢。视频前的你还在为水壶中的难以清除的水垢烦恼不堪吗？以后吃剩下的橙皮就可以发挥作用了。用橙皮加入水壶中，与水同煮，煮开后，水垢很容易就能擦掉。二是白萝卜清洁厨房台面。用切开的白萝卜搭配清洁剂擦洗厨房台面，将会产生意想不到的清洁效果。你也可以用切片的小黄瓜或胡萝卜代替，不过白萝卜的效果最佳。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `秋冬季节最风雅的事情啊，莫过于煮茶。比起冲泡，煮饮更能激发茶叶中的精华，出来的茶汤也更加的温润醇厚。但并不是所有的茶都能煮哦，比方说绿茶跟黄茶就绝对不能煮。因为它们喝的就是新鲜，长时间的高温煮饮会严重破坏茶的鲜嫩。咖啡碱也会大量的浸出，不仅茶汤会变黄，口感上也会又苦又涩。同样，新鲜的白茶也经不起煮饮，比方说新的白毫银针。白牡丹呢，如果用来煮的话，那完全就是在糟蹋好茶了。但是上了年份的贡眉、寿眉都可以适合用来煮的。因为老茶经过时间的转化，内在的物质已经相对平衡了。茶中的茶多酚、咖啡碱等物质也在逐渐的下降，煮出来的茶汤不会有苦涩感。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        }
      ],
      [
        {
          info: `今天我们要详细评测的车型，是这台吉利的星瑞。希望能够给喜欢它的同学带来一定的参考。先来看看外观啊，首先我觉得整个前脸设计呢还是蛮耐看的。黑色的logo配上一个盾形的直瀑式的前进气隔扇，相得益彰。两个小黑色方块是毫米波雷达，可以帮助你进行一个自动驾驶的辅助。机舱盖的线条非常的锐利，肌肉感就这么出来了。再配上这个前大灯，全LED的，加上这个日行灯一亮，锐利的眼神就出来了。来到侧面，十八寸的车轮，诚意满满。但是我觉得最好看的是在这里。一条横贯到尾部的腰线，配上这个溜背的线条，真的很不错。尾部设计我觉得中规中矩，虽然也是LED大灯，稍稍微翘的尾巴。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `然后双边单出的排气，也配了一定的扰流板，这个还是加强了一点运动感的。坐进车内，给我的第一印象，竟然是豪华感。蓝色和白色的双拼色的设计很有格调。其次是用料还是不错的，整个是翻毛皮的，包括侧面也是真皮带缝线的。中间的扶手箱也是翻毛皮的材质。其次就是双联屏的设计了，都是十二点三英寸的，中间这个会根据驾驶模式的改变进行改变。中间这个也是十二点三英寸的触控屏，反应速度还是非常不错的。可以车联网，网络还是比较稳定的。其次就是它的这个语音控制，我觉得还是蛮实用的。来到后排，它是有两米八的轴距，腿部空间和头部空间都不错。然后整个座椅比较软，也是真皮材质的。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `妆前乳它是比较适合干皮跟混合皮。有点偏粉的半透明膏体，特别好推开。它的主要作用就是滋润，干了之后会有点哑光的质地。大家就顺着自己毛孔的方向这样抹开就可以了，一些小的毛孔它是可以填平的。粉底液给大家选的是这个恒久粉底液，真的非常扒脸不脱妆。奶油质地，流动性一般，但是延展性非常好，很丝滑。它大面积铺开是带有微微的光泽感，粉质很细，非常适合油皮跟混油皮。干皮如果想用的话，一定要做好妆前保湿，要不然就会干。这种粉底液成膜很快，一定要用刷子快速的上脸，海绵蛋飞快的点开。因为它粉质很细，所以整体的妆感是比较清透的，鼻子间的毛孔几乎看不到。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `YSL的气垫粉底是一种以轻盈、透气的质地为主打的化妆品。能够快速遮盖瑕疵、提亮肤色、调节肤色，让肌肤看起来自然、明亮。与普通粉底相比，气垫粉底不会堵塞毛孔，更容易上妆。而且具有防晒效果，能够有效地保护肌肤免受紫外线伤害。YSL的气垫粉底更是采用了独特的气垫设计，让使用者更加方便，轻松掌握粉底的用量和均匀度。想要使用YSL的气垫粉底，需要先将粉扑轻轻压到气垫上，让粉底均匀地吸附到粉扑上。然后，将粉扑轻轻拍打于面部，直至粉底均匀涂抹。由于气垫粉底质地轻盈，所以可以适当叠加涂抹，以达到更好的遮盖效果。同时，也建议在使用气垫粉底前，先进行基础护肤，保证肌肤状态良好。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        },
        {
          info: `首先是黑松露小贝，浓浓的都是黑芝麻味，肉松是酥酥的。蛋糕体湿湿软软的，蛋香味儿很浓，就是一贯的小贝风格。它应该叫黑芝麻酱多多，跟黑松露一点关系都没有，味道是很好吃的。可可风味的酥松小贝，吃起来是纯甜的肉松口儿，有一点儿可可风味。这是一个让我觉得稍微有点突兀的组合。对我个人而言啊，我觉得算不上很好吃，如果喜欢纯甜口的话，可以去试一下哦。南瓜蛋黄酥，咸蛋黄很多，看起来沙沙的，南瓜还会有一点点拉丝。南瓜蓉细细绵绵的，淡淡的蜂蜜的味道，甜度不高，混合上咸蛋黄简直是绝了。酥皮做的层次非常不错，咬起来酥酥松松的，口感非常棒。`,
          audioData: null,
          audioUrl: null,
          status: "wating"
        }
      ]
    ];
    return soundList;
  },
  //数字仓所用到的视频和照片
  getFutureVideo() {
    const video = [
      {
        title: "交互数字人-产品优势-数字永生奶奶孙子",
        poster: "https://oss.iiii.com/web/digital-immortality-poster.jpg",
        url: "https://oss.iiii.com/web/digital-immortality.mp4"
      },
      {
        title: "优秀案例-数字人直播应用-教育直播",
        poster: "https://oss.iiii.com/web/teacher-live-poster.jpg",
        url: "https://oss.iiii.com/web/teacher-live-post.mp4"
      }
    ];
    return video;
  }
};
