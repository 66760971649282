<script setup lang="ts">
import { Star, InfoFilled } from "@element-plus/icons-vue";
import { Timer, Picture, VideoCamera, Film } from "@element-plus/icons-vue";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div class="min-h-screen bg-white py-12 md:py-24">
    <div class="container mx-auto px-4">
      <!-- 标题 -->
      <h2 class="text-2xl md:text-4xl font-bold text-center mb-8 md:mb-16">
        {{ t("welcome.AdvantagesLayout.productAdvantageComparison") }}
      </h2>

      <!-- 包裹容器 -->
      <div class="w-[90%] lg:w-[60vw] mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-20 relative">
          <!-- VS 标志 -->
          <div
            class="absolute z-[9] mt-[-1rem] lg:-mt-[0rem] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div
              class="w-16 md:w-24 h-16 md:h-24 rounded-full bg-gradient-to-r from-[var(--el-color-primary)] to-[var(--el-color-primary-stop)] flex items-center justify-center shadow-xl"
            >
              <span
                class="text-xl md:text-4xl font-bold text-white"
                style="font-family: 'Impact'"
                >VS</span
              >
            </div>
          </div>

          <!-- 左侧：数字人 -->
          <div class="relative p-4 md:p-8 rounded-2xl shadow-xl">
            <!-- 顶部色块 & 标题 -->
            <div
              class="absolute -top-0 rounded-2xl left-0 opacity-30 bg-gradient-to-br from-[var(--el-color-primary-stop)] w-full h-full to-white"
            ></div>
            <div
              class="relative z-1 bg-gradient-to-r from-[var(--el-color-primary)] to-[var(--el-color-primary-stop)] rounded-xl p-4 md:p-6 shadow-lg -mt-6 md:-mt-12 mb-4 md:mb-8"
            >
              <div
                class="flex text-yellow-400 items-center justify-center space-x-2"
              >
                <el-icon><Star /></el-icon>
                <span class="text-white font-bold text-lg md:text-2xl">
                  {{ useSystemConfigStoreHook().config.sysConfig.appName }}
                  {{ t("welcome.AdvantagesLayout.digitalHuman") }}
                </span>
              </div>
            </div>

            <!-- （1）相似度99% -->
            <div class="group relative">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105"
              >
                <div class="relative flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-[#f7f6f6] flex items-center justify-center transition-colors"
                  >
                    <!-- 图标 -->
                    <component
                      :is="VideoCamera"
                      class="text-[var(--el-color-primary)] text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-900">
                      {{ t("welcome.AdvantagesLayout.cloneSimilarity99") }}
                    </h3>
                    <div
                      class="relative flex px-3 py-0 justify-center items-center"
                    >
                      <span
                        class="absolute w-full h-full opacity-[0.15] bg-[--el-color-primary-stop] text-[#fff] rounded-full text-sm"
                      ></span>
                      <span
                        class="text-[--el-color-primary] rounded-full text-sm"
                      >
                        {{ t("welcome.AdvantagesLayout.similarity") }}
                      </span>
                    </div>
                  </div>
                  <p class="text-gray-600 text-sm md:text-base">
                    {{
                      t("welcome.AdvantagesLayout.mostRealisticDigitalHuman")
                    }}
                  </p>
                </div>
              </div>
            </div>

            <!-- （2）克隆速度 -->
            <div class="group relative">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105"
              >
                <div class="relative flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-[#f7f6f6] flex items-center justify-center transition-colors"
                  >
                    <component
                      :is="Timer"
                      class="text-[var(--el-color-primary)] text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-900">
                      {{ t("welcome.AdvantagesLayout.rapidCloning") }}
                    </h3>
                    <div
                      class="relative flex px-3 py-0 justify-center items-center"
                    >
                      <span
                        class="absolute w-full h-full opacity-[0.15] bg-[--el-color-primary-stop] text-[#fff] rounded-full text-sm"
                      ></span>
                      <span
                        class="text-[--el-color-primary] rounded-full text-sm"
                      >
                        {{ t("welcome.AdvantagesLayout.speed") }}
                      </span>
                    </div>
                  </div>
                  <p class="text-gray-600 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.oneMinuteRapidClone") }}
                  </p>
                </div>
              </div>
            </div>

            <!-- （3）多形象 -->
            <div class="group relative">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105"
              >
                <div class="relative flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-[#f7f6f6] flex items-center justify-center transition-colors"
                  >
                    <component
                      :is="Picture"
                      class="text-[var(--el-color-primary)] text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-900">
                      {{
                        t("welcome.AdvantagesLayout.multipleDigitalHumanImages")
                      }}
                    </h3>
                    <div
                      class="relative flex px-3 py-0 justify-center items-center"
                    >
                      <span
                        class="absolute w-full h-full opacity-[0.15] bg-[--el-color-primary-stop] text-[#fff] rounded-full text-sm"
                      ></span>
                      <span
                        class="text-[--el-color-primary] rounded-full text-sm"
                      >
                        {{ t("welcome.AdvantagesLayout.image") }}
                      </span>
                    </div>
                  </div>
                  <p class="text-gray-600 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.switchWhenever") }}
                  </p>
                </div>
              </div>
            </div>

            <!-- （4）高清+超高清 -->
            <div class="group relative">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105"
              >
                <div class="relative flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-[#f7f6f6] flex items-center justify-center transition-colors"
                  >
                    <component
                      :is="Film"
                      class="text-[var(--el-color-primary)] text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-900">
                      {{ t("welcome.AdvantagesLayout.hdAndUhdDoubleModel") }}
                    </h3>
                    <div
                      class="relative flex px-3 py-0 justify-center items-center"
                    >
                      <span
                        class="absolute w-full h-full opacity-[0.15] bg-[--el-color-primary-stop] text-[#fff] rounded-full text-sm"
                      ></span>
                      <span
                        class="text-[--el-color-primary] rounded-full text-sm"
                      >
                        {{ t("welcome.AdvantagesLayout.quality") }}
                      </span>
                    </div>
                  </div>
                  <p class="text-gray-600 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.switchQualityFreely") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- 右侧：其他家 -->
          <div
            class="bg-gradient-to-br from-gray-50 to-white rounded-2xl p-4 md:p-8 shadow-xl"
          >
            <!-- 顶部标题 -->
            <div
              class="bg-gray-500 rounded-xl p-4 md:p-6 shadow-lg -mt-6 md:-mt-12 mb-4 md:mb-8"
            >
              <div class="flex items-center justify-center space-x-2">
                <el-icon class="text-gray-300"><InfoFilled /></el-icon>
                <span class="text-white font-bold text-lg md:text-2xl">
                  {{ t("welcome.AdvantagesLayout.otherHouseDigitalHuman") }}
                </span>
              </div>
            </div>

            <!-- （1）相似度60% -->
            <div class="group">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md"
              >
                <div class="flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-gray-100 flex items-center justify-center"
                  >
                    <component
                      :is="VideoCamera"
                      class="text-gray-400 text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-700">
                      {{ t("welcome.AdvantagesLayout.cloneSimilarity60") }}
                    </h3>
                    <span
                      class="bg-gray-100 text-gray-500 rounded-full text-xs md:text-sm px-2 py-1"
                    >
                      {{ t("welcome.AdvantagesLayout.similarity") }}
                    </span>
                  </div>
                  <p class="text-gray-500 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.poorEffect") }}
                  </p>
                </div>
              </div>
            </div>

            <!-- （2）克隆速度较慢 -->
            <div class="group">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md"
              >
                <div class="flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-gray-100 flex items-center justify-center"
                  >
                    <component
                      :is="Timer"
                      class="text-gray-400 text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-700">
                      {{ t("welcome.AdvantagesLayout.slowCloneSpeed") }}
                    </h3>
                    <span
                      class="bg-gray-100 text-gray-500 rounded-full text-xs md:text-sm px-2 py-1"
                    >
                      {{ t("welcome.AdvantagesLayout.speed") }}
                    </span>
                  </div>
                  <p class="text-gray-500 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.typicallyTakes") }}
                  </p>
                </div>
              </div>
            </div>

            <!-- （3）仅单形象 -->
            <div class="group">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md"
              >
                <div class="flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-gray-100 flex items-center justify-center"
                  >
                    <component
                      :is="Picture"
                      class="text-gray-400 text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-700">
                      {{
                        t("welcome.AdvantagesLayout.singleDigitalHumanImage")
                      }}
                    </h3>
                    <span
                      class="bg-gray-100 text-gray-500 rounded-full text-xs md:text-sm px-2 py-1"
                    >
                      {{ t("welcome.AdvantagesLayout.image") }}
                    </span>
                  </div>
                  <p class="text-gray-500 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.onlyOneSet") }}
                  </p>
                </div>
              </div>
            </div>

            <!-- （4）仅单一质量 -->
            <div class="group">
              <div
                class="flex items-start gap-2 md:gap-4 bg-white rounded-xl p-4 md:p-6 shadow-md"
              >
                <div class="flex-shrink-0">
                  <div
                    class="w-8 md:w-12 h-8 md:h-12 rounded-full bg-gray-100 flex items-center justify-center"
                  >
                    <component
                      :is="Film"
                      class="text-gray-400 text-lg md:text-xl"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2 md:gap-3 mb-1 md:mb-2">
                    <h3 class="font-bold text-gray-700">
                      {{ t("welcome.AdvantagesLayout.singleModel") }}
                    </h3>
                    <span
                      class="bg-gray-100 text-gray-500 rounded-full text-xs md:text-sm px-2 py-1"
                    >
                      {{ t("welcome.AdvantagesLayout.quality") }}
                    </span>
                  </div>
                  <p class="text-gray-500 text-sm md:text-base">
                    {{ t("welcome.AdvantagesLayout.onlyOneQualityAvailable") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- END: 其他家 -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.el-icon {
  --el-icon-size: 1.25rem;
}
.group {
  margin-bottom: 2rem;
}
</style>
