export const avatarColors = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD',
  '#D4A5A5', '#9B59B6', '#3498DB', '#1ABC9C', '#F1C40F'
]

export const nameColors = [
  '#FF8C94', '#4A90E2', '#50C878', '#9B59B6', '#F39C12',
  '#E74C3C', '#16A085', '#8E44AD', '#2980B9', '#F1C40F'
]

export const getRandomColor = (colors: string[]) => {
  return colors[Math.floor(Math.random() * colors.length)]
}