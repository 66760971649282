<!-- filepath: /Users/haigen/Desktop/ts_workspace/iiii-web/src/components/DigitalHumanCard.vue -->

<script lang="ts" setup>
import { defineProps, ref } from "vue";
import { Check } from "@element-plus/icons-vue";
import { ItemScoreRechargeType } from "@/api";
import { Constants } from "@/config";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";

// 定义props
const props = defineProps<{
  item: ItemScoreRechargeType;
  selectItem: ItemScoreRechargeType;
  logoIsEmpty: Boolean;
}>();

const dollarSign = ref(useSystemConfigStoreHook().dollarSign);
if (props.item.id == Constants.Common.TEMP_ID) {
  dollarSign.value = null;
}
const getItemLogo = () => {
  const tempItem = Constants.logoOfTheProductFoSale.find(
    innerItem => innerItem.name == props?.item?.logo
  );
  const tempLogo =
    props.selectItem?.id == props.item.id ? tempItem?.activeKey : tempItem?.key;
  console.log("logo:", tempLogo);
  return tempLogo;
};
</script>
<template>
  <div class="h-full !w-[17.5rem]">
    <div class="flex flex-col gap-2">
      <div v-if="!logoIsEmpty" class="w-full flex justify-center">
        <component :is="getItemLogo()" />
      </div>
      <p class="text-[1.4rem] font-[OPPOSansH]">
        {{ item.desc }}
      </p>
      <div class="mb-[1.8rem]">
        <div
          class="w-full flex justify-center items-center text-[2rem] font-[OPPOSansH]"
        >
          <div class="text-primary">
            {{ dollarSign }}
            {{ item.discountPrice }}
          </div>
          <div
            v-if="item.price > item.discountPrice"
            class="ml-[0.5rem] text-[1rem]"
          >
            <del class="text-[#646464]">
              {{ dollarSign }}
              {{ item.price }}
            </del>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-[8px] lg:gap-5">
      <div
        class="flex flex-col items-center w-full"
        v-for="(childItem, index) in item.descList"
        :key="index"
      >
        <div class="w-[70%]">
          <div
            class="flex w-full justify-start text-start flex-row leading-[21px]"
          >
            <div class="text-[1.2rem] text-primary mr-[0.3rem] font-[900]">
              <el-icon><Check /></el-icon>
            </div>
            <span class="leading-[1.6rem]" v-html="childItem.desc" />
            <div
              v-if="childItem.info"
              @click="childItem.show = !childItem.show"
              class="flex flex-row flex-1 mr-[0.5rem] justify-end"
            >
              <IconifyIconOnline
                icon="ri:arrow-down-s-line"
                class="text-[16px]"
              />
            </div>
          </div>
          <div
            class="flex flex-row text-[0.9rem] justify-center items-center gap-[10px] leading-[41px]"
            v-if="childItem.show"
          >
            <p v-html="childItem.info" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 你的样式 */
</style>
