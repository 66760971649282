import { RouterNameEnum } from "@/config";
import { $t } from "@/plugins/i18n";
const Layout = () => import("@/layout/index.vue");

export default [
  {
    path: "/login",
    name: RouterNameEnum.Login,
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: $t("menus.hslogin"),
      showLink: false,
      rank: 101
    },
    children: [
      {
        path: "/login/:params",
        name: RouterNameEnum.Login,
        component: () => import("@/views/login/index.vue"),
        meta: {
          title: $t("menus.hslogin")
        }
      }
    ]
  },
  {
    path: "/digital-workbench",
    name: RouterNameEnum.DigitalWorkbench,
    component: () => import("@/views/operate/my-digital-workbench/index.vue"),
    meta: {
      title: "工作台",
      showLink: false
    }
  },
  {
    path: "/error/404",
    name: "404",
    component: () => import("@/views/error/404.vue"),
    meta: {
      title: $t("menus.hsfourZeroFour"),
      showLink: false
    }
  },
  {
    path: "/detail",
    name: RouterNameEnum.ImgDetail,
    component: () => import("@/views/ai-tools/deal-img/ImgDetail.vue"),
    meta: {
      title: $t("menus.hsfourZeroFour"),
      showLink: false
    }
  },
  {
    path: "/cleanUp",
    name: RouterNameEnum.ImgCleanUp,
    component: () => import("@/views/ai-tools/deal-img/ImgCleanUp.vue"),
    meta: {
      title: $t("menus.hsfourZeroFour"),
      showLink: false
    }
  },
  {
    path: "/agreement",
    name: RouterNameEnum.Agreement,
    component: () => import("@/views/agreement/index.vue"),
    meta: {
      title: "协议",
      showLink: false,
      rank: 103
    }
  },
  {
    path: "/redirect",
    component: Layout,
    meta: {
      icon: "HomeFilled",
      title: $t("menus.hshome"),
      showLink: false,
      rank: 102
    },
    children: [
      {
        path: "/redirect/:path(.*)",
        name: "Redirect",
        component: () => import("@/layout/redirect.vue")
      }
    ]
  }
] as Array<RouteConfigsTable>;
