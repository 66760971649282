<script setup lang="ts">
import { useRoute } from "vue-router";
import { CommonUtils } from "@/utils/commonUtils";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import { Constants } from "@/config";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { useI18n } from "vue-i18n";
import { hasLogin } from "@/router/utils";
const { t } = useI18n();
const { locale } = useTranslationLang();
const route = useRoute();
const gotoHelp = url => {
  window.open(url, "_blank");
};
</script>
<template>
  <div class="flex min-h-full bg-[#F3F3F3] pt-[2rem] pb-[1rem]">
    <div class="w-[5.5vw] flex flex-col items-center">
      <div v-for="item in Constants.optionsMenuItems" :key="item.path">
        <router-link :to="item.path" class="block" custom v-slot="{ navigate }">
          <div
            @click="
              item.path === '/operate/agent-chat/index'
                ? (hasLogin(), navigate())
                : navigate()
            "
            class="w-[5rem] h-[5rem] text-center text-[8px] lg:text-[14px] flex-col hover:bg-gray-200 ani-scale mb-4 rounded-lg flex items-center justify-center cursor-pointer transition-all duration-200"
            :class="[
              route.path === item.path ? 'text-white bgcorol' : ' text-black'
            ]"
          >
            <IconifyIconOnline :icon="item.icon" class="w-[2rem] h-[2rem]" />
            <div
              :class="{
                'text-[5px] lg:text-[9px]': locale == Constants.Lang.en
              }"
              class="mt-[0.3rem]"
            >
              {{ CommonUtils.getTranslateText(item.label, locale) }}
            </div>
          </div>
        </router-link>
      </div>
      <div
        v-if="useSystemConfigStoreHook().config.sysConfig.digitalHumanHelpUrl"
        @click="
          gotoHelp(
            useSystemConfigStoreHook().config.sysConfig.digitalHumanHelpUrl
          )
        "
        class="w-[5rem] h-[5rem] text-center text-[8px] lg:text-[14px] flex-col hover:bg-gray-200 ani-scale mb-4 rounded-lg flex items-center justify-center cursor-pointer transition-all duration-200"
      >
        <IconifyIconOnline icon="ix:book" class="w-[2rem] h-[2rem]" />
        <div
          :class="{
            'text-[5px] lg:text-[9px]': locale == Constants.Lang.en
          }"
          class="mt-[0.3rem]"
        >
          {{ t("aiTools.tutorial") }}
        </div>
      </div>
    </div>
    <div
      class="w-[93.5vw] mr-[1vw] bg-white bgshadow overflow-hidden rounded-2xl"
    >
      <router-view>
        <template #default="{ Component, route }">
          <el-scrollbar height="90vh">
            <component :is="Component" :key="route.fullPath" />
          </el-scrollbar>
        </template>
      </router-view>
    </div>
  </div>
</template>
<style scoped>
.bgcorol {
  background: linear-gradient(
    184deg,
    var(--el-color-primary-stop) -64%,
    var(--el-color-primary) 170%
  );
}
.bgshadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
}
</style>
