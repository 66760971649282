<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { controlOnlyOneAudioPlay } from "@/utils";
import { deviceDetection, isAllEmpty } from "@pureadmin/utils";
import {
  // 下拉框可选语言列表
  PublicLanguageCodeArr,
  VoicesEnum,
  SexEnum,
  PinnedFixKeyEnum,
  GlobalStateEnum
} from "@/config";
import { Search } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import MediaListComponets from "@/views/operate/MediaListComponets.vue";
// 获取声音 TS 类型
import { VoiceCloneType } from "@/api/types";
import { useOperatePlantWorkStore } from "@/store/modules/operatePlantWorkStore";
import { useCommonStore } from "@/store/modules/commonStore";
const { t } = useI18n();
const isMobile = deviceDetection();
// 文本转音频声音类别
const myVoicesOptions = [
  {
    label: t("createWork.footer.myVoice"),
    value: VoicesEnum.MY_VOICES
  },
  {
    label: t("createWork.footer.systemVoice"),
    value: VoicesEnum.SYS_VOICES
  }
];
// 性别下拉框数组 -1 全部 1 男 2  女
const sexOptions = ref([
  {
    value: SexEnum.ALL,
    label: t("createWork.footer.all")
  },
  {
    value: SexEnum.MALE,
    label: t("createWork.footer.male")
  },
  {
    value: SexEnum.FEMALE,
    label: t("createWork.footer.female")
  }
]);
// 音色对话 弹窗 显隐控制
const selectVoiceCloneDialogVisible = ref(false);

// 语言下拉框数组
const languageOptions = PublicLanguageCodeArr;

// 来源系统还是个人
const fromType = ref(VoicesEnum.SYS_VOICES);
const selectValue = ref("");
// 当前选中的语言，默认 zh-CN
const selectLanguageValue = ref("zh-CN");
// 当前选中的性别
const selectSexValue = ref(SexEnum.ALL);
// 当前根据下条件筛选的音色集合
const currentVoiceList = ref<Array<VoiceCloneType>>([]);
// 当前选中的音色id
const selectVoiceId = ref("");
// 列表项音频dom引用
const currentAudioRef = ref(null);
// 列表项音频播放控制对象
const currentAudioSrcRef = ref();

/**语言下拉框改变事件 */
const handleSelectChange = async () => {
  if (fromType.value === VoicesEnum.MY_VOICES) {
    currentVoiceList.value = await useOperatePlantWorkStore().getMyVoiceList(
      true
    );
  } else {
    currentVoiceList.value = await useOperatePlantWorkStore().getSysVoiceList(
      fromType.value,
      selectLanguageValue.value,
      selectSexValue.value,
      selectValue.value
    );
  }
};

const handleOpen = (from: VoicesEnum) => {
  selectVoiceId.value = useOperatePlantWorkStore().selectedVoice?.voiceId;
  selectVoiceCloneDialogVisible.value = true;
  fromType.value = from;
  handleSelectChange();
};
// 关闭弹窗
const handleClose = () => {
  selectVoiceCloneDialogVisible.value = false;
};

// 刷新列表,需要已经成功克隆的声音,已经克隆成功的声音才可以进行克隆
const refreshMediaList = (fixList: Array<VoiceCloneType>) => {
  currentVoiceList.value = useCommonStore()
    .updateFixedDataList(PinnedFixKeyEnum.VOICE, "voiceId", fixList)
    .filter(item => item.status == GlobalStateEnum.Success);
};
watch(
  () => useOperatePlantWorkStore().selectedVoice,
  newVal => {
    console.log("newVal", newVal);
    selectVoiceId.value = newVal?.voiceId;
  }
);

// 提交 声音选择信息
const handleSubmit = () => {
  selectVoiceCloneDialogVisible.value = false;
};

const gotoVoliceClone = () => {
  emit("gotoClone");
  selectVoiceCloneDialogVisible.value = false;
};
// 对外数据
defineExpose({
  handleOpen,
  handleClose
});
const emit = defineEmits(["gotoClone"]);
onMounted(() => {
  // 控制当前界面只有一个元素在播放
  controlOnlyOneAudioPlay();
});
</script>

<template>
  <el-dialog
    v-if="selectVoiceCloneDialogVisible"
    v-model="selectVoiceCloneDialogVisible"
    :width="isMobile ? '80%' : '1200px'"
    :top="isMobile ? '5vh' : '15vh'"
    @close="handleClose"
    class="speaker-dialog !rounded-xl"
  >
    <!-- 用来播放体验声音 -->
    <audio ref="currentAudioRef" :src="currentAudioSrcRef" />
    <template #header>
      <div>
        <!-- 选择声音类型栏系统声音和我的声音 -->
        <div class="w-full flex justify-center">
          <el-radio-group @change="handleSelectChange" v-model="fromType">
            <el-radio
              v-for="(item, index) in myVoicesOptions"
              :key="index"
              :value="item.value"
              :label="item.value"
            >
              {{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
    </template>
    <!-- 操作栏 -->
    <div class="px-[10px] py-[20px]">
      <el-row
        v-if="fromType !== VoicesEnum.MY_VOICES"
        class="flex items-center text-[12px] mb-[23px] font-bold gap-[10px]"
      >
        <el-col :span="isMobile ? 3 : 1" class="text-right lg:mr-[10px]">
          {{ t("createWork.footer.language") }}
        </el-col>
        <el-col :span="isMobile ? 18 : 6">
          <el-select
            class="w-[180px] lg:!w-[200px]"
            v-model="selectLanguageValue"
            :placeholder="t('createWork.footer.selectLanguage')"
            @change="handleSelectChange"
            size="small"
          >
            <el-option
              v-for="item in languageOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="isMobile ? 3 : 1" class="text-right lg:mr-[10px]">
          {{ t("createWork.footer.sex") }}
        </el-col>
        <el-col :span="isMobile ? 18 : 4" class="lg:mt-0">
          <el-radio-group
            size="small"
            v-model="selectSexValue"
            @change="handleSelectChange"
          >
            <el-radio-button
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-col>
        <el-col :span="isMobile ? 3 : 1" class="text-right lg:mr-[10px]">
          {{ t("workbench.searchModel") }}
        </el-col>
        <el-col :span="isMobile ? 18 : 6">
          <el-input
            size="small"
            class="w-[180px] lg:!w-[200px]"
            v-model="selectValue"
            @keyup.enter="handleSelectChange"
            :placeholder="t('digital-human-workbench.enterKeywordsToSearch')"
          >
            <template #append>
              <el-button
                size="small"
                @click="handleSelectChange"
                class="!w-[20px] !flex !justify-center !px-0"
                :icon="Search"
              />
            </template>
          </el-input>
        </el-col>
      </el-row>
      <div class="flex flex-row overflow-auto">
        <!-- 声音列表信息 -->
        <el-scrollbar class="flex flex-col w-full" height="50vh">
          <MediaListComponets
            :mediaList="currentVoiceList"
            :mediaType="fromType"
            @goto:cloneFile="gotoVoliceClone"
            @update:refreshMediaList="refreshMediaList"
          />
        </el-scrollbar>
      </div>
    </div>
    <div class="flex flex-row justify-end mt-[20px]">
      <el-button @click="selectVoiceCloneDialogVisible = false" size="small">{{
        t("common.cancel")
      }}</el-button>
      <el-button type="primary" @click="handleSubmit" size="small">
        {{ t("common.apply") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.speaker-dialog .el-dialog__body {
  padding: 0 25px 20px 25px;
}
</style>
<style lang="scss" scoped>
:deep(.el-tag--small) {
  height: 18px;
  border: 0.2px;
}
.btnColor {
  background: linear-gradient(
      184deg,
      var(--el-color-primary-stop) -64%,
      var(--el-color-primary) 170%
    ),
    var(--el-color-main-transform);
}
</style>
