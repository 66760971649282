import * as XLSX from "xlsx";
import FileSaver from "file-saver";
export const controlOnlyOneVideoPlay = () => {
  const videoObjs = document.getElementsByTagName("video");
  console.log("videoObjs:", videoObjs.length);
  const videoObjLength = videoObjs.length;
  if (videoObjLength > 0) {
    for (let i = 0; i < videoObjLength; i++) {
      videoObjs[i].addEventListener("play", () => {
        for (let j = 0; j < videoObjLength; j++) {
          if (i != j) {
            videoObjs[j].pause();
          }
        }
      });
    }
  }
};
export const controlOnlyOneAudioPlay = () => {
  const audioObjs = document.getElementsByTagName("audio");
  console.log("audioObjs:", audioObjs.length);

  const audioObjLength = audioObjs.length;
  if (audioObjLength > 0) {
    for (let i = 0; i < audioObjLength; i++) {
      audioObjs[i].addEventListener("play", () => {
        for (let j = 0; j < audioObjLength; j++) {
          if (i != j) {
            audioObjs[j].pause();
          }
        }
      });
    }
  }
};

export const pauseAllAudio = () => {
  const audioObjs = document.getElementsByTagName("audio");
  const videoObjLength = audioObjs.length;
  if (videoObjLength > 0) {
    for (let i = 0; i < videoObjLength; i++) {
      audioObjs[i].pause();
    }
  }
};

export const pauseAllVideos = () => {
  const videoObjs = document.getElementsByTagName("video");
  const videoObjLength = videoObjs.length;
  if (videoObjLength > 0) {
    for (let i = 0; i < videoObjLength; i++) {
      videoObjs[i].pause();
    }
  }
};
export const pauseCurrentVideo = (index: number) => {
  const videoObjs = document.getElementsByTagName("video");
  const videoObjLength = videoObjs.length;
  if (videoObjLength > 0) {
    for (let i = 0; i < videoObjLength; i++) {
      if (i === index) {
        // Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
        // videoObjs[index].play();
      } else {
        videoObjs[i].pause();
      }
    }
  }
};


export const exportExcel = (tableId: string) => {
  const wb = XLSX.utils.table_to_book(document.querySelector(`#${tableId}`)); //关联dom节点
  /* get binary string as output */
  const wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array"
  });
  try {
    FileSaver.saveAs(
      new Blob([wbout], {
        type: "application/octet-stream"
      }),
      "激活表.xlsx"
    );
  } catch (e) {
    if (typeof console !== "undefined") console.log(e, wbout);
  }
  return wbout;
};
