// import "@/utils/sso";
import { getConfig, Lang } from "@/config";
import { NProgress } from "@/utils";
import { usePermissionStoreHook } from "@/store/modules/permission";
import { useAppStoreHook } from "@/store/modules/app";
import {
  Router,
  createRouter,
  RouteRecordRaw,
  RouteComponent,
  createWebHistory
} from "vue-router";
import {
  ascending,
  isOneOfArray,
  handleAliveRoute,
  formatTwoStageRoutes,
  formatFlatteningRoutes,
  initRouter,
  findRouteByPath,
  getTopMenu
} from "./utils";
import { buildHierarchyTree } from "@/utils";
import { isAllEmpty, isUrl, openLink, storageLocal } from "@pureadmin/utils";

import remainingRouter from "./modules/remaining";
import { emitter } from "@/utils";
import axios from "axios";
import { useMultiTagsStoreHook } from "@/store/modules/multiTags";
export const sessionKey = "futureai-version";
import { CommonUtils } from "@/utils/commonUtils";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { transformI18n } from "@/plugins/i18n";

/** 自动导入全部静态路由，无需再手动引入！匹配 src/router/modules 目录（任何嵌套级别）中具有 .ts 扩展名的所有文件，除了 remaining.ts 文件
 * 如何匹配所有文件请看：https://github.com/mrmlnc/fast-glob#basic-syntax
 * 如何排除文件请看：https://cn.vitejs.dev/guide/features.html#negative-patterns
 */
const modules: Record<string, any> = import.meta.glob(
  ["./modules/**/*.ts", "!./modules/**/remaining.ts"],
  {
    eager: true
  }
);

/** 原始静态路由（未做任何处理） */
const routes = [];

Object.keys(modules).forEach(key => {
  routes.push(modules[key].default);
});

/** 导出处理后的静态路由（三级及以上的路由全部拍成二级） */
export const constantRoutes: Array<RouteRecordRaw> = formatTwoStageRoutes(
  formatFlatteningRoutes(buildHierarchyTree(ascending(routes)))
);

/** 用于渲染菜单，保持原始层级 */
export const constantMenus: Array<RouteComponent> = ascending(routes).concat(
  ...remainingRouter
);

/** 不参与菜单的路由 */
export const remainingPaths = Object.keys(remainingRouter).map(v => {
  return remainingRouter[v].path;
});

/** 创建路由实例 */
export const router: Router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes.concat(...(remainingRouter as any)),
  strict: true,
  //vue router的 scrollBehavior 只能控制原生的scrollbar 的行为
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        return savedPosition;
      } else {
        if (from.meta.saveScrollTop) {
          const top: number =
            document.documentElement.scrollTop || document.body.scrollTop;
          resolve({ left: 0, top: top });
        }
      }
    });
  }
});

/** 重置路由 */
export function resetRouter() {
  router.getRoutes().forEach(route => {
    const { name, meta } = route;
    if (name && router.hasRoute(name) && meta?.backstage) {
      router.removeRoute(name);
      router.options.routes = formatTwoStageRoutes(
        formatFlatteningRoutes(buildHierarchyTree(ascending(routes)))
      );
    }
  });
  usePermissionStoreHook().clearAllCachePage();
}
// 检查服务端是否已经更新，如果更新刷新页面
async function checkAppNewVersion() {
  const url = `/version.json?t=${Date.now()}`;
  let res = null;
  try {
    res = await axios.get(url);
  } catch (err) {
    console.error("checkAppNewVersion error: ", err);
  }
  if (!res) return;
  const version = res.data.version;

  const localVersion = storageLocal().getItem(sessionKey);
  if (localVersion && localVersion !== version) {
    storageLocal().setItem(sessionKey, version);
    window.location.reload();
  }
  storageLocal().setItem(sessionKey, version);
}
/** 路由白名单 */
const whiteList = ["/login"];
router.beforeEach(async (to: ToRouteType, _from, next) => {
  // 检查版本更新
  if (_from.path !== "/" && import.meta.env.PROD) {
    checkAppNewVersion();
  }

  // 需要登陆的页面切换到混合布局
  if (
    to.fullPath.indexOf("/admin") !== -1 ||
    to.fullPath.indexOf("/capcut") !== -1 ||
    to.fullPath.indexOf("/image-square") !== -1 ||
    to.fullPath.indexOf("/image-ai") !== -1
  ) {
    if (useAppStoreHook().layout === "horizontal") {
      emitter.emit("changeLayoutMode", "mix");
      emitter.emit("tagViewsChange", "false");
      console.log("当前是horizontal切换到mix");
    } else {
      // console.log("在混合布局的页面之间相互跳转不切换布局");
    }
  } else {
    if (useAppStoreHook().layout === "mix") {
      emitter.emit("changeLayoutMode", "horizontal");
      emitter.emit("tagViewsChange", "true");
      console.log("当前是mix切换到horizontal");
    } else {
      // console.log("在横向布局的页面之间相互跳转不切换布局");
    }
  }
  // 创建作品页面不需要侧边栏
  if (to.name === "CreateWork") {
    emitter.emit("changeLayoutMode", "horizontal");
    emitter.emit("tagViewsChange", "true");
  }
  if (to.meta?.keepAlive) {
    handleAliveRoute(to, "add");
    // 页面整体刷新和点击标签页刷新
    if (_from.name === undefined || _from.name === "Redirect") {
      handleAliveRoute(to);
    }
  }
  const userInfo = CommonUtils.getUserInfo();
  NProgress.start();
  const externalLink = isUrl(to?.name as string);
  if (!externalLink) {
    to.matched.some(item => {
      if (!item.meta.title) return "";
      let lang: Lang = useSystemConfigStoreHook()?.lang;
      lang = lang ? lang : Lang.LAN_CN;
      const Title = getConfig().Title;
      if (Title) {
        if (CommonUtils.isJsonString(item.meta.title)) {
          document.title = `${CommonUtils.getTranslateText(
            item.meta.title,
            lang
          )}
      | ${Title}`;
        } else {
          document.title = `${transformI18n(item.meta.title)} | ${Title}`;
        }
      } else {
        if (CommonUtils.isJsonString(item.meta.title)) {
          document.title = CommonUtils.getTranslateText(item.meta.title, lang);
        } else {
          document.title = transformI18n(item.meta.title as string);
        }
      }
    });
  }
  /** 如果已经登录并存在登录信息后不能跳转到路由白名单，而是继续保持在当前页面 */
  function toCorrectRoute() {
    whiteList.includes(to.fullPath) ? next(_from.fullPath) : next();
  }
  console.log("router  beaforeeach", userInfo);
  if (userInfo) {
    // 无权限跳转403页面
    if (to.meta?.roles && !isOneOfArray(to.meta?.roles, userInfo?.roles)) {
      next({ path: "/error/403" });
      return; // 确保后续代码不执行
    }
    // 开启隐藏首页后在浏览器地址栏手动输入首页welcome路由则跳转到404页面
    // if (VITE_HIDE_HOME === "true" && to.fullPath === "/welcome") {
    //   next({ path: "/error/404" });
    // }
    if (_from?.name) {
      // name为超链接
      if (externalLink) {
        openLink(to?.name as string);
        NProgress.done();
        next(false); // 终止导航
        return; // 确保后续代码不执行
      } else {
        console.log("正常走这里-----");
        toCorrectRoute();
      }
    } else {
      console.log("刷新走这类-----");
      // 刷新
      if (
        usePermissionStoreHook().wholeMenus.length === 0 &&
        to.path !== "/login"
      ) {
        initRouter().then((router: Router) => {
          if (!useMultiTagsStoreHook().getMultiTagsCache) {
            const { path } = to;
            const route = findRouteByPath(
              path,
              router.options.routes[0].children
            );
            getTopMenu(true);
            // query、params模式路由传参数的标签页不在此处处理
            if (route && route.meta?.title) {
              if (isAllEmpty(route.parentId) && route.meta?.backstage) {
                // 此处为动态顶级路由（目录）
                const { path, name, meta } = route.children[0];
                useMultiTagsStoreHook().handleTags("push", {
                  path,
                  name,
                  meta
                });
              } else {
                const { path, name, meta } = route;
                useMultiTagsStoreHook().handleTags("push", {
                  path,
                  name,
                  meta
                });
              }
            }
          }
          // 确保动态路由完全加入路由列表并且不影响静态路由（注意：动态路由刷新时router.beforeEach可能会触发两次，第一次触发动态路由还未完全添加，第二次动态路由才完全添加到路由列表，如果需要在router.beforeEach做一些判断可以在to.name存在的条件下去判断，这样就只会触发一次）
          if (isAllEmpty(to.name)) router.push(to.fullPath);
        });
      }
      // else {
      //   //当是已经登录了，有用户信息点击到用户登录页面，跳到wellcome
      //   next({ path: "/welcome" });
      // }
      toCorrectRoute();
    }
  } else {
    if (to.path !== "/login") {
      console.log("角色信息", to.meta?.roles);
      // if (whiteList.indexOf(to.path) !== -1) {
      // 没有角色信息
      if (!to.meta?.roles) {
        console.log("菜单信息", usePermissionStoreHook().wholeMenus);
        let flag = false;
        // 没有菜单
        if (usePermissionStoreHook().wholeMenus.length === 0) {
          // console.log(11111)
          await initRouter();
          flag = true;
          // usePermissionStoreHook().handleWholeMenus([]);
        }
        // ImageAI页面是需要看到菜单栏但是点击之后，未登录需要跳转登陆
        if (to.path === "/image-ai/index" || to.path.startsWith("/ai-tools")) {
          console.log("路由拦截", to.path);
          next({ path: "/login" });
        } else {
          if (_from?.name) {
            // name为超链接
            if (externalLink) {
              openLink(to?.name as string);
              NProgress.done();
            } else {
              next();
            }
          } else {
            flag ? next({ path: to.path, replace: true }) : next();
          }
        }
      } else {
        next({ path: "/login" });
      }
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  emitter.emit("scrollTop");
  NProgress.done();
});

export default router;
