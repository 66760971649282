// import speaker01 from "@/assets/speaker/speaker-01.png";
import speaker02 from "@/assets/speaker/speaker-02.png";
import speaker03 from "@/assets/speaker/speaker-03.png";
import speaker04 from "@/assets/speaker/speaker-04.png";
import speaker05 from "@/assets/speaker/speaker-05.png";
import speaker06 from "@/assets/speaker/speaker-06.png";
import speaker07 from "@/assets/speaker/speaker-07.png";
import speaker08 from "@/assets/speaker/speaker-08.png";
import speaker09 from "@/assets/speaker/speaker-09.png";
import speaker10 from "@/assets/speaker/speaker-10.png";
import speaker11 from "@/assets/speaker/speaker-11.png";
import speaker12 from "@/assets/speaker/speaker-12.png";
import speaker13 from "@/assets/speaker/speaker-13.png";
import speaker14 from "@/assets/speaker/speaker-14.png";
import speaker15 from "@/assets/speaker/speaker-15.png";
import speaker16 from "@/assets/speaker/speaker-16.png";
import speaker17 from "@/assets/speaker/speaker-17.png";
import speaker18 from "@/assets/speaker/speaker-18.png";
import speaker19 from "@/assets/speaker/speaker-19.png";
import speaker20 from "@/assets/speaker/speaker-20.png";
import speaker21 from "@/assets/speaker/speaker-21.png";
import speaker22 from "@/assets/speaker/speaker-22.png";
import speaker23 from "@/assets/speaker/speaker-23.png";
import speaker24 from "@/assets/speaker/speaker-24.png";
const speakerAvatarList = [
  // speaker01,
  speaker02,
  speaker03,
  speaker04,
  speaker05,
  speaker06,
  speaker07,
  speaker08,
  speaker09,
  speaker10,
  speaker11,
  speaker12,
  speaker13,
  speaker14,
  speaker15,
  speaker16,
  speaker17,
  speaker18,
  speaker19,
  speaker20,
  speaker21,
  speaker22,
  speaker23,
  speaker24
];
// const countries = {
//   "zh-CN": "中文(简体)",
//   "zh-HK": "中文(香港)",
//   "zh-TW": "中文(台湾)",
//   "en-US": "英语(美国)",
//   "en-GB": "英语(英国)",
//   "de-DE": "德语(德国)",
//   "fr-FR": "法语(法国)",
//   "ru-RU": "俄语(俄罗斯)",
//   "ja-JP": "日语(日本)",
//   "el-GR": "希腊语(希腊)",
//   "ko-KR": "韩语(韩国)",
//   "th-TH": "泰语(泰国)",
//   "es-ES": "西班牙语(西班牙)",
//   "fil-PH": "菲律宾语(菲律宾)",
//   "he-IL": "希伯来语(以色列)",
//   "it-IT": "意大利语(意大利)",
//   "ms-MY": "马来语(马来西亚)",
//   "pt-BR": "葡萄牙语(巴西)",
//   "pt-PT": "葡萄牙语(葡萄牙)",
//   "ar-SA": "阿拉伯语(沙特阿拉伯)",
//   "id-ID": "印度尼西亚语(印度尼西亚)",
//   "ar-AE": "阿拉伯语(阿拉伯联合酋长国)"
// };
const RoleObjList = [
  { label: "全部", value: "" },
  { label: "女孩", value: "Girl" },
  { label: "男孩", value: "Boy" },
  { label: "女青年", value: "YoungAdultFemale" },
  { label: "男青年", value: "YoungAdultMale" },
  { label: "女中年", value: "OlderAdultFemale" },
  { label: "男中年", value: "OlderAdultMale" },
  { label: "女老年", value: "SeniorFemale" },
  { label: "男老年", value: "SeniorMale" }
];
// const StyleDescription = {
//   advertisement_upbeat: "用兴奋和精力充沛的语气推广产品或服务。",
//   affectionate:
//     "以较高的音调和音量表达温暖而亲切的语气。 说话者处于吸引听众注意力的状态。 说话者的个性往往是讨喜的。",
//   angry: " 表达生气和厌恶的语气。",
//   assistant: "数字助理用的是热情而轻松的语气。",
//   calm: "以沉着冷静的态度说话。 语气、音调和韵律与其他语音类型相比要统一得多。",
//   chat: " 表达轻松随意的语气。",
//   cheerful: " 表达积极愉快的语气。",
//   customerservice: "以友好热情的语气为客户提供支持。",
//   depressed: "调低音调和音量来表达忧郁、沮丧的语气。",
//   disgruntled: "表达轻蔑和抱怨的语气。 这种情绪的语音表现出不悦和蔑视。",
//   "documentary-narration":
//     "用一种轻松、感兴趣和信息丰富的风格讲述纪录片，适合配音纪录片、专家评论和类似内容。",
//   embarrassed: "在说话者感到不舒适时表达不确定、犹豫的语气。",
//   empathetic: " 表达关心和理解。",
//   envious: " 当你渴望别人拥有的东西时，表达一种钦佩的语气。",
//   excited:
//     " 表达乐观和充满希望的语气。 似乎发生了一些美好的事情，说话人对此非常满意。",
//   fearful:
//     "以较高的音调、较高的音量和较快的语速来表达恐惧、紧张的语气。 说话人处于紧张和不安的状态。",
//   friendly: " 表达一种愉快、怡人且温暖的语气。 听起来很真诚且满怀关切。",
//   gentle: " 以较低的音调和音量表达温和、礼貌和愉快的语气。",
//   hopeful: "表达一种温暖且渴望的语气。 听起来像是会有好事发生在说话人身上。",
//   lyrical: " 以优美又带感伤的方式表达情感。",
//   "narration-professional": " 以专业、客观的语气朗读内容。",
//   "narration-relaxed": "为内容阅读表达一种舒缓而悦耳的语气。",
//   newscast: "以正式专业的语气叙述新闻。",
//   "newscast-casual": "以通用、随意的语气发布一般新闻。",
//   "newscast-formal": " 以正式、自信和权威的语气发布新闻。",
//   "poetry-reading": "在读诗时表达出带情感和节奏的语气。",
//   sad: " 表达悲伤语气。",
//   serious:
//     "表达严肃和命令的语气。 说话者的声音通常比较僵硬，节奏也不那么轻松。",
//   shouting: " 就像从遥远的地方说话或在外面说话，但能让自己清楚地听到",
//   sports_commentary: "用轻松有趣的语气播报体育赛事。",
//   sports_commentary_excited: "用快速且充满活力的语气播报体育赛事精彩瞬间。",
//   whispering: "说话非常柔和，发出的声音小且温柔",
//   terrified:
//     "表达一种非常害怕的语气，语速快且声音颤抖。 听起来说话人处于不稳定的疯狂状态。",
//   unfriendly: " 表达一种冷淡无情的语气。"
// };

// const StyleObjList = [
//   { label: "全部", value: "" },
//   { label: "积极推广", value: "advertisement_upbeat" },
//   { label: "温情亲切", value: "affectionate" },
//   { label: "生气", value: "angry" },
//   { label: "数字助理", value: "assistant" },
//   { label: "冷静", value: "calm" },
//   { label: "随意聊天", value: "chat" },
//   { label: "愉悦", value: "cheerful" },
//   { label: "客户服务", value: "customerservice" },
//   { label: "沮丧", value: "depressed" },
//   { label: "不满", value: "disgruntled" },
//   { label: "纪录片旁白", value: "documentary-narration" },
//   { label: "尴尬", value: "embarrassed" },
//   { label: "同理心", value: "empathetic" },
//   { label: "羡慕", value: "envious" },
//   { label: "兴奋", value: "excited" },
//   { label: "恐惧", value: "fearful" },
//   { label: "友好", value: "friendly" },
//   { label: "温和", value: "gentle" },
//   { label: "充满希望", value: "hopeful" },
//   { label: "抒情", value: "lyrical" },
//   { label: "专业朗读", value: "narration-professional" },
//   { label: "悦耳自然", value: "narration-relaxed" },
//   { label: "新闻叙述", value: "newscast" },
//   { label: "随意新闻", value: "newscast-casual" },
//   { label: "正式新闻", value: "newscast-formal" },
//   { label: "诗歌朗诵", value: "poetry-reading" },
//   { label: "悲伤", value: "sad" },
//   { label: "严肃", value: "serious" },
//   { label: "大声喊叫", value: "shouting" },
//   { label: "体育赛事评论", value: "sports_commentary" },
//   { label: "兴奋体育赛事评论", value: "sports_commentary_excited" },
//   { label: "低语", value: "whispering" },
//   { label: "恐慌", value: "terrified" },
//   { label: "冷漠", value: "unfriendly" }
// ];
const StyleObjList = [
  { label: "全部", value: "" },
  { label: "助理", value: "assistant" },
  { label: "聊天", value: "chat" },
  { label: "顾客服务", value: "customerservice" },
  { label: "新闻播报", value: "newscast" },
  { label: "深情", value: "affectionate" },
  { label: "生气", value: "angry" },
  { label: "冷静", value: "calm" },
  { label: "欢快", value: "cheerful" },
  { label: "不满", value: "disgruntled" },
  { label: "恐惧", value: "fearful" },
  { label: "温柔", value: "gentle" },
  { label: "抒情", value: "lyrical" },
  { label: "悲伤", value: "sad" },
  { label: "严肃", value: "serious" },
  { label: "诗歌朗诵", value: "poetry-reading" },
  { label: "友好", value: "friendly" },
  { label: "叙述-放松", value: "narration-relaxed" },
  { label: "尴尬", value: "embarrassed" },
  { label: "郁闷", value: "depressed" },
  { label: "体育评论", value: "sports-commentary" },
  { label: "体育评论-兴奋", value: "sports-commentary-excited" },
  { label: "记录片", value: "documentary" },
  { label: "叙述-专业", value: "narration-professional" },
  { label: "休闲新闻", value: "newscast-casual" },
  { label: "羡慕", value: "envious" },
  { label: "积极推广", value: "advertisement-upbeat" },
  { label: "纪录片旁白", value: "documentary-narration" },
  { label: "默认", value: "general" },
  { label: "兴奋", value: "excited" },
  { label: "恐惧", value: "terrified" },
  { label: "大喊", value: "shouting" },
  { label: "不友好", value: "unfriendly" },
  { label: "低语", value: "whispering" },
  { label: "充满希望", value: "hopeful" },
  { label: "新闻正式", value: "newscast-formal" },
  { label: "有同情心", value: "empathetic" },
  { label: "讲述", value: "narration" },
  { label: "嫉妒", value: "envy" },
  { label: "广告积极", value: "advertisement_upbeat" }
];

// const RoleDescription = {
//   Girl: "声音模仿女孩。",
//   Boy: "声音模仿男孩。",
//   YoungAdultFemale: "声音模仿年轻的成年女性。",
//   YoungAdultMale: " 声音模仿年轻的成年男性。",
//   OlderAdultFemale: "声音模仿年长的成年女性。",
//   OlderAdultMale: "声音模仿年长的成年男性。",
//   SeniorFemale: " 声音模仿年老女性。",
//   SeniorMale: "声音模仿年老男性。"
// };

// const RoleList = {
//   Girl: "女孩",
//   Boy: "男孩",
//   YoungAdultFemale: "女青年",
//   YoungAdultMale: "男青年",
//   OlderAdultFemale: "女中年",
//   OlderAdultMale: "男中年",
//   SeniorFemale: "女老年",
//   SeniorMale: "男老年"
// };
class Person {
  Name: string;
  DisplayName: string;
  LocalName: string;
  voiceId: string;
  Gender: string;
  Locale: string;
  LocaleName: string;
  SampleRateHertz: string;
  VoiceType: string;
  Status: string;
  WordsPerMinute: string;
  RolePlayList: Array<string>;
  StyleList: Array<string>;
}

/**
 *
 * @param params 获取最终的筛选结果
 * @returns
 */
// const filterByLanguage = (
//   data,
//   params: {
//     language: string;
//     sex?: string;
//     role?: string;
//     style?: string;
//   }
// ): Array<Person> => {
//   let dataList = [];
//   dataList = data.filter(item => item.Locale == params.language);
//   dataList = dataList.filter(item => !params.sex || item.Gender == params.sex);
//   dataList = dataList.filter(
//     item =>
//       !params.role ||
//       (item.RolePlayList && item.RolePlayList.includes(params.role))
//   );
//   dataList = dataList.filter(
//     item =>
//       !params.style || (item.StyleList && item.StyleList.includes(params.style))
//   );
//   return dataList;
// };

/**
 *
 * @param params 获取最终的筛选结果
 * @returns
 */
const filterByVoiceId = (data: any, voiceId: string): any => {
  let dataList = [];
  dataList = data.filter(item => item.ShortName == voiceId);
  return dataList[0];
};
// console.log(languageList.length);

// const countryObjList = (): Map<string, any> => {
//   const map = new Map();
//   data.reduce((a, item) => {
//     map.set(item.Locale, {
//       value: item.Locale,
//       label: countries[item.Locale]
//     });
//     return a;
//   }, {});
//   return map;
// };
/**
 * 
 * JavaScript中的Map和普通对象（也称为哈希表）都是用于存储键值对的数据结构，它们可以通过键（key）来获取值（value）。它们的区别在于：

键的类型：普通对象的键必须是字符串或符号类型，而Map的键可以是任何JavaScript类型，包括对象、数组、甚至是函数等。

键的顺序：普通对象的键是无序的，即它们的顺序是不确定的；而Map的键是有序的，即键的顺序与添加顺序一致。

内存占用：普通对象是通过哈希表实现的，它的存储方式是稀疏的，即只有存储了键值对的位置才会占用内存；而Map是通过红黑树实现的，它的存储方式是密集的，即每个节点都会占用内存，因此对于大量数据的存储，Map可能会占用更多的内存。

在实际应用中，如果您需要使用非字符串类型的键或有序的键，或者需要对大量数据进行操作，建议使用Map。而如果您只需要使用字符串类型的键并且数据量较小，使用普通对象即可。
 * 
 */
// const countryObjList2 = (): any => {
//   return data.reduce((a, item) => {
//     a[item.Locale] = { value: item.Locale, label: countries[item.Locale] };
//     return a;
//   }, {});
// };
const getRandomSpeakerAvatar = () => {
  const randomIndex = Math.floor(Math.random() * speakerAvatarList.length);
  return speakerAvatarList[randomIndex];
};

export {
  StyleObjList,
  RoleObjList,
  //filterByLanguage,
  getRandomSpeakerAvatar,
  filterByVoiceId
};
// console.log(Array.from(countryObjList().values()));
// console.log(countryObjList2());
