<script setup lang="ts">
import { ref, computed } from "vue";
import CommentRow from "./CommentRow.vue";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
const appName = useSystemConfigStoreHook().config.sysConfig.appName;
const firstNames = [
  "张",
  "Smith",
  "李",
  "Johnson",
  "赵",
  "Williams",
  "钱",
  "Brown",
  "孙",
  "Jones",
  "周",
  "Garcia",
  "吴",
  "Miller",
  "郑",
  "Davis",
  "陈",
  "Rodriguez",
  "林",
  "Martinez",
  "黄",
  "Hernandez",
  "刘",
  "Lopez",
  "胡",
  "Gonzalez",
  "杨",
  "Wilson",
  "王",
  "Anderson",
  "李",
  "Smith",
  "赵",
  "Johnson",
  "钱",
  "Williams",
  "孙",
  "Brown",
  "周",
  "Jones",
  "吴",
  "Garcia",
  "郑",
  "Miller",
  "陈",
  "Davis",
  "林",
  "Rodriguez",
  "黄",
  "Martinez",
  "刘",
  "Hernandez",
  "胡",
  "Lopez",
  "杨",
  "Gonzalez",
  "王",
  "Wilson",
  "李",
  "Anderson",
  "张",
  "Smith",
  "赵",
  "Johnson",
  "钱",
  "Williams",
  "孙",
  "Brown",
  "周",
  "Jones",
  "吴",
  "Garcia",
  "郑",
  "Miller",
  "陈",
  "Davis",
  "林",
  "Rodriguez",
  "黄",
  "Martinez",
  "刘",
  "Hernandez",
  "胡",
  "Lopez",
  "杨",
  "Gonzalez",
  "王",
  "Wilson",
  "李",
  "Anderson",
  "张",
  "Smith",
  "赵",
  "Johnson",
  "钱",
  "Williams",
  "孙",
  "Brown",
  "周",
  "Jones",
  "吴",
  "Garcia",
  "郑",
  "Miller",
  "陈",
  "Davis",
  "林",
  "Rodriguez",
  "黄",
  "Martinez",
  "刘",
  "Hernandez",
  "胡",
  "Lopez",
  "杨",
  "Gonzalez",
  "王",
  "Wilson"
];

const contents = [
  `${appName}的数字人效果太自然了，完全超出预期！`,
  `一键生成数字人，节省了我很多时间和成本`,
  `客服团队非常专业，解答问题很及时`,
  `数字人表情自然，声音也很真实，太赞了`,
  `性价比很高，对小团队来说是个福音`,
  `界面设计简洁直观，新手也能快速上手`,
  `朋友推荐使用的，确实没让人失望`,
  `制作效率提升了好几倍，强烈推荐`,
  `数字人的定制化程度很高，能满足各种需求`,
  `产品更新很快，体验越来越好了`,
  `用了${appName}后，内容创作效率提升了很多`,
  `数字人的口型同步度很高，非常专业`,
  `价格实惠，功能强大，很适合创作者使用`,
  `团队的技术支持很给力，问题都能快速解决`,
  `${appName}让我的创作之路变得更轻松了`,
  `${appName}的数字人效果极佳，完美符合我的需求！`,
  `使用${appName}，我节省了大量的制作时间`,
  `${appName}的客服反应迅速，解决问题高效`,
  `数字人的动画流畅，声音效果逼真，真的很棒！`,
  `${appName}的高性价比让我无法抗拒`,
  `界面简洁，操作方便，${appName}让工作更轻松`,
  `朋友推荐的${appName}，果然不负众望`,
  `通过${appName}，我的制作效率提升了数倍，强烈推荐`,
  `${appName}的定制功能让我能够满足不同的项目需求`,
  `产品更新迅速，每次使用都有新惊喜`,
  `使用${appName}后，我的内容创作效率大幅提升`,
  `数字人的口型配合度极高，专业水平满分`,
  `价格合理，功能全面，${appName}是创作者的首选`,
  `${appName}团队技术支持到位，问题解决得非常快`,
  `有了${appName}，我的创作之路更加顺畅了`,
  `数字人的细节处理非常到位，超出我的预期！`,
  `${appName}一键生成的功能实在太强大了，省时省力`,
  `客服团队专业耐心，使用${appName}无后顾之忧`,
  `通过${appName}，数字人的表现力更上一层楼了`,
  `高性价比的${appName}，小团队的福音`,
  `界面设计人性化，${appName}让操作变得简单易懂`,
  `朋友推荐的${appName}，使用后完全满意`,
  `使用${appName}后，制作效率提升显著，值得推荐`,
  `${appName}的定制化功能非常强大，满足了各种需求`,
  `产品更新频繁，每次更新都有新功能`,
  `用了${appName}，我的创作效率明显提高了`,
  `数字人的口型同步精准，专业水准高`,
  `价格实惠，功能齐全，${appName}非常适合创作者`,
  `${appName}的技术支持团队反应迅速，问题轻松解决`,
  `有了${appName}，我的创作之路更加轻松了`,
  `数字人的效果自然逼真，超出我的期待！`,
  `使用${appName}，我节省了大量时间和成本`,
  `${appName}的客服反应迅速，问题解决高效`,
  `数字人的动画流畅，声音真实，非常棒！`,
  `${appName}的高性价比让我无法拒绝`,
  `界面简洁，操作方便，${appName}让工作更轻松`,
  `朋友推荐的${appName}，果然不负众望`,
  `通过${appName}，我的制作效率提升了数倍，强烈推荐`,
  `${appName}的定制功能让我能够满足不同的项目需求`,
  `产品更新迅速，每次使用都有新惊喜`,
  `使用${appName}后，我的内容创作效率大幅提升`,
  `数字人的口型配合度极高，专业水平满分`,
  `价格合理，功能全面，${appName}是创作者的首选`,
  `${appName}团队技术支持到位，问题解决得非常快`,
  `有了${appName}，我的创作之路更加顺畅了`,
  `数字人的细节处理非常到位，超出我的预期！`,
  `${appName}一键生成的功能实在太强大了，省时省力`,
  `客服团队专业耐心，使用${appName}无后顾之忧`,
  `通过${appName}，数字人的表现力更上一层楼了`,
  `高性价比的${appName}，小团队的福音`,
  `界面设计人性化，${appName}让操作变得简单易懂`,
  `朋友推荐的${appName}，使用后完全满意`,
  `使用${appName}后，制作效率提升显著，值得推荐`,
  `${appName}的定制化功能非常强大，满足了各种需求`,
  `产品更新频繁，每次更新都有新功能`,
  `用了${appName}，我的创作效率明显提高了`,
  `数字人的口型同步精准，专业水准高`,
  `价格实惠，功能齐全，${appName}非常适合创作者`,
  `越来越喜欢${appName}，它让我创作更有动力`,
  `使用${appName}后，我的作品更加吸引人了`,
  `${appName}的功能丰富，满足了我所有的需求`,
  `通过${appName}, 我的项目进展明显加快`,
  `${appName}的用户界面友好，操作非常便捷`,
  `朋友推荐的${appName}, 确实让我爱不释手`,
  `使用${appName}后，我的工作流程变得更加高效了`,
  `${appName}的智能功能让我省下了大量时间`,
  `通过${appName}, 我的项目质量显著提升`,
  `${appName}的功能让我在创作中游刃有余`,
  `感谢${appName}，我能够更快速地完成工作`,
  `${appName}的支持团队始终能及时解决我的问题`,
  `使用${appName}让我的内容创作更加专业`,
  `${appName}的设计精美，提升了我作品的质量`,
  `有了${appName}, 我的项目完成速度大幅提升`,
  `${appName}的技术支持非常及时，解决了我的急迫问题`,
  `使用${appName}后，我的内容创作更加专业了`,
  `${appName}的价格非常亲民，适合各类用户`,
  `通过${appName}, 我的团队协作更高效`,
  `${appName}的产品质量非常优秀`,
  `感谢${appName}, 让我在创作上更加自如`,
  `${appName}的功能全面，极大地助力了我的工作`,
  `使用${appName}后，我的创作流程更顺畅`,
  `${appName}的技术更新非常及时`,
  `通过${appName}, 我的内容更加精彩`,
  `${appName}的用户体验十分出色`,
  `感谢${appName}, 提高了我的业务水平`,
  `${appName}的数字人效果令人惊叹`,
  `使用${appName}后, 我的工作时间大幅缩短`,
  `${appName}的功能设计非常贴心`,
  `通过${appName}, 我能更轻松地完成项目`,
  `${appName}的产品非常稳定，使用无忧`,
  `感谢${appName}, 我的创作更加高效`,
  `${appName}的数字人技术领先，值得信赖`,
  `使用${appName}后, 我的创作质量显著提升`,
  `${appName}的界面友好，操作简便`,
  `通过${appName}, 我的效率提高了许多`,
  `${appName}的服务态度非常好`,
  `感谢${appName}, 让我更好地完成任务`,
  `${appName}的工具功能强大，帮了大忙`,
  `使用${appName}后, 我的项目进展顺利`,
  `${appName}的数字人表情非常自然`,
  `通过${appName}, 我能快速生成高质量内容`,
  `${appName}的操作非常简单`,
  `使用${appName}后, 我的创作变得更加专业了`,
  `${appName}的功能非常全面`,
  `有了${appName}, 我可以轻松制作音乐视频`,
  `${appName}的性能非常稳定`,
  `使用${appName}后, 我的创作变得更加高效`,
  `${appName}的界面非常美观`,
  `有了${appName}, 我可以轻松制作广告视频`,
  `${appName}的功能非常强大`,
  `使用${appName}后, 我的创作变得更加有趣`,
  `${appName}的效果非常惊人`,
  `有了${appName}, 我可以轻松制作特效`,
  `${appName}的操作非常简单`,
  `使用${appName}后, 我的创作变得更加专业`,
  `${appName}的功能非常全面`,
  `有了${appName}, 我可以轻松制作音乐视频`,
  `${appName}的性能非常稳定`,
  `使用${appName}后, 我的创作变得更加高效`,
  `${appName}的界面非常美观`,
  `有了${appName}, 我可以轻松制作广告视频`,
  `${appName}的功能非常强大`,
  `使用${appName}后, 我的创作变得更加有趣`,
  `${appName}的效果非常惊人`,
  `有了${appName}, 我可以轻松制作特效`,
  `${appName}的操作非常简单`,
  `使用${appName}后, 我的创作变得更加专业`,
  `${appName}的功能非常全面`,
  `有了${appName}, 我可以轻松制作音乐视频`,
  `${appName}的性能非常稳定`,
  `使用${appName}后, 我的创作变得更加高效`,
  `${appName}的界面非常美观`,
  `有了${appName}, 我可以轻松制作广告视频`,
  `${appName}的功能非常强大`,
  `使用${appName}后, 我的创作变得更加有趣`,
  `${appName}的效果非常惊人`,
  `有了${appName}, 我可以轻松制作特效`,
  `${appName}的操作非常简单`,
  `使用${appName}后, 我的创作变得更加专业`
];

const generateName = () => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  return firstName;
};

const generateRandomContent = () => {
  const content = contents[Math.floor(Math.random() * contents.length)];
  return content;
};

const generateComments = (count: number) => {
  return Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    name: generateName(),
    content: generateRandomContent(),
    rating: Math.floor(Math.random() * 3) + 3
  }));
};
const totalComments = 100;
const comments = ref(generateComments(totalComments));

const rowCount = 4;
const commentsPerRow = computed(() => Math.ceil(totalComments / rowCount));

const rows = computed(() => {
  const result = [];
  for (let i = 0; i < rowCount; i++) {
    const start = i * commentsPerRow.value;
    const end = start + commentsPerRow.value;
    result.push(comments.value.slice(start, end));
  }
  return result;
});
</script>

<template>
  <div class="comments-wrapper">
    <CommentRow
      v-for="(row, index) in rows"
      :key="index"
      :comments="row"
      :direction="index % 2 === 0 ? 'normal' : 'reverse'"
    />
  </div>
</template>

<style scoped>
.comments-container {
  min-height: 60vh;
  background: var(--el-color-primary);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  text-align: center;
  margin-bottom: 4rem;
  animation: fadeIn 1s ease-out;
}

.main-title {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sub-title {
  font-size: 1.75rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.comments-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
