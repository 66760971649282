<script setup lang="ts">
import playSvg from "@/assets/svg/welcome/play.svg";
import micSvg from "@/assets/svg/welcome/mic.svg";
import videoSvg from "@/assets/svg/welcome/video.svg";
import { ArrowRight } from "@element-plus/icons-vue";
import { deviceDetection } from "@pureadmin/utils";
import router from "@/router";
import { RouterNameEnum } from "@/config";
import { useI18n } from "vue-i18n";

const isMobile = deviceDetection();

const { t } = useI18n();

// 去到数字人页面
const gotoDigital = () => {
  router.push({ name: RouterNameEnum.DigitHuman });
};
</script>

<template>
  <div
    class="relative h-full pb-[3rem] lg:pb-[0rem] lg:h-[95vh] pt-[2rem] lg:pt-[20vh]"
  >
    <!-- 渐变背景 -->
    <div class="absolute inset-0 bg-color"></div>
    <div class="relative">
      <!-- 标题区域 -->
      <div class="text-center mb-10 lg:mb-16">
        <h2
          class="text-[2rem] lg:text-[4rem] font-[opposansm] text-white mb-4 drop-shadow-lg"
        >
          {{ t("welcome.ProcessLayout.digitalHumanProductionProcess") }}
        </h2>
        <p class="text-[1rem] lg:text-[1.7rem] text-white/90 mb-4">
          {{ t("welcome.ProcessLayout.onlyThreeSteps") }},
          {{ t("welcome.ProcessLayout.easyForBeginners") }}
        </p>
      </div>
      <!-- 步骤卡片 -->
      <div
        class="grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-9 w-[90%] lg:max-w-7xl mx-auto"
      >
        <!-- 第1步 -->
        <div class="relative group">
          <div
            class="bg-white rounded-2xl p-6 lg:p-8 shadow-xl group-hover:shadow-2xl group-hover:scale-105 transition-all duration-500"
          >
            <!-- 图标 -->
            <div class="absolute -top-5 left-1/2 -translate-x-1/2">
              <div
                class="w-14 h-14 rounded-full bg-gradient-to-r from-[--el-color-primary] to-[--el-color-primary-stop] flex items-center justify-center group-hover:scale-110 transition-transform duration-300 boxshadow"
              >
                <component :is="playSvg" class="w-7 h-7 text-white" />
              </div>
            </div>

            <!-- 内容 -->
            <div class="pt-10 text-center min-h-[14rem]">
              <h3 class="text-2xl font-bold text-gray-800 mb-4">
                {{ t("welcome.ProcessLayout.stepOne") }}
              </h3>
              <p class="text-gray-600 mb-6">
                {{ t("welcome.ProcessLayout.uploadVideoMaterial") }},
                {{ t("welcome.ProcessLayout.quicklyGenerateDigitalAvatar") }}
              </p>
              <ul class="space-y-4">
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.oneMinuteRapidClone")
                  }}</span>
                </li>
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.oneToOneRealHumanReplica")
                  }}</span>
                </li>
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.ultraHighDefinitionQuality")
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 步骤数字 -->
          <div
            v-if="!isMobile"
            class="absolute -bottom-4 left-1/2 -translate-x-1/2"
          >
            <div
              class="w-8 boxshadow h-8 rounded-full bg-primary flex items-center justify-center text-white font-bold shadow-lg"
            >
              1
            </div>
          </div>
        </div>

        <!-- 第2步 -->
        <div class="relative group">
          <div
            class="bg-white rounded-2xl p-6 lg:p-8 shadow-xl group-hover:shadow-2xl group-hover:scale-105 transition-all duration-500"
          >
            <!-- 图标 -->
            <div class="absolute -top-5 left-1/2 -translate-x-1/2">
              <div
                class="w-14 h-14 rounded-full bg-gradient-to-r from-[--el-color-primary] to-[--el-color-primary-stop] flex items-center justify-center shadow-lg group-hover:scale-110 transition-transform duration-300 boxshadow"
              >
                <component :is="micSvg" class="w-7 h-7 text-white" />
              </div>
            </div>

            <!-- 内容 -->
            <div class="pt-10 text-center min-h-[14rem]">
              <h3 class="text-2xl font-bold text-gray-800 mb-4">
                {{ t("welcome.ProcessLayout.stepTwo") }}
              </h3>
              <p class="text-gray-600 mb-6">
                {{ t("welcome.ProcessLayout.recordVoiceSample") }}
                {{ t("welcome.ProcessLayout.generateAiVoiceModel") }}
              </p>
              <ul class="space-y-4">
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.comparableToRealHumanVoice")
                  }}</span>
                </li>
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{ t("welcome.ProcessLayout.supportBilingual") }}</span>
                </li>
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.naturalEmotionalExpression")
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 步骤数字 -->
          <div
            v-if="!isMobile"
            class="absolute -bottom-4 left-1/2 -translate-x-1/2"
          >
            <div
              class="w-8 h-8 boxshadow rounded-full bg-primary flex items-center justify-center text-white font-bold shadow-lg"
            >
              2
            </div>
          </div>
        </div>

        <!-- 第3步 -->
        <div class="relative group">
          <div
            class="bg-white rounded-2xl p-6 lg:p-8 shadow-xl group-hover:shadow-2xl group-hover:scale-105 transition-all duration-500"
          >
            <!-- 图标 -->
            <div class="absolute -top-5 left-1/2 -translate-x-1/2">
              <div
                class="w-14 h-14 rounded-full bg-gradient-to-r from-[--el-color-primary] to-[--el-color-primary-stop] flex items-center justify-center shadow-lg group-hover:scale-110 transition-transform duration-300 boxshadow"
              >
                <component :is="videoSvg" class="w-7 h-7 text-white" />
              </div>
            </div>

            <!-- 内容 -->
            <div class="pt-10 text-center min-h-[14rem]">
              <h3 class="text-2xl font-bold text-gray-800 mb-4">
                {{ t("welcome.ProcessLayout.stepThree") }}
              </h3>
              <p class="text-gray-600 mb-6">
                {{ t("welcome.ProcessLayout.inputScript") }}
                {{
                  t("welcome.ProcessLayout.automaticallyGenerateVideoContent")
                }}
              </p>
              <ul class="space-y-4">
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.threeMinutesNewVideo")
                  }}</span>
                </li>
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{ t("welcome.ProcessLayout.support1080P4K") }}</span>
                </li>
                <li class="flex items-center text-gray-700">
                  <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>
                  <span>{{
                    t("welcome.ProcessLayout.oneClickExportShare")
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 步骤数字 -->
          <div
            v-if="!isMobile"
            class="absolute -bottom-4 left-1/2 -translate-x-1/2"
          >
            <div
              class="w-8 h-8 boxshadow rounded-full bg-primary flex items-center justify-center text-white font-bold shadow-lg"
            >
              3
            </div>
          </div>
        </div>
      </div>

      <!-- 底部按钮 -->
      <div class="mt-6 lg:mt-20 text-center">
        <el-button
          type="primary"
          size="large"
          @click="gotoDigital"
          class="!text-[1.3rem] my-press-button-shadow !border-[0] my-gradient-green-left ani-scale !px-[2rem] !py-[1.5rem] !rounded-full shadow-lg hover:shadow-xl transition-shadow"
        >
          {{ t("welcome.ProcessLayout.startMaking") }}
          <el-icon class="ml-1"><ArrowRight /></el-icon>
        </el-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.boxshadow {
  box-shadow: 0 0 8px 1px rgba(255, 255, 255, 0.6); /* 调整颜色和大小以符合设计 */
}
.bg-color {
  background: linear-gradient(
    187deg,
    var(--el-color-primary-stop) -51%,
    var(--el-color-primary) 157%
  );
}
</style>
