import { store } from "@/store";
import {
  appType,
  CommonValueAndLable,
  ScoreRechargeType,
  ChatCategory,
  GiftAndDescObj,
  AgentDTOType,
} from "./types";
import { NewsItemType } from "@/api/types";
import { defineStore } from "pinia";
import {
  getConfig,
  GlobalStateEnum,
  NewsGroupEnum,
  UniLockEnum
} from "@/config";
import { deviceDetection, storageLocal } from "@pureadmin/utils";
import { adminApi, ItemScoreRechargeType, SquareDetail, userApi } from "@/api";
import { CommonUtils } from "@/utils/commonUtils";

const isMobile = deviceDetection();
export const useAppStore = defineStore({
  id: "pure-app",
  state: (): appType => ({
    sidebar: {
      opened:
        storageLocal().getItem<StorageConfigs>("responsive-layout")
          ?.sidebarStatus ?? getConfig().SidebarStatus,
      withoutAnimation: false,
      isClickCollapse: false
    },
    // 这里的layout用于监听容器拖拉后恢复对应的导航模式
    layout:
      storageLocal().getItem<StorageConfigs>("responsive-layout")?.layout ??
      getConfig().Layout,
    device: isMobile ? "mobile" : "desktop",
    fontSize: null,
    category: {
      firstPrompt: null,
      systemId: null,
      isCreate: null,
      uuid: null
    },
    squarePrompt: {
      prompt: null,
      promptEn: null,
      imagePath: null,
      url: null
    },
    lockMap: new Map<string, string>(),
    // 推广官类型
    sellRechargeType: [],
    // 数字人类型
    digitHumanRechargeType: [],
    scoreRechargeType: null,
    newsMap: null,
    agentDTO: null
  }),
  getters: {
    getSidebarStatus() {
      return this.sidebar.opened;
    },
    getDevice() {
      return this.device;
    },
    getFontSize() {
      return this.fontSize;
    }
  },
  actions: {
    TOGGLE_SIDEBAR(opened?: boolean, resize?: string) {
      const layout =
        storageLocal().getItem<StorageConfigs>("responsive-layout");
      if (opened && resize) {
        this.sidebar.withoutAnimation = true;
        this.sidebar.opened = true;
        layout.sidebarStatus = true;
      } else if (!opened && resize) {
        this.sidebar.withoutAnimation = true;
        this.sidebar.opened = false;
        layout.sidebarStatus = false;
      } else if (!opened && !resize) {
        this.sidebar.withoutAnimation = false;
        this.sidebar.opened = !this.sidebar.opened;
        this.sidebar.isClickCollapse = !this.sidebar.opened;
        layout.sidebarStatus = this.sidebar.opened;
      }
      storageLocal().setItem("responsive-layout", layout);
    },
    async toggleSideBar(opened?: boolean, resize?: string) {
      await this.TOGGLE_SIDEBAR(opened, resize);
    },
    toggleDevice(device: string) {
      this.device = device;
    },
    setLayout(layout) {
      this.layout = layout;
    },
    setFontSize() {
      this.fontSize = Number(
        document.documentElement.style.fontSize.slice(0, -2)
      );
    },
    clearCategoryFirstPrompt() {
      this.category.firstPrompt = null;
    },
    setSquarePrompt(prompt: SquareDetail) {
      this.squarePrompt = prompt;
    },
    getSquarePrompt(): SquareDetail {
      return this.squarePrompt;
    },
    setLock(key: string, value: string) {
      if (value == UniLockEnum.DELETE) {
        this.lockMap.delete(key);
      } else {
        this.lockMap.set(key, value);
      }
    },
    getLock(key: string): string {
      return this.lockMap.get(key);
    },
    setSellRechargeType(sellRechargeType: any) {
      this.sellRechargeType = sellRechargeType;
    },
    setCategory(category: ChatCategory) {
      this.category = category;
    },
    getCategory(): ChatCategory {
      return this.category;
    },
    getSellRechargeType(): Array<any> {
      return this.sellRechargeType;
    },
    setNewMap(tempNewsMap: Map<number, Array<NewsItemType>>) {
      this.newsMap = tempNewsMap;
    },

    async getNews(newsGroupEnum: NewsGroupEnum, reload?: boolean) {
      if (this.newsMap && !reload) {
        return this.newsMap.get(newsGroupEnum);
      }
      const { data } = await userApi.postNewsList(NewsGroupEnum.NEWS_ALL);
      // 使用 reduce 方法按 type 分组
      const demoMap: Map<number, Array<NewsItemType>> = new Map();
      const advertiseMap: Map<number, Array<NewsItemType>> = new Map();
      const tempNewsMap = data.reduce((acc, obj) => {
        // 如果Map中还没有当前type的键，则初始化为空数组
        if (obj.status == GlobalStateEnum.Success) {
          if (!acc.has(obj.group)) {
            acc.set(obj.group, []);
          }
          // 将当前对象添加到对应type键下的数组中
          if (obj.group == NewsGroupEnum.NEWS_VIDEO_DEMO) {
            if (!demoMap.has(obj.type)) {
              demoMap.set(obj.type, []);
            }
            demoMap.get(obj.type).push(obj);
          } else if (obj.group == NewsGroupEnum.DEMO_ADVERTISE) {
            if (!advertiseMap.has(obj.type)) {
              advertiseMap.set(obj.type, []);
            }
            advertiseMap.get(obj.type).push(obj);
          } else {
            acc.get(obj.group).push(obj);
          }
        }
        return acc;
      }, new Map());
      if (tempNewsMap) {
        tempNewsMap.set(NewsGroupEnum.NEWS_VIDEO_DEMO, demoMap);
        tempNewsMap.set(NewsGroupEnum.DEMO_ADVERTISE, advertiseMap);
        this.setNewMap(tempNewsMap);
        return tempNewsMap.get(newsGroupEnum);
      }
      return [];
    },
    setAgentDTOList(tempAgentDTO: AgentDTOType) {
      this.agentDTOList = tempAgentDTO;
    },
    async getAgentDTOList(reload?: boolean): Promise<AgentDTOType> {
      if (this.agentList?.length > 0 && !reload) {
        return this.agentList;
      }
      const { data } = await adminApi.getAgentList();
      const agentDTO: AgentDTOType = {
        agentList: data.list,
        domainList: Array.from(new Set(data.list.flatMap(item => item.domain))),
        childDomainList: Array.from(
          new Set(data.list.flatMap(item => item.childDomain))
        )
      };
      agentDTO.domainAndChildDomainMap = new Map<string, Array<string>>();
      agentDTO.domainList.forEach(domain => {
        const childDomainList = agentDTO.agentList.filter(
          item => item.domain == domain
        );
        if (childDomainList?.length > 0) {
          agentDTO.domainAndChildDomainMap.set(
            domain,
            Array.from(
              new Set(childDomainList.flatMap(item => item.childDomain))
            )
          );
        }
      });
      this.setAgentDTOList(agentDTO);
      return agentDTO;
    },
    setRechargeScore(tempScoreRechargeType: ScoreRechargeType) {
      this.scoreRechargeType = tempScoreRechargeType;
    },

    async getRechargeScore(
      locale,
      reload?: boolean
    ): Promise<ScoreRechargeType> {
      if (this.scoreRechargeType?.allData && !reload) {
        return this.scoreRechargeType;
      }
      const { data } = await userApi.getRechargeScoreType();
      // 处理国际化问题
      data.forEach((item: ItemScoreRechargeType) => {
        locale = locale?.value ? locale?.value : locale;
        item.title = CommonUtils.getTranslateText(item.title, locale);
        item.subTitle = CommonUtils.getTranslateText(item.subTitle, locale);
        item.desc = CommonUtils.getTranslateText(item.desc, locale);
        const giftAndDescObj: GiftAndDescObj = CommonUtils.getJSONObj(
          item.gift
        );
        if (giftAndDescObj && giftAndDescObj.desc) {
          const descJson: Array<string> = giftAndDescObj.desc[locale] as any;
          if (descJson?.length > 0) {
            const descList = [];
            for (let count = 0; count < descJson.length; count++) {
              if (descJson[count]?.includes("|")) {
                const descAndInfo = descJson[count].split("|");
                descList.push({
                  desc: descAndInfo[0],
                  info: descAndInfo[1],
                  show: false
                });
              } else {
                descList.push({
                  desc: descJson[count]
                });
              }
            }
            item.descList = descList;
          }
        }
      });

      const tempDigital = data.filter(item => {
        return CommonUtils.isDigitalType(item.type);
      });
      const digital = new Map<number, Array<ItemScoreRechargeType>>();
      const digitalTypeAndTitle: Array<CommonValueAndLable> = [];
      tempDigital.forEach(item => {
        if (!digital[item.type]) {
          digital[item.type] = [];
          digitalTypeAndTitle.push({
            label: item.title,
            value: item.type
          });
        }
        digital[item.type].push(item);
      });
      const obj = {
        digitalTypeAndTitle: digitalTypeAndTitle,
        digital: digital,
        allData: data
      } as ScoreRechargeType;
      this.setRechargeScore(obj);
      return obj;
    }
  }
});

export function useAppStoreHook() {
  return useAppStore(store);
}
