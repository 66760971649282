aboutChatAI:
  one: >-
    Chat AI is a chatbot that uses the LLM to answer users' questions. LLM
    is a natural language processing model that can understand natural language
    and answer questions like a human. By using Chat AI, users can interact with
    it in natural language, getting relevant information and answers.
  two: >-
    Chat AI learns language knowledge and reasoning ability by training on a
    large amount of text data. This text data may include news articles, novels,
    etc. Through continuous training, its neural network can learn to extract
    information from the text and use this information to answer questions.
aboutFutureAI:
  five: >-
    If you are looking for a reliable digital human and AI tool software service
    provider, please contact us! We can not only meet your needs but also exceed
    your expectations, bringing you more opportunities and benefits.
  four: >-
    In addition, in the field of AI tool software, we cover businesses such as
    natural language processing, computer vision, and data analysis. Our tool
    software can not only accelerate people's daily work but also provide
    customers with the best intelligent solutions, helping them achieve business
    transformation and upgrading.
  one: >-
    Hello, welcome to FutureAI's micro-universe, we are a leading provider of
    digital humans and AI tool software, headquartered in Shenzhen, China, aimed
    at providing customers with the best intelligent solutions.
  three: >-
    Digital humans are one of our core businesses. It provides companies with a
    new digital solution, significantly shortens recruitment time, reduces trial
    and error costs, and can also achieve customized functions. Our digital
    human technology rivals real humans, can interact with users via voice,
    video, text, greatly improving the efficiency and precision of the business.
  two: >-
    We firmly believe that digital humans and AI technologies are changing the
    world, and will continue to create more opportunities and benefits for
    people. Therefore, we are committed to developing the most cutting-edge
    digital human and AI tool software to help companies improve efficiency,
    reduce costs, enhance security, and achieve sustainable competitive
    advantages.
account:
  creationDate: Time
  modelCount: Number of Models
  orderId: Order ID
  orientation: Orientation
  remainTime: Video Duration Balance
  remark: Remark
  spendTimes: Video Duration Used
  userID: User ID
  workName: Work Name
  workTime: Duration
imageSquare:
  creationPlatform: Creation Platform
  justOneSentence: Just one sentence, let your text become a painting
  model: Model
  oneKeySame: One Key Duplicate
  support: Support
  public: Public
  mine: Mine
  createInfo: Create Info
  creator: Creator
  oneKeySameDesc: This work needs to pay {msg} credits for the one key same
  description: Description
  size: Size
  modelName: Model Name
  seed: Seed
  free: Free
  prompt: Prompt
  negativePrompt: Negative Prompt
  onlyShowOnekeySame: only show  one key same
  style: Style
  step: Step
  searchPlaceholder: Support prompt word, nickname, title search
  aiImage: AI Paint
buttons:
  hsLoginOut: Log out
  hscloseAllTabs: Close all tabs
  hscloseCurrentTab: Close current tab
  hscloseLeftTabs: Close left tabs
  hscloseOtherTabs: Close other tabs
  hscloseRightTabs: Close right tabs
  hscontentExitFullScreen: Exit full screen in content area
  hscontentFullScreen: Full screen in content area
  hsexitfullscreen: Exit full screen
  hsfullscreen: Full screen
  hslogin: Login
  hsreload: Reload
  hssystemSet: Open project settings
  hswholeExitFullScreen: Exit full screen
  hswholeFullScreen: Full screen
chat:
  switchToLinkContext: Context Related
  switchToCancelLinkContext: Cancel Context Related
  clearChat: Clear Chat
  linkContext: Context Related
  clearChatConfirm: Confirm to clear chat?
  clearHistoryConfirm: Confirm to clear chat history?
  copied: Successfully copied
  copy: Copy
  copyCode: Copy Code
  dayTheme: Day mode
  deleteHistoryConfirm: Confirm to delete?
  deleteMessage: Delete Message
  deleteMessageConfirm: Confirm to delete this message?
  exportChat: Export Chat
  exportFailed: Export failed
  exportImage: Save Chat as Image
  exportImageConfirm: Confirm to save chat as image?
  exportSuccess: Export successful
  newConversation: New Conversation
  nightTheme: Night mode
  placeholder: Something to say... (Shift + Enter = New Line)
  placeholderMobile: Something to say...
  regenerate: Regenerate
  stopRespond: Stop Responding
  subscribeMember: Subscribe Member
  turnOffContext: In this mode, sent messages do not carry previous chat history
  turnOnContext: In this mode, sent messages carry previous chat history
  usingContext: Context mode
  waitAnswer: Please wait for the answer to be completed
  youSubscribed: You have subscribed
  create_digital_avatar: Create your digital avatar with this content
  select_conversation: Please select a conversation
  message_deleted: Message deleted
  delete_failed: Delete failed, please try again
  press_enter_send: Press Enter to send message
chatAICategory:
  ask: Ask
  beyond: Already surpassing 90% of humans
  enterScene: Enter Scene
  optimize: Feel free to ask questions, it can always provide the best answers
  pleaseInput: (You can choose not to enter anything and enter the scene directly.)
  useGuide: Use Guide
  selectModel: Select Model
  currentModel: Current Model：
common:
  pleaseInput: Please input
  pauseDuration: Pause Duration
  inputContent: Input content cannot exceed
  remove: Remove
  stop: Stop
  send: Send
  changeVoice: Change Voice
  selectVoiceClone: Please select voice
  clickChange: Click change
  processing: Click processing
  text: Chinese
  known: I already know
  action: Action
  add: Add
  addSuccess: Add successful
  apply: Apply
  back: Back
  select: select
  retryProcessing: Retry Processing
  allImages: All Images
  myFavorites: My Favorites
  today: Today
  yesterday: Yesterday
  bg: Background
  deleteConfirmation: The image deletion cannot be undone, are you sure you want to delete it?
  clear: Clear
  clearSuccess: Clear successful
  confirm: Confirm
  copy: Copy
  copySuccess: Copy successful
  deleteConfirm: Confirm Delete?
  deleteSuccess: Delete successful
  download: Download
  applypattr: Application
  edit: Edit
  editSuccess: Edit successful
  export: Export
  exportSuccess: Export successful
  failed: Failed
  imageFailed: Loading failed
  deleteFailed: Delete Failed
  img: Image
  import: Import
  importSuccess: Import successful
  insert: Insert
  more: More
  "no": "No"
  noData: No Data Available
  pleaseSelect: Please select
  preview: Preview
  reset: Reset
  save: Save
  saveSuccess: Save successful
  saveFail: Save fail
  submit: Submit
  submitSuccess: Submit successful
  success: Success
  tips: Tips
  unauthorizedTips: Unauthorized, please verify first.
  claiming: Login required, redirecting you now
  verify: Verify
  wrong: Something went wrong, please try again later.
  "yes": "Yes"
  upload: Upload
  psImage: PS
  video: More Video Cases
  buyRightNow: Buy Now
  credits: Credits
  creditsRecharge: Credits Recharge
  billRules: Billing Rules
  alipay: Alipay
  payChange: Click the corresponding payment button to jump to complete the payment.
  weChatPay: WeChat Pay
  paypal: PayPal Pay
  reUpload: ReUpload
  resubmit: Resubmit
  cancelUpload: Cancel Upload
  pleaseUpload: Please upload an authorization letter photo with the correct ratio
  videoFormat: The video format must be one of the following
  clearPhoto: Please upload a clear photo of the authorization letter
  pre: Pre
  next: Next
  paidSuccess: Paid
  paidSuccessTips: After payment success, click the button below
  free: Free
  cooperation: Telephone
  weChatCustomer: WeChat Customer Service
  dedicatedCustomer: Dedicated customer service,special offers & benefits
  officialAccount: Official Account
  latestAccount: Latest news,cutting-edge trends
  noPage: Sorry, the page you visited does not exist
  backHome: Return to home page
  id: ID
  title: Title
  desc: Description
  createTime: Creation Time
  updateTime: Update Time
  operation: Operation
  loading: Loading
  delete: Delete
  create: Create
  cancel: Cancel
  warning: Warning
  confirmDelete: Are you sure you want to delete?
  deleteFail: Delete failed
  getFail: Failed to get data
  logo: Logo
createWork:
  availTimes: Available Duration
  composite: Composite Video
  createWorkSuccess: Video creation successful
  customizeModel: Custom Model
  default: Default
  draftEmpty: Draft content is empty, copying failed
  creditsPerMin: Credits / Min
  durationPackage: Duration Package
  modelUnavailable: Model Unavailable
  drawer:
    background: Background
    draft: Draft
    img: Image
    material: Material
    model: Model
    video: Video
  extract: Extract
  extractDrawer:
    copyLink: Please paste the video link
    pleaseGetVideoDetail: Please get the video details first
    pleaseGetText: Please extract the text first
    textCopied: The text has been copied to the clipboard
    textExtract: Text Extraction
    extract: Please enter the Douyin share link to extract the text with one click
    textExtractTitle: 5 easy steps to extract video text
    textExtractSubTitle: Work efficiency increases linearly
    pendingGetVideoInfo: Retrieving, please wait.
    1step: Step 1
    2step: Step 2
    3step: Step 3
    4step: Step 4
    5step: Step 5
    1desc: Paste link
    2desc: Get video details
    3desc: Extract copy
    4desc: Save audio/video
    5desc: Copy/Apply Text
    inputLinkPlaceholder: Please enter Douyin, Xiaohongshu, Kuaishou, Station B (password or link)
    getVideoDetail: Get video details
    extractText: Extract copy text
    saveAudio: Save Audio
    saveVideo: Save Video
    copyAudioAddress: Copy Audio Address
    copyVideoAddress: Copy Video Address
    copyText: Copy Text
    applyText: Application Text
  footer:
    addPause: Adjust the pause length between words at the cursor
    all: All
    audioModel: Voice
    audioPitch: Pitch
    audioSpeed: Speech Speed {times}x
    deviceNotSupport: The current device does not support audio trial listening
    female: Female
    inputOptimizeText: Please input the text to be optimized in text synthesis
    inputTextPlaceholder: Input or paste text.
    inputTextRule: Content must be at least 5 characters
    inputTranslateText: Please input the text to be translated in text synthesis
    language: Language
    male: Male
    predictCost: Estimated Cost
    recordComposition: Record Synthesis
    role: Role
    selectAudioModel: Please select a voice
    selectAudio: Please select a voice
    selectAudioStatus: The current voice is not completed, please select another voice
    selectLanguage: Please select a language
    myVoice: My Voice
    systemVoice: System voice
    sex: Gender
    style: Style
    testAudio: Trial Listening
    textComposition: Text Synthesis
    insertPause: Insert Pause
    predictTime: Predict Time
    seconds: seconds
    audioUploadDisable: credits less than 10 cannot be uploaded
    startConvert: Start Convert
  freeModel: Available Model
  goImageAI: Go to Paint
  horizontal: Horizontal
  imageAI: AI Painting
  imgDirection: Image Direction
  inputTextOrAudio: Please input content for explanation or upload recording
  modelDrawer:
    noneCustomizeModel: none customize model
  optimizeDrawer:
    engaging: More engaging
    exception: An exception occurred
    fix: Copy correction
    inputOrCopy: Input or paste text
    longer: Longer
    optimizeGPT: 🔥 Use ChatAI to optimize the copy
    professional: More professional
    reWritten: Rewrite
    shorter: Shorter
    stopGenerate: stop generate
  painting: Painting
  pleaseSelectModel: Please select a model
  selectBg: Select Background
  selectDraft: Select Draft
  selectImg: Select Image
  selectMaterial: Select Material
  selectModel: Select Model
  selectVideo: Select Video
  switchOrientation: Switch Ratio
  switchTips: >-
    Switching screen orientation will not keep edited data, do you want to
    continue?
  translate: Translate
  translateDrawer:
    originLanguage: Source Language
    targetLanguage: Target Language
    textUpdated: Copy has been updated
    translate: Translate
    translating: Translating...
  unNamed: Unnamed
  vertical: Vertical
dialog:
  xbTip:
    one: Please note that a confirmation email is on its way, please check it.
    two: If you don't receive the email, don't forget to check your spam folder, it may be there.
    three: Add and contact our customer service, we are always ready to assist you!
  buyTip:
    one: This product is a virtual product
    two: Refunds will not be supported after purchase and activation
  agentInit:
    exitTips: Click to confirm, you will exit this site and be redirected to your exclusive website {website}
  discountCode:
    discountCode: Discount Code
    apply: Partner Application
    suggestInput: It is recommended to fill in the mobile phone number, name, and main traffic platform to facilitate our review as soon as possible.
    applySuccess: Apply Success
    getDiscountCode: Apply for a Discount Code
    discountforCode: Apply for a Discount Code
    clickDiscountCode: Click to Apply for a Discount Code
    whatIsDiscountCode: What Is Discount ?
    discountNow: Discount Now
    baseinfo: Base Info
    purl: Digital human video link
    urlinfo: Please enter your digital human video link here.
    yourName: Your Name
    phoneNumber: Your Phone Number
    yourRequest: Your Request
    pleaseRequest: Please enter your request
    weChat: WeChat QR Code
  digitalRegisterInfo:
    title: Add Mobile And Email
    titleForAi: Supplement Personal Information
    tips1: 1. Please note that a confirmation email is on its way, please check it~
    tips2: 2. If you don't receive the email, don't forget to check your spam folder, it may be there.
    tips3: 3. Add and contact our customer service, we are always ready to assist you!
  chatai:
    chooseMember: Please choose a member
    one: Unlock CHAT AI member, enjoy smooth questioning
    paidSuccess: Paid
    two: Current recharge only supports CHAT AI
    wechatPay: Click to Buy Now,Scan to Pay
  credits: Credits
  immediateRecharge: Recharge Now
  question: If you encounter problems with scanning, you can obtain it through the following methods
  clickToView: Click to view
  addWeChat: WeChat： Search and add the WeChat ID
  sendEmail: Exclusive Application Code
  to: to
  emailAddress: the email address
  contactUs: please contact our customer service through any of the following methods：
  addLine: Line： Search and add the Line account
  support: Choose the contact method that is convenient for you. Our customer service team is always ready to assist you!
  sendCode: I have sent it
  compositeTips: Estimated duration of this composite:{time}
  discountCodeDesc: Discount code can be used to purchase products at discounted prices
  inputDiscountCode: Input Discount Code
  buyWarning: Hello dear friends, once virtual products are sold, refunds are not supported. Please read the <a class="text-primary" href="https://mp.weixin.qq.com/s/0Zc2LbX5x9E5JzRxxeQTDw">user guide</a> carefully before placing an order, or contact our customer service for consultation.
  buyWarning2: Hello dear friends, once virtual products are sold, refunds are not supported. If you have any questions, please contact our customer service for consultation.
  buyWarningline1: Hello dear friends, once virtual products are sold, refunds are not supported. If you have any questions
  buyWarningline2: please contact our customer service for consultation.
  digitHuman:
    minute: minute
    one: Unlock Digital Human member, easily make digital human videos
    two: Current recharge only supports digital human video production
  digitalVoice:
    audioDuration: Duration
    effectiveAudioDuration: Effective Audio Duration
    lessMinutes: It is recommended that the audio is no less than 10 minutes
    recordingEnvironmen: Recording Environment
    noise: Quiet Environment Background Noise
    keepNoEcho: Keep no echo, reverb, or noise while recording
    RecordingEquipment: Recording Equipment
    recommended: Recommended
    microphone: Noise-Cancelling Microphone/BeeMic
    conditionsAllow: If conditions allow, it is recommended to use a windproof coat or pop filter
    recordingPerson: Recording Person
    close: Should not be too close to the microphone
    tooClose: to prevent breath and pop sounds
    coherently: Finish a sentence coherently, with a pause of 1-2 seconds between sentences
    consistent: Maintain consistent context and style, avoid mixing multiple emotions
    recordingParameters: Recording and Recording Parameters
    recommendedToUse: Recommended to use
    recordingAudio: 40kHz sampling rate for recording audio
    losslessAudio: Recommend lossless audio quality format for saving audio, such as
    zipAudio: Do not save the audio in a compressed format, such as
    pleaseUpload: Please submit the training audio
    submitAudio: Please submit the training audio
    synthesizedAudio: Please submit the recorded synthesized audio
    requiredField: Please upload the audio.Required field
    resSex: Gender cannot be empty
    submittedSuccessfully: Training audio submitted successfully
    trainingAudio: Training audio
    deleteSound: Are you sure to delete?
    supports: Audio only supports wav, m4a, flac formats
    notExcee: each audio file must not exceed 10MB
    submitted: Do not submit identical documents
    uptoTenAudio: a maximum of {fileLimit} file clips can be submitted
    supportedTypes: supported types are wav/m4a/flac
    drag: drag files here or click
  kindTips: Kind tips
  paySuccess: Payment Successful
  points_purchase: Points Purchase
  points_balance: Points Balance
  scanWechat: Scan WeChat Add Service
  contactWechat: Scan the QR Code with WeChat
  purchaseSuccessful: Purchase Successful
  contact: Contact Us
  freeDigital: Scan the QR code to receive free digital human production time
  getDigital: Receive a digital human for free
  purchaseSuccess: You can contact us through the following information.
  apply: Scan the QR code to get it for free
  below: Follow the official account
  clickCopy: click
  freeTime: Choose to receive free digital human duration.
  send: Reply with the exclusive application code：
  problem: Or send the following content：
  problem0: Claim your ID number for free
  problem2: the exclusive application code
  score:
    one: Unlock Score Member, enjoy AI painting
    two: Current recharge only supports AI painting
  serviceDigitHuman:
    five: >-
      A professional digital consultant will give you a detailed introduction to
      the digital human customization process.
    four: Plan 2 - Corporate collaboration, contact our sales consultant
    one: Hello, creating a digital human is part of
    three: Plan 1 - Collaboration with regular users, direct top-up
    two: VIP member benefits, which requires a fee to use
  billRuleDialog:
    function: Function
    price: Price
    creditsOnce: Credits / Once
    creditsMinute: Credits / Minute
    credits100Words: Credits / 100 Words
    extractVideoText: Extract Video Text
    getVideoDetail: Get Video Detail
    extractWatermarkFreeVideos: Extract WatermarkFree Videos
    chatAiToken: Credits/100 characters"
    extractAudio: Extract Audio
    yuanMinute: Credits/ Minute
  cropper:
    cropperImage: Crop Image
    freeMode: Free Mode
    imageSize: image size
    pixel: pixel
    fileSize: file size
    roundRectCropper: round/rectangular cropping
    roundRect: circle/rectangle
    reset: reset
    backgroundColor: background color
    customColor: custom color
    close: close
    flip: flip
    xFlip: Flip horizontally
    yFlip: flip vertically
    rotate: rotate
    clockwiseRotate: rotate clockwise
    AnticlockwiseRotate: Rotate counterclockwise
    longClick: long press
    zoomIn: zoom in
    zoomOut: zoom out
    saveImage: Save Image
    insertCanvas: Insert the canvas
    view: View
    viewAll: View All
digitHuman:
  choose: Choose the digital human package that best suits you
  superior: Superior quality and exceptional value
  exhibit: Exhibit
  videoCaseStudy: Video Case Study
  product:
    digitaLive: Live Digital Human
    digitalVoice: Voice Customization
    digitalBusinessCard: interactive digital people
    guiJi:
      title: Gui Ji Digit Man
      desc: As a <span class='text-primary'>Tencent-based artificial intelligence enterprise and a national-level specialized special new little giant</span>, Silicon-based Intelligence has won <span class='text-primary'>CMB International and Guoxin With investments from state-owned enterprises, Ocean Pine Capital, Red Shirt Capital, etc.</span>, it has <span class='text-primary'>nearly a hundred authorized invention patents</span> and has nearly a thousand R&D personnel. Silicon-based digital people are advanced Constructed with digital human technology, the digital human image can be restored to a one-to-one real-life form, movement, expression, and voice, <span class='text-primary'>to create a digital human image driven by strong computing power, driven by quantum mechanics, and equipped with sales progress. , open source, silicon-based life without emotions, unlimited supply, never leaving the company, and not participating in distribution. </span>Free human beings from heavy repetitive mental work and return people to human values.
    xiaoBing:
      newTitle: Short Video Avatar
      title: Xiao Bing Digit Man
      desc: Xiaobing Digital People was born out of <span class='text-primary'>Microsoft Corporation</span>, and its core technology and team originated from <span class='text-primary'>Microsoft Asia Internet Engineering Academy</span>. In order to speed up the pace of innovation in XiaoIce's product line, XiaoIce was spun off from Microsoft and operated as an independent company on July 13, 2020. After independence, <span class='text-primary'>Dr. Shun Xiangyang, the former global executive vice president of Microsoft, was appointed as the chairman of XiaoIce Company</span>. The new company will use and continue to develop complete XiaoIce technology.
      desc2: In 2021, Xiaoice will complete the A round of financing and grow into the fastest unicorn company in the AI industry. On November 7, 2022, it will complete a new round of strategic financing of 1 billion yuan. In the A1 field, Xiaoice has full-stack core Al capabilities such as computer vision, speech, NLP and artificial intelligence creation</span>, and is the leader in the industry One of the earliest companies to deploy digital employees</span>, it currently ranks first in the number of cases that have been <span class='text-primary'>implemented, experienced, and generated business value</span>.
      basicName: Digital man experience version
      liteName: digital human lite version
      prodName: digital human pro version
    tengXun:
      title: Zhi Ying Digit Man
      desc: Tencent Zhiying Digital Human is a digital human product wholly developed by <span class='text-primary'>Tencent Company</span>. The world's first A1 synthetic anchor was released in November 2018. After 6 years, it has been developed from the original simplified version to the current hyper-realistic 3D digital version. And it is widely used in <span class='text-primary'>radio and television, meteorology, securities, finance, and other fields</span>. <span class='text-primary'>cooperative customers include State Grid, Industrial and Commercial Bank of China, and Xinhua China Everbright Bank, Ping An Finance, Qingdao Metro and other customers. </span>
  containsContent: Contains Content
  portraitCustomize: Portrait Customization
  proPortraitCustomize: Pro Portrait Customization
  soundClone: Sound Clone
  digitClone: Digital Human Clone
  unlimited: Unlimited
  basicDesc1: Generate exclusive cloned digital human
  basicDesc2: have {msg} minutes of short video time
  proDesc: Generate your own sound clone
  userInfo: User Info
  pleaseRead: Please read the instructions below and check the box
  promotionalPrice: New Year Day promotion price
  discountedPrice: discountedPrice
  customizedRequirements: Customized Requirements (other special needs)
  websitePrivateDeployment: Website Private Deployment
  customizedPlans: Customized Plans
  digitApi: API Calls
  digitApiDoc: API documentation
  enterpriseContact: Enterprise Contact
  editionFeaturesplus: All Business Edition Features plus
digitTwins:
  selectDigitalMan: select digital man
  demoVideo: Demo Video
  errorExample: Poor Example
  errorPersonalPhoto: Non-personal Photo
  improperlyDressed: Improperly Dressed
  inputModelRule: Please enter the model name
  inputModelValue: Please upload the model video
  selectCutoutType: Please select cutout type
  inputEmail: Please input email
  authDoc: Authorization letter template
  maskFace: Facial Obstruction
  modelName: Model Name
  multipleFaces: Multiple Faces
  precautions: Precautions
  reSubmit: Please resubmit on the current page
  videTime: The duration should be between {videoTimeMin} and {videoTimeMax} minutes
  tips:
    five: >-
      The uploaded video will be used for digital human customization, please
      carefully choose the video to upload
    four: >-
      Please keep the face ratio in the video moderate, cover the shoulder
      area, the head proportion should not be too large or too small
    one: >-
      Upload a local video, after human review and filtering, we will train
      to output a more beautiful digital person image for you
    three: >-
      The video requires a front view, clear facial features without
      obstruction, do not tilt your head or side
    two: >-
      Training videos must be between 4 minutes and 6 minutes long, with dimensions of 1920*1080(16:9),1080*1920(9:16),3840*2160(16:9), or 2160*3840(9:16). Other sizes are not currently supported.
    size: For example
  tooBigFace: Too Large Face
  trainVideo: Training Video
  uploadPrompt: Upload Prompt
  vague: Blurry
imageAI:
  currentPrompt: Current Prompt
  historyRecord: History Record
  apply: Apply
  describeCost: Describe cost {msg} credits
  uploadImage: Upload Image
  senior:
    seedDesc: Random noise seed
    chaosDesc: Lower chaos values give more reliable, repeatable results.</br>High chaos values will produce more unusual and unexpected results and combinations
    stylizeDesc: Low stylize values produce images that closely match the prompt, but are less artistic.</br>High stylize values create images that are very artistic but less connected to the prompt.
    styleDesc: cute Create charming and cute characters, props and scenes. <br />expressive has a more refined sense of illustration. <br />original Use original Niji model version 5 <br />scenic to create beautiful backgrounds and movie character moments against the backdrop of fantasy environments.
    tileDesc: The generated image can be used as repeating tiles to create seamless patterns for fabrics, wallpapers and textures.
    inputSeed: input number
    sampleDesc: Which sampler to use during diffusion. <br />If this value is omitted, we will automatically select the appropriate sampler for you.
    cfgDesc: The higher the value, the closer the image is to the prompt word
  advancedSetting: Advanced Setting
  positivePrompt: Positive Prompt
  negativePrompt: Negative Prompt
  describe: Describe
  allowed: Commercial Use Allowed
  tutorial: Tutorial
  lawTips:
    Please abide by laws and regulations, uploading of pornographic, violent,
    reactionary images is prohibited,Violation of the rules will be banned
  warning: Warning
  modelSelection: Model Selection
  apiService: API Service
  backSquare: Back to Square
  calculateStep: Calculation Steps
  confirmPublish: Confirm Publish?
  creationCost: Creation Costs {totalPrice} Credits
  creditRecharge: Credit Recharge
  deleteImage: Delete Image
  doubleScale: 2x Enlargement
  fastQueue: Fast mode, {count} people in queue
  fastMode: Fast Mode
  generateNum: Generation Number
  generateSpeed: Generation Speed
  generating: Generating...
  hdDownload: HD Download
  imageDescription: Image Description
  imageToImage: Generate Image from Image
  imageWeight: Original Image Weight
  imageWeightRange:
    default: Default
    high: High
    low: Low
    mid: Mid
  inputPrompt: Please Enter Prompt
  inputPromptRule: Prompt must be at least 5 characters

  midButtonTypeDesc:
    draw: Original Image
    r: Redraw This
    u1: Enlarge U1
    u2: Enlarge U2
    u3: Enlarge U3
    u4: Enlarge U4
    v1: Transform V1
    v2: Transform V2
    v3: Transform V3
    v4: Transform V4
    high_variation: High variation
    low_variation: Low variation
    out_paint_2: 2x zoom
    out_paint_1_5: 1.5x zoom
    pan_left: Left pan
    pan_right: Right pan
    pan_up: Up pan
    pan_down: Down pan

  painting: Painting...
  picViolateRule: Image suspected of violation
  predictTime: Estimated time within {time} minutes
  promptGenerator: Prompt Generator
  promptPlaceholder: >-
    Image description is better in short sentences or phrases, supporting input
    in languages such as Chinese and English
  publishSquare: Publish to Square
  publishSuccess: Publish Successful
  redrawCost: Redrawing costs {credits} credits
  zoomCost: Zoom costs {credits} credits
  relaxQueue: Regular mode, {count} people in queue
  styleSelect: Style Selection
  upscaleCost: Enlargement costs {credits} credits
  variation: Variation
  variationCost: Variation costs {credits} credits
  waiting: In Queue...
  waitingBack: Task Submitted...
  widthHeight: Width and Height Dimension
  publishDesc: Set to paid, other users need to pay credits to view, 80% of the paid credits will be returned to you
  publishDescribe: Describe
  publishIsFree: Is Free
  publishFree: Free
  publishPaid: Not Free
  publishTag: Tag
  publishSuccessDetail: Published successfully, please go to the square to view my

login:
  back: Back
  become: Become
  definite: Confirm
  emailOrPhone: Email or phone number
  emailOrPhoneReg: Please enter a valid email or phone number
  unsupportedCountry: Unsupported country phone number format
  inputEmailOrPhoneReg: please enter your mobile number
  switchToEmail: Click to switch to email mode
  switchToPhone: click to switch to phone number mode
  welCometoLogin: Welcome to login
  email: Please enter your email
  forget: Forgot password?
  weChatQRCodeLogin: WeChat QR Code Login
  loginWitWeChatScan: WeChat QR Code Login
  mobileEmailLogin: Mobile/Email Login
  otherloginMethods: Other login methods
  verificationCodeLogin: Verification Code Login
  freeLogin: 7 days free login
  getVerifyCode: Get verification code
  info: Resend after a few seconds
  inviteCode: Invitation Code
  inviteCodePlaceholder: Invitation Code (optional)
  joinUs: Join us
  login: Login
  loginFirst: Please login first
  loginSuccess: Login successful
  oneMember: a member
  password: Password
  passwordDifferentReg: The two passwords are not the same!
  passwordReg: Please enter password
  passwordRuleReg: >-
    The password should be a combination of any two of numbers, letters,
    symbols, 6-18 characters in length
  passwordSureReg: Please enter confirm password
  passwordUpdateReg: Password update successful
  phone: Phone number
  phoneCorrectReg: Please enter a correct phone number format
  nameCannotEmpty: Name cannot be empty
  informationCannotEmpty: Contact information cannot be empty
  phoneLogin: Phone login
  phoneReg: Please enter phone number
  emailReg: Please enter email
  emailCorrectReg: Please enter a valid email
  pleaseCompleteVerify: Please complete the verification first!
  pleaseWaitVerify: Please wait for the verification to complete
  privacyPolicy: Privacy Policy
  qRCodeLogin: QR code login
  qqLogin: QQ login
  readAccept: I have carefully read and accept
  register: Register
  passwordLogin: Password Login
  registerSuccess: Registration successful
  remember: Remember password
  serviceAgreement: Service Agreement
  smsVerifyCode: SMS verification code
  sure: Confirm password
  test: Simulation test
  thirdLogin: Third party login
  tickPrivacy: Please tick privacy policy
  tip: Scan the code and click "confirm" to login
  scanTip: After scanning the QR code to follow the official account for the first time, a new account will be automatically registered
  scanTips: WeChat QR Code Login/Register
  lateQr: QR code expired, click to refresh
  reScan: Click to refresh
  loginRefresh: Login information expired, click QR code to refresh
  updatePassword: Change Password
  username: Username
  callbackAddress: Callback Address
  changeLoginPassword: Change Login Password
  usernameReg: Please enter username
  verifyCode: Verification code
  verifyCodeCorrectReg: Please enter the correct verification code
  verifyCodeLogin: Verify login
  verifyCodeReg: Please enter verification code
  verifyCodeSend: Verification code has been sent
  verifyCodeSixReg: Please enter a 6-digit verification code
  weChatLogin: WeChat login
  wxMobileTips: Please copy the current page link to WeChat and open it to log in.
  changeWithdrawPwd: Change Withdraw PWD
  verifyIDCardReg: Input ID Card
  verifyIDCardCorrectReg: Input Correct ID Card
  verifyNameReg: Input Name
  pleaseRequest: Please enter your request
  websiteName: Website Name
  verifyWebsiteNameReg: Input Website Name
  key: personalized secondary domain (such as ai 99, etc.)
  verifyKeyReg: Input personalized secondary domain(such as ai 99, etc.)
  verifyKeyLengthReg: Please enter any combination of 2-10 characters letters or numbers
  agentInitTips: Please enter your real name information and we will contact you as soon as possible
  discountCodeApplyInfoReg: Please enter discount code apply information
  enterChName: Please enter your name
  enterAlipayAccount: please enter your Alipay account
  setWithdrawalInformation: Set Withdrawal Information
  enterPaymentAccount: Please enter your payment account
  enterValidPaymentAccount: Please enter a valid payment account
  enterBankAccount: Please enter your bank account number
  enterBankBranchLocation: Please enter the bank branch location
  enterValidBankAccount: Please enter a valid bank account number
  alipay: Alipay
  bankCard: Bank Card
  withdrawalMethod: Withdrawal Method：
menus:
  about: About
  aboutChatAI: About Chat AI
  artificialIntelligenceCertification: Artificial Intelligence Training
  aboutDigitHuman: About Digit Human
  aboutFutureAI: About Future AI
  aiImage: AI Drawing 🔥
  beginCreation: Start creation
  clickCollapse: Click to collapse
  clickExpand: Click to expand
  createWork: Create work
  digitHuman: Digital Human
  realPerson: Real Person
  digitHumanOperation: Digital Human Operation
  digitTwins: Digital Twins
  enterpriseCooperation: Enterprise cooperation
  hsFive: "500"
  hsabnormal: Exception page
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hshome: Home
  hslogin: Login
  mine: My account
  paintSquare: Painting square 🔥
  permission: Permission management
  permissionButton: Button permission
  permissionPage: Page permission
  techSupport: Technical Support
  userCenter: User Center
  MenuWorkManagement: Work Management
  MenuDigitalAssets: Digital Assets
  outstandingCase: Outstanding Case
  customizedMode: Customized Model
  helpCenter: Help Center
  aboutUs: About Us
  MenuMyCustom: My Customizations
  MenuMyRights: My Assets
  usageRecord: Consumption Record
  useRecord: Usage records
  videoSynthesisDuration: Video Synthesis Duration
  videoDuration: Video Synthesis (Seconds)
setting:
  api: API
  avatarLink: Avatar Link
  balance: API Balance
  chatHistory: Chat History
  config: Configuration
  description: Description
  general: Overview
  httpsProxy: HTTPS Proxy
  language: Language
  name: Name
  resetUserInfo: Reset User Info
  reverseProxy: Reverse Proxy
  setting: Settings
  socks: Socks
  theme: Theme
  timeout: Timeout
status:
  loading: Loading...
store:
  addRepeatContentTips: Content repeat {msg}, please reenter
  addRepeatTitleTips: Title repeat, please reenter
  clearStoreConfirm: Confirm to clear data?
  description: Description
  downloadError: Please check network status and validity of the JSON file
  editRepeatContentTips: Content conflict {msg}, please reedit
  editRepeatTitleTips: Title conflict, please reedit
  importError: Key-value mismatch
  importPlaceholder: Please paste JSON data here
  importRepeatContent: Content repeat skipped {msg}
  importRepeatTitle: Title repeat skipped {msg}
  local: Local
  online: Online
  onlineImportWarning: Note Please check the source of the JSON file!
  title: Title
table:
  date: Date
  dateRange: Date range
  endDate: End date
  fromDate: From date
  name: Name
  reason: Reason
  recent3Month: Recent three months
  recentMonth: Recent month
  recentWeek: Recent week
  status: Status
  to: To
  video: Video
  creationTime: Creation Time
  format: Format
  title: Title
  text: Text
  audio: Audio
  buyDate: Purchase Date
  undataDate: update time
  contactName: Name
  mobile: Mobile
  phone: Telephone
  idCard: ID Card
  email: Email
  telphone: Telephone
  digitalHuman: Digital Human
  childType: Detail
  version: Version
  type: types of
  operate: Operate
  updateInfo: Update Info
  updateBasicInfo: Video information to be supplemented
  updateAudioBasicInfo: audio information to be supplemented
  info: Personal Information to be Added
  uploadInfo: Upload training video
  uploadAudio: Uploading training audio
  pendingReview: Under review
  pendingAudio: Under review
  pendingTraining: Digital people in training
  pendingTrainingAudio: The audio is currently in training
  trainingSuccess: Training completed
  clickToMake: Click to create
  successaActition: Click to create
  trainingAudio: Audio cloning completed
  waitingForUpload: Approved, waiting for upload
  waitingForVideoUpload: Approved, waiting for video upload
  mySound: My Voice
  soundName: Voice Name
  soundSex: Voice Gender
  soundType: Voice Type
  soundAct: Status
  cloningProcess: process
  illustrate: Illustrate
  largeScreen: Large Screen Management
techSupport:
  one: >-
    Hello, we also provide customized solutions for digital humans, voice
    recognition, smart painting, Chat AI and other business to help companies
    achieve digital and intelligent upgrades.
  two: >-
    If you need related cooperation, please send an email to our email address
    {email}, our professional engineers will reply to you as soon as possible,
    and provide detailed solutions.
upload:
  tipsType: Supported file type
  fileSize: File size cannot exceed {maxSize}MB
  filePixError: The current resource resolution is incorrect, please upload the correct resolution resource.
  video:
    rule1: Video only supports mp4 format!
    rule2: Video size cannot exceed {videoMaxSize}MB!
    rule3: Video size cannot exceed {videoMaxSize}G!
    tips1: Drag the video to this area or click
    tips2: Supported video type mp4
  audio:
    rule1: Audio only supports wav/m4a/flac format!
    rule2: Audio size cannot exceed {maxSize}MB!
    tips1: Drag the recording to this area or click
    tips2: Supported recording types wav/m4a/flac
  img:
    rule1: Image only supports jpg/png/gif !
    rule2: Image size cannot exceed {imgMaxSize} MB!
    rule3: Image only supports jpg/png !
    tips1: Drag the image to this area or click
    tips2: Supported image types jpg/png/gif
    tips3: Supported image types jpg/png
  image:
    rule2: Image size cannot exceed {imgMaxSize} MB!
  application:
    rule2: pdf file size cannot exceed {maxSize}MB!
  model:
    rule2: 3d file size cannot exceed {maxSize}MB!
  maxUploadNum: Maximum support for uploading 2 images
  uploadAvatar: Upload Avatar
  uploadFailed: Upload Failed
  uploadSameImage: Please do not upload the same image
  uploadSuccess: Upload Successful
userCenter:
  pleaseUpload: Please upload an image
  rule1: Only JPG or PNG format images can be uploaded
  rule2: Image size cannot exceed 500KB
  changeAvatar: Change Avatar
  avatarConfirm: Confirm to change avatar
  AIQA: AI Q&A
  accountInfo: Account Information
  activateNow: Activate Now
  activationCode: Activation Code
  activationMethod: Activation Method
  amount: Amount
  creationTime: Creation Time
  dedicatedService: Exclusive Customer Service
  digitalHumanTime: Digital Human Time
  expiresOn: Expires On
  inputActivationCode: Please enter activation code
  invitationPlan: Invitation Plan
  inviteLink: Invitation Link
  memberType: Member Type
  nickname: Nickname
  nicknameRule: Nickname must be between 2-20 characters
  callbackAddressMustBeURL: Callback address must be a URL
  inviteCode: Invitation code cannot be empty
  orderId: Order ID
  orderRecord: Order Record
  paintingCredits: Painting Credits
  price: Price
  recharge: Recharge
  rechargeRecord: Purchase Record
  remainAvailTimes: You have {count} times remaining
  renew: Renew
  rewardActivity: Reward Activity

  shareFriend: Share with Friends
  subscribeNow: Subscribe Now
  subscribedRecord: Subscription Record
  subscribedService: Subscribed Service
  type: Type
  withdrawAmount: Withdraw Amount
  setWithdrawInfo: Set Withdraw Info
  updateAgentInfo: Update Agent Info
  agentWebsiteConfig: Agent Website Config
  withdraw: Withdraw
  configRealNameTips: You need to log in again after configuring real-name information for the first time.
  scanQrCodeAddService: Customer Service
  AudioUpload: Audio Upload Requirements - Please Pay Attention to the Following Points
  mineDigits: My Digital Human
  mineServe: My Product
  inviteQRCode: Invite QR Code
  downloadQRCode: Download Invitation QR Code
  mineInvite: My Invite
  InvitationList: Invitation List
  rebateList: Rebate List
  withdrawalList: Withdrawal List
  username: Username
  orderID: Order ID
  userID: User ID
  remarks: Remarks
  mySuperior: My Superior：
  mustPicType: Please select the type of keying, required fields
  mustVideoType: Please upload the training video, required fields
  mustFileType: Please upload the authorization file, required fields
  uploadNotice: Video Upload Requirements-Please note the following points.
  chooseClippinType: Choose the image clipping type, and please pay attention to the following points
  videoSuccess: Training video submitted successfully.
  buttonPass: We are reviewing and optimizing your video to enhance the digital character effect. Please be patient, the optimized video will be presented to you soon!
  buttonPassAudio: We are reviewing and optimizing the audio you uploaded to enhance the audio cloning effect!
  awaitingAudio: Your audio has been approved and is awaiting backend submission
  awaitingVideo: Your video has been approved and is awaiting backend submission
  seeSoon: Your digital character is in the process of being created and is expected to meet you within 24 hours.
  seeSoonAudio: Your cloned audio is being produced and is expected to meet you within 24 hours.
  setInformation: Please set the withdrawal information first
  warmReminder: Warm Reminder
  completeInformation: Personal Information to be Supplemented
  orderCompleted: Order Completed
  aiOrderCompleted: Contact Customer Service
  waitingOpen: Awaiting Activation
  waitingOpenDesc: Account activation in progress, expected to be successful within 5 to 30 minutes. You will be notified by email once activated.
welcome:
  five:
    contactUs: Contact Us
    inFutureAI: Come to FUTURE AI
    propeller: Equip your creativity with a propeller
    simple: More efficient, more professional, simpler
    nowsq: Apply for your exclusive discount code now
    digitSimple: Making your interaction with digital humans so simple
    highsend: Highest Commission
    sendinfo: Create a promotional video and get your exclusive discount code now.
    nowdo: Apply Now
    partner: Become a Partner
    common_problem: Common Problems
  footer:
    aboutUs: About Us
    businessCooperation: Business Cooperation
    chatAIMember: ChatAI Member
    companyName: Shenzhen Knock Knock Tech Ltd.
    contactNumber: Contact Number
    contactEmail: Contact email
    contactLine: Contact line
    creditsRecharge: Credits Recharge
    digitHumanRecharge: Digital Human Recharge
    digitalHumanPlate: Digital Human Section
    officeAddress: Office Address
    officeAddressValue: >-
      Room 501, Nanfeng Building, Phase I, Yun Valley, Nanshan District,
      Shenzhen
    one: AI Empowerment - Faster, Stronger, Simpler
    record: 粤ICP备2023038424号
    understandChatAI: Understand Chat AI
    understandDigitHuman: Understand Digital Humans
  four:
    experienceNow: Experience Now
    isMe: Yes, it's me, your efficiency-enhancing elf
    youAsk: Feel free to ask questions, there's always a more professional answer
  one:
    AIPower: AI Empowerment
    combine: Chat AI + Digital Humans, empowering every industry
    createNow: Create Now
    fast: Faster, Stronger, Simpler
  three:
    customizeNow: Customize Now
    digitHumanCustomization: Digital Human Customization
    earnLiving: Let everyone have their own dedicated worker
  two:
    future: The future
    imagine: Every day, more people unleash their imagination and creativity through AI
    youWalk: Letting you walk into
  imageAI:
    imageAI: AI Painting
    support: Supports Stable Diffusion and Midjourney models
    desc: Simple word prompts, wonderful paintings
    desc2: Imagination comes to mind
  sixCooperationCase:
    cooperationCase: Easily create digital humans in bulk
    leiJunSpeech: The Insights I Gained from Lei Jun's Speech
    aiDigitalHumanDo: What can AI digital humans do
    howHumanCreated: How was my digital human created
    WhatDigital: What is an AI digital human
    illusions: Anxiety and fear are just illusions
    selfIntroduction: The one-minute self-introduction trick
    StudyAbroad: Study Abroad in the USA, UK, and Australia
    notInteractWithDigitalHumans: Those who are unprepared should not interact with digital humans
    theCEOofJPMorganChase: The CEO of JPMorgan Chase said that we only need to work three days a week
    aiDigitalHuman: AI Digital Human
    landCases: Land Cases
    advantagesOfYoga: Advantages of Yoga
  news:
    learnMore: Learn More
  AdvantagesLayout:
    similarity: Similarity
    cloneSimilarity99: Clone Similarity 99%
    mostRealisticDigitalHuman: The most realistic digital human on the internet
    cloneSimilarity60: Clone Similarity 60%
    poorEffect: Poor effects, clearly identifiable as digital human
    speed: Speed
    rapidCloning: Rapid Cloning
    oneMinuteRapidClone: 1-minute rapid cloning, video generated in 3 minutes
    slowCloneSpeed: Slow cloning speed
    typicallyTakes: Typically takes 3-5 days
    image: Image
    multipleDigitalHumanImages: Multiple digital human images
    switchWhenever: Switch whenever you want, no fear of mistakes
    singleDigitalHumanImage: Single digital human image
    onlyOneSet: Only one set available, extra sets cost money
    quality: Quality
    hdAndUhdDoubleModel: HD and UHD double model
    switchQualityFreely: Switch between two qualities freely, 1080P and 4K at your choice
    singleModel: Single model
    onlyOneQualityAvailable: Only one quality available
    productAdvantageComparison: Product Advantage Comparison
    digitalHuman: Digital Human
    otherHouseDigitalHuman: Other Companies' Digital Human
  ProcessLayout:
    stepOne: Step 1： Image Cloning
    uploadVideoMaterial: Upload video material
    quicklyGenerateDigitalAvatar: Quickly generate a digital avatar
    oneMinuteRapidClone: 1-minute rapid cloning
    oneToOneRealHumanReplica: 1:1 real human replica
    ultraHighDefinitionQuality: Ultra-high definition quality
    stepTwo: Step 2： Voice Cloning
    recordVoiceSample: Record voice sample
    generateAiVoiceModel: Generate AI voice model
    comparableToRealHumanVoice: Comparable to real human voice
    supportBilingual: Supports bilingual in Chinese and English
    naturalEmotionalExpression: Natural emotional expression
    stepThree: Step 3： Video Production
    inputScript: Input script
    automaticallyGenerateVideoContent: Automatically generate video content
    threeMinutesNewVideo: 3 minutes to generate new video
    support1080P4K: Supports 1080P/4K
    oneClickExportShare: One-click export and share
    digitalHumanProductionProcess: Digital Human Production Process
    onlyThreeSteps: Only three steps
    easyForBeginners: Easy for beginners to make videos
    startMaking: Start Making
  UseCasesLayout:
    shortVideoAcquisition: Short Video Acquisition
    noProfessionalEquipment: No professional equipment needed, a smartphone is enough to produce high-quality short videos. AI digital humans help you easily create hit content and quickly gain precise traffic.
    personalIPCreation: Personal IP Creation
    breakTimeAndEffortLimits: Break the limits of time and effort, let AI digital humans continuously produce high-quality content. Intelligent content creation helps you quickly establish a personal brand.
    brandPromotion: Brand Promotion
    customDigitalSpokesperson: Customize exclusive digital spokespersons for enterprises, applicable to product demonstrations, brand promotions, corporate training, etc. Ensure a unified brand image communication.
    courseProduction: Course Production
    quicklyTransformCourseContent: Quickly transform course content into vivid video explanations. AI digital human instructors are available online around the clock, helping you create professional online courses.
    digitalHumanApplicationScenarios: Digital Human Application Scenarios
    realHumanAppearanceScenes: Real human appearance scenes, AI digital humans are a full-fledged replacement
  CommentWallLayout:
    everyoneIsUsingIt: Everyone Is Using It
    makeCreationEasier: Make Creation Simpler, Monetization Easier
workManage:
  compositing: Compositing
  createNow: Create Now
  empty: Nothing is here
  finish: Finish
  myDraft: My Drafts
  myWork: My Works
  searchWork: Search Works
  time: Duration
  unFinish: Unfinished
  workStatus:
    "0": Compositing
    "1": Successful
    "2": Failed
  workNameReg: Name must be between 2-20 characters
workbench:
  copyEdit: Copy and Edit
  customize: Customize
  digitalAssets: My Digital Assets
  downloadClip: GO Clip
  draft:
    tips: Work can't be restored after deletion, please confirm the deletion?
  free: Free
  inProduction: Editing
  makeVideo: Make Video
  moreWorks: More Works
  myModel: My Model
  packageDownload: Client Download
  public: Public
  available: Available
  quickCreate: Quick Create
  recentWorks: Recent Works
  searchModel: Search
  toEdit: Go to Edit
  video: Video
  all: All
  sex: Gender
  male: Male
  female: Female
  maleVoice: male
  femaleVoice: female
  background: Background
  transparent: Transparent
  fixed: Fixed
  racial: Race
  asia: Asian
  america: Caucasian
aiTools:
  aiTools: AI Tools
  navigator: Navigator
  cleanUp: Clean Up
  cleanUpHot: Clean Up 🔥
  cutout: AI Cutout
  cutoutHot: AI Cutout 🔥
  cutoutFace: Cutout Face
  losslessHD: Lossless HD
  photoClearer: Photo Clearer
  photoColoring: Photo Coloring
  cartoonAvatar: Photo To Cartoon
  aiPoster: AI Poster
  textExtract: Text Extract
  textToAudio: Text To Audio
  textToAudioSubTitle: Free switching of text and voice, unlimited creation
  videoTranslation: video translation
  avoidBackgroundMusic: Avoid using videos with background music and noise, as they can reduce the quality of the output video.
  translateWork: Translate Work
  originalLanguage: Original Language
  targetLanguage: Target Language
  selectInitialLanguage: Please select the initial language of the video
  translateTo: Translate to
  noWorks: No Works Available
  selectTargetLanguage: Please select the target language for video output
  videoInitialLanguageNotEmpty: The initial language of the video cannot be empty
  videoTargetLanguageNotEmpty: The target language of the video cannot be empty
  videoNotEmpty: The video cannot be empty
  videoTranslationinfo: Translate your explanation videos into other languages ​​with one click while retaining your voice and style
  audioToText: Audio To Text
  audioToTextDesc: Switch without restrictions, save worry, time and effort
  imageDetail: Image Detail
  slogan1: Explore the unknown
  allTools: All Tools
  creativitySlogan: Let Creativity Illuminate Reality
  slogan2: Massive tool software,，{msg} One-stop experience
  midjourneyPaint: Midjourney Paint
  bussinessCard: Interactive Digital People
  bussinessCardDesc: Digital people who can interact in real time
  supportMidCommand: Support midjourney all command
  sdPaint: Stable Diffusion Paint
  sdDesc: Become professional painter in seconds
  chatAIDesc: It will always answer you fluently
  human: human
  humanDesc: Customize now
  digitHuman: Digital Human Video Production
  digitHumanDesc: Make a professional short video in 3 minutes
  digitLiveHuman: digital human live broadcast
  digitLiveHumanDesc: live broadcast 24 hours a day until the sun sets
  cleanUpDesc: One key to erase debris
  cutoutDesc: Easily cutout images
  cutoutFaceDesc: Cutout avatar with one key
  losslessHDDesc: One key processing of low-quality pictures
  photoClearerDesc: Blurred images become clear
  photoColoringDesc: Coloring old black and white photos
  cartoonAvatarDesc: One key switch "comic faces"
  textExtractDesc: One key extract video text
  textToAudioDesc: Text, audio conversion
  comingSoon: Coming soon ...
  expect: Expect
  expectDesc: More functions expect
  extractRecord: Extract Record
  converting: Converting ...
  convertFail: Convert Fail
  textExtractSuccess: Text Extract Success
  convertRecord: Convert Record
  audioToTextTips1: Supports the identification of audio files within 100MB and no longer than 2 hours.
  audioToTextTips2: 1:Supports 30+ dialects and 50+ foreign languages
  audioToTextTips3: 2:Complete 30 minutes of speech in 10 seconds at the fastest
  audioToTextResult: Audio to text result
  return: Return
  startProcessing: Start Processing
  Re-upload: Re-upload
  tutorial: Tutorial
partnerPlan:
  month: Month
  silver: Silver
  gold: Gold
  diamond: Diamond
  king: King
  exclusiveIndependentWebsite: Exclusive Independent Website
  partnerPlan: Partner Plan
  study: Training Base
  partnerPlanDesc: Become a top promoter
  enthusiast: You are an AI enthusiast
  humanExplorer: You are a digital human explorer
  understandMore: You need to understand more detailed cooperation plans
  partnerDesc1: |
    <p>Dear Partner,</p>
    <p>Thank you for your continuous support for {name}.</p>
    <p>To establish a longer-lasting relationship and sharing,</p>
    <p>We cordially invite you to be part of our <span class='font-bold'>{desc}</span>.</p>
    <p>Become a top promoter.</p>
  partnerDesc2: |
    <p>We will create for you,</p>
    <p>A completely exclusive AI tool website,</p>
    <p>100% replicating {msg}'s features and pages.</p>
  partnerDesc3: |
    <p>Now, it only takes three easy steps To own it</p>
  partnerDesc4: |
    <p>01 Choose your promotion plan.</p>
    <p>02 Invite more friends to experience your website.</p>
    <p>03 Enjoy generous rewards instantly.</p>
  plan: ¥ Plan
  exclusive: |
    <p class="text-[1rem] mt-2">You will have your exclusive website.</p>
    <p class="text-[1rem] mt-2 font-bold">Recharge benefits of</p>
  xiaoBingDigitReward: Xiao Bing Digit Man Reward
  customizedWebSite: Customized exclusive website
  otherDigitReward: GuiJi/TengXun Digit Man Reward
  otherProductReward: Other Product Reward
  giftDesc: have {number} XiaoIce trial versionDigital Human
  giftXbLiteDesc: Get {number} Xiaobing lite version digital human for free
  giftGjLiteDesc: Get {number} GuiJi advanced custom-made digital figures for free
  invitation_message: Dear Partner, we sincerely invite you to join our Partner Program, grow with us, and explore the infinite possibilities of the AI era
  top_promoter: Become a Top Promoter
  easy_three_steps: Own It in Just Three Easy Steps
  ai_platform_replica: We will create a completely exclusive AI tool platform for you, 100% replicating the platform’s functions and pages
  move_fingers: Move Your Fingers
  choose_package: Choose Your Promotion Package
  invite_friends: Invite More Friends
  experience_website: Experience Your Exclusive Website
  enjoy_now: Enjoy Now
  generous_rewards: Generous Rewards
  core_advantages: Five Core Advantages
  easy_ai_entrepreneurship: Easily Achieve Light Entrepreneurship in the AI Era
  authoritative_certification: MIIT Standard
  ai_operator_project: The AI Operations Engineer standards are set by the MIIT Education and Examination Center. The learning system is built systematically in stages, progressively advancing through the content. The course material closely follows AI technology application trends and is continuously updated
  policy_support: Policy Support
  policy_guidance: Complies with the national policy guidance on deepening the research and application of big data and artificial intelligence, responding to the "AI+" initiative
  continuous_update: Continuous Updates
  updated_training: Our training continuously updates course content based on industry development and technological progress to ensure that students can master the latest AI technologies and applications
  ai_tools: AI Tools
  cutting_edge_services: We provide the most cutting-edge AI digital human services and AI toolsets to support your market promotion and brand promotion
  solutions: Solutions
  partnerships: We closely collaborate with tech companies like Microsoft Xiaobing, Tencent Zhiying, and Sil-based Intelligence, providing not only training services but also one-stop AI solution services
customImage:
  shareImage: Share Image
  posterDesign: Poster Design
  editImage: Edit Image
  historyImages: Image History
  aiTools: AI Tools
  shareTips: Share image display information
  customTitle: custom title (optional)
  saveImage: Save Image
  imageLink: Image Link
aiPoster:
  background: Background
  element: Element
  cartoon: Cartoon
  layer: Layer
cleanUp:
  cleanUp: Clean Up
  cleanUpDesc: Repair wherever you want, erase defects in one second, leaving no trace
  tryOtherDemo: Or try the following example
  imageHistory: Image Processing History
  demo: For example
  frequentQA: Frequently asked questions
  tryThisExample: Try this example
  Q1: What is Clean Up?
  A1: Clean Up is a special technique used to remove unwanted objects from photos. Repair wherever you want, and erase the flaws in a second without leaving any traces
  Q2: Why is Clean Up better than other repair tools?
  A2: Clean Up is an advanced editing tool based on artificial intelligence, which is much better than other clone stamp tools. Cloning tools like Adobe Photoshop Fix require background references, whereas our AI can actually guess unwanted text, unwanted people, unwanted objects in just a few clicks.
  Q3: What image resolutions can Clean Up handle?
  A3: You can import any size image in Clean Up.
  Q4: How much does it cost to Clean Up?
  A4: Prices can be viewed in the billing rules on the top menu
  chooseUs: Why Choose Us
  fiveGood: Five Core Advantages,Customizable Options
  choose: Ultra-Low Threshold
  onTime: Real-Time Supervision
  fit: Custom-made
  aiBox: AI Tools
  oneChoose: One-Stop Service
  QQ1: Joining us as a partner requires a very low threshold, starting at just 999 yuan
  QQ2: Our online system grants partners direct management authority, allowing you to personally review clients' cloned video and audio materials to ensure high-quality service delivery
  QQ3: We custom-build a customizable website for you, where you can freely adjust the pricing system, color scheme, contact information, etc., according to your needs
  QQ4: In addition to providing digital human services, we also prepare the most cutting-edge AI toolset for you, supporting your market promotion and brand publicity
  QQ5: We offer comprehensive digital human agency services, including well-known products like Xiaoice, Silicon-based, and Zhiying, providing you with a one-stop solution
  removePassersby: Remove Passersby
  removePassersbyDesc: Quickly remove pedestrians or tourists who suddenly break into the photo
  removeClutterFromPhoto: Remove clutter from photos
  removeClutterFromPhotoDesc: Removes clutter from a photo, such as trash cans, buildings, or power cords
  removeText: Delete text, logo, etc. in the picture
  removeTextDesc: Say goodbye to complicated and cumbersome operations. Intelligent AI accurately identifies unwanted objects
  startClean: Start Clean
  smearClean: Smear Clean
  rectClean: Rect Clean
  selectClean: Select Clean
  brushSize: Brush Size
  smear: Smear
  move: Move
cutout:
  smartCutout: AI Intelligent Cutout
  smartCutoutDesc: Upload pictures with one click to achieve quick cutout. The intelligent AI has been trained on millions of images and can accurately identify the subject that needs to be retained and remove redundant background, making image cutout easier and more convenient.
  product: Product
  productDesc: Remove Product Background
  portrait: Portrait
  portraitDesc: Remove portrait background
  car: Car
  carDesc: Remove car background
  animal: Animal
  animalDesc: Remove animal background
  graphics: Graphics
  graphicsDesc: Remove graphics background
cutoutFace:
  cutoutFace: Avatar Cutout
  cutoutFaceDesc: ID card, visa, cartoon body, mugshot, POD favorite
imgBeautify:
  imgBeautify: One-Click Beautification
  imgBeautifyDesc: Process poor-quality images to generate beautiful images with one click
  autoExposure: Automatic Exposure
imgFaceBecomesClear:
  imgFaceBecomesClear: Blurred faces become clear, old photos can be enhanced with one click
  imgFaceBecomesClearDesc: Low-quality photos that are out of focus, low-resolution, blurry, pixelated, old, or damaged can be intelligently repaired into high-quality photos with sharp images and clear focus.
  demo1: Face becomes clearer
  demo1Desc: Uses artificial intelligence deep learning technology to identify blurry faces and restore facial details in high definition. Even night photos taken due to too dark light and underexposure can easily light up your beauty with one click.
  demo2: Professional restoration of old photos
  demo2Desc: Use AI technology to add details to original blurry photos, restore high-definition photos, and make old photos glow with new vitality. Whether it's an old photo that's yellowed or taken with an old device, it can be made clear with just one click, helping you regain your childhood memories.
  demo3: Not only the portrait becomes clearer
  demo3Desc: Old photos that are out of focus, low resolution, pixelated, or damaged can be intelligently repaired, and the noise and jagged parts of the image will be automatically processed to easily restore true high-definition photos. In addition to intelligent portrait restoration, we also support one-click lossless enlargement of picture size and high-definition enlargement of local details!
  demo4: Intelligent repair of blurry pictures
  demo4Desc: Using artificial intelligence AI learning technology, one-click accurate intelligent recognition can easily repair blurred product images, icons, animation materials, etc., and restore real high-definition pictures without loss.
imgPhotoColoring:
  imgPhotoColoring: Automatically convert black and white photos into color photos
  imgPhotoColoringDesc: Colorize old black and white photos to make them shine again and show a different charm. Make the memories of you and your family more vivid, as if they were yesterday again. Experience different moods and touches.
  demo1: Give old photos new life
  demo1Desc: Recolor old black and white photos of your family and bring the past back to you with vibrant colors to make memories come alive.
  demo2: Restore true and natural colors
  demo2Desc: Different from ordinary shaders, we are based on intelligent AI coloring algorithms and through deep learning of large amounts of data, we can easily restore more realistic and natural colors to black and white photos.
imgCartoonAvatar:
  imgCartoonAvatar: Disney Special Effects Cartoon Avatar
  imgCartoonAvatarDesc: This is an AI artificial intelligence that switches "comic face" with one click. Upload an avatar and you can instantly transform into a Disney princess on the run.
  demo1: Case 1
  demo2: Case 2
  demo3: Case 3
  demo4: Case 4
messageTips:
  fileUploaded: File Uploaded
  updateSuccess: Update Success
  deleteSuccess: Delete Success
  publishSuccess: Publish Success
  putTopSuccess: Put Top Success
  copySuccess: Copy Success
  createSuccess: Create Success
  convertSuccess: Convert Success
  convertJobSubmit: Convert Job Submit
  imgProcessSuccess: Image Process Success
  smearTips: Please apply the area to be repaired
  rectTips: Please choose the area where the restoration is repaired
  clearSuccess: Clear Success
  chatAIHistoryMode: Under the current mode, sending messages will bring previous chat records
  chatAISingleMode: In the current mode, send messages will not carry the previous chat history
  submitSuccess: Submit Success
  updateWithdrawPWDSuccess: Modify the withdrawal password, please log in again!
  setWithdrawInfoSuccess: Set the successful withdrawal information, please log in again!
  selectPrompt: Please select a prompt
  promptRule: The prompt cannot exceed 1000
  loginFirst: Login First
  validateCodeFirst: Please get the verification code first
  compositeWorkName: The name must be between 2-20 characters
  withdrawSuccess: Withdraw Success
  configSuccess: Config Success
audioClone:
  title: Online cloning
  voliceUpload: Local Upload
  subTitle: Personalized Voice Customization Service
  browserNoSupport: Browser incompatibility
  browserNoSupportInfoA: Sorry, your browser cannot use the microphone.
  browserNoSupportInfoD: Currently only supports computer version.
  browserNoSupportInfoB: Please open the browser to scan or click and copy the URL.
  browserNoSupportInfoC: It is recommended to use browsers such as Chrome, Firefox, Safari, etc. that support recording.
  copyUrl: Copy the URL
  copySuccess: The address has been successfully copied
  copyError: This browser does not support automatic copying
  langsInfo: The countries where your voice cloning can support the national language
  langs: English Japanese Hindi German French Spanish Korean Portuguese Italian Indonesian Dutch Turkish Filipino Polish Swedish Bulgarian Arabic Czech Romanian Finnish Malay Greek Croatian Slovak Danish Tamil Ukrainian
  iknow: I see
  stepOneTitle: Personalized voice cloning process
  stepOneInfoAtitle: Environmental detection
  stepOneInfoAinfo: Please grant permission for the website to use a microphone in a quiet environment, such as a recording studio or bedroom with minimal echo and noise.
  stepOneInfoBtitle: Record 5 high-quality audio clips
  stepOneInfoBinfo1: 1、We recommend using the built-in microphone of a mobile phone at a distance of about 10cm to ensure clear recordings.
  stepOneInfoBinfo2: 2、As a voice actor, I need to ensure clear pronunciation, full and rich voice, and reduce noise like swallowing saliva during recording.
  stepOneInfoCtitle: Waiting for voice training
  stepOneInfoCinfo1: Please wait patiently,It will take about 1 minutes to clone your voice.
  stepOneInfoDtitle: Try listening and using it
  stepOneInfoDinfo: You can view and listen to the voice in Personalized Voice Customization.
  iread: I have read and agreed.
  iservece: 《Privacy Policy》
  iokkonw: Click to confirm and agree.
  ilangs: 《Multilingual Support》
  irules: 《Billing Rules》
  readyClone: Start cloning
  dagreeTitle: Personalized Voice Customization Service Agreement.
  not: Note
  notice: Note:This voice cloning will consume your
  credits: Points!
  notiread: Please confirm and agree to the 《Privacy Policy》 first.
  iok: Confirmation
  stepTwoTitle: Voice Naming
  stepTwoSound: Voice
  stepTwoIDempty: Voice ID Not Empty
  stepempty: Voice name Not Empty
  stepTwoInputInfo: Please enter a 15-character Chinese, numerical, or English character
  stepTwoInput: Please enter a 15-character Chinese, numerical, or English character
  stepTwoInputInfo2: You can use Chinese, numbers, or English characters, with a maximum of 15 characters.
  uploadSound: Upload sound
  uploadLimit: 4:Up to {maxAuido} audio files can be submitted , each audio file must not exceed {maxAuidoSize}MB
  stepTwoSex: Choose your gender
  stepTwoSex1: Male
  stepTwoSex2: Female
  stepTwoSence: Choose a usage scenario
  stepTwoSence1: Technological knowledge
  stepTwoSence2: news broadcasts
  stepTwoSence3: fairy tales
  stepTwoSence4: knowledge sharing
  stepTwoSence5: product introductions
  stepTwoOpenMic: How to enable microphone permissions
  stepTwoMic: Start recording
  stepTwoMic1: Please allow access to the microphone permissions
  stepTwoMic2: Without a microphone, voice recording cannot be conducted ~
  stepTwoMic3: Setup Method
  stepTwoMic4: Safari Users
  stepTwoMic5: Refresh > Click to start recording >
  stepTwoMic6: allow
  stepTwoMic7: Chrome Users
  stepTwoMic8: Click AddressBar /【More】
  stepTwoMic9: orientation
  stepTwoMic10: Click Website information
  stepTwoMic11: Setting >
  stepTwoMic12: Enable microphone permissions
  stepThreeDialog: Considerate advice
  stepThreeDialog1: Please make sure to record in English with a moderate speed.
  stepThreeDialog2: Keep your phone or earphones within 1-2 fist-length distance.
  stepThreeDialog3: Use earphones to translate
  stepThreeDialog4: It provides better results.
  stepThreeDialog5: Make sure to keep the surrounding environment quiet.
  stepThreeDialog6: After clicking the record button, wait for 2 seconds before starting to record. The effect will be better.
  strpThreeBack: Exit
  strpThree: Recording Voice
  strpThree1: Recording progress
  strpThree2: Waiting
  strpThree3: Recording
  strpThree4: Completed
  strpThree5: Preview
  strpThree6: Start
  newstrpThree6: Click to record
  strpThree7: Stop
  strpThree8: Refresh
  strpThree9: Next
  strpThree10: Please confirm all recordings after completion
  strpThree11: Click Confirm to submit all recordings
  strpThree12: Your recording is uploading. Please wait for about 1-5 minutes. To ensure the integrity of the uploaded sound, please do not close or refresh the current page during the upload! Thank you!
  strpThree13: Congratulations! Your cloned voice has been completed. Please go to the "Personal Center - My Customization" section to check it out!
  strpThree14: The recording upload failed. Please close it and click the "Confirm" button again to try again, or contact the system administrator. We apologize for any inconvenience caused. Thank you!
digitalHumanPage:
  nobuy: You have not purchased the digital human yet. Please purchase it and then proceed with the operation.
  downloading: Digital Human Authorization Letter Downloading...
  chosehuman: Chose Digital Human
  imgtype: KeyingType
  imgtype1: KeepBackground
  imgtype2: OnlyPortraits
  imgtype3: Manual Image Clipping
  videohuman: TrainingVideo
  egvideo: EgVideo
  faceuse: ImageAuthorization
  pclick: Click
  downUse: 《Download Authorization Letter Template》
  upface: Upload after signing and taking photos
Withdrawal:
  withdrawal: Withdrawal
  withdrawalInformation: Withdrawal Information
  name: Name
  callyou: Please fill in your request, and we will contact you as soon as possible after submission
  alipayAccount: Alipay Account
  withdrawalAmount: Withdrawal Amount
  withdrawalPassword: Withdrawal Password
future:
  one:
    intelligentCabin: Intelligent Digital Human Holographic Chamber
    interactiveDigitalHuman: Intelligent Interactive Digital Human
    intelligent: Intelligent
    cabin: holographic cabin
    professional: Digital Human & Large Language Model & Interactive All-in-One Machine Screen
    listening: I'm listening, please spea
    clickBelow: Click below to stop recording
    tapMe: tap me to
    conversation: start a conversation
    consult: Consult Immediately
  two:
    core: Core technology conquers R&D challenges in all dimensions
    hardware: Intelligent Hardware
    standard_solution: Standard Solution for Intelligent
    standard_solution_line2: Digital Employee All-in-One Machine
    holographic: Industry-leading intelligent holographic screens
    virtual: Virtual Human Creation
    Metaverse: Rapidly construct virtual human application products
    analysis: User Behavior Analysis
    camera: Camera data collection,real-time monitoring of user behavior data analysis
    interaction: AI Multi-modal Interaction
    variousForms: Real-life and digital human interactions in various forms,Simulating real-life conversations
  three:
    scenarios: Use Scenarios
    methods: Production Methods
    issues: Inquiry about issues
    processing: Business processing
    guide: Functional shopping guide
    weakUp: By integrating core AI technologies such as wake-up, voice recognition, natural language understanding, and virtual human synthesis, we provide users with services including multimodal interaction, business processing, inquiry about issues, news playback, and service guidance.
  four:
    activities: Offline brand activities
    projection: holographic projection
    idols: photo ops with idols
    guide: functional shopping guide
    live: Real-time shooting and transmission of holographic images function, which allows users to experience entertainment play such as real-time face swapping, taking photos with idols, and accelerating brand dissemination.
    contact: Contact Us
  five:
    smart: Shopping mall smart display windows
    advertising: holographic advertising
    analytics: data analytics
    delivery: intelligent delivery
    holographic: Holographic advertising can significantly increase user attention, allowing users to be immersed without the need for 3D glasses, bringing an immersive experience. At the same time, sensors capture user behavior, and the holographic cabin can integrate various data from advertising delivery and user behavior, providing intelligent delivery solutions.
  six:
    plan: Learning plan
    answering: Interactive answering
    streaming: holographic live streaming
  seven:
    stores: Retail commodity brand stores
    cultural: cultural and tourism scenic spots
    useScenarios: Use Scenarios
    service: service-oriented institutions:banks/hospitals/hotels
    public: government and public institutions
    learningPlans: By customizing learning plans, engaging in interactive Q&A, and providing holographic live lectures, we can break through the limitations of time and space in party construction work, making it more intelligent, convenient, and digital. With immersive and interactive learning experiences, party members can more deeply understand the arduous journey of the party, unite and concentrate, temper their party spirit, and improve their literacy.
intelligent:
  oneScreen:
    examinationCenter: MIIT Talent Training
    examinationCenter_line2: "Artificial Intelligence Operator"
    BringingTogether: Courses are designed and continuously updated by experts from the MIIT Education and Examination Center, renowned university professors in China, AIGC practice experts, and top AI enterprise application talents
    project: The project is application-oriented, with standards set by the MIIT Education and Examination Center. It establishes close cooperation with leading companies in the field of artificial intelligence and major universities
    people: Aimed at cultivating professionals with skills in artificial intelligence operations, creating value for enterprises or individuals. Building a talent cultivation ecosystem that integrates industry and education, and collaborates between schools and enterprises
    generative_ai_engineer: Artificial Intelligence Operator
    future_start: Future Start
    ai_skills_upgrade: AI Skills Upgrade, Accelerating Enterprise Transformation
    talent_gap: Talent Gap
    ai_era: AI Era, High Demand for AI Talent
    authoritative_certification: MIIT Standard
    mii_certification: Implementing MIIT Talent Training Project Occupational Technical Standard
    skills_upgrade: Skills Upgrade
    systematic_training: Systematic Training, from AI Basics to Advanced Applications
    enterprise_transformation: Enterprise Transformation
    ai_empowerment: AI Empowerment, New Driving Force for Digital Transformation
  twoScreen:
    course: Course Modules Professional Skills
    artificialIntelligenceOperator: Artificial Intelligence Operator
    gettingStarted: Getting Started
    comprehensive: Comprehensive understanding of artificial intelligence applications
    Professional: Professional
    comprehensiveTools: Comprehensive use of artificial intelligence tools, and deployment of applications
    advanced: Advanced
    advancedDeployment: Advanced deployment of artificial intelligence applications, and data-driven project management
    gettingStarteItem: Fundamental understanding and application of artificial intelligence and large models, proficiency in generating capabilities for AI text, image, audio, and video modalities, introduction to prompt engineering, AI short video operations, AI digital human operations, and how businesses and individuals can embrace the AI era
    professionaItem: Advanced understanding of AI models, prompt writing and optimization, multimodal prompt techniques and applications, enterprise-level AI applications： AI + New Media, AI + E-commerce, AI + Finance, AI + Education, and the implementation of enterprise-level AI intelligent projects
    advancedItem: Artificial intelligence data analysis, customized model development and training, enterprise AI transformation, AI-powered office, AI-driven marketing, deep application of AI in industries, and project management
  threeScreen:
    teaching_objectives: Teaching Objectives
    ai_training_overview: Trainees Systematically Master the Core Knowledge and Skills of AI Operations, Practice-Oriented, Requiring Trainees to Operate in at Least Two Business Scenarios
    ai_training: Targeting Employment, Jointly Creating Communication Centers with Leading Tech Companies and Enterprises to Achieve Precise Talent Delivery
    ai_ecommerce_operations: AI E-commerce Operations
    digital_human_application: Digital Human Application
    ai_live_stream_operations: AI Live Stream Operations
    ai_intelligent_interaction: AI Intelligent Interaction
    ai_graphic_design: AI Graphic Design
    ai_smart_customer_service: AI Smart Customer Service
    customized_customer_service: Customized Model
    others: Others
  fiveScreen:
    smart_future: AI Empowerment
    talent_training_program_all: MIIT Talent Training Project, Empowering AI Operators to Soar!
    talent_training_program: MIIT Talent Training Project
    talent_training_program_line2: Empowering AI Operators to Soar!
    ai_operator_certification: AI Operations Engineer Exam Registration Portal
    exam_name: Name：
    contact_information: Contact Information：
    enter_phone_number: Your Phone Number
    enter_exam_name: Your Name
    sign_up_now: Sign Up Now
    disclaimer: Note： This website is not an official registration site; it only provides registration inquiry and appointment services. All information is subject to the official website of the Ministry of Industry and Information Technology.
firstPage:
  oneScreen:
    industrial: Industrial Informationization Education Examination
    certification: MIIT —— Artificial Intelligence Operations Engineer
    certificationLine2: Professional Technical Certificate
    cultivating: Aimed at cultivating professional talents with skills in artificial intelligence operations, enabling them to proficiently utilize AI technology to solve real-world problems, thus creating value for businesses or individuals
    practicePriented: Practice-oriented
    national: Through national training bases, students engage in practical exercises in AI technology, delve into case studies, and learn driven by project-based problems, ensuring a close integration of skills with actual job requirements
    integration: Integration of Industry and Education
    universities: Collaborating with universities, organizing regular industry expert lectures, synchronizing course content with enterprise needs, continuously updating, and nurturing AI professionals synchronized with the market
    school: School-enterprise Collaboration
    technology: Collaborating with technology companies, involving corporate experts in teaching, providing internship opportunities, customizing talent development according to corporate needs, and facilitating student employment and enterprise development
    solution: Solution
    provideIntegrated: Provide integrated solutions for delivering talents in product services for enterprise's digital transformation and upgrading, simultaneously driving enterprise upgrades and increasing employment opportunities for students
  twoScreen:
    applicable_people: Target Audience for Artificial Intelligence Operator Application Engineer
    ai_impact: The widespread application of AI is revolutionizing the entire process of education and teaching, reshaping the social and workplace ecosystem, and providing vast opportunities for the innovation and upgrading of corporate business models and operational forms
    national_university_students: National University Student Group
    leading_edge: Master the Leading Edge
    ai_skills_for_students: Providing Cutting-edge AI Application Skills for University Students, College Students, and Graduate Students
    employment_advantage: Employment Advantage
    practical_training: Significantly Enhance Students’ Employment Competitiveness Through Practical Guide Training
    professional_group: Social Professional Group
    skill_update: Skill Update
    ai_skill_enhancement: Help Professionals from All Industries Master the Latest AI Skills, Enhance Personal Abilities, and Achieve Career Advancement
    efficiency_improvement: Efficiency Improvement
    ai_integration: Integrate AI Technology with Existing Business to Significantly Improve Work Efficiency and Workplace Competitiveness
    corporate_customized_training: Corporate Customized Training
    innovation_upgrade: Innovation and Upgrade
    managerial_support: Assist Managers in Establishing New Quality Productivity Thinking and Promoting Business Model Innovation and Upgrading
    capacity_optimization: Capacity Optimization
    employee_ai_skills: Enhance Company Employees’ AI Application Skills to Increase Work Productivity and Optimize Work Processes
    school_enterprise_course_development: School-Enterprise Joint Course Development
    industry_education_integration: Industry-Education Integration
    ai_course_development: Respond to the National “AI+ Initiative by Integrating AI Application Capabilities into Specializations to Create High-quality Courses
    job_adaptability: Job Adaptability
    market_trend_courses: Courses Closely Follow Market Trends, Significantly Enhancing Students’ Job Adaptability and Workplace Competitiveness
  threeScreen:
    short_video_marketing: Short Video Marketing Era
    ai_digital_human_content: AI Digital Humans Assist in Efficient Content Production
    digital_human_customization: Customize a Unique Digital Human Image and Voice Clone in Just One Minute
    efficient_voice_over: Efficiently Complete Voice-over Video Output Anytime, Anywhere
  fourScreen:
    interactive_digital_human: Interactive Digital Human
    ai_digital_human_platform: Professional AI Digital Human Platform
    custom_digital_human_ip: Custom Digital Human Exclusive IP
    create_business_value: Create Infinite Business Value
interactive:
  twoScreen:
    user_behavior_analysis: User Behavior Analysis
    camera_data_collection: Camera Data Collection for Real-time User Behavior Data Analysis
    ai_multimodal_interaction: AI Multimodal Interaction
    human_digital_interaction: Various Forms of Interaction between Humans and Digital Humans, Simulating Real
  fourScreen:
    product_advantages: Product Advantages
    stability: Stability：
    stabilityItem: 24/7 Uninterrupted Operation, Consistent Service Quality
    accuracy: Accuracy：
    accuracyItem: Reducing Human Errors, Ensuring Accuracy in Business Processing
    intelligence: Intelligence：
    intelligenceItem: Multimodal Interaction Capabilities, Able to Think, Communicate, and Have Emotions
    flexibility: Flexibility：
    flexibilityItem: Quick Deployment in Different Business Scenarios, Easy to Expand
    professionalism: Professionalism：
    professionalismItem: Deep Integration with Internal Knowledge Systems to Achieve Custom Large Language Models
    ip_creation: IP Creation：
    ip_creationItem: Image that Meets Promotional Needs, Better Conveying IP Value
  fiveScreen:
    popular_topics: Popular Topics
    business_qa: Business Q&A
    business_explanation: Business Explanation
    fallback_phrases: Fallback Phrases
    current_events_interpretation: Current Events Interpretation
    multi_turn_conversations: Multi-turn Conversations
    voice_activation: Voice Activation
    greeting: Greeting
    product_features: Product Features
  sixScreen:
    diversified_scenarios: Diversified Scenarios
    interactive_digital_human: Interactive digital humans, with their highly intelligent and personalized capabilities, play an important role in multiple scenarios such as intelligent education, customer service, virtual assistants, entertainment interaction, business navigation, medical consultation, and legal consultation. They can understand and respond to user needs, provide customized services, and optimize interactive experiences through continuous learning, bringing innovation power and efficiency improvements to all walks of life.
    4s_store_customer_service: 4S Store Customer Service
    digital_immortality: Digital Immortality
    smart_reading_guide: Smart Reading Guide
    digital_lawyer: Digital Lawyer
    virtual_shopping_assistant: Virtual Shopping Assistant
outstandingCase:
  oneScreen:
    personal_ip: Personal IP
    blogger_entrepreneur_merchant: Bloggers, entrepreneurs, merchants, etc., can create their own digital human image, quickly batch update videos, express opinions, and keep users engaged, trusted, and paying
    corporate_application: Corporate Application
    brand_ambassador: Integrate corporate genes to create brand ambassadors, image spokespersons, digital employees, digital experts, etc., which can be used for internal and external corporate promotional material production, marketing campaign innovation, and RPA digitalization
    live_broadcasting_application: Live Broadcasting Application
    live_streaming_support: Supports live streaming, with digital human hosts replacing real hosts, easily meeting the needs of e-commerce, sports commentary, news, entertainment broadcasting, and other scenarios
    digital_interaction: Digital Interaction
    emotional_companion: Digital interaction can be used in various scenarios such as emotional companionship, customer service, business processing, virtual advisors, and intelligent training partners, providing an intuitive and vivid interactive experience
  twoScreen:
    Short_Video: Short Video
    Interactive_Digital_Human: Interactive Digital Human
    Live_Digital_Human: Live Digital Human
    Voice_Customization: Voice Customization
    Fast_Digital_Human: Fast Digital Human
    digital_human_applications: Applications for digital people in all walks of life
    short_video_broadcasting: AI digital humans are now widely used in the short video field, meeting the short video broadcasting needs of various users, and have broad application prospects in the Internet, commerce, education, healthcare, government, legal, cultural tourism, and other fields
  threeScreen:
    digital_human_live_streaming: Digital Human Live Streaming
    standardized_processes: Digital human live streaming is more suitable for standard process live broadcast rooms. By using digital humans, it reduces the dependency on hosts, amplifies the advantages of the live broadcast room, lowers operational costs, and normalizes live broadcasting
  fourScreen:
    interactive_digital_human_application: Interactive Digital Human Application
    high_frequency_services: AI interactive digital humans are typically used in systematic, repetitive, high-frequency service scenarios. In cultural tourism services, digital tour guides/virtual spokespersons are embedded in interactive screens, deeply engaging with tourists; equipped with AI large models, interactions become smarter and more efficient, providing personalized service experiences
aboutUs:
  ai_initiative_response: Respond to the National AI+ Initiative to Drive Market Innovation Applications
  talent_enterprise_link: Link Talent and Enterprises, Introduce Intelligent Solutions to Support Digital Transformation of Enterprises
  project_overview: Project Overview
  services_provided: We provide intelligent digital human application services and customized AI model development services, including intelligent interactive digital humans, digital human image customization, voice cloning, video synthesis, digital human live broadcasting, industry-specific small models, and more solutions;
  training_certification: We also provide professional AIGC commercial application training, MIIT Education and Examination Center AI Operations Engineer training and certification, among other core services. Additionally, we supply enterprises with professional AI operations talents, promoting and innovating the implementation of AI applications.
  ai_operator_training: AI Operations Engineer
  training_certificat: Training and Certification
  training_program: Through systematic and phased training courses, we cultivate professional AI application talents. The training adheres to the standards of the Industrial and Information Technology Talent Training Project. Students who complete the training and pass the exams can obtain the AI Operations Engineer Professional Technical Certificate from the MIIT Education and Examination Center
  learn_more: Learn More
  ai_digital_human_products: AI Digital Human Products
  solutions: Solutions
  business_solutions: Provide intelligent digital human application solutions based on specific business scenarios, including digital human customization, voice cloning, video synthesis, digital human live broadcasting, AI video translation, intelligent interactive digital humans, and more. Assist enterprises in intelligent upgrades in content production, traffic acquisition, and brand promotion.
  ai_customization: AI Customization
  model_services: Model Services
  training_services: Train task models from the business demand side and establish workflows to improve efficiency, including AI customer service model training, AI e-commerce model training, AI design model training, and more.
offtenQuersion:
  image_customization_change: Can the custom image be changed?
  image_customization_changeA: Sorry, the image video cannot be changed after it is approved and uploaded for training, as training is the most important part of our customization process. To ensure the quality of the digital human clone, we recommend that you send the video to customer service for review before uploading.
  action_required: Why must actions be performed between 15 seconds and 54 seconds?
  action_requiredA: Because the system’s motion capture period is the first minute of the video (14 seconds-54 seconds), your actions during this period need to be more diverse. Additionally, to accommodate future content expressions, it is recommended to perform more general actions (such as handshakes, spreading hands, etc.), and avoid actions with specific indications (such as holding up 3 or 1 fingers).
  video_duration: How long should the video recording be?
  imageVersionSuggestedDuration: Image Version： Recommended duration 1~30 minutes.
  durationVersionSuggestedDurationAndQuality: Duration Version： Recommended duration 3~5 minutes, the closer to 5 minutes, the better. The quality and length of the sample video determine the final effect of the digital persona, so please pay special attention to the filming of the sample video.
  imageVersionNoReview: Image Version： No review required, generally 1~3 minutes training is sufficient.
  durationVersionAfterReview: Duration Version： After approval, generally 3~5 business days for training completion.
  video_durationA: The recommended duration is 3-5 minutes, with closer to 5 minutes being better. The quality and length of the sample video determine the final effect of the digital human, so please pay special attention to the shooting of the sample video.
  audio_duration: How long should the audio recording be?
  audio_durationA: The recommended duration is 8-12 minutes, with closer to 12 minutes being better. The more training data, the better the generated effect.
  production_time: How long does it usually take to produce a digital human?
  production_timeA: Generally, it can be generated within 3-5 working days after approval.
  refund_policy: Can I get a refund after purchasing a digital human?
  refund_policyA: Digital humans are virtual products, so they cannot be refunded after purchase. Therefore, it is recommended to carefully review the product content before purchasing, and confirm with customer service if necessary.
  review_time: How long does the review process usually take?
  review_timeA: On working days, staff will first check the original video. If the original video meets the upload requirements, the video will be uploaded on the same day. If the original video does not meet the requirements, staff will optimize the video and audio, and then upload it after completing the optimization.
  discount_code: What is a discount code?
  discount_codeA: If you apply for and receive a discount code, your friends or customers can use it to get a discount. For example, if you get a 10% off discount code, you can use it to get 10% off the base price and also receive a corresponding commission. Note that you must publish a promotional video to get a discount code.
  interaction_paths: What does a single interaction path mean?
  interaction_pathsA: The more interaction paths, the more customers can be served simultaneously. For example, a single path means one customer service representative. If the representative is on a call, the next customer must wait until the first call is finished. With two paths, two customers can be served simultaneously, and the third must wait, and so on.
  card_count: What does the number of business cards mean?
  card_countA: If you have three business cards, you can publish three at the same time. Each card can have a different image and job description.
  live_duration: What does live duration mean?
  live_durationA: Live duration represents the total time you can live stream within the validity period. For example, if you have 360 hours of live duration within a 3-month validity period, you cannot live stream beyond 360 hours even if still within the validity period. After using up your live duration within the validity period, you can contact staff to purchase more live duration. Unused live duration within the validity period will be reset to zero after the period ends.
bannerCard:
  digital_human_live: Digital Human Live
  digital_human_customization: Digital Human Custom
  customized_models: Custom Models
  interactive_digitHuman: Interactive Digital Human
  artificial_intelligence_certification: Artificial Intelligence Certification
customizedModels:
  customized_model_service: Customized Model Service
  ai_art_beauty: The Beauty of AI Art
  ai_art_beauty_line2: in Every Scene You See
  technical_advantages: Technical Advantages
  solve_large_model_issues: Solves the Issue of "Inaccurate Implementation" of Large Models,
  solve_large_model_issues_line2: Truly Helping Clients Greatly Enhance Productivity from the Specific Demand Side
  application_layer: Application Layer
  customized_model: Customized Model
  large_model: Large Model
  precise_efficient_solution: Precise and Efficient Implementation Solutions
  customized_workflow: Customized Workflow
  demand_oriented: Demand Oriented
  targeted_training_model: Targeted Training Model
  wig_style_effect_images: Generate Specified Wig Style Effect Images 4A Interface Effect Images
  wig_materials: Wig Materials
  train_model: Train Model
  batch_image_generation: Batch Image Generation
  customer_selection: Customer Selection
  customized_model_images_output: Output Customized Model Images
  customized_model_images: Based on Product Pictures
digital-human-workbench:
  emotionalVoiceLanguage: Emotional Voice Can Choose Language
  emotionalVoiceEmotion: Emotional Voice Can Choose Emotion
  professionalRemainingCloneCount: Clone Count {count} Times
  minimalRemainingCloneCount: Remaining Clone Count {count} Times
  setInfo: parameter setting
  previewCloneAudio: Preview Clone Audio
  emotionalVersion: Emotional Version
  emotionalVer: Emotional
  basicVersion: Basic Version
  confirmClone: Confirm Clone
  continueCreate: Continue
  youHave: You have
  preCloneChances: voice pre-clone test opportunities
  preCloneDescription: . Each pre-clone will consume one opportunity, and you can confirm the voice effect through the audition function. When satisfied with the effect, please click Confirm Clone to complete voice training. Please note：
  preCloneWarning: Only voices that have been Confirm Clone can be used for digital avatars, and cannot be modified or retrained after confirmation.
  confirmEffectPrompt: Please confirm if you are satisfied with the voice effect. After confirmation
  confirmWarning: this voice will complete training and can be used for digital avatars, and cannot be modified or retrained afterwards
  continuePrompt: . Do you want to continue?
  preClone: Pre Clone
  remaining: Remaining
  times: Times
  userCommitmentNotice: User Commitment Notice
  statement: This statement will help you better use the platform tools to upload and manage your works. By uploading works, you are deemed to have fully understood and accepted the following： the use of platform tools to upload and manage your works. By uploading works, you are deemed to have fully understood and accepted the following：
  intellectualProperty: As a user, the works you upload or publish on this platform must have independent and complete intellectual property rights and must not infringe on the intellectual property rights or any other rights of others.
  compliance: When using this platform and uploading or publishing works, you must consciously abide by national laws and regulations, maintain public order, respect social morality, the socialist system, national interests, the legitimate rights of citizens, social ethics, and the authenticity of information. In case of violations, the platform will take measures according to the severity of the violation, including but not limited to deletion, removal, banning content publication, or account suspension. If it causes serious negative impact or is suspected of illegal activities, the platform has the right to submit relevant content to the competent authorities or public security agencies and cooperate in the investigation.
  complianceUser: As a user, the works you upload or publish on this platform must have independent and complete intellectual property rights and must not infringe on the intellectual property rights or any other rights of others.
  infringement: If the works or materials (including but not limited to creative content, text, portraits, audio, images, videos, etc.) you upload infringe on any third-party rights, the platform has the right, upon receiving related infringement complaints, to handle the reported works or user accounts according to the corresponding rules, including but not limited to removal, warning, or account suspension.
  acknowledged: I have acknowledged and agreed
  digitalAvatar: Digital Avatar
  uploadVideo: Upload Video
  seats: seats
  rapidCloneDigitalAvatar: 3-Minute Quick Digital Avatar Cloning
  quickReplication: Quick Replication
  uploadAudio: Upload Audio
  createPersonalVoiceModel: 1-Minute Quick Voice Cloning
  rapidClone: Rapid Clone
  myDigital: My Customization
  myDigitalAvatar: My Digital Avatar
  goCreate: Go Create
  noDigitalAvatarYet: No Digital Avatar Yet
  clickForCustomDigitalPersona: Click Above "Quick Replication" to Customize Exclusive Digital Persona
  myVoiceClone: My Voice Clone
  noVoiceCloneYet: No Voice Clone Yet
  clickForPersonalVoiceModel: Click Above "Rapid Clone" to Upload Audio and Create Your Personal Voice Model
  replicateDigitalAvatar: Replicate Digital Avatar
  digitalAvatarName: Digital Avatar Name
  enterDigitalAvatarName: Please Enter Digital Avatar Name
  upVideo: Upload Video
  videoOrientation: ①Video Orientation：
  orientation: Landscape or Portrait
  fileFormat: ②File Format：
  videoDuration: ③Video Duration：
  time: 30 seconds to 5 minutes
  resolution: ④Resolution：
  fileSize: ⑤File Size：
  less: Less than 500MB
  notes: Notes
  errorExample: Error Example
  doNotUseMultiplePeopleVideo: Do Not Use Videos with Multiple People
  ensureFullFaceInScreen: Do not have faces too large; ensure the entire face is within the screen area without going off-screen
  ensureFaceUnobstructed: Ensure facial features are not obstructed and strive for clear visibility of the face
  videoResolutionGuidance: Uploaded video resolution should not be less than 360p or more than 3840p, recommended resolutions are 720p or 1080p
  translateVideo: The resolution must be between 480x480 and 2560x2560
  translateVideoDur: between 480x480 and 2560x2560
  videoLengthRange: Video length should be between 30 seconds to 5 minutes
  multipleFaces: Multiple Faces
  faceTooLarge: Face Too Large
  faceObstructed: Face Obstructed
  faceNotDetected: Face Not Detected
  iHaveReadAndAgree: I Have Read and Agree
  canCloneMultipleTimes: Can Clone Multiple Times
  confirmCloneAfter: After Confirmation, It Cannot Be Updated
  pleasePreCloneFirst: Please Pre Clone First
  preCloneSuccess: Pre Clone Success
  notice: User Commitment Notice
  generating: Generating
  readAndAgreeUserCommitment: Please Read and Agree to the User Commitment Notice
  enter: Please Enter Digital Avatar Name
  digitalAvatarNameCharRange: Digital Avatar Name Should Be Between 2 and 20 Characters
  submitVideoFile: Please Submit a Video File
  submitting: Submitting
  enterVoiceName: Please Enter Voice Name, with Character Count Between 2 and 20
  voiceNameCharRange: Voice Name Should Be Between 2 and 20 Characters
  submitVoiceFile: Please Submit a Voice File
  submitAudioFileFormat: Please Submit Audio Files in mp3, m4a, wav Formats, File Size Not Exceeding 10MB
  basicVoiceClone: Voice Clone
  highFidelityVoiceClone: Voice Clone - High Fidelity
  enterName: Please Enter Voice Name
  languageSelection: Language Selection
  selectLanguage: Language
  selectSex: Please select gender
  audioRequirements: Audio Requirements
  myfileFormat: ①File Format
  audioDuration: ②Audio Duration：
  mytime: 10 seconds to 3 minutes
  audioSize: ③Audio Size：
  myless: Less than 10MB
  kindReminder: Kind Reminder
  suggestedAudioDuration: Suggested Audio Duration： Limit audio to 10 seconds to 3 minutes
  audioQuality: Quality
  oneSpeakerOnly: a. Only one person speaking, avoid multiple voices in the audio;
  ensureLowBackgroundNoise: b. Ensure low background noise. The clearer the speaker, the better the cloning result, as background noise severely affects the clone quality
  maintainStableVolumeAndPace: c. Maintain consistent volume and pace, be mindful of phrasing, and avoid oral noises (such as saliva sounds), ambient noises, and reverb
  avoidManualNoises: d. Do not include noises such as chair movements, keyboard clicks, or clothing rustling in the audio;
  pauseForMistakes: e. It's okay to have speech errors. No need to stop recording, just pause for 1-2 seconds and then continue.
  audioContent: Content
  decideVoiceStyleFirst: It's advised to decide on the voice style before recording and to stick close to that style to avoid a monotone delivery.
  emotionalRhythm: If you want a voice clone with rich emotions and varied rhythm, please upload a performance-rich audio.
  supportedAudioFormats: Formats
  selectEmotion: Emotion
  supportedFormatsAndSize: Supports mp3, wav file formats, and audio files not exceeding 10MB.
  uploadVoiceOrEnterText: Please Upload Voice or Enter Text
  selectVoiceFirst: Please Select a Voice First
  estimatedVideoLength: Estimated Video Length is {time} seconds
  confirmSynthesis: Confirm Synthesis
  newDigitalPersonaVideoGenerating: New Digital Persona Driven Video Generating, Available in Works Management
  workbench: Workbench
  public_model: public model
  myWorks: Look at My Works
  continueCreating: Continue Creating
  massive_models: Create Videos Using Public Models
  audioDriven: Audio Driven
  textDriven: Text Driven
  enterTextHere: Please Enter Text Here
  audition: Audition
  selectTextToAudition: Use the mouse to select text content and listen to it sentence by sentence. You can select up to 300 words.
  extract: Text Extraction
  textInput: Text Input
  currentSelection: Currently Selected：
  previewSynthesizing: Preview Synthesizing
  speedSpeech: Speed of Speech
  adjustRate: Click to adjust speech rate
  currentSpeed: Current Speed：
  rename: Rename
  cloneAgain: Clone Again
  name: Please Enter a Name
  renameSuccess: Rename Successful
  renameFailure: Rename Failed
  myAssest: My Assets
  usedAlready: Used
  available: Available
  speedAdjustment: Speed Adjustment
  myAvailable: Available for
  usedfor: Used for
  myused: Used
  already: Available
  viewLine: View Line
  recreate: Recreate
  driveMode: Drive Mode
  selectDigitalPersonaVideoMode: Select the mode for driving digital persona videos：
  sequentialDrive: Sequential Drive： Drives from the first frame of your uploaded original video when generating works with digital persona
  randomMode: Random model： Randomly selects segments for driving (when the generated audio length exceeds the original video length, it will drive sequentially and not enter random mode)
  sampleAudio: Sample Audio
  noAudioMaterials: No audio materials temporarily? Use our sample materials to experience the effect.
  random: Random model
  sequentia: Sequential Drive
  persevereSilently: Persevere Silently
  beYourselfComfortably: Be Yourself Comfortably
  giveYourselfTime: Give Yourself Time
  selectOption: Select
  myOption: My
  publicOption: Public
  enterKeywordsToSearch: Please Enter Keywords to Search
  noVoiceModelAvailable: No Voice Model Available, Please Choose Another
  generatingWait: Generating, please wait
  generationFailed: Generation Failed
  waitingToStart: Waiting to Start
  computingPower: Computing Power
  dontRemindAgain: Don't remind again
  enable: Using
  professionalMode: the Ultra HD version
  professionMode: the Ultra HD
  synthesizingVideoWill: will consume
  doubleComputingPowerConsumption: twice the video synthesis duration, i.e., the Ultra HD version that originally took the duration of 1 video synthesis now equals the duration of 2 video syntheses for the HD version.
  confirmContinue: Please confirm if you want to continue?
  normalMode: HD Version
  normal: HD
  generationMode: Generation Mode
  description: Description：
  normalModeDescription: HD Version： Consumes computing power, synthesizing 1 minute of video consumes 1 computing power unit
  professionalModeDescription: the Ultra HD version： Using the Ultra HD version will consume twice the video synthesis computing power. That is, the Ultra HD version that originally required 1 computing power unit will now require 2 computing power units.
  noSoundDetected: No sound detected. Please ensure the microphone is recording and you speak for a sufficient duration.
  reasonForFailure: Reason for Failure
  voiceCloneProcess: Voice Clone Process
  uploadReferenceAudio: Audio
  uploadAudioDescription: Upload an audio clip for voice cloning. The quality of the audio will directly affect the final cloned voice effect, so please ensure the audio meets the following requirements：
  audioDurationTitle: (1)Audio Duration：
  audioDurationRange: Recommended audio duration is between 1 to 3 minutes;
  audioQualityTitle: (2)Audio Quality：
  audioContentTitle: (3)Audio Content：
  audioFormatTitle: (4)Audio Format
  previewEffect: Preview
  previewAudioTip: After uploading the audio, please preview and listen to the voice effect first.
  editChancesWarning: If you are not satisfied with the voice effect, you can upload new audio for preview,
  editChancesLimit: Each voice has 2 chances for modification and editing.
  avoidWastingChances: Please ensure the audio meets the above requirements to avoid wasting editing chances.
  pointsConsumption: Consumption
  highFidelityPointsCost: Voice Clone - High Fidelity Points Cost：
  pointsPerVoice: 10000 points/voice
  freeTrialsExhausted: If you have no free trials remaining
  purchaseDisclaimer: We will deduct 10000 points for voice purchase, no refunds after purchase
  emotion:
    happy: Happy
    sad: Sad
    angry: Angry
    fearful: Fearful
    disgusted: Disgusted
    surprised: Surprised
    neutral: Neutral
  language:
    zhCN: Chinese (Mandarin)
    zhHK: Chinese (Cantonese)
    enUS: English
    jaJP: Japanese
    koKR: Korean
    esES: Spanish
    frFR: French
    ruRU: Russian
    itIT: Italian
    ptPT: Portuguese
    deDE: German
    idID: Indonesian
audioModeSelectionDialog:
  recommended: Recommended
  title: Simple and Easy to Use, Beyond Your Expectations
  subtitle: Leading technology experience in voice cloning, chosen by over 20,000 users
  advanced:
    title: Advanced Edition
    description: Easily experience voice cloning technology, quickly restore your unique voice
    button: Register for Free Trial
    features:
      fastClone: Fast Clone
      fastCloneDescription: Easily experience voice cloning technology, quickly restore your unique voice
      zeroCost: Zero Cost Experience
      zeroCostDescription: Start your voice cloning journey without any cost
      simpleProcess: Simple Process
      simpleProcessDescription: Easy-to-use interface design, allowing you to quickly get started and complete voice cloning
  emotional:
    title: Emotional Edition
    description: Easily experience voice cloning technology, quickly restore your unique voice
    button: Use Now
    features:
      emotionalClone: Emotional Voice Clone
      emotionalCloneDescription: Not only restores your voice, but also accurately clones the emotional expression of the original material, making the voice more vivid and realistic
      instantPreview: Instant Effect Preview
      instantPreviewDescription: Real-time preview of cloning effects, adjust to satisfaction at any time, making voice cloning more precise and fitting
      resetOpportunity: Enjoy {times} times of preview reset opportunities
      resetOpportunityDescription: Enjoy {times} times of preview reset opportunities, preview effect is not satisfactory, you can enjoy {times} times of reset duration opportunities, after clicking confirm clone for the first time, only one emotional voice authorization quota will be consumed
      coreAdvantagesLabel: "Core Advantage:"
      coreAdvantagesValue: "Multi-emotion & Multi-language"
      voiceSimilarityLabel: "Voice Similarity:"
      voiceSimilarityValue: "80-95%"
      emotionalModesLabel: "Voice Emotions:"
      emotionalModesValue: "7 emotional modes including happy, sad, angry, etc."
      languageSupportLabel: "Language Support:"
      languageSupportValue: "Chinese, Cantonese, English, and 9 other languages"
      previewSystemLabel: "Preview System:"
      previewSystemValue: "{times} previews before cloning(full verification before cloning)"
  highFidelity:
    title: Professional Edition
    description: Easily experience voice cloning technology, quickly restore your unique voice
    button: Use Now
    features:
      highRestore: High Restore
      highRestoreDescription: Adopts advanced technology, restoring the voice and rhythm of the original material to the highest degree
      extremeFidelity: Extreme Fidelity
      extremeFidelityDescription: Once cloned, you can enjoy high-fidelity voice effects, satisfying your ultimate pursuit of voice quality
      highFidelityClone: High Fidelity Voice Clone
      highFidelityCloneDescription: Not only restores your voice, but also accurately clones the emotional expression of the original material, making the voice more vivid and realistic
      retrainOpportunity: Enjoy {highTimes} times of retrain opportunities
      retrainOpportunityDescription: After cloning is completed, you can enjoy {highTimes} times of voice replacement and retraining opportunities within the validity period
      coreAdvantagesLabel: "Core Advantage:"
      coreAdvantagesValue: "High-precision Flexible Adjustment"
      voiceSimilarityLabel: "Voice Similarity:"
      voiceSimilarityValue: "80-95%"
      languageSupportLabel: "Language Support:"
      languageSupportValue: "Chinese, English"
      previewSystemLabel: "Preview System:"
      previewSystemValue: "{highTimes} re-cloning opportunities after cloning(reduces adjustment costs)"
