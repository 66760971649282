<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { RouterNameEnum } from "@/config";
import { router } from "@/router";
import { ref, onMounted, watch } from "vue";
import BuyDigitHumanDialog from "@/views/components/dialog/buy-digital-human-dialog/index.vue";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { useAppStoreHook } from "@/store/modules/app";
import { useRoute } from "vue-router";
import { ScoreRechargeType } from "@/store/modules/types";
import { formatUtils } from "@/utils";
import elementResizeDetectorMaker, { Erd } from "element-resize-detector";
import { ItemScoreRechargeType } from "@/api";
const { t } = useI18n();
//大屏查看数组
const watchModelList = ref([]);
// const activeIndex = ref(0);
const route = useRoute();
const erd = ref<Erd>();
// 多语言
const { locale } = useI18n();
const digitHumanContainerRef = ref();
// 购买数字人对话框
const buyDigitHumanDialogRef = ref();
const digitHumanRechargeType = ref<Map<number, Array<ItemScoreRechargeType>>>();
const productLists = ref();
const currentTabType = ref(
  +useSystemConfigStoreHook().config.sysConfig.digits[0]
);

watch(
  () => locale.value,
  () => {
    console.log("切换语言", locale.value);
    getProductList(true);
  }
);
const getProductList = async (load?: boolean) => {
  productLists.value = await formatUtils.getDigitalHumanOption();
  const scoreRechargeType: ScoreRechargeType =
    await useAppStoreHook().getRechargeScore(locale, load);
  //获取数据后台传来的商品数据
  const typeKeys = Object.keys(scoreRechargeType.digital);
  console.log("typeKeys", typeKeys, scoreRechargeType.digital);
  if (typeKeys?.length > 0) {
    typeKeys.forEach(item => {
      const itemDigitalArray: Array<any> = scoreRechargeType.digital[item];
      const itemObj = productLists.value[item];
      if (itemObj && itemDigitalArray?.length > 0) {
        itemObj["title"] = itemDigitalArray[0].title;
        itemObj["itemList"] = itemDigitalArray;
      }
    });
  }
  const typeStr = currentTabType.value.toString();
  watchModelList.value = productLists.value[typeStr].videoUrl;
  //写在这防止buyDigitHumanDialogRef.value,未加载好就进行渲染
  await getRechargeList();
};

const getRechargeList = async () => {
  const scoreRechargeType: ScoreRechargeType =
    await useAppStoreHook().getRechargeScore(locale);
  console.log("scoreRechargeType", scoreRechargeType);
  digitHumanRechargeType.value = scoreRechargeType.digital;
  // 打开购买弹窗
  buyDigitHumanDialogRef.value.showDialog(
    digitHumanRechargeType.value[currentTabType.value]
  );
};

erd.value = elementResizeDetectorMaker({
  strategy: "scroll"
});

onMounted(() => {
  getProductList();
  if (route.query?.type) {
    console.log("有参数", route.query?.type);
    const value = route.query?.type;
    if (typeof value === "string" || typeof value === "number") {
      currentTabType.value = parseInt(value);
    }
  }
  //submit();
  //todo 处理背景颜色不撑满布局的问题
});

const handleBuyRightNow = () => {
  router.push({ name: RouterNameEnum.DigitHuman });
};
</script>

<template>
  <div
    class="el-button-ml-reset overflow-auto h-min-[100vh] no-scrollbar"
    ref="digitHumanContainerRef"
  >
    <div
      class="w-[80%] mt-[2.56rem] mx-auto text-center relative bg-[#fff] lg:pb-[20px] mb-[40px] lg:mb-[100px]"
    >
      <!-- 数字人价格框 -->
      <div class="px-8 lg:px-[18px] leading-10 mt-[20px]">
        <BuyDigitHumanDialog
          ref="buyDigitHumanDialogRef"
          :unShowBottom="true"
          :unshowContactUs="false"
        />
        <div
          class="mt-[30px] mx-auto font-bold ani-scale flex-c my-gradient-green-left text-[1.5rem] lg:text-[21px] text-center text-white rounded-[2rem] lg:rounded-[2.6rem] px-[2.6rem] py-[1rem] lg:px-[5.3rem] lg:py-[1.25rem] hover:cursor-pointer w-[20rem] lg:w-[28rem] h-[4rem]"
          @click="handleBuyRightNow"
        >
          {{ t("common.buyRightNow") }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Webkit */
}
::v-deep .el-carousel__arrow {
  /* 改变箭头大小 */
  font-size: 1.5rem;
  /* 改变箭头颜色 */
  color: #bdbdbd;
  /* 改变背景颜色 */
  // background-color: rgba(175, 171, 171, 0.5);
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #bdbdbd;
  opacity: 0.7;
}
/* 媒体查询，针对屏幕宽度小于某个值的设备 */
@media (max-width: 768px) {
  ::v-deep .el-carousel__arrow {
    /* 在屏幕宽度较小的设备上应用 */
    top: 7.5rem;
  }
}
::v-deep .el-carousel__arrow:hover {
  /* 改变背景颜色 */
  background-color: rgba(230, 230, 230, 0.5);
  opacity: 1;
}
::v-deep .el-carousel__arrow:active {
  /* 改变背景颜色 */
  background-color: rgba(158, 155, 155, 0.5);
}
:deep(.el-carousel__arrow--left) {
  left: 0.2rem;
}
:deep(.el-carousel__arrow--right) {
  right: 0.2rem;
}
.bogBoxBg {
  background: linear-gradient(
    42deg,
    #95bbf9 -5%,
    #cbeaff 27%,
    #eef4fe 70%,
    var(--el-color-primary) 162%
  );
}
</style>
