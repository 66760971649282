buttons:
  hsLoginOut: 退出登录
  hscloseAllTabs: 关闭全部标签页
  hscloseCurrentTab: 关闭当前标签页
  hscloseLeftTabs: 关闭左侧标签页
  hscloseOtherTabs: 关闭其他标签页
  hscloseRightTabs: 关闭右侧标签页
  hscontentExitFullScreen: 内容区退出全屏
  hscontentFullScreen: 内容区全屏
  hsexitfullscreen: 退出全屏
  hsfullscreen: 全屏
  hslogin: 登录
  hsreload: 重新加载
  hssystemSet: 打开项目配置
  hswholeExitFullScreen: 退出全屏
  hswholeFullScreen: 全屏
login:
  back: 返回
  definite: 确定
  emailOrPhone: 邮箱或手机号
  emailOrPhoneReg: 请输入正确的邮箱或手机号
  unsupportedCountry: 不支持的国家手机号格式
  inputEmailOrPhoneReg: 请输入手机号
  switchToEmail: 点击切换到邮箱模式
  switchToPhone: 点击切换到手机号模式
  email: 请输入邮箱
  forget: 忘记密码?
  welCometoLogin: 欢迎登录
  weChatQRCodeLogin: 微信扫码登录
  loginWitWeChatScan: 使用微信扫一扫登录
  mobileEmailLogin: 手机号/邮箱登录
  otherloginMethods: 其他方式登录
  verificationCodeLogin: 验证码登录
  freeLogin: 7  天免登陆
  getVerifyCode: 获取验证码
  info: 秒后重新获取
  login: 登录
  loginSuccess: 登录成功
  password: 密码
  passwordDifferentReg: 两次密码不一致!
  passwordReg: 请输入密码
  passwordRuleReg: 密码格式应为 6-18 位数字、字母、符号的任意两种组合
  passwordSureReg: 请输入确认密码
  passwordUpdateReg: 修改密码成功
  updatePassword: 修改密码
  phone: 手机号码
  phoneCorrectReg: 请输入正确的手机号码格式
  nameCannotEmpty: 姓名不能为空
  informationCannotEmpty: 联系方式不能为空
  phoneLogin: 手机登录
  phoneReg: 请输入手机号码
  emailReg: 请输入邮箱
  emailCorrectReg: 请输入正确的邮箱
  privacyPolicy: 《隐私政策》
  serviceAgreement: 《服务协议》
  qRCodeLogin: 二维码登录
  qqLogin: QQ 登录
  readAccept: 我已仔细阅读并接受
  register: 注册
  passwordLogin: 密码登录
  registerSuccess: 注册成功
  remember: 记住密码
  smsVerifyCode: 短信验证码
  sure: 确认密码
  test: 模拟测试
  thirdLogin: 第三方登录
  tickPrivacy: 请勾选隐私政策
  tip: 扫码后点击"确认"，即可登录
  scanTip: 首次扫码关注公众号后将自动注册新账号
  scanTips: 微信扫码登录/注册
  lateQr: 二维码失效,点击刷新
  reScan: 点击刷新
  loginRefresh: 登录信息失效，点击二维码刷新
  username: 用户名
  callbackAddress: 回调地址
  changeLoginPassword: 修改登录密码
  usernameReg: 请输入用户名
  verifyCode: 验证码
  verifyCodeCorrectReg: 请输入正确的验证码
  verifyCodeReg: 请输入验证码
  verifyCodeSixReg: 请输入 6 位数字验证码
  verifyCodeLogin: 验证码登陆
  weChatLogin: 微信登录
  joinUs: 加入我们
  become: 成为
  oneMember: 一员
  inviteCodePlaceholder: 邀请码(选填)
  verifyCodeSend: 验证码已经发送
  pleaseCompleteVerify: 请先完成验证！
  pleaseWaitVerify: 请等待验证初始化完成
  inviteCode: 邀请码
  loginFirst: 请先登陆
  wxMobileTips: 请复制当前页面链接到微信打开进行登录
  changeWithdrawPwd: 修改提现密码
  verifyIDCardReg: 请输入身份证号
  verifyIDCardCorrectReg: 请输入正确的身份证号
  verifyNameReg: 请输入姓名
  pleaseRequest: 请输入您的诉求
  websiteName: 网站名称
  verifyWebsiteNameReg: 请输入网站名称
  key: 个性化二级域名(例如 ai 99 等等)
  verifyKeyReg: 请输入个性化二级域名(例如 ai 99 等等)
  verifyKeyLengthReg: 请输入 2-10 个字符的字母或数字任意组合
  agentInitTips: 请输入您的实名信息,我们会尽快联系您
  discountCodeApplyInfoReg: 请输入折扣码申请信息
  enterChName: 请输入姓名
  enterAlipayAccount: 请输入支付宝账号
  setWithdrawalInformation: 设置提现信息
  enterPaymentAccount: 请输入支付账号
  enterValidPaymentAccount: 请输入正确的支付账号
  enterBankAccount: 请输入银行卡号
  enterBankBranchLocation: 请输入开户行所在地
  enterValidBankAccount: 请输入正确的银行卡号
  alipay: 支付宝
  bankCard: 银行卡
  withdrawalMethod: 提现方式：
menus:
  about: 关于
  digitTwins: 数字孪生
  artificialIntelligenceCertification: 人工智能实训
  hsFive: "500"
  hsabnormal: 异常页面
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hshome: 首页
  hslogin: 登录
  mine: 我的账户
  permission: 权限管理
  permissionButton: 按钮权限
  permissionPage: 页面权限
  MenuWorkManagement: 作品管理
  MenuDigitalAssets: 数字资产
  userCenter: 个人中心
  digitHuman: 数字人
  realPerson: 真人
  aiImage: AI 绘画 🔥
  paintSquare: 绘画广场 🔥
  beginCreation: 开始创作
  enterpriseCooperation: 企业合作
  digitHumanOperation: 数字人操作
  createWork: 创建作品
  aboutFutureAI: 关于 Future AI
  aboutChatAI: 关于 Chat AI
  techSupport: 技术支持
  clickCollapse: 点击折叠
  clickExpand: 点击展开
  outstandingCase: 优秀案例
  customizedMode: 定制化模型
  aboutDigitHuman: 关于数字人
  aboutUs: 关于我们
  helpCenter: 帮助中心
  MenuMyCustom: 我的定制
  MenuMyRights: 我的权益
  usageRecord: 消耗记录
  useRecord: 使用记录
  videoSynthesisDuration: 视频合成时长
  videoDuration: 视频时长(秒)
table:
  date: 日期
  name: 名称
  status: 状态
  video: 视频
  reason: 原因
  dateRange: 日期范围
  to: 到
  fromDate: 开始日期
  endDate: 结束日期
  recentWeek: 最近一周
  recentMonth: 最近一个月
  recent3Month: 最近三个月
  creationTime: 创建时间
  title: 标题
  text: 文本
  audio: 音频
  buyDate: 购买日期
  undataDate: 更新时间
  format: 形式
  contactName: 姓名
  mobile: 手机号码
  phone: 电话
  idCard: 身份证
  email: 邮箱
  telphone: 手机
  digitalHuman: 数字人
  childType: 详情
  version: 版本
  type: 类型
  operate: 操作
  updateInfo: 修改信息
  updateBasicInfo: 待补充视频信息
  updateAudioBasicInfo: 待补充音频信息
  info: 待补充个人信息
  uploadInfo: 上传训练视频
  uploadAudio: 上传训练音频
  pendingReview: 视频正在审核中
  pendingAudio: 音频正在审核中
  pendingTraining: 数字人训练中
  pendingTrainingAudio: 音频训练中
  trainingSuccess: 训练完成
  clickToMake: 点击去制作
  successaActition: 点击制作
  trainingAudio: 声音训练完成
  waitingForUpload: 等待上传
  mySound: 我的声音
  soundName: 声音名称
  soundSex: 声音性别
  soundType: 声音类型
  soundAct: 声音状态
  cloningProcess: 操作
  illustrate: 说明
  largeScreen: 大屏管理
status:
  loading: 加载中...
common:
  pleaseInput: 请输入文本
  pauseDuration: 停顿时长
  inputContent: 输入字数不能超过
  remove: 移除
  stop: 停止
  send: 发送
  processing: 点击处理
  text: 中文
  known: 我已知晓
  add: 添加
  addSuccess: 添加成功
  edit: 编辑
  editSuccess: 编辑成功
  delete: 删除
  retryProcessing: 再次处理
  allImages: 全部图片
  myFavorites: 我的收藏
  today: 今天
  yesterday: 昨天
  deleteSuccess: 删除成功
  save: 保存
  saveSuccess: 保存成功
  saveFail: 保存失败
  reset: 重置
  action: 操作
  export: 导出
  exportSuccess: 导出成功
  import: 导入
  importSuccess: 导入成功
  clear: 清空
  clearSuccess: 清空成功
  yes: 是
  no: 否
  deleteConfirmation: 形象删除无法恢复,确定删除吗？
  applypattr: 申请
  download: 下载
  noData: 暂无数据
  wrong: 好像出错了，请稍后再试。
  success: 成功
  failed: 失败
  imageFailed: 加载失败
  deleteFailed: 删除失败
  verify: 验证
  unauthorizedTips: 未经授权，请先进行验证。
  cancel: 取消
  submit: 提交
  changeVoice: 更换声音
  selectVoiceClone: 请选择音色
  clickChange: 点击更换
  copy: 复制
  copySuccess: 复制成功
  claiming: 需要登录,现为您跳转
  deleteConfirm: 确认删除吗?
  tips: 提示
  submitSuccess: 提交成功
  back: 返回
  select: 已选择
  preview: 预览
  insert: 插入
  more: 更多
  pleaseSelect: 请选择
  img: 图片
  bg: 背景
  apply: 应用
  upload: 上传
  psImage: P 图
  video: 更多视频案例
  buyRightNow: 立即购买
  credits: 积分
  creditsRecharge: 积分充值
  billRules: 计费规则
  alipay: 支付宝支付
  payChange: 点击对应支付按钮，跳转完成支付
  weChatPay: 微信支付
  paypal: PayPal支付
  reUpload: 重新上传
  resubmit: 再次提交
  pre: 上一步
  next: 下一步
  paidSuccess: 我已支付
  paidSuccessTips: 支付完成后，请点击下方按钮
  free: 免费
  cooperation: 咨询
  weChatCustomer: 微信客服
  dedicatedCustomer: 专属客服,优惠福利
  officialAccount: 公众号
  latestAccount: 最新咨询,前沿动态
  noPage: 抱歉，您访问的页面不存在
  backHome: 回到首页
  id: ID
  title: 标题
  desc: 描述
  createTime: 创建时间
  updateTime: 更新時間
  operation: 操作
  loading: 載入中
  create: 創建
  confirm: 確定
  warning: 警告
  confirmDelete: 確定要刪除嗎？
  deleteFail: 刪除失敗
  getFail: 獲取數據失敗
  logo: Logo
chat:
  placeholder: 来说点什么吧...（Shift + Enter = 换行）
  placeholderMobile: 来说点什么...
  create_digital_avatar: 使用该内容创作自己的数字人分身
  select_conversation: 请选择一个会话
  message_deleted: 消息已删除
  delete_failed: 删除失败，请重试
  press_enter_send: 按Enter发送消息
  copy: 复制
  copied: 复制成功
  copyCode: 复制代码
  clearChat: 清空会话
  linkContext: 关联上下文
  switchToLinkContext: 已开启上下文关联
  switchToCancelLinkContext: 已取消上下文关联
  exportChat: 导出会话
  clearChatConfirm: 是否清空会话?
  exportImage: 保存会话到图片
  exportImageConfirm: 是否将会话保存为图片?
  exportSuccess: 导出成功
  exportFailed: 导出失败
  usingContext: 上下文模式
  turnOnContext: 当前模式下, 发送消息会携带之前的聊天记录
  turnOffContext: 当前模式下, 发送消息不会携带之前的聊天记录
  deleteMessage: 删除消息
  deleteMessageConfirm: 是否删除此消息?
  deleteHistoryConfirm: 确定删除?
  clearHistoryConfirm: 确定清空聊天记录?
  waitAnswer: 请等待回答完成
  regenerate: 重新生成
  stopRespond: 停止响应
  newConversation: 新会话
  dayTheme: 白天模式
  nightTheme: 夜间模式
  subscribeMember: 订阅会员
  youSubscribed: 您已订阅
setting:
  setting: 设置
  general: 总览
  config: 配置
  avatarLink: 头像链接
  name: 名称
  description: 描述
  resetUserInfo: 重置用户信息
  chatHistory: 聊天记录
  theme: 主题
  language: 语言
  api: API
  reverseProxy: 反向代理
  timeout: 超时
  socks: Socks
  httpsProxy: HTTPS Proxy
  balance: API余额
store:
  local: 本地
  online: 在线
  title: 标题
  description: 描述
  clearStoreConfirm: 是否清空数据？
  importPlaceholder: 请粘贴 JSON 数据到此处
  addRepeatTitleTips: 标题重复，请重新输入
  addRepeatContentTips: 内容重复：{msg}，请重新输入
  editRepeatTitleTips: 标题冲突，请重新修改
  editRepeatContentTips: 内容冲突{msg} ，请重新修改
  importError: 键值不匹配
  importRepeatTitle: 标题重复跳过：{msg}
  importRepeatContent: 内容重复跳过：{msg}
  onlineImportWarning: 注意：请检查 JSON 文件来源！
  downloadError: 请检查网络状态与 JSON 文件有效性
imageSquare:
  oneKeySame: 一键同款
  creationPlatform: 创作平台
  support: 支持
  model: 模型
  justOneSentence: 只需一句话，让你的文字变成画作
  public: 公共
  mine: 我的
  createInfo: 创作信息
  creator: 创作者
  oneKeySameDesc: 本作品一键同款需支付{msg}积分
  description: 描述
  size: 尺寸
  modelName: 模型名称
  seed: 种子
  free: 免费
  prompt: 提示词
  negativePrompt: 负向提示词
  onlyShowOnekeySame: 仅支持一键同款可看
  style: 风格
  step: 步数
  searchPlaceholder: 支持提示词,昵称,标题搜索
  aiImage: AI 作画
aboutFutureAI:
  one: 你好，欢迎来到{msg}的小宇宙，我们是一家专注于数字人和 AI 工具软件的领先者，旨在为客户提供最佳的智能解决方案。
  two: 我们坚信，数字人和 AI 技术正在改变世界，并将持续为人们创造更多的机会和效益。因此，我们致力于研发最前沿的数字人及 AI 工具软件，帮助企业提高效率、降低成本、增强安全性，并取得可持续的竞争优势。
  three: 数字人是我们最核心的业务之一，它为企业提供了一个全新的数字化解决方案，可以大幅度缩短招聘时间、降低试错成本，同时还可以实现个性化需求定制等功 能。我们的数字人技术媲美真实人类，可以通过语音、视频、文字等方式与用户进行互动，极大地提升了业务的效率和精准度。
  four: 另外，在 AI 工具软件领域涵盖了自然语言处理、计算机视觉、数据分析等业务。我们的工具软件不仅可以加速人们的日常工作，还可以为客户提供最佳的智能化方案，帮助他们实现业务转型升级。
  five: 如果您正在寻找一家可靠的数字人和 AI 工具软件服务商，那么请与我们联系！我们不仅可以满足您的需求，也可以超越您的期望，为您带来更多的机会和收益。
aboutChatAI:
  one: Chat AI 是一个聊天机器人，它使用 LLM 来回答用户的问题，LLM 是一种自然语言处理模型，它能够理解自然语言，并像人类一样回答问题。通过使用 Chat AI，用户可以和它进行自然语言交互，获得相关信息和回答。
  two: Chat AI 会通过对大量文本数据进行训练，来学习语言知识和推理能力。这些文本数据可能包括新闻文章、小说等内容。通过不断地训练，它的神经网络可以学会提取文本中的信息，并使用这些信息来回答问题。
techSupport:
  one: 您好，我们还提供数字人定制、语音识别、智能绘画、Chat AI 等企业定制方案，帮助企业实现数字化转型和智能化升级。
  two: 如果您需要相关合作，请发送邮件至我们的邮箱{email}，我们将有专业的工程师尽快回复您，并提供详细的解决方案。
userCenter:
  pleaseUpload: 请上传图片
  rule1: 只能上传 JPG 或 PNG 格式的图片
  rule2: 图片大小不能超过 500KB
  changeAvatar: 更改头像
  avatarConfirm: 确认更改头像
  accountInfo: 账户信息
  nickname: 昵称
  dedicatedService: 专属客服
  subscribedService: 已订阅服务
  subscribedRecord: 订阅记录
  rechargeRecord: 购买记录
  activationCode: 激活码
  inputActivationCode: 请输入激活码
  activateNow: 立即激活
  AIQA: AI问答
  expiresOn: 到期时间为
  renew: 续费
  subscribeNow: 立即订阅
  nicknameRule: 昵称必须为 2-20 字符之间
  callbackAddressMustBeURL: 回调地址需要为URL
  inviteCode: 邀请码不能为空
  remainAvailTimes: 您剩余可用次数为{count}次
  digitalHumanTime: 数字人时长
  recharge: 充值
  paintingCredits: 绘画积分
  shareFriend: 分享好友
  invitationPlan: 邀请计划
  rewardActivity: 返利活动
  inviteLink: 邀请链接
  orderRecord: 订单记录
  creationTime: 创建时间
  orderId: 订单 ID
  price: 价格
  amount: 金额
  type: 类型
  memberType: 会员类型
  activationMethod: 开通方式
  withdrawAmount: 可提现额
  setWithdrawInfo: 设置提现信息
  updateAgentInfo: 修改代理商实名信息
  agentWebsiteConfig: 代理商网站配置
  withdraw: 提现
  configRealNameTips: 首次配置实名信息后需要重新登陆
  scanQrCodeAddService: 专属客服
  AudioUpload: 上传音频要求-请注意以下要点
  mineDigits: 我的数字人
  mineServe: 我的商品
  inviteQRCode: 邀请二维码
  mineInvite: 我的邀请
  downloadQRCode: 邀请二维码下载
  InvitationList: 邀请列表
  rebateList: 反佣列表
  withdrawalList: 提现列表
  username: 用户名
  orderID: 订单ID
  userID: 用户ID
  remarks: 备注
  mySuperior: 我的上级：
  mustPicType: 请选择抠像类型，必填字段
  mustVideoType: 请上传训练视频，必填字段
  mustFileType: 请上传授权文件，必填字段
  uploadNotice: 上传视频要求-请注意以下要点
  chooseClippinType: 选择扣像类型,请注意以下要点
  videoSuccess: 训练视频提交成功。
  buttonPass: 我们正在对您的视频进行审核和优化，以提升数字人效果。请耐心等待，优化后的视频将很快呈现给您！
  buttonPassAudio: 我们正在对您上传的音频进行审核和优化,以提升音频克隆效果。请耐心等待,优化后的音频将很快呈现给您!
  awaitingAudio: 您的音频审核通过,等待后台上传
  awaitingVideo: 您的视频审核通过,等待后台上传
  seeSoon: 您的数字人正在制作中,预计24小时内,会和您见面。
  seeSoonAudio: 您的克隆音频正在制作中,预计24小时内,会和您见面。
  setInformation: 请先设置提现信息
  warmReminder: 温馨提示
  completeInformation: 待补充个人信息
  orderCompleted: 订单完成
  aiOrderCompleted: 联系客服
  waitingOpen: 等待创建
  waitingOpenDesc: 账号开通中,预计5～30分钟开通成功后会有邮件提示
dialog:
  xbTip:
    one: 资料填写之后,我们会给您发送一份帮助邮件，请注意查收哦~
    two: 如果未收到邮件，别忘了检查垃圾箱，也许在里面.
    three: 一定要添加并联系我们的客服，我们随时准备协助您!
  buyTip:
    one: 本产品为虚拟商品。
    two: 购买并激活后将不支持退款
  agentInit:
    exitTips: 点击确认后会退出本站,并跳转到您的专属网站 {website}
  discountCode:
    discountCode: 折扣码
    apply: 合伙人申请
    suggestInput: 建议填写,手机号,姓名,主要流量平台,方便我们尽快审核
    applySuccess: 申请成功
    getDiscountCode: 赚取佣金,点击申请折扣码
    clickDiscountCode: 点击申请折扣码
    discountforCode: 申请折扣码
    whatIsDiscountCode: 什么是折扣码 ?
    discountNow: 立即打折
    baseinfo: 基本信息
    purl: 数字人视频链接
    urlinfo: (选填)请输入您的数字人视频链接
    yourName: 你的名字
    phoneNumber: 您的手机号
    yourRequest: 您的诉求
    weChat: 微信二维码
  digitalRegisterInfo:
    title: 补充手机号和邮箱
    titleForAi: 补充个人信息
    tips1: 1. 请留意，一封确认邮件已经在路上了,请注意查收哦~
    tips2: 2. 如果未收到邮件，别忘了检查垃圾箱，也许在里面.
    tips3: 3. 添加并联系我们的客服，我们随时准备协助您!
  kindTips: 温馨提示
  scanWechat: 微信扫一扫添加专属客服
  contactWechat: 微信扫描二维码
  purchaseSuccessful: 购买成功
  contact: 联系我们
  purchaseSuccess: 可通过以下信息和我们联系
  getDigital: 免费领取数字人
  freeDigital: 扫码领取免费数字人制作时长
  below: 关注公众号
  freeTime: 选择免费获取数字人时长.
  send: 回复以下专属申请码：
  clickCopy: 点击
  problem: 或发送以下内容：
  problem0: “免费领取数字人，专属申请码
  problem2: 到该邮箱
  paySuccess: 支付成功
  immediateRecharge: 立即充值
  credits: 积分
  points_purchase: 积分购买
  points_balance: 积分余额
  question: 如扫描遇到问题，您可以通过以下方式领取
  clickToView: 点击查看
  apply: 扫描二维码，即可免费领取
  addWeChat: 微信：搜索并添加微信号
  sendEmail: 发送专属申请码
  emailAddress: 邮件地址
  contactUs: 请通过以下任一方式联系我们的客服：
  to: 至
  addLine: Line：搜索并添加Line账号
  support: 选择方便的联系方式，我们的客服团队将随时准备协助您！
  sendCode: 我已发送
  compositeTips: 本次合成预计时长:{time}
  discountCodeDesc: 折扣码可以用优惠的价格购买产品
  inputDiscountCode: 请输入折扣码
  buyWarning: 亲爱的小伙伴你好，虚拟产品一经销售，不支持退款。请在下单请详细阅读<a class="text-primary" href="https://mp.weixin.qq.com/s/0Zc2LbX5x9E5JzRxxeQTDw">使用指南</a>，或者与我们的客服取得联系咨询。
  buyWarning2: 亲爱的小伙伴你好，虚拟产品一经销售，不支持退款。如有疑问，请与我们的客服取得联系咨询。
  buyWarningline1: 亲爱的小伙伴你好，虚拟产品一经销售，不支持退款。
  buyWarningline2: 如有疑问，请与我们的客服取得联系咨询。
  chatai:
    one: 解锁 CHAT AI 会员，享受丝滑提问
    two: 当前充值仅支持 CHAT AI
    wechatPay: 点击立即购买,扫码支付
    paidSuccess: 已支付
    chooseMember: 请选择一个会员
  digitHuman:
    one: 解锁数字人会员，轻松制作数字人视频
    two: 当前充值仅支持 制作数字人视频
    minute: 分钟
  digitalVoice:
    audioDuration: 音频时长
    effectiveAudioDuration: 有效音频时长
    lessMinutes: 音频建议不少于10分钟
    recordingEnvironmen: 录音环境
    noise: 安静时环境底噪
    keepNoEcho: 录制时保持无回音、无混响、无噪声等
    RecordingEquipment: 录音设备
    recommended: 推荐
    microphone: 麦克风/小蜜蜂
    conditionsAllow: 条件允许建议使用防风棉衣或防喷罩
    recordingPerson: 录音人
    close: 不宜离麦太近
    tooClose: 防止喷麦和录入呼吸声
    coherently: 连贯说完一句话,句与句之间停顿1～2秒
    consistent: 保持语境风格一致,避免多种情绪混杂
    recordingParameters: 录音和录音参数
    recommendedToUse: 推荐使用
    recordingAudio: 40kHz采样率录制音频
    losslessAudio: 推荐无损音质格式保存音频,如
    zipAudio: 不能使用压缩格式保存音频，如
    submitAudio: 请提交训练音频
    synthesizedAudio: 请提交录音合成音频
    requiredField: 请上传音频,必填字段
    resSex: 性别不能为空
    submittedSuccessfully: 训练音频提交成功
    trainingAudio: 训练音频
    deleteSound: 确定删除吗?
    supports: 音频仅支持wav,m4a,flac格式
    notExcee: 每个音频大小不超过10MB
    submitted: 请勿提交相同的文件
    uptoTenAudio: 最多支持提交{fileLimit}段文件
    supportedTypes: 支持类型wav/m4a/flac
    drag: 将文件拖动到此处或者点击
  score:
    one: 解锁积分会员，享受 AI 绘画
    two: 当前充值仅支持 AI 绘画
  serviceDigitHuman:
    one: 您好，制作数字人属于
    two: VIP 会员权益,需要付费使用
    three: 方案1—— 普通用户合作，直接充值。
    four: 方案2—— 企业合作，联系我们的销售顾问
    five: 将有专业的数字顾问为您详细介绍数字人定制的流程。
  billRuleDialog:
    function: 功能
    price: 售价
    creditsOnce: 积分 / 次
    creditsMinute: 积分 / 分钟
    credits100Words: 积分 / 100 字
    extractVideoText: 提取视频文案
    getVideoDetail: 获取视频详情
    extractWatermarkFreeVideos: 提取无水印视频
    chatAiToken: 积分 / 100 字符
    extractAudio: 提取音频
    yuanMinute: 积分/分钟
  cropper:
    cropperImage: 裁剪图片
    freeMode: 自由模式
    imageSize: 图像大小
    pixel: 像素
    fileSize: 文件大小
    roundRectCropper: 圆形/矩形裁剪
    roundRect: 圆/矩
    reset: 重置
    backgroundColor: 背景颜色
    customColor: 自定义颜色
    close: 关闭
    flip: 翻转
    xFlip: 水平翻转
    yFlip: 垂直翻转
    rotate: 旋转
    clockwiseRotate: 顺时针旋转
    AnticlockwiseRotate: 逆时针旋转
    longClick: 可长按
    zoomIn: 放大
    zoomOut: 缩小
    saveImage: 保存图片
    insertCanvas: 插入画布
    view: 查看
    viewAll: 查看全部
welcome:
  one:
    AIPower: AI 赋能
    fast: 更快、更强、更简单
    combine: ChatAI + 数字人 ，为 360 行助力赋能
    createNow: 立即创作
  two:
    youWalk: 让你走进
    future: 未来
    imagine: 每一天，都有更多人因为 AI 解放想象力、放飞创造力
  three:
    digitHumanCustomization: 数字人定制
    earnLiving: 让每个人都拥有一个专属打工人
    customizeNow: 立即定制
  four:
    isMe: 没错，是我，你的效率提升精灵
    youAsk: 你尽管提出问题，总有更专业的回答
    experienceNow: 立即体验
  five:
    simple: 更高效、更专业、更简单
    inFutureAI: 来 FUTURE AI
    propeller: 为你的创造力安上螺旋桨
    contactUs: 联系我们
    nowsq: 立即申请专属折扣码
    digitSimple: 让您与数字人的交互如此简单
    highsend: 最高返佣
    sendinfo: 只需现在制作一条推广视频，即刻拥有您的独家折扣码
    nowdo: 立即申请
    partner: 成为合伙人
    common_problem: 常见问题
  imageAI:
    imageAI: AI 绘画
    support: 支持 Stable Diffusion 、Midjourney 模型
    desc: 简单提词，文字妙变精彩画作
    desc2: 想象跃然眼前
  sixCooperationCase:
    cooperationCase: 轻松批量制作数字人,优秀案例
    leiJunSpeech: 雷军演讲给我的感悟
    aiDigitalHumanDo: AI数字人可以做什么
    howHumanCreated: 我的数字人到底怎么诞生的
    WhatDigital: 什么是AI数字人
    illusions: 焦虑害怕都是幻想
    selfIntroduction: 一分钟自我介绍法宝
    notInteractWithDigitalHumans: 没准备的人别碰数字人
    theCEOofJPMorganChase: 摩根大通CEO说每周只用工作三天
    StudyAbroad: 美英澳留学
    aiDigitalHuman: AI数字人
    landCases: 土地案件
    advantagesOfYoga: 瑜伽的优势
  news:
    learnMore: 了解详情
  footer:
    one: AI 赋能 ——更快、更强、更简单
    contactNumber: 联系电话
    contactEmail: 联系邮箱
    contactLine: 联系Line
    officeAddress: 办公地址
    officeAddressValue: 深圳市南山区云谷一期南风楼501室
    companyName: 深圳市敲敲撬撬科技有限公司
    aboutUs: 关于我们
    digitalHumanPlate: 数字人板块
    understandDigitHuman: 了解数字人
    digitHumanRecharge: 数字人充值
    understandChatAI: 了解 Chat AI
    chatAIMember: ChatAI 会员
    creditsRecharge: 积分充值
    businessCooperation: 商务合作
    record: 粤ICP备2023038424号
  AdvantagesLayout:
    similarity: 相似度
    cloneSimilarity99: 克隆相似度99%
    mostRealisticDigitalHuman: 全网最真实的数字人
    cloneSimilarity60: 克隆相似度60%
    poorEffect: 效果差，能明显看出来是数字人
    speed: 速度
    rapidCloning: 极速克隆
    oneMinuteRapidClone: 1分钟极速克隆，3分钟生成视频
    slowCloneSpeed: 克隆速度慢
    typicallyTakes: 一般要3～5天
    image: 形象
    multipleDigitalHumanImages: 多套数字人形象
    switchWhenever: 想换就换，不怕犯错
    singleDigitalHumanImage: 单套数字人形象
    onlyOneSet: 只能1套，多了要收钱
    quality: 品质
    hdAndUhdDoubleModel: 高清超清双模型
    switchQualityFreely: 两个品质随心切换，1080P与4K任你选
    singleModel: 单模型
    onlyOneQualityAvailable: 只有一种品质可以选
    productAdvantageComparison: 产品优势对比
    digitalHuman: 数字人
    otherHouseDigitalHuman: 其它家数字人
  ProcessLayout:
    stepOne: 第1步：形象克隆
    uploadVideoMaterial: 上传视频素材
    quicklyGenerateDigitalAvatar: 快速生成数字分身
    oneMinuteRapidClone: 1分钟极速克隆
    oneToOneRealHumanReplica: 1:1真人复刻
    ultraHighDefinitionQuality: 超高清画质
    stepTwo: 第2步：声音克隆
    recordVoiceSample: 录制声音样本
    generateAiVoiceModel: 生成AI声音模型
    comparableToRealHumanVoice: 媲美真人声音
    supportBilingual: 支持中英双语
    naturalEmotionalExpression: 情感表达自然
    stepThree: 第3步：视频制作
    inputScript: 输入文案
    automaticallyGenerateVideoContent: 自动生成视频内容
    threeMinutesNewVideo: 3分钟生成新视频
    support1080P4K: 支持1080P/4K
    oneClickExportShare: 一键导出分享
    digitalHumanProductionProcess: 数字人制作流程
    onlyThreeSteps: 仅需3步
    easyForBeginners: 小白也能轻松做视频
    startMaking: 开始制作
  UseCasesLayout:
    shortVideoAcquisition: 短视频获客
    noProfessionalEquipment: 无需专业设备，一部手机即可完成高质量短视频制作。AI数字人帮您轻松打造爆款内容，快速获取精准流量。
    personalIPCreation: 个人IP打造
    breakTimeAndEffortLimits: 突破时间和精力限制，让AI数字人持续输出优质内容。智能化内容创作，帮您快速建立个人品牌。
    brandPromotion: 品牌宣传
    customDigitalSpokesperson: 为企业定制专属数字代言人，应用于产品展示、品牌推广、企业培训等场景。统一的品牌形象传播。
    courseProduction: 课程制作
    quicklyTransformCourseContent: 快速将课程内容转化为生动的视频讲解。AI数字人讲师全天候在线，帮您打造专业的在线课程。
    digitalHumanApplicationScenarios: 数字人应用场景
    realHumanAppearanceScenes: 真人出镜场景，AI数字人全能替代
  CommentWallLayout:
    everyoneIsUsingIt: 他们都在用
    makeCreationEasier: 让创作更简单，让变现更轻松
digitHuman:
  choose: 选择最合适您的数字人套餐
  superior: 超高品质，超高性价比
  exhibit: 展示
  videoCaseStudy: 视频案例
  product:
    digitalBusinessCard: 交互数字人
    digitaLive: 直播数字人
    digitalVoice: 声音定制
    guiJi:
      title: 硅基数字人
      desc: 硅基智能作为<span class='text-primary'>腾讯系人工智能企业，国家级专精特新小巨人</span>，获得<span class='text-primary'>招银国际、国新央企、海松资本、红衫资本等投资</span>，拥有<span class='text-primary'>近百项授权发明专利</span>，研发人员近千人，硅基数字人是通过先进的数字李生技术构建，一比一真实还原真人形态、动作、表情、声音的数字人形象，<span class='text-primary'>打造由强算力驱动、量子力学驱动、具备售体进步、开源开放、没有情绪、无限供给、永不离职、不参与分配的硅基生命。</span>把人类从繁重的重复性脑力劳动中解放出来，让人回归人的价值。
    xiaoBing:
      newTitle: 短视频数字人
      title: 小冰数字人
      desc: 小冰数字人脱胎于<span class='text-primary'>微软公司</span>，其核心技术和团队源于<span class='text-primary'>微软亚洲互联网工程院</span>。为加快小冰产品线的本土创新步伐，2020 年 7 月 13 日小冰从微软分拆为独立公司运营。独立后，由<span class='text-primary'>微软前全球执行副总裁沈向洋博士为小冰公司董事长</span>，新公司使用及继续研发完整的小冰技术。
      desc2: 2021 年小冰完成 A 轮融资，成长为 AI 行业最快的独角兽企业，并于 2022 年 11 月 7 日完成新一轮 10 亿元战略融资。在 AI 领域，小冰拥有<span class='text-primary'>计算机视觉、语音、NLP.人工智能创作</span>等全栈核心 Al 能力，是<span class='text-primary'>业内布局数字员工最早的企业之一</span>，目前<span class='text-primary'>已落地、可体验、产生业务价值</span>的案例数也稳居第一。
      basicName: 数字人体验版
      liteName: 数字人lite版
      prodName: 数字人pro版
    tengXun:
      title: 智影数字人
      desc: 腾讯智影数字人是由<span class='text-primary'>腾讯公司全资开发的数字人产品</span>，于2018年11月发布全球首个 AI 合成主播。经过6年时间已经从最初的简版送代至如今的超写实3D数字。并在<span class='text-primary'>广电、气象、证券、金融、等领域</span>都有广泛应用<span class='text-primary'>合作客户包含国家电网、工商银行、新华社、光大银行、平安金融、青岛地铁等客户。</span>
  containsContent: 包含内容
  portraitCustomize: 形象定制
  proPortraitCustomize: Pro形象定制
  soundClone: 声音克隆
  digitClone: 形象克隆
  unlimited: 无限制
  basicDesc1: 生成专属克隆数字人
  basicDesc2: 增送{msg}分钟短视频制作时长
  proDesc: 生成专属声音克隆
  userInfo: 用户信息
  pleaseRead: 请阅读下方提示语,并打勾
  promotionalPrice: 新春促销价
  discountedPrice: 折扣价
  customizedRequirements: 定制化需求(其他特殊需求)
  websitePrivateDeployment: 网站私有化部署
  customizedPlans: 定制化计划
  digitApi: API调用
  digitApiDoc: API文档
  enterpriseContact: 企业联系
  editionFeaturesplus: 商务版所有功能外加
chatAICategory:
  beyond: 已经超越 90% 的人类
  optimize: 你尽管提出问题，它总能给出最优答案
  useGuide: 使用引导
  ask: 提问
  pleaseInput: (您可以选择不输入任何内容，直接进入场景。)
  enterScene: 进入场景
  selectModel: 选择模型：
  currentModel: 当前模型：
imageAI:
  currentPrompt: 当前提示词
  historyRecord: 历史记录
  apply: 应用
  describeCost: 图片生提示词 消耗{msg}积分
  uploadImage: 上传图片
  senior:
    seedDesc: 随机噪声种子
    chaosDesc: 低的 chaos 值具有更可靠、可重复的结果。</br>高 chaos 值将产生更多不寻常和意想不到的结果和组合
    stylizeDesc: 低 stylize 值生成的图像与提示非常匹配，但艺术性较差。</br>高 stylize 值创建的图像非常艺术，但与提示的联系较少。
    styleDesc: cute 创造迷人可爱的角色、道具和场景。<br />expressive 更有精致的插画感。<br />original 使用原始 Niji 模型版本 5 <br />scenic 在奇幻环境的背景下制作美丽的背景和电影角色时刻。
    tileDesc: 生成的图像可用作重复图块，为织物、壁纸和纹理创建无缝图案。
    inputSeed: 请输入随机数字
    sampleDesc: 扩散过程中使用哪种取样器。<br />如果省略此值，我们将自动为您选择合适的采样器。
    cfgDesc: 数值越高，图像越接近提示词
  advancedSetting: 高级设置
  positivePrompt: 正向提示词
  negativePrompt: 负向提示词
  describe: 图片生提示词
  allowed: 允许商用
  tutorial: 使用教程
  lawTips: 请遵守法律法规,禁止上传色情,暴力,反动图片,违反规则会进行封号处理
  warning: 警告
  modelSelection: 模型选择
  apiService: API服务
  backSquare: 回到广场
  creditRecharge: 积分充值
  styleSelect: 风格选择
  imageDescription: 画面描述
  promptGenerator: 提示词生成器
  promptPlaceholder: 画面描述以短句、短语为佳，支持中英文等语言输入
  generateNum: 生成数量
  generateSpeed: 生成速度
  calculateStep: 计算步数
  widthHeight: 宽高尺寸
  imageToImage: 以图生图
  imageWeight: 原图权重
  relaxQueue: 慢速 排队{count}人
  fastQueue: 快速 排队{count}人
  fastMode: 快速 模式
  predictTime: 预计{time}分钟内
  creationCost: 创作 消耗{ totalPrice } 积分
  doubleScale: 2倍放大
  hdDownload: 高清下载
  upscaleCost: 放大消耗{credits}积分
  variationCost: 变换消耗{credits}积分
  redrawCost: 重绘消耗{credits}积分
  zoomCost: 延展消耗{credits}积分
  confirmPublish: 确认发布吗?
  variation: 变换
  midButtonTypeDesc:
    u1: 左上放大
    u2: 右上放大
    u3: 左下放大
    u4: 右下放大
    r: 重绘这张
    v1: 左上变换
    v2: 右上变换
    v3: 左下变换
    v4: 右下变换
    draw: 原始图片
    high_variation: 强变换
    low_variation: 弱变换
    out_paint_2: 2 倍延展
    out_paint_1_5: 1.5倍延展
    pan_left: 左延展
    pan_right: 右延展
    pan_up: 上延展
    pan_down: 下延展
  publishSquare: 发布广场
  deleteImage: 删除图片
  picViolateRule: 图片涉嫌违规
  imageWeightRange:
    low: 低
    default: 默认
    mid: 中
    high: 高
  inputPrompt: 请输入提示词
  inputPromptRule: 提示词不能少于 5 个字符
  publishSuccess: 发布成功
  painting: 绘画中...
  waiting: 正在排队中...
  waitingBack: 任务已提交...
  generating: 正在生成中...
  publishDesc: 设置为付费,其它用户需要支付积分才能查看,支付的积分 80% 将会返还给您
  publishDescribe: 描述
  publishIsFree: 是否免费
  publishFree: 免费
  publishPaid: 付费
  publishTag: 标签
  publishSuccessDetail: 发布成功,请前往广场查看我的
workbench:
  packageDownload: 客户端下载
  recentWorks: 最近作品
  video: 视频
  quickCreate: 快速创建
  moreWorks: 更多作品
  digitalAssets: 我的数字资产
  myModel: 我的模特
  searchModel: 搜索
  customize: 定制
  makeVideo: 去制作
  downloadClip: 去剪辑
  draft:
    tips: 作品删除后不可恢复，请确认是否删除?
  inProduction: 编辑中
  toEdit: 前往编辑
  copyEdit: 复制并编辑
  free: 免费
  public: 公共
  available: 可用
  all: 全部
  sex: 性别
  male: 男
  maleVoice: 男声
  female: 女
  femaleVoice: 女声
  background: 背景颜色
  transparent: 绿幕/透明
  fixed: 固定背景
  racial: 种族
  asia: 亚洲
  america: 欧美
createWork:
  selectModel: 选择模特
  selectBg: 选择背景
  selectVideo: 选择视频
  selectImg: 选择图片
  selectMaterial: 选择素材
  selectDraft: 选择草稿
  freeModel: 可用模特
  customizeModel: 定制模特
  unNamed: 未命名
  pleaseSelectModel: 请选择模特
  createWorkSuccess: 创建视频成功
  inputTextOrAudio: 请输入讲解内容或上传录音
  draftEmpty: 草稿内容为空,复制失败
  switchTips: 切换横竖屏方向不保留已编辑的数据，是否继续
  horizontal: 横屏
  vertical: 竖屏
  availTimes: 可用时长
  composite: 合成视频
  extract: 提取
  translate: 翻译
  painting: 绘画
  switchOrientation: 切换比例
  default: 默认
  imageAI: AI 绘画
  imgDirection: 图片方向
  goImageAI: 去绘画
  durationPackage: 时长包
  modelUnavailable: 该模特暂不可用
  creditsPerMin: 积分 / 分钟
  drawer:
    model: 模特
    background: 背景
    video: 视频
    img: 图片
    material: 素材
    draft: 草稿
  modelDrawer:
    noneCustomizeModel: 还没有定制模特
  footer:
    all: 全部
    male: 男
    female: 女
    deviceNotSupport: 当前设备暂不支持音频试听
    selectAudioModel: 请选择音色
    selectAudio: 请选择声音
    selectAudioStatus: 当前声音未完成,请更换声音
    inputTextRule: 文字内容不能少于 5 个字符
    inputTranslateText: 请在文字合成中输入翻译的文本
    inputOptimizeText: 请在文字合成中输入待优化的文本
    textComposition: 文字合成
    recordComposition: 录音合成
    inputTextPlaceholder: 输入或者粘贴文本
    addPause: 在光标处调整文字之间的停顿时长
    predictCost: 预计消耗
    audioModel: 音色
    audioSpeed: 语速{times}倍
    audioPitch: 音调
    testAudio: 试听
    language: 语言
    selectLanguage: 请选择语言
    systemVoice: 系统声音
    myVoice: 我的声音
    style: 风格
    sex: 性别
    role: 角色
    insertPause: 插入停顿
    predictTime: 预计时长
    seconds: 秒
    audioUploadDisable: 积分小于10,无法上传
    startConvert: 开始转换
  extractDrawer:
    copyLink: 请粘贴视频链接
    pleaseGetVideoDetail: 请先获取视频详情
    pleaseGetText: 请先提取文案
    textCopied: 文案已复制到剪贴板
    textExtract: 文案提取
    extract: 请输出抖音分享链接,一键提取文案
    textExtractTitle: 轻松 5 步，视频文案秒提取
    textExtractSubTitle: 工作效率直线提升
    pendingGetVideoInfo: 正在提取,请稍等
    1step: 第1步
    2step: 第2步
    3step: 第3步
    4step: 第4步
    5step: 第5步
    1desc: 粘贴链接
    2desc: 获取视频详情
    3desc: 提取文案
    4desc: 保存音频/视频
    5desc: 复制/应用文案
    inputLinkPlaceholder: 请输入抖音、小红书、快手、B 站（口令或链接）
    getVideoDetail: 获取视频详情
    extractText: 提取文案
    saveAudio: 保存音频
    saveVideo: 保存视频
    copyAudioAddress: 复制音频地址
    copyVideoAddress: 复制视频地址
    copyText: 复制文案
    applyText: 应用文案
  translateDrawer:
    translating: 翻译中...
    translate: 翻译
    originLanguage: 源语言
    targetLanguage: 目标语言
    textUpdated: 文案已更新
  optimizeDrawer:
    exception: 出现异常
    optimizeGPT: 🔥 用 ChatAI 来优化文案
    inputOrCopy: 输入或者粘贴文本
    shorter: 更短
    longer: 更长
    professional: 更专业
    engaging: 更吸引
    fix: 文案修正
    reWritten: 重写
    stopGenerate: 停止输出

digitTwins:
  selectDigitalMan: 请选择模特
  inputModelRule: 请输入模特名称
  inputModelValue: 请上传模特视频
  inputAuthImage: 请上传授权图片
  selectCutoutType: 请选择抠图类型
  inputEmail: 请输入邮箱
  authDoc: 授权书模板
  modelName: 模特名称
  trainVideo: 训练视频
  demoVideo: 示例视频
  precautions: 注意事项
  uploadPrompt: 上传提示
  videTime: 时长应在{videoTimeMin}分钟至{videoTimeMax}分钟之间
  tips:
    one: 上传本地视频，人工审核精选过滤后为您训练输出更精美的数字人形象
    two: 训练视频仅支持时长大于4分钟,小于6分钟,视频尺寸必须是:2560*1440(16:9)、1440*2560(9:16)、1920*1080(16:9)、1080*1920(9:16)、3840*2160(16:9)、2160*3840(9:16),
    size: 暂不支持其他尺寸如
    three: 视频要求为正面、五官清晰无遮挡，不要歪头、侧头
    four: 请保持视频中人脸比例适中，涵盖启膀区域，头部比例不要过大或者过小
    five: 上传视频将用于数字人定制，请慎重选择视频上传
  errorExample: 不佳示范
  errorPersonalPhoto: 非人物照
  maskFace: 五官遮挡
  vague: 模糊不清
  improperlyDressed: 衣着不当
  tooBigFace: 面部过大
  multipleFaces: 多张人脸
  reSubmit: 请在当前页面重新提交
  cancelUpload: 取消上传
  pleaseUpload: 请上传正确比例的授权书照片
  videoFormat: 视频格式必须是以下几种
  clearPhoto: 请上传清晰的授权书照片
upload:
  tipsType: 支持类型
  fileSize: 文件大小不能超过 {maxSize}MB
  filePixError: 当前资源分辨率不正确,请上传正确的分辨率资源
  video:
    tips1: 将视频拖动到此区域或点击
    tips2: 支持视频类型:mp4
    rule1: 视频仅支持 mp4 格式!
    rule2: 视频大小不能超过 {maxSize}MB!
    rule3: 视频大小不能超过 {maxSize}G!
  img:
    tips1: 将图片拖动到此区域或点击
    tips2: 支持图片类型:jpg/png/gif
    tips3: 支持图片类型:jpg/png
    rule1: 图片仅支持 jpg/png/gif 格式!
    rule2: 图片大小不能超过 {maxSize}MB!
    rule3: 图片仅支持 jpg/png 格式!
  audio:
    tips1: 将音频拖动到此处或点击
    tips2: 支持音频类型:wav/m4a/flac
    rule1: 音频仅支持:wav/m4a/flac 格式!
    rule2: 音频大小不能超过 {maxSize}MB!
  image:
    rule2: 图片大小不能超过 {maxSize}MB!
  application:
    rule2: pdf大小不能超过 {maxSize}MB!
  model:
    rule2: 3d文件大小不能超過 {maxSize}MB！
  uploadAvatar: 上传头像
  uploadSuccess: 上传成功
  uploadFailed: 上传成功
  maxUploadNum: 最大支持 2 张图片上传
  uploadSameImage: 请不要上传相同图片
workManage:
  time: 时长
  finish: 已完成
  unFinish: 未完成
  myWork: 我的作品
  myDraft: 我的草稿
  searchWork: 搜索作品
  compositing: 合成中
  workStatus:
    0: 合成中
    1: 成功
    2: 失败
  empty: 这里什么都没有哦
  createNow: 立即创建
  workNameReg: 名称必须为 2-20 字符之间
account:
  remainTime: 视频时长余额
  spendTimes: 视频时长已使用
  userID: 用户 ID
  orderId: 订单 ID
  workName: 作品名
  orientation: 方向
  workTime: 时长
  modelCount: 模特数
  remark: 备注
  creationDate: 时间
aiTools:
  aiTools: AI 工具
  navigator: 导航
  cleanUp: 杂物消除
  cleanUpHot: 杂物消除 🔥
  cutout: AI 智能抠图
  cutoutHot: AI 智能抠图 🔥
  cutoutFace: AI 抠头像
  losslessHD: 无损高清
  photoClearer: 照片变清晰
  photoColoring: 老照片上色
  cartoonAvatar: 照片变卡通
  aiPoster: AI 海报
  textExtract: 文案提取
  textToAudio: 文本转音频
  videoTranslation: 视频翻译
  avoidBackgroundMusic: 尽量避免使用有背景音乐和噪音的视频，因为它们会降低输出视频的质量
  translateWork: 翻译作品
  originalLanguage: 原语言
  targetLanguage: 目标语言
  selectInitialLanguage: 请选择视频的初始语言
  translateTo: 翻译为
  noWorks: 暂无作品
  selectTargetLanguage: 请选择视频输出的目标语言
  videoTranslationinfo: 一键将您的讲解视频翻译成其他语言讲解，并且还能保留您说话的音色和风格
  videoInitialLanguageNotEmpty: 视频的初始语言不能为空
  videoTargetLanguageNotEmpty: 视频的目标语言不能为空
  videoNotEmpty: 视频不能为空
  textToAudioSubTitle: 文字语音自由切换、创作无界限
  audioToText: 音频转文本
  audioToTextDesc: 切换无拘束，省心省时省力
  imageDetail: 图片详情
  slogan1: 探索未知，开启无限游戏
  creativitySlogan: 让创意照进现实
  allTools: 所有工具
  slogan2: 海量工具软件，{msg}一站体验
  midjourneyPaint: Midjourney 绘画
  bussinessCard: 交互数字人
  bussinessCardDesc: 可实时互动的数字人
  supportMidCommand: 支持 midjourney 官方全部指令
  sdPaint: Stable Diffusion 绘画
  sdDesc: 简易有趣，秒变专业画手
  chatAIDesc: 你尽管提问，它总能应答如流
  human: 数字人
  humanDesc: 立即定制
  digitHuman: 数字人视频制作
  digitHumanDesc: 无需出镜，3 分钟制作专业短视频
  digitLiveHuman: 数字人直播
  digitLiveHumanDesc: 无需出镜，23小时日不落直播
  cleanUpDesc: 一键抹去杂物，真实无痕
  cutoutDesc: 轻松扣图，细腻自然
  cutoutFaceDesc: 一键抠出头像
  losslessHDDesc: 一键处理劣质图片生成精美图片
  photoClearerDesc: 模糊图变清晰,无损放大
  photoColoringDesc: 黑白老照片上色
  cartoonAvatarDesc: 一键切换“漫画脸”
  textExtractDesc: 一键提取视频文案
  textToAudioDesc: 文本,音频相互转换
  comingSoon: 即将上线 ...
  expect: 敬请期待
  expectDesc: 更多功能,敬请期待
  extractRecord: 提取记录
  converting: 转换中 ...
  convertFail: 转换失败请重试
  textExtractSuccess: 文案提取完成
  convertRecord: 转换记录
  audioToTextTips1: 支持 100MB 以内且时长不超过 2 小时的音频文件的识别。
  audioToTextTips2: 1:支持 30+ 方言，50+ 外语
  audioToTextTips3: 2:最快 10 秒内完成 30 分钟语音
  audioToTextResult: 音频转文本结果
  return: 返回
  startProcessing: 开始处理
  Re-upload: 重新上传
  tutorial: 教程
partnerPlan:
  month: 个月
  silver: 白银
  gold: 黄金
  diamond: 钻石
  king: 王者
  exclusiveIndependentWebsite: 独立专属网站
  study: 实训基地
  partnerPlan: 合伙人计划
  partnerPlanDesc: 成为顶级推广官
  enthusiast: 您是AI爱好者
  humanExplorer: 您是数字人探索者
  understandMore: 需要了解更加详细的合作方案
  partnerDesc1: <p>亲爱的小伙伴</p>
    <p>感谢你对一直以来的支持</p>
    <p>为了建立更长久的连接和分享</p>
    <div>
    现诚邀你参与我们的
    <div class="relative inline-block">
    <span
    class="absolute bottom-0 block bg-[#B8DCC9] w-full h-[50%]"></span>
    <span class="relative font-bold">合伙人计划</span>
    </div>
    </div>
    <p>成为顶级推广官</p>
  partnerDesc2: <p>我们将为你打造一个</p>
    <p>完全专属于你的 AI 工具平台</p>
    <p>100% 复刻平台的功能和页面</p>
  partnerDesc3: <p>现在，只需轻松三步 就能拥有</p>
  partnerDesc4: <p>01 动动手指，选择您的推广套餐</p>
    <p>02 邀请更多好友体验您的专属网站</p>
    <p>03 立刻享受丰厚返利</p>
  plan: 元
  exclusive: <p class="text-[1rem] mt-2">将享有您专属网站</p>
    <p class="text-[1rem] mt-2 font-bold">充值收益的</p>
  xiaoBingDigitReward: 小冰数字人返佣
  customizedWebSite: 定制您的专属分销网站
  otherDigitReward: 硅基/智影数字人返佣
  otherProductReward: 其它产品返佣
  giftDesc: 送{number}个小冰试用版数字人
  giftXbLiteDesc: 送{number}个小冰Lite版数字人
  giftGjLiteDesc: 送{number}个硅基高级定制版数字人
  invitation_message: 亲爱的伙伴，诚挚邀请你加入我们的合伙人计划，与我们共同成长，探索AI时代的无限可能
  top_promoter: 成为顶级推广官
  easy_three_steps: 只需轻松三步 就能拥有
  ai_platform_replica: 我们将为你打造一个 完全专属于你的 AI 工具平台100% 复刻平台的功能和页面
  move_fingers: 动动手指
  choose_package: 选择您的推广套餐
  invite_friends: 邀请更多好友
  experience_website: 体验您的专属网站
  enjoy_now: 立刻享受
  generous_rewards: 丰厚返利
  core_advantages: 五大核心优势
  easy_ai_entrepreneurship: 轻松实现AI时代轻创业
  authoritative_certification: 工信标准
  ai_operator_project: 《人工智能运营师》由工信部教育与考试中心制定标准，分阶段系统性构建层层递进的学习体系，课程内容紧跟AI技术应用趋势，持续更新
  policy_support: 政策支持
  policy_guidance: 符合国家关于深化大数据、人工智能等研发应用的政策导向，响应“人工智能+”行动
  continuous_update: 持续更新
  updated_training: 我们的培训根据行业发展和技术进步不断更新课程内容，确保学员能够掌握最新的人工智能技术和应用
  ai_tools: AI工具
  cutting_edge_services: 我们为您准备了最前沿的AI数字人服务和AI工具集，助力您的市场推广和品牌宣传
  solutions: 解决方案
  partnerships: 我们与微软小冰、腾讯智影、硅基智能等科技公司紧密合作，不仅提供培训服务，还能够提供一站式AI解决方案服务
customImage:
  shareImage: 分享图片
  posterDesign: 海报设计
  editImage: 编辑图片
  historyImages: 历史图片
  aiTools: AI工具
  shareTips: 分享图展示信息
  customTitle: 自定义标题(可选)
  saveImage: 保存图片
  imageLink: 图片连接
aiPoster:
  background: 背景
  element: 元素
  cartoon: 卡通
  layer: 图层
cleanUp:
  cleanUp: 杂物消除
  cleanUpDesc: 想修哪里修哪里、一秒抹去瑕疵、毫无痕迹
  tryOtherDemo: 或者尝试以下例子
  imageHistory: 图片处理历史
  demo: 举个例子
  frequentQA: 经常问的问题
  tryThisExample: 尝试这个例子
  Q1: 什么是杂物消除？
  A1: 杂物消除是一种特殊技术，用于从照片中删除不需要的对象。想修哪里修哪里、一秒抹去瑕疵、毫无痕迹
  Q2: 为什么杂物消除比其他修复工具更好？
  A2: 杂物消除是一款基于人工智能的高级编辑工具，比其他克隆图章工具要好得多。像 Adobe Photoshop Fix 这样的克隆工具需要背景参考，而我们的 AI 只需单击几下即可真正猜测出不需要的文本、不需要的人、不需要的物体。
  Q3: 杂物消除可以处理什么图像分辨率？
  A3: 您可以在杂物消除中导入任何尺寸的图像。
  Q4: 杂物消除费用是多少？
  A4: 价格可在顶部菜单计费规则中查看
  ask1: 有效期一个月是什么意思？
  reply1: 从账号激活之日起计算，有效期有1个月，因此建议样本视频拍摄完成并且录音完成之后再激活账号。
  ask2: 使用时长10分钟是什么意思？
  reply2: 数字人训练好后,可生成的时间,假设一分钟一个视频,则最多可以制作10个视频。
  ask3: 形象上传后可修改吗？
  reply3: 形象克隆一旦上传后，后期不可更改，为了保证克隆质量，拍完视频一定要发给服务商审核后再上传。
  ask4: 为何要注重样本的拍摄？
  reply4: 样本视频质量决定着是数字人的最终效果，因此请一定要特别重视样本视频的拍摄。
  chooseUs: 为什么选择我们
  fiveGood: 五大核心优势,提供可定制化选择
  choose: 超低门槛
  onTime: 实时监督
  fit: 量身定制
  aiBox: AI工具
  oneChoose: 一站式服务
  QQ1: 加入我们成为合伙人的门槛极低，仅需999元起
  QQ2: 我们的在线系统赋予合伙人直接管理权限，您可亲自审核客户的克隆视频和声音素材，确保服务交付的高品质
  QQ3: 我们为您量身打造可定制化的网站，您可以根据需要自由调整价格体系、配色方案、联系信息等
  QQ4: 除了提供数字人服务，我们还为您准备了最前沿的AI工具集，助力您的市场推广和品牌宣传
  QQ5: 我们提供全方位的数字人代理服务，包括小冰、硅基、智影等知名产品，助您一站式解决方案
  removePassersby: 移除路人
  removePassersbyDesc: 快速移除照片中突然闯入的行人或游客
  removeClutterFromPhoto: 清除照片中的杂物
  removeClutterFromPhotoDesc: 清除照片中的杂物，例如垃圾桶、建筑物或电源线
  removeText: 删除图片中的文字、Logo 等
  removeTextDesc: 告别复杂繁琐操作。智能AI能够准确地识别不需要的对象
  startClean: 开始消除
  smearClean: 涂抹
  rectClean: 框选
  selectClean: 选区
  brushSize: 笔刷大小
  smear: 涂抹
  move: 移动
cutout:
  smartCutout: AI 智能抠图
  smartCutoutDesc: 一键上传图片，即可实现快速抠图。智能AI经历了数百万张图像训练，可以精确识别需要保留的主体并移除多余背景，让抠图更加简单、便捷。
  product: 商品
  productDesc: 去掉商品背景
  portrait: 人像
  portraitDesc: 去掉人像背景
  car: 汽车
  carDesc: 去掉汽车背景
  animal: 动物
  animalDesc: 去掉动物背景
  graphics: 图形
  graphicsDesc: 去掉图形背景
cutoutFace:
  cutoutFace: 头像抠图
  cutoutFaceDesc: 身份证、签证、卡通身体、变大头照，POD 最爱
imgBeautify:
  imgBeautify: 一键美化
  imgBeautifyDesc: 一键处理劣质图片生成精美图片
  autoExposure: 自动曝光
imgFaceBecomesClear:
  imgFaceBecomesClear: 模糊⼈脸变清晰，老照片⼀键增强
  imgFaceBecomesClearDesc: 没对好焦、低分辨率、模糊、像素化、⽼照片、损坏的，这些低质量照⽚都能智能修复为，成像锐利且焦点清晰的高品质照片。
  demo1: 人脸变清晰
  demo1Desc: 使用人工智能深度学习技术，识别模糊的人脸，将面部细节进行高清修复，哪怕是因光线太暗、曝光不足拍摄的夜间照片，也能轻松一键点亮你的美。
  demo2: 专业修复老照片
  demo2Desc: 把原本模糊的照片，通过 AI 技术补充细节，还原出高清晰度的照片，使老照片焕发出新的活力。无论是发黄的老照片，旧设备拍摄的陈年旧照，都能一键变清晰，帮你找回儿时的记忆。
  demo3: 不只人像变清晰
  demo3Desc: 失焦、低分辨率、像素化、损坏的旧照片，都能智能修复，自动处理图片噪点和锯齿部分，轻松还原真实高清的照片。除了人像智能修复，我们还支持一键无损放大图片尺寸，高清放大局部细节！
  demo4: 智能修复模糊图片
  demo4Desc: 使用人工智能 AI 学习技术，一键精准智能识别，轻松修复模糊产品图、图标、动漫素材等，无损还原真实高清的图片。
imgPhotoColoring:
  imgPhotoColoring: 黑白照片⾃动转换成彩色照片
  imgPhotoColoringDesc: 黑白老照片上色，让照片重新焕发光彩，展示出不一样的魅力。让您和家人的记忆更加栩栩如⽣，仿佛昨日重现。体会到不一样的心情与感动。
  demo1: 赋予旧照片新生
  demo1Desc: 为家里的黑白旧照片重新上色，用鲜艳的色彩将过去带回你的眼前，使回忆变得鲜活。
  demo2: 还原真实自然的颜色
  demo2Desc: 与普通着色器不同，我们基于智能 AI 着色算法，经过大量数据的深度学习，轻松为黑白照片还原更真实自然的颜色。
imgCartoonAvatar:
  imgCartoonAvatar: 迪士尼特效卡通头像
  imgCartoonAvatarDesc: 这是款一键切换“漫画脸”的 AI 人工智能，上传一张头像就可以秒变迪士尼在逃公主
  demo1: 案例1
  demo2: 案例2
  demo3: 案例3
  demo4: 案例4
messageTips:
  fileUploaded: 文件已上传
  updateSuccess: 更新成功
  deleteSuccess: 删除成功
  publishSuccess: 发布成功
  putTopSuccess: 置顶成功
  copySuccess: 复制成功
  createSuccess: 新增成功
  convertSuccess: 转换完成
  convertJobSubmit: 转换任务已提交
  imgProcessSuccess: 图片处理完成
  smearTips: 请涂抹待修复的区域
  rectTips: 请框选待修复的区域
  clearSuccess: 清空成功
  chatAIHistoryMode: 当前模式下, 发送消息会携带之前的聊天记录
  chatAISingleMode: 当前模式下, 发送消息不会携带之前的聊天记录
  submitSuccess: 提交成功
  updateWithdrawPWDSuccess: 修改提现密码成功,请重新登陆!
  setWithdrawInfoSuccess: 设置提现信息成功,请重新登陆!
  selectPrompt: 请选择提示词
  promptRule: 提示词不能超过 1000
  loginFirst: 请先登陆
  validateCodeFirst: 请先获取验证码!
  compositeWorkName: 名称必须为 2-20 字符之间
  withdrawSuccess: 提现成功
  configSuccess: 配置成功
audioClone:
  title: 在线克隆
  voliceUpload: 本地上传
  subTitle: 个性化人声克隆定制服务
  browserNoSupport: 浏览器不兼容
  browserNoSupportInfoA: 抱歉，您的浏览器无法使用麦克风
  browserNoSupportInfoD: 当前仅支持电脑端
  browserNoSupportInfoB: 请打开浏览器扫码或者点击复制网址
  browserNoSupportInfoC: 建议使用 Chrome 谷歌、Firefox 火狐、Safari 等能够支持录音的浏览器进行录制
  copyUrl: 复制网址
  copySuccess: 地址复制成功
  copyError: 该浏览器不支持自动复制
  langsInfo: 您的声音克隆可支持的国家语言
  langs: 英文 日文 印地文 德文 法文 西班牙文 韩文 葡萄牙文 意大利文 印尼文 荷兰文 土耳其文 菲律宾文 波兰文 瑞典文 保加利亚文 阿拉伯文 捷克文 罗马尼亚文 芬兰文 马来文 希腊文 克罗地亚文 斯洛伐克文 丹麦文 泰米尔文 乌克兰文
  iknow: 我知道了
  stepOneTitle: 个性化声音克隆流程
  stepOneInfoAtitle: 环境检测
  stepOneInfoAinfo: 请同意授权网站使用麦克风，并确保环境安静，比如少回声、少杂音的录音棚、卧室。
  stepOneInfoBtitle: 录制 5 句高质量音频
  stepOneInfoBinfo1: 1、建议使用手机自带麦克风10cm左右距离，尽量保证录制的每一条都清晰无比。
  stepOneInfoBinfo2: 2、想象自己是配音演员，在录制时需要保证发音清晰，声音饱满富有情感，减少吞咽口水等噪音。
  stepOneInfoCtitle: 等待声音训练
  stepOneInfoCinfo1: 请您耐心等待，约 1 分钟后，即可克隆你的声音。
  stepOneInfoDtitle: 试听并使用
  stepOneInfoDinfo: 在个人中心 - 个性化人声定制查看和试听声音。
  iread: 我已阅读并同意
  iservece: 《个性化人声定制服务协议》
  iokkonw: 点击确认阅读并同意
  ilangs: 《多国语言支持》
  irules: 《积分计费规则》
  readyClone: 开始克隆
  dagreeTitle: 个性化人声定制服务协议
  not: 注意
  notice: 注意：该次声音克隆将会消耗您
  credits: 积分！
  notiread: 请先确认阅读并同意《个性化人声定制服务协议》
  iok: 确认
  stepTwoTitle: 声音命名
  stepTwoSound: 声音
  stepTwoIDempty: 声音 ID 不能为空
  stepempty: 声音名称不能为空
  stepTwoInputInfo: 请输入15位以内的中文、数字、英文字符
  stepTwoInput: 请输入15位内的中文、数字、英文字符
  stepTwoInputInfo2: 可以使用中文、数字、英文字符，最多 15 字
  uploadSound: 上传声音
  uploadLimit: 4:最多支持提交{maxAuido}段音频,每个音频大小不超过{maxAuidoSize}MB
  stepTwoSex: 选择性别
  stepTwoSex1: 男
  stepTwoSex2: 女
  stepTwoSence: 选择使用场景
  stepTwoSence1: 科技知识
  stepTwoSence2: 新闻播报
  stepTwoSence3: 童话故事
  stepTwoSence4: 知识分享
  stepTwoSence5: 产品介绍
  stepTwoOpenMic: 如何开启麦克风权限
  stepTwoMic: 开始录制
  stepTwoMic1: 请允许获取麦克风权限
  stepTwoMic2: 没有麦克风，无法进行声音录制哦 ~
  stepTwoMic3: 设置方法
  stepTwoMic4: Safari用户
  stepTwoMic5: 刷新页面 > 点击「开始录制」>
  stepTwoMic6: 允许
  stepTwoMic7: Chrome 用户
  stepTwoMic8: 点击地址栏 / 菜单栏的【更多】
  stepTwoMic9: 或
  stepTwoMic10: 点击【网站信息】
  stepTwoMic11: 点击「网站设置」>
  stepTwoMic12: 开启麦克风权限
  stepThreeDialog: 贴心提示
  stepThreeDialog1: 请务必使用【英语】录音，语速适中
  stepThreeDialog2: 与耳机 / 手机保存 1-2 个拳头距离以内
  stepThreeDialog3: 使用耳麦
  stepThreeDialog4: 效果更好哦
  stepThreeDialog5: 务必保持周围环境安静
  stepThreeDialog6: 点完录音按钮后，等 2 秒钟再开始录音，效果会更好哦
  strpThreeBack: 退出录制
  strpThree: 声音录制
  strpThree1: 录制进度：
  strpThree2: 等待录制
  strpThree3: 正在录制
  strpThree4: 录制完成
  strpThree5: 试听
  strpThree6: 开始录制
  newstrpThree6: 点击录制
  strpThree7: 点击停止
  strpThree8: 重新录制
  strpThree9: 下一个
  strpThree10: 请完成所有录制后确认
  strpThree11: 点击确认提交所有录音
  strpThree12: 您的录音正在上传，请耐心等待大约 1-5 分钟，为确保上传声音完整性，上传期间请勿关闭或者刷新当前页面！谢谢！
  strpThree13: 恭喜您！您的克隆声音已完成，请前往【个人中心 - 我的定制】栏目查阅！
  strpThree14: 录音上传失败，请关闭后再次点击【确认】按钮尝试，或者联系系统管理员，非常抱歉给您带来不便！谢谢！
digitalHumanPage:
  nobuy: 您还未购买数字人，请购买后，再进行操作！
  downloading: 数字人授权书下载中...
  chosehuman: 请选择数字人
  imgtype: 抠像类型
  imgtype1: 保留拍摄背景
  imgtype2: 仅抠人像
  imgtype3: 人工扣像
  videohuman: 训练视频
  egvideo: 示例视频
  faceuse: 形象授权
  pclick: 请点击
  downUse: 《下载授权书模板》
  upface: 签名拍照后上传
Withdrawal:
  withdrawal: 提现
  withdrawalInformation: 提现信息
  name: 姓名
  callyou: 请填写您的诉求，提交后我们将尽快联系您
  alipayAccount: 支付宝账号
  withdrawalAmount: 提现金额
  withdrawalPassword: 提现密码
future:
  one:
    intelligentCabin: 智能数字人全息舱
    interactiveDigitalHuman: 智能交互数字人
    intelligent: 智能
    cabin: 全息舱
    professional: 数字人&大语言模型&交互一体机大屏
    listening: 我在听，请说话
    clickBelow: 点击下方，停止录音
    tapMe: 拍拍我
    conversation: 开启对话
    consult: 立即咨询
  two:
    core: 核心技术全维度攻克研发难题
    hardware: 智能硬件
    standard_solution: 数智员工一体机
    standard_solution_line2: 标准解决方案
    holographic: 业内领先的智能全息舱
    virtual: 虚拟人制作
    Metaverse: 快速构建虚拟人应用产品
    analysis: 用户行为分析
    camera: 摄像头采集教据,实时查春用户行为数据分析
    interaction: AI多模态交互
    variousForms: 真人与数字人多种形式互动,模拟真人对话
  three:
    scenarios: 使用场景
    methods: 制作方式
    issues: 问题咨询
    processing: 业务办理
    guide: 职能导购
    weakUp: 通过融合唤醒、语音识别、自然语音理解、虚拟人合成等AI核心技术为用户提供多模态互动、业务办理、问题咨询、资讯播放、服务导览等服务
  four:
    activities: 线下品牌活动
    projection: 全息投屏
    idols: 偶像合影
    guide: 职能导购
    contact: 联系我们
    live: 实时拍摄传输全息影像功能，可以让用户体验实时换脸、与偶像合影等娱乐玩法，加速品牌传播
  five:
    smart: 商场智能橱窗
    advertising: 全息广告
    analytics: 数据统计
    delivery: 智能投放
    holographic: 全息广告能大幅度提升用户注意力,无需佩戴3D眼镜即可身临其境,给用户带来沉浸式体验。同时,传感器获取用户行为,全息舱能整合广告投放、用户行为多种数据,提供智能投放解决方案。
  six:
    plan: 学习计划
    answering: 互动回答
    streaming: 全息直播
  seven:
    stores: 零售商品品牌店
    cultural: 文旅景区
    useScenarios: 使用场景
    service: 服务型机构：银行/医院/酒店
    public: 政府、事业单位
    learningPlans: 通过学习计划定制、互动问答、全息直播讲解，使党建工作突破时空限制，让党建工作更加智能化、便捷化、数字化。以沉浸式、互动式的体验学习方式，让党员更加深切感悟到党的艰辛历程，凝心聚力，锤炼党性，提高素养
intelligent:
  oneScreen:
    examinationCenter: 工信人才培养
    examinationCenter_line2: 《人工智能运营师》
    BringingTogether: 集合了工信部教育与考试中心专家、国内知名高校老师、AIGC实践专家、头部AI企业应用人才等进行课程设计与持续性更新
    project: 项目以应用为导向，由工信部教育与考试中心制定标准，与人工智能领域的领先企业以及各大院校建立紧密合作
    people: 旨在培养具备人工智能运营技能的专业人才，为企业或个人创造价值。打造产教融合、校企联合的人才培养生态圈
    generative_ai_engineer: 《人工智能运营师》
    future_start: 智启时代
    ai_skills_upgrade: AI技能升级，企业转型加速
    talent_gap: 人才缺口
    ai_era: AI时代，人工智能应用人才需求旺盛
    authoritative_certification: 工信标准
    mii_certification: 执行工信人才培养工程职业技术标准
    skills_upgrade: 技能升级
    systematic_training: 系统化实训，从AI基础到高级应用
    enterprise_transformation: 企业转型
    ai_empowerment: AI赋能数智化转型，企业新动力
  twoScreen:
    course: 课程模块
    course_line2: 职业技能
    artificialIntelligenceOperator: 《人工智能运营师》
    gettingStarted: 入门
    comprehensive: 全面的人工智能应用认知
    Professional: 专业
    comprehensiveTools: 全面的人工智能工具使用及应用部署
    advanced: 高阶
    advancedDeployment: 高阶的人工智能应用部署及数智化项目管理
    gettingStarteItem: 人工智能与大模型基础认知及应用，熟练操作AI文本、图像、音视频多模态生成能力，提示词工程入门，AI短视频运营，AI数字人运营以企业及个人如何迎接AI时代
    professionaItem: 人工智能模型高阶认知、提示词编写与优化、多模态提示词技巧与应用、企业级AI应用：AI+新媒体、AI+电商、AI+金融、AI+教育等企业级AI智能项目实施
    advancedItem: 人工智能数据分析、模型定制与训练、企业AI化生产与转型、AI智能办公、AI智能营销、AI行业深度应用与项目管理
  threeScreen:
    teaching_objectives: 教学目标
    ai_training_overview: 学员系统掌握人工智能运营的核心知识和技能，以实践操作为导向，学员需实操至少两种业务场景
    ai_training: 以就业为目标，联合科技大厂与企业，打造交流中心，实现精准人才输送
    ai_ecommerce_operations: AI电商运营
    digital_human_application: 数字人运用
    ai_live_stream_operations: AI直播运营
    ai_intelligent_interaction: AI智能交互
    ai_graphic_design: AI图形设计
    ai_smart_customer_service: AI智能客服
    customized_customer_service: 定制化模型
    others: 其他
  fiveScreen:
    smart_future_line2: AI赋能
    talent_training_program_all: 工信人才培养工程，助力AI运营师腾飞！
    talent_training_program: 工信人才培养工程
    talent_training_program_line2: 助力AI运营师腾飞！
    ai_operator_certification: 《人工智能运营师》考试报名入口
    exam_name: 姓名：
    contact_information: 联系方式：
    sign_up_now: 立即报名
    enter_exam_name: 请输入您的姓名
    enter_phone_number: 请输入您的手机号
    disclaimer: 注意:本网站非官方报名网站,只提供报名查询预约服务,一切以工信官网公布为准。
firstPage:
  oneScreen:
    industrial: 工信教考
    certification: 工信《人工智能运营师》
    certificationLine2: 职业技术证书
    cultivating: 旨在培养具备人工智能运营技能的专业人才，使其能够熟练运用人工智能技术解决实际工作中的问题，为企业或个人创造价值
    practicePriented: 实践导向
    national: 通过全国实训基地，学员进行AI技术实操，深入案例分析，以项目问题驱动学习，确保技能与实际工作需求紧密结合
    integration: 产教融合
    universities: 与高校合作，定期举办行业专家讲座，课程内容与企业需求同步，持续更新，培养与市场同步的AI专业人才
    school: 校企联动
    technology: 与科技企业合作，企业专家参与教学，提供实习机会，根据企业需求定制人才培养，促进学员就业与企业发展
    solution: 解決方案
    provideIntegrated: 为企业提供数智化转型升级的产品服务人才输送一体化解决方案，推动企业升级的同时增加学员就业机会
  twoScreen:
    applicable_people: 《人工智能运营师》适用人群
    ai_impact: AI的广泛应用正在革新教育教学的全流程，重塑社会职场生态，更为企业商业模式和业务形态的创新升级提供了广阔空间
    national_university_students: 全国大学生群体
    leading_edge: 掌握前沿
    ai_skills_for_students: 为大学生、大专生、研究生等提供前沿AI应用技能
    employment_advantage: 就业优势
    practical_training: 通过实践向导的培训，显著提升学生的就业竞争力
    professional_group: 社会从业者群体
    skill_update: 技能更新
    ai_skill_enhancement: 帮助各行各业人士掌握最新的AI技能，提升个人能力，实现职业升级
    efficiency_improvement: 效率提升
    ai_integration: 将AI技术与现有业务结合，极大提升工作效率和职场竞争力
    corporate_customized_training: 企业定制化培训
    innovation_upgrade: 创新升级
    managerial_support: 助力管理者建立新质生产力思维，推动业务模式的创新升级
    capacity_optimization: 产能优化
    employee_ai_skills: 通过提高公司员工的AI应用能力，提升工作产能，优化工作流程
    school_enterprise_course_development: 校企联合课程开发
    industry_education_integration: 产教融合
    ai_course_development: 响应国家“人工智能+”行动，将AI应用能力融入专业，打造精品课程
    job_adaptability: 就业适应性
    market_trend_courses: 课程紧贴市场前沿趋势，显著提升学生就业适应性和职场竞争力
  threeScreen:
    short_video_marketing: 短视频营销时代
    ai_digital_human_content: AI数字人助力高效内容产出
    digital_human_customization: 1分钟即可定制专属数字人形象及声音克隆
    efficient_voice_over: 随时随地高效完成口播视频输出
  fourScreen:
    interactive_digital_human: 交互式数字人
    ai_digital_human_platform: 专业的AI数字人平台
    custom_digital_human_ip: 定制数字人专属IP
    create_business_value: 创造无限商业价值
interactive:
  twoScreen:
    user_behavior_analysis: 用户行为分析
    camera_data_collection: 摄像头采集数据,实时查看用户行为数据分析
    ai_multimodal_interaction: AI多模态交互
    human_digital_interaction: 真人与数字人多种形式互动,模拟真人对话
  fourScreen:
    product_advantages: 产品优势
    stability: 稳定性：
    stabilityItem: 24小时不间断工作，服务质量始终如一
    accuracy: 准确性：
    accuracyItem: 减少人为错误，确保业务处理的准确性
    intelligence: 智能性：
    intelligenceItem: 具备多模态交互能力，会思考、能交流、有感情
    flexibility: 灵活性：
    flexibilityItem: 应不同业务场景，快速部署，易于扩展
    professionalism: 专业性：
    professionalismItem: 深度结合内部知识库体系，实现专属大语言模型
    ip_creation: IP打造：
    ip_creationItem: 符合宣传需要的形象、更好的传递IP价值
  fiveScreen:
    popular_topics: 热门话题
    business_qa: 业务问答
    business_explanation: 业务讲解
    fallback_phrases: 兜底话术
    current_events_interpretation: 时事解读
    multi_turn_conversations: 多轮会话
    voice_activation: 语音唤醒
    greeting: 迎宾问候
    product_features: 产品功能
  sixScreen:
    diversified_scenarios: 多样化场景
    interactive_digital_human: 交互式数字人凭借其高度的智能化和个性化能力，在智能教育、客户服务、虚拟助理、娱乐互动、业务导览及医疗咨询、法律咨询等多个场景中发挥着重要作用。它们能够理解并响应用户需求，提供定制化服务，同时通过持续学习优化交互体验，为各行各业带来创新动力和效率提升
    4s_store_customer_service: 4S店客服
    digital_immortality: 数字永生
    smart_reading_guide: 智慧导读
    digital_lawyer: 数字律师
    virtual_shopping_assistant: 虚拟导购
outstandingCase:
  oneScreen:
    personal_ip: 个人IP
    blogger_entrepreneur_merchant: 博主、企业主、商家等，可以打造自己的数字人形象，快速批量更新视频，做观点表达，让用户持续看到、信任并付费
    corporate_application: 企业应用
    brand_ambassador: 融合企业基因，打造品牌代言人、形象代言人、数字员工、数字专家等，可用于企业内外宣物料制作、营销Campaign创新、RPA数字人化等
    live_broadcasting_application: 直播应用
    live_streaming_support: 支持直播推流，数字人主播代替真人主播出镜，轻松满足电商带货、赛事解说、新闻资讯、娱乐播报等场景
    digital_interaction: 数字交互
    emotional_companion: 数字交互可以用于情感陪伴、客户服务、业务处理、虚拟顾问、智能陪练等多种场景，提供直观、生动的交互体验
  twoScreen:
    Short_Video: 短视频
    Interactive_Digital_Human: 交互数字人
    Live_Digital_Human: 直播数字人
    Voice_Customization: 声音定制
    Fast_Digital_Human: 极速数字人
    digital_human_applications: 数字人百行百业应用
    short_video_broadcasting: AI数字人目前已经广泛应用在短视频领域，满足各类用户对短视频播报的场景需求，在互联网、商业、教育、医疗、政务、法律、文旅等领域都有着广泛的应用前景
  threeScreen:
    digital_human_live_streaming: 数字人直播应用
    standardized_processes: 数字人直播更适合有标准流程的标品直播间。通过数字人，降低直播间对主播的依赖，放大直播间优势，降低直播运营成本，让直播常态化。
  fourScreen:
    interactive_digital_human_application: 交互数字人应用
    high_frequency_services: AI交互数字人通常用在系统化、重复性、高并发的服务场景中。如文旅服务中，将数字人导游/虚拟代言人等虚拟形象内置于交互大屏中，与游客深度联动；搭载AI大模型，让交互更智能、更效率，还能提供个性化的服务体验
aboutUs:
  ai_initiative_response: 响应国家人工智能+行动 推动市场创新应用
  talent_enterprise_link: 链接人才与企业，引入智能化解决方案 助力企业数智化转型
  project_overview: 项目概览
  services_provided: 我们提供智能数字人应用服务以及定制化AI模型开发服务，包括智能交互式数字人、数字人形象定制、声音克隆、视频合成、数字人直播、行业垂直小模型等解决方案；
  training_certification: 同时提供专业的AIGC商业应用培训、工信教育与考试中心《人工智能运营师》培训考证等核心服务，并为企业输送人工智能专业运营人才，实现人工智能应用落地的推进和创新。
  ai_operator_training: 《人工智能运营师》
  training_certificat: 培训及考证
  training_program: 通过系统化、分阶段的实训课程，培养专业的人工智能应用型人才。培训执行工业和信息化人才培养工程标准，通过实训及考试的学员可获得工信部教育与考试中心《人工智能运营师》职业技术证书
  learn_more: 了解更多
  ai_digital_human_products: AI数字人产品
  solutions: 解决方案
  business_solutions: 根据企业的具体业务需求场景提供智能数字人应用方案，包括数字人定制、声音克隆、视频合成、数字人直播、AI视频翻译、智能交互式数字人等。助力企业在内容制作、流量获取、品牌宣传等方面的智能化升级
  ai_customization: AI定制化
  model_services: 模型服务
  training_services: 从企业业务需求端出发定向训练任务模型，并制定工作流，提高工作效率，包括AI客服模型训练、AI电商模型训练、AI设计模型训练等
offtenQuersion:
  image_customization_change: 形象定制是否可以更换形象？
  image_customization_changeA: 抱歉，形象视频在审核通过并上传训练后无法更换，因为训练是我们定制过程中最重要的一环。为了保证数字人形象的克隆质量，建议您在拍完视频后一定要发给客服人员审核后上传。
  action_required: 为何要在15秒到54秒中间必须要做动作？
  action_requiredA: 因为系统的动作捕捉期是视频的第1分钟（14秒-54秒），所以您在这个时间段的动作需要多样化一些。同时，为了兼容未来想表达内容，建议多做一些通用型动作（比如双手握手、双手摊手，往前展开等），不要做有特别指向性的动作（比如举3根或者1根手指之类）。
  video_duration: 视频拍摄时长应该是多少？
  imageVersionSuggestedDuration: 形象版：建议时长1~30分钟。
  durationVersionSuggestedDurationAndQuality: 时长版：建议时长3~5分钟，越接近5分钟越好。样本视频质量和长度，决定数字人的最终效果，因此请一定要特别重视样本视频的拍摄。
  imageVersionNoReview: 形象版：无需审核，一般1~3分钟即可训练完成。
  durationVersionAfterReview: 时长版：审核通过后，一般3~5个工作日即可训练完成。
  video_durationA: 建议时长是3-5分钟,越接近5分钟越好。样本视频质量和长度，决定着数字人的最终效果，因此请一定要特别重视样本视频的拍摄。
  audio_duration: 音频录制时长应该多少？
  audio_durationA: 建议时长是8-12分钟,越接近12分钟越好,训练数据量越多,生成效果越好。
  production_time: 数字人制作一般要多久？
  production_timeA: 审核通过后，一般3-5个工作日即可生成完成
  refund_policy: 数字人购买之后是否可以退款？
  refund_policyA: 数字人作为虚拟产品,因此在购买之后无法退款,所以建议您在购买前，必须要看清包含的产品内容,可以在咨询客服后确认无误再进行购买。
  review_time: 审核时间一般多久？
  review_timeA: 在工作日内，工作人员会先查看原视频，如果原视频符合上传要求，在当天上传视频。如果原视频不符合要求，工作人员将对视频和音频进行二次优化处理，完成优化后再做上传。
  discount_code: 什么是折扣码？
  discount_codeA: 如果您申请了折扣码后并通过，您的朋友或者客户使用该折扣码就可以获得一定的折扣。例如您获得了九折折扣码，您可以在基础价格的九折价格，同时还可以获取相对应的返佣。您必须要注意的是，申请折扣码之前您必须要发布一条推广视频才能获得。
  interaction_paths: 交互路数一路是什么意思？
  interaction_pathsA: 交互路数越多，可以同时服务的客户数量就越多。举例： 一路交互路数就代表一个客服，当该客服在与客户通话的时候,第二个客户再打进来就必须等待第一个客户结束后才可接入，如果您有两路,就可以同时服务两个客户,此时第三个客户就会等待，以此类推。
  card_count: 名片张数是什么意思？
  card_countA: 假设您拥有名片三张,那么您可以同时发布三张名片。每张名片可以配置不同的形象、不同的职位介绍。
  live_duration: 直播时长是什么意思？
  live_durationA: 直播时长代表您在有效期内可以直播的时长。例如您在有效期3个月内有360个小时的直播时长，如果您直播超过360个小时,即使在有效期内也不能开播。在有效期内，您使用完直播时长后可以联系工作人员，购买直播时长。注意了，有效期内直播时长未使用完的，超过有效期后剩余的直播时长将会被归零。
bannerCard:
  digital_human_live: 数字人直播
  digital_human_customization: 数字人定制
  customized_models: 定制化模型
  interactive_digitHuman: 交互式数字人
  artificial_intelligence_certification: 人工智能实训
customizedModels:
  customized_model_service: 定制化模型服务
  ai_art_beauty: 你所见之境
  ai_art_beauty_line2: 尽是AI艺术之美
  technical_advantages: 技术优势
  solve_large_model_issues: 解决了大模型“无法精准落地”的痛点
  solve_large_model_issues_line2: 从具体需求端真正帮助客户大幅提高生产力
  application_layer: 应用层
  customized_model: 定制化模型
  large_model: 大模型
  precise_efficient_solution: 精准、高效的落地方案
  customized_workflow: 定制化工作流
  demand_oriented: 从需求端出发
  targeted_training_model: 定向训练模型
  wig_style_effect_images: 指定假发款式效果图生成4A界面效果图生成
  wig_materials: 假发素材
  train_model: 训练模型
  batch_image_generation: 批量跑图
  customer_selection: 客户挑选
  customized_model_images_output: 根据产品图片
  customized_model_images: 输出定制化模型图
digital-human-workbench:
  professionalRemainingCloneCount: 克隆剩余次数{count}次
  minimalRemainingCloneCount: 预克隆剩余次数{count}次
  setInfo: 参数设置
  previewCloneAudio: 预克隆音频
  emotionalVersion: 情感版
  emotionalVer: 情感版
  basicVersion: 基礎版
  confirmClone: 确定克隆
  preClone: 预克隆
  remaining: 剩余
  times: 次
  seats: 席位
  userCommitmentNotice: 使用者承诺须知
  statement: 本声明将帮助您更好地在平台使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并接受以下内容：平台使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并接受以下内容：
  intellectualProperty: 您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。
  compliance: 您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，维护公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚及信息真实性等要求。如有违反，一经本平台发现，将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
  complianceUser: 您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。
  infringement: 若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对被投诉的作品或用户账号依据相应规则，采取包括但不限于下架、警告、封禁账号等处理方式。
  acknowledged: 我已知晓，并同意
  digitalAvatar: 数字分身
  uploadVideo: 上传视频
  rapidCloneDigitalAvatar: 3分钟极速克隆数字分身
  quickReplication: 快速复刻
  uploadAudio: 上传音频
  createPersonalVoiceModel: 1分钟极速复刻声音
  rapidClone: 快速克隆
  myDigital: 我的定制
  myDigitalAvatar: 我的数字分身
  goCreate: 去创作
  massive_models: 使用公共模特創作視頻
  noDigitalAvatarYet: -还没有数字分身-
  clickForCustomDigitalPersona: 点击“快速复刻”定制专属数字人分身
  myVoiceClone: 我的声音克隆
  noVoiceCloneYet: -还没有声音克隆-
  clickForPersonalVoiceModel: 点击上方“快速克隆”上传音频，打造专属个人声音模型
  replicateDigitalAvatar: 复刻数字分身
  digitalAvatarName: 数字分身名称
  enterDigitalAvatarName: 请输入数字分身名称
  upVideo: 上传视频
  videoOrientation: ①视频方向：
  orientation: 横向或纵向
  fileFormat: ②文件格式：
  videoDuration: ③视频时长：
  time: 30秒~5分钟
  resolution: ④分辨率：
  fileSize: ⑤文件大小：
  less: 小于500MB
  notes: 注意事项
  errorExample: 错误示例
  doNotUseMultiplePeopleVideo: 不要使用有多个人的视频
  ensureFullFaceInScreen: 人脸不要太大，确保整张人脸都在屏幕区域内，人脸不要出屏幕
  ensureFaceUnobstructed: 确保面部特征没有被遮挡，并努力让面部清晰可见
  videoResolutionGuidance: 上传的视频分辨率不应低于360p，也不应超过3840p，建议分辨率为720p或1080p
  translateVideo: 分辨率必须介于480x480和2560x2560之间
  translateVideoDur: 介于480x480和2560x2560之间
  videoLengthRange: 视频长度在30秒～5分钟范围内
  multipleFaces: 多张人脸
  faceTooLarge: 人脸太大
  faceObstructed: 脸部遮挡
  faceNotDetected: 检测不到人脸
  iHaveReadAndAgree: 我已阅读并同意
  notice: 《使用者承诺需知》
  pleasePreCloneFirst: 请先预克隆
  canCloneMultipleTimes: 可多次克隆,找到最佳效果
  confirmCloneAfter: 确认后不可更新
  continueCreate: 继续制作
  youHave: 您拥有
  preCloneChances: 次声音预克隆测试机会
  preCloneDescription: 。每次预克隆将消耗一次机会，您可以通过试听功能确认声音效果。当效果满意时，请点击"确认克隆"完成声音训练。请注意：
  preCloneWarning: 仅点击"确认克隆"的声音可用于数字分身，且确认后将无法修改或重新训练。
  confirmEffectPrompt: 请确认声音效果是否满意。确认后,
  confirmWarning: 该声音将完成训练并可用于数字分身，并且无法进行预克隆,
  continuePrompt: 是否继续？
  preCloneSuccess: 预克隆成功
  generating: 生成中
  readAndAgreeUserCommitment: 请先阅读并同意《使用者承诺须知》
  enter: 请输入数字分身名称
  digitalAvatarNameCharRange: 数字分身名称字符数应在 2 到 20 之间
  submitVideoFile: 请提交视频文件
  submitting: 提交中
  enterVoiceName: 请输入声音名称，且字符数在 2 到 20 之间
  voiceNameCharRange: 声音名称字符数应在 2 到 20 之间
  submitVoiceFile: 请提交声音文件
  submitAudioFileFormat: 请提交mp3、m4a、wav格式的音频文件，文件大小不超过10MB
  basicVoiceClone: 声音克隆
  highFidelityVoiceClone: 声音克隆—高保真
  enterName: 请输入声音名称
  languageSelection: 语言选择
  selectLanguage: 选择语言
  selectSex: 请选择性别
  audioRequirements: 音频要求
  myfileFormat: ①文件格式
  audioDuration: ②音频时长：
  mytime: 10秒～3分钟
  audioSize: ③音频大小：
  myless: 小于10MB
  kindReminder: 温馨提示
  suggestedAudioDuration: 音频时长建议音频时长限制在10秒～3分钟
  audioQuality: 音频质量
  oneSpeakerOnly: a. 只有一个在说话，避免音频中出现多个人的声音；
  ensureLowBackgroundNoise: b. 保证低底噪。说话人说话越清晰，最终克隆效果越好，音频底噪会严重影响克隆效果
  maintainStableVolumeAndPace: c. 保持音量大小、语速稳定、注意断句、避免口腔噪音(如口水声)、杂音、混响等情况
  avoidManualNoises: d. 音频中不要有桌椅响声、键盘鼠标敲击声、衣服摩擦声等人为噪声；
  pauseForMistakes: e. 音频中可以存在口误。口误时无需终止录音,可停顿1~2秒后,继续录制即可。
  audioContent: 音频内容
  decideVoiceStyleFirst: 在录制音频前建议先确定好声音风格，在录音时尽量去贴近风格，避免录制的音频情绪韵律趋于平淡。
  emotionalRhythm: 如果希望克隆出的声音情绪饱满、韵律有起伏，请尽量上传表现力强的音频来克隆。
  supportedAudioFormats: 音频格式
  selectEmotion: 选择情感
  emotionalVoiceLanguage: 情感版声音可选择语言
  emotionalVoiceEmotion: 情感版声音可选择情感
  supportedFormatsAndSize: 支持mp3、wav、m4a文件格式，音频文件大小不超过10M。
  uploadVoiceOrEnterText: 请上传声音或者输入文本
  selectVoiceFirst: 请先选择声音
  estimatedVideoLength: 预估合成视频时长为{time}秒
  confirmSynthesis: 确定合成
  newDigitalPersonaVideoGenerating: 您的视频已经开始生成，稍后可在【作品】板块查看及下载。
  workbench: 工作台
  myWorks: 查看我的作品
  continueCreating: 继续创作
  public_model: 公共模特
  audioDriven: 音频驱动
  textDriven: 文本驱动
  enterTextHere: 请在此输入文字
  audition: 试听
  selectTextToAudition: 鼠标滑动选取文本内容, 进行逐句试听, 最多选取300字
  extract: 文案提取
  textInput: 文本输入
  currentSelection: 当前选中：
  previewSynthesizing: 预览合成中
  speedSpeech: 语速
  adjustRate: 点击调整
  currentSpeed: 当前语速
  rename: 重命名
  cloneAgain: 再次克隆
  name: 请输入名称
  renameSuccess: 修改命名成功
  renameFailure: 修改命名失败
  myAssest: 我的素材
  usedAlready: 已用
  number: 个
  speedAdjustment: 语速调整
  myAvailable: 可用
  usedfor: Used for
  available: Available
  myused: Used
  already: already
  viewLine: 查看台词
  recreate: 再次创作
  driveMode: 驱动模式
  selectDigitalPersonaVideoMode: 选择数字人视频在驱动时的模式：
  sequentialDrive: 顺序驱动：使用数字人生成作品时会从你上传的原始视频第一帧开始顺序驱动
  randomMode: 随机模式：随机挑选片段来进行驱动（生成的音频时长超过原视频长度时，按照顺序驱动，不会走随机模式）
  sampleAudio: 示例音频
  noAudioMaterials: 暂时没有音频素材？使用我们的示例素材来感受效果吧
  random: 随机模式
  sequentia: 顺序驱动
  persevereSilently: 坚强隐忍
  beYourselfComfortably: 安心做自己
  giveYourselfTime: 给自己时间
  selectOption: 选用
  myOption: 我的
  publicOption: 公用
  enterKeywordsToSearch: 请输入关键词进行搜索
  noVoiceModelAvailable: 暂无语音模型，请选择其他
  generatingWait: 生成中,请稍等
  generationFailed: 生成失败
  waitingToStart: 等待开始
  computingPower: 算力
  dontRemindAgain: 不再提醒
  enable: 启用
  professionalMode: 超清版本
  professionMode: 超清
  synthesizingVideoWill: 合成视频将会
  doubleComputingPowerConsumption: 消耗2倍的视频合成时长,即原来1视频合成时长的超清版本=2视频合成时长的高清版本,
  confirmContinue: 请确认是否继续？
  normalMode: 高清版本
  normal: 高清
  generationMode: 生成模式
  description: 说明：
  normalModeDescription: 高清版本：消耗算力，合成1分钟视频消耗1算力
  professionalModeDescription: 超清版本：使用超清版本将消耗2倍的视频合成算力，即原来1算力的超清版本=2算力的高清版本
  noSoundDetected: 检测不到声音，请确认麦克风处于录制中，并且保障足够的说话时长
  reasonForFailure: 失败原因
  voiceCloneProcess: 声音克隆流程
  uploadReferenceAudio: 上传参考音频
  uploadAudioDescription: 上传一段音频用于克隆声音。音频的质量会直接影响到最后克隆声音的效果，因此请您尽量保证用于克隆的音频符合以下要求：
  audioDurationTitle: (1)音频时长：
  audioDurationRange: 建议音频时长限制在1分钟～3分钟：
  audioQualityTitle: (2)音频质量：
  audioContentTitle: (3)音频内容：
  audioFormatTitle: (4)音频格式
  previewEffect: 预览效果
  previewAudioTip: 音频上传后，请先预览试听声音效果。
  editChancesWarning: 如果对声音效果不满意，您可重新上传新的音频进行效果试听，
  editChancesLimit: 每个声音共有2次修改编辑的机会。
  avoidWastingChances: 建议您保证音频符合上述要求，避免浪费修改编辑的次数。
  pointsConsumption: 积分消耗
  highFidelityPointsCost: 声音克隆-高保真 积分消耗：
  pointsPerVoice: 10000积分/个
  freeTrialsExhausted: 如果您的免费次数为0
  purchaseDisclaimer: 我们会扣除10000积分，用于购买声音经购买，概不退货
  language:
    zhCN: 中文(普通话)
    zhHK: 中文(粤语)
    enUS: 英语
    jaJP: 日语
    koKR: 韩语
    esES: 西班牙语
    frFR: 法语
    ruRU: 俄语
    itIT: 意大利语
    ptPT: 葡萄牙语
    deDE: 德语
    idID: 印尼语
  emotion:
    happy: 高兴
    sad: 悲伤
    angry: 愤怒
    fearful: 害怕
    disgusted: 厌恶
    surprised: 惊讶
    neutral: 中性
audioModeSelectionDialog:
  recommended: 推荐版本
  title: 简单易用，超乎您的期望
  subtitle: 领先的技术体验声音克隆，超过2万用户的选择
  advanced:
    title: 高级版
    description: 轻松体验声音克隆技术，快速还原你的独特音色
    button: 注册免费体验
    features:
      fastClone: 极速克隆
      fastCloneDescription: 轻松体验声音克隆技术，快速还原你的独特音色
      zeroCost: 0成本体验
      zeroCostDescription: 无需任何费用，即可开始你的声音克隆之旅
      simpleProcess: 简洁流程
      simpleProcessDescription: 简单易用的界面设计，让你轻松上手，快速完成声音克隆
  emotional:
    title: 情感版
    description: 轻松体验声音克隆技术，快速还原你的独特音色
    button: 立即使用
    features:
      emotionalClone: 声音情感克隆采用先进技术，最高程度还原语料的音色、语调及说话节奏
      emotionalCloneDescription: 不仅还原你的音色，更精准克隆原语料的情感表达，让声音更加生动真实
      instantPreview: 效果及时预览
      instantPreviewDescription: 实时预览克隆效果，随时调整至满意状态，让声音克隆更加精准贴合
      resetOpportunity: 享{times}次预克隆重置机会
      resetOpportunityDescription: 随时特享{times}次预克隆机会，预览效果不满意可享有{times}次重置时长的机会，首至点击确认克隆后，才消耗一次情感版声音授权额度
      coreAdvantagesLabel: "核心优势："
      coreAdvantagesValue: "多情感多语言"
      voiceSimilarityLabel: "声音相似度："
      voiceSimilarityValue: "80-95%"
      emotionalModesLabel: "声音情感："
      emotionalModesValue: "高兴、悲伤、愤怒等7种情感模式"
      languageSupportLabel: "语言支持："
      languageSupportValue: "中文、粤语、英文等12种语言"
      previewSystemLabel: "试听机制："
      previewSystemValue: "克隆前,{times}次试听(克隆前充分验证）"
  highFidelity:
    title: 专业版
    description: 轻松体验声音克隆技术，快速还原你的独特音色
    button: 立即使用
    features:
      highRestore: 高度还原音色与节奏
      highRestoreDescription: 采用先进技术，最高程度还原语料的音色、语调及说话节奏
      extremeFidelity: 极致高保真
      extremeFidelityDescription: 一次克隆，即可享受高保真声音效果，满足你对声音品质的极致追求
      retrainOpportunity: 享{highTimes}次重新训练的机会
      retrainOpportunityDescription: 克隆完成后，在有效期内可享受{highTimes}次声音替换与重新训练的机会
      coreAdvantagesLabel: "核心优势："
      coreAdvantagesValue: "高精度灵活调整"
      voiceSimilarityLabel: "声音相似度："
      voiceSimilarityValue: "80-95%"
      emotionalModesLabel: "情感模式："
      emotionalModesValue: "暂不支持"
      languageSupportLabel: "语言支持："
      languageSupportValue: "中文、英文"
      previewSystemLabel: "试听机制："
      previewSystemValue: "克隆后，{highTimes}次重新克隆机会(降低调整成本）"
