buttons:
  hsLoginOut: 登出系統
  hscloseAllTabs: 關閉所有標籤頁
  hscloseCurrentTab: 關閉目前標籤頁
  hscloseLeftTabs: 關閉左側標籤頁
  hscloseOtherTabs: 關閉其他標籤頁
  hscloseRightTabs: 關閉右側標籤頁
  hscontentExitFullScreen: 內容區域退出全螢幕
  hscontentFullScreen: 內容區域全螢幕
  hsexitfullscreen: 退出全螢幕
  hsfullscreen: 全螢幕
  hslogin: 登入
  hsreload: 重新載入
  hssystemSet: 開啟專案設定
  hswholeExitFullScreen: 退出全螢幕
  hswholeFullScreen: 全螢幕
login:
  back: 返回
  definite: 確定
  emailOrPhone: 電子郵件或手機號碼
  emailOrPhoneReg: 請輸入正確的電子郵件或手機號碼
  inputEmailOrPhoneReg: 請輸入您的手機號碼
  forget: 忘記密碼?
  weChatQRCodeLogin: 微信掃碼登入
  loginWitWeChatScan: 使用微信掃一掃登入
  mobileEmailLogin: 手機號/電子郵箱登入
  otherloginMethods: 其他方式登入
  verificationCodeLogin: 驗證碼登入
  freeLogin: 7天免登入
  getVerifyCode: 獲取驗證碼
  info: 秒後重新獲取
  login: 登入
  loginSuccess: 登入成功
  password: 密碼
  passwordDifferentReg: 兩次密碼不一致!
  passwordReg: 請輸入密碼
  passwordRuleReg: 密碼格式應為 6-18 位數字、字母、符號的任意兩種組合
  passwordSureReg: 請輸入確認密碼
  passwordUpdateReg: 修改密碼成功
  updatePassword: 修改密碼
  phone: 手機號碼
  phoneCorrectReg: 請輸入正確的手機號碼格式
  nameCannotEmpty: 姓名不能為空
  informationCannotEmpty: 聯繫方式不能為空
  phoneLogin: 手機登入
  phoneReg: 請輸入手機號碼
  emailReg: 請輸入電子郵件
  emailCorrectReg: 請輸入正確的電子郵件
  privacyPolicy: 《隱私政策》
  serviceAgreement: 《服務協議》
  qRCodeLogin: QR 碼登入
  qqLogin: QQ 登入
  readAccept: 我已仔細閱讀並接受
  register: 註冊
  passwordLogin: 密碼登錄
  registerSuccess: 註冊成功
  remember: 記住密碼
  smsVerifyCode: 簡訊驗證碼
  sure: 確認密碼
  test: 模擬測試
  thirdLogin: 第三方登入
  tickPrivacy: 請勾選隱私政策
  tip: 掃碼後點擊"確認"，即可登入
  scanTip: 首次掃碼關注公眾號後將自動註冊新賬號
  scanTips: 微信掃碼登錄/註冊
  lateQr: 二維碼失效，點擊刷新
  reScan: 點擊刷新
  loginRefresh: 登錄信息失效，點擊二維碼刷新
  username: 用戶名
  callbackAddress: 回調地址
  changeLoginPassword: 修改登錄密碼
  usernameReg: 請輸入用戶名
  verifyCode: 驗證碼
  verifyCodeCorrectReg: 請輸入正確的驗證碼
  verifyCodeReg: 請輸入驗證碼
  verifyCodeSixReg: 請輸入 6 位數字驗證碼
  verifyCodeLogin: 驗證碼登入
  weChatLogin: 微信登入
  joinUs: 加入我們
  become: 成為
  oneMember: 一員
  inviteCodePlaceholder: 邀請碼(選填)
  verifyCodeSend: 驗證碼已經發送
  pleaseCompleteVerify: 請先完成驗證！
  pleaseWaitVerify: 請等待驗證初始化完成
  inviteCode: 邀請碼
  loginFirst: 請先登入
  wxMobileTips: 請複製當前頁面連結到微信打開進行登入
  changeWithdrawPwd: 修改提現密碼
  verifyIDCardReg: 請輸入身份證號
  verifyIDCardCorrectReg: 請輸入正確的身份證號
  verifyNameReg: 請輸入姓名
  pleaseRequest: 請輸入您訴求
  websiteName: 網站名稱
  verifyWebsiteNameReg: 請輸入網站名稱
  key: 個性化二級域名(例如 ai 99 等等)
  verifyKeyReg: 請輸入個性化二級域名(例如 ai 99 等等)
  verifyKeyLengthReg: 請輸入 2-10 個字元的字母或數字任意組合
  agentInitTips: 請輸入您的實名資訊，我們會儘快聯繫您
  discountCodeApplyInfoReg: 請輸入折扣碼申請資訊
  enterChName: 請輸入姓名
  enterAlipayAccount: 請輸入支付寶帳號
  setWithdrawalInformation: 設置提現資訊
  enterPaymentAccount: 請輸入支付賬號
  enterValidPaymentAccount: 請輸入正確的支付賬號
  enterBankAccount: 請輸入銀行卡號
  enterValidBankAccount: 請輸入正確的銀行卡號
  enterBankBranchLocation: 請輸入開戶行所在地
  alipay: 支付寶
  bankCard: 銀行卡
  withdrawalMethod: 提現方式：
menus:
  about: 關於
  artificialIntelligenceCertification: 人工智能實訓
  digitTwins: 數字孿生
  hsFive: "500"
  hsabnormal: 異常頁面
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hshome: 首頁
  hslogin: 登入
  mine: 我的帳戶
  permission: 權限管理
  permissionButton: 按鈕權限
  permissionPage: 頁面權限
  MenuWorkManagement: 作品管理
  MenuDigitalAssets: 數字資產
  userCenter: 個人中心
  digitHuman: 數位人
  realPerson: 真人
  aiImage: AI繪畫 🔥
  paintSquare: 繪畫廣場 🔥
  beginCreation: 開始創作
  enterpriseCooperation: 企業合作
  digitHumanOperation: 數位人操作
  createWork: 創建作品
  aboutFutureAI: 關於 Future AI
  aboutChatAI: 關於 Chat AI
  techSupport: 技術支援
  clickCollapse: 點擊折疊
  clickExpand: 點擊展開
  aboutDigitHuman: 關於數位人
  outstandingCase: 優秀案例
  customizedMode: 客製化模型
  aboutUs: 關於我們
  helpCenter: 幫助中心
  MenuMyCustom: 我的定制
  MenuMyRights: 我的權益
  usageRecord: 消耗記錄
  useRecord: 使用記錄
  videoSynthesisDuration: 视频合成时长
  videoDuration: 视频合成时长(秒)
table:
  date: 日期
  name: 名稱
  status: 狀態
  video: 影片
  reason: 原因
  dateRange: 日期範圍
  to: 到
  fromDate: 起始日期
  endDate: 結束日期
  recentWeek: 最近一週
  recentMonth: 最近一個月
  recent3Month: 最近三個月
  creationTime: 建立時間
  format: 形式
  title: 標題
  text: 文本
  audio: 音頻
  buyDate: 購買日期
  undataDate: 更新時間
  contactName: 姓名
  mobile: 手機號碼
  phone: 電話
  idCard: 身份證
  email: 郵箱
  telphone: 手機
  digitalHuman: 數位人
  childType: 詳情
  version: 版本
  type: 類型
  operate: 操作
  updateInfo: 修改個人資訊
  updateBasicInfo: 待補充影片訊息
  updateAudioBasicInfo: 待補充音訊訊息
  info: 待補充個人資訊
  uploadInfo: 上傳訓練影片
  uploadAudio: 上傳訓練音頻
  pendingReview: 影片正在審核中
  pendingAudio: 音頻正在審核中
  pendingTraining: 數字人訓練中
  pendingTrainingAudio: 音頻訓練中
  trainingSuccess: 訓練完成
  clickToMake: 點擊去製作
  successaActition: 點擊製作
  trainingAudio: 聲音訓練完成
  waitingForUpload: 審核通過，等待上傳
  mySound: 我的聲音
  soundName: 聲音名稱
  soundSex: 聲音性別
  soundType: 聲音類型
  soundAct: 狀態
  cloningProcess: 操作
  illustrate: 说明
  largeScreen: 大屏管理
status:
  loading: 載入中...
common:
  pleaseInput: 請輸入文本
  pauseDuration: 停頓時長
  inputContent: 输入字数不能超过
  remove: 移除
  stop: 停止
  send: 發送
  changeVoice: 更換聲音
  selectVoiceClone: 請選擇音色
  clickChange: 點擊更換
  processing: 點擊處理
  text: 中文
  known: 我已知曉
  add: 新增
  addSuccess: 新增成功
  edit: 編輯
  editSuccess: 編輯成功
  delete: 刪除
  deleteSuccess: 刪除成功
  save: 儲存
  saveSuccess: 儲存成功
  saveFail: 儲存失敗
  reset: 重設
  action: 操作
  export: 匯出
  exportSuccess: 匯出成功
  import: 匯入
  importSuccess: 匯入成功
  clear: 清空
  clearSuccess: 清空成功
  yes: 是
  no: 否
  confirm: 確定
  download: 下載
  applypattr: 申請
  noData: 暫無資料
  wrong: 好像出錯了，請稍後再試。
  success: 成功
  failed: 失敗
  imageFailed: 載入失敗
  deleteFailed: 刪除失敗
  verify: 驗證
  unauthorizedTips: 未經授權，請先進行驗證。
  claiming: 需要登錄，現為您跳轉
  cancel: 取消
  deleteConfirmation: 形象刪除無法恢復,確定刪除嗎？
  submit: 提交
  copy: 複製
  copySuccess: 複製成功
  deleteConfirm: 確定刪除嗎?
  tips: 提示
  submitSuccess: 提交成功
  back: 返回
  select: 返回
  retryProcessing: 再次處理
  allImages: 全部圖片
  myFavorites: 我的收藏
  today: 今天
  yesterday: 昨天
  preview: 預覽
  insert: 插入
  more: 更多
  pleaseSelect: 請選擇
  img: 圖片
  bg: 背景
  apply: 應用
  upload: 上傳
  psImage: P 圖
  video: 更多視頻案例
  buyRightNow: 立即購買
  credits: 點數
  creditsRecharge: 點數充值
  billRules: 計費規則
  alipay: 支付寶
  payChange: 點選對應支付按鈕，跳轉完成支付
  weChatPay: 微信支付
  paypal: PayPal支付
  reUpload: 重新上傳
  resubmit: 再次提交
  pre: 上一步
  next: 下一步
  paidSuccess: 已完成付款
  paidSuccessTips: 付款完成後，請點擊下方按鈕
  free: 免費
  cooperation: 咨询
  weChatCustomer: 微信客服
  dedicatedCustomer: 專屬客服,優惠福利
  officialAccount: 公眾號
  latestAccount: 最新諮詢,前緣動態
  noPage: 抱歉，您造訪的頁面不存在
  backHome: 回到首頁
  id: ID
  title: 標題
  desc: 描述
  createTime: 創建時間
  updateTime: 更新時間
  operation: 操作
  loading: 載入中
  create: 創建
  warning: 警告
  confirmDelete: 確定要刪除嗎？
  deleteFail: 刪除失敗
  getFail: 獲取數據失敗
  logo: Logo
chat:
  placeholder: 說點什麼吧...（Shift + Enter = 換行）
  placeholderMobile: 說點什麼...
  create_digital_avatar: 使用該內容創作自己的數字人分身
  select_conversation: 請選擇一個會話
  message_deleted: 訊息已刪除
  delete_failed: 刪除失敗，請重試
  press_enter_send: 按Enter發送訊息
  copy: 複製
  copied: 複製成功
  copyCode: 複製程式碼
  clearChat: 清空對話
  linkContext: 關聯上下文
  switchToLinkContext: 已開啟上下文關聯
  switchToCancelLinkContext: 已取消上下文關聯
  exportChat: 匯出對話
  clearChatConfirm: 是否清空對話?
  exportImage: 儲存對話為圖片
  exportImageConfirm: 是否將對話儲存為圖片?
  exportSuccess: 匯出成功
  exportFailed: 匯出失敗
  usingContext: 上下文模式
  turnOnContext: 在目前模式下，發送訊息會攜帶之前的聊天記錄
  turnOffContext: 在目前模式下，發送訊息不會攜帶之前的聊天記錄
  deleteMessage: 刪除訊息
  deleteMessageConfirm: 是否刪除此訊息?
  deleteHistoryConfirm: 確定刪除?
  clearHistoryConfirm: 確定清空對話記錄?
  waitAnswer: 請等待回答完成
  regenerate: 重新生成
  stopRespond: 停止回應
  newConversation: 新對話
  dayTheme: 日間模式
  nightTheme: 夜間模式
  subscribeMember: 訂閱會員
  youSubscribed: 您已訂閱
setting:
  setting: 設定
  general: 總覽
  config: 配置
  avatarLink: 頭像連結
  name: 名稱
  description: 描述
  resetUserInfo: 重設使用者資訊
  chatHistory: 聊天紀錄
  theme: 主題
  language: 語言
  api: API
  reverseProxy: 反向代理
  timeout: 逾時
  socks: Socks
  httpsProxy: HTTPS Proxy
  balance: API餘額
store:
  local: 本地
  online: 線上
  title: 標題
  description: 描述
  clearStoreConfirm: 是否清空資料？
  importPlaceholder: 請將 JSON 資料貼上此處
  addRepeatTitleTips: 標題重複，請重新輸入
  addRepeatContentTips: 內容重複：{msg}，請重新輸入
  editRepeatTitleTips: 標題衝突，請重新修改
  editRepeatContentTips: 內容衝突{msg}，請重新修改
  importError: 鍵值不匹配
  importRepeatTitle: 標題重複跳過：{msg}
  importRepeatContent: 內容重複跳過：{msg}
  onlineImportWarning: 注意：請檢查 JSON 檔案來源！
  downloadError: 請檢查網路狀態與 JSON 檔案有效性
imageSquare:
  oneKeySame: 一鍵同款
  creationPlatform: 創作平台
  support: 支援
  model: 模型
  justOneSentence: 只需一句話，讓你的文字變成畫作
  public: 公開
  mine: 我的
  createInfo: 創作資訊
  creator: 創作者
  oneKeySameDesc: 本作品一鍵同款需支付{msg}積分
  description: 描述
  size: 尺寸
  modelName: 模型名稱
  seed: 種子
  free: 免費
  prompt: 提示詞
  negativePrompt: 負向提示詞
  onlyShowOnekeySame: 僅支援一鍵同款可看
  style: 風格
  step: 步數
  searchPlaceholder: 支援提示詞、暱稱、標題搜尋
  aiImage: AI 繪畫
aboutFutureAI:
  one: 你好，歡迎來到{msg}的小宇宙，我們是一家專注於数字人和AI工具軟體的領先，旨在為客戶提供最佳的智能解決方案。
  two: 我們堅信，数字人和 AI 技術正在改變世界，並將持續為人們創造更多機會和效益。因此，我們致力於研發最前沿的數位人及 AI 工具軟體，幫助企業提高效率、降低成本、增強安全性，並取得可持續的競爭優勢。
  three: 数字人是我們最核心的業務之一，它為企業提供了一個全新的數位化解決方案，可以大幅度縮短招聘時間、降低試錯成本，同時還可以實現個性化需求定制等功能。我們的数字人技術媲美真實人類，可以通過語音、視頻、文字等方式與使用者進行互動，極大地提升了業務的效率和精準度。
  four: 另外，在 AI 工具軟體領域涵蓋了自然語言處理、計算機視覺、數據分析等業務。我們的工具軟體不僅可以加速人們的日常工作，還可以為客戶提供最佳的智能化方案，幫助他們實現業務轉型升級。
  five: 如果您正在尋找一家可靠的数字人和 AI 工具軟體服務商，那麼請與我們聯繫！我們不僅可以滿足您的需求，也可以超越您的期望，為您帶來更多的機會和收益。
aboutChatAI:
  one: Chat AI 是一個聊天機器人，它使用 LLM 來回答使用者的問題，LLM 是一種自然語言處理模型，它能夠理解自然語言並像人類一樣回答問題。通過使用 Chat AI，使用者可以與它進行自然語言交互，獲得相關資訊和回答。
  two: Chat AI 會通過對大量文本資料進行訓練，來學習語言知識和推理能力。這些文本資料可能包括新聞文章、小說等內容。通過不斷地訓練，它的神經網絡可以學會提取文本中的資訊，並使用這些資訊來回答問題。
techSupport:
  one: 您好，我們還提供数字人客製化、語音辨識、智能繪畫、Chat AI 等企業客製方案，幫助企業實現數位轉型和智能升級。
  two: 如果您需要相關合作，請發送郵件至我們的信箱{email}，我們將有專業的工程師儘快回覆您，並提供詳細的解決方案。
userCenter:
  pleaseUpload: 請上傳圖片
  rule1: 只能上傳 JPG 或 PNG 格式的圖片
  rule2: 圖片大小不能超過 500KB
  changeAvatar: 更改頭像
  avatarConfirm: 確定更改頭像
  accountInfo: 帳戶資訊
  nickname: 暱稱
  dedicatedService: 專屬客服
  subscribedService: 已訂閱服務
  subscribedRecord: 訂閱記錄
  rechargeRecord: 購買記錄
  activationCode: 啟動碼
  inputActivationCode: 請輸入啟動碼
  activateNow: 立即啟動
  AIQA: AI問答
  expiresOn: 到期時間為
  renew: 續費
  subscribeNow: 立即訂閱
  nicknameRule: 暱稱必須為 2-20 個字元之間
  callbackAddressMustBeURL: 回調地址需要為URL
  inviteCode: 邀請碼不能為空
  remainAvailTimes: 您剩餘可用次數為{count}次
  digitalHumanTime: 数字人時長
  recharge: 充值
  paintingCredits: 繪畫積分
  shareFriend: 分享好友
  invitationPlan: 邀請計畫
  rewardActivity: 返利活動
  inviteLink: 邀請連結
  orderRecord: 訂單記錄
  creationTime: 創建時間
  orderId: 訂單ID
  price: 價格
  amount: 金額
  type: 類型
  memberType: 會員類型
  activationMethod: 開通方式
  withdrawAmount: 可提現額
  setWithdrawInfo: 設定提現資訊
  updateAgentInfo: 修改代理商實名資訊
  agentWebsiteConfig: 代理商網站配置
  withdraw: 提現
  configRealNameTips: 首次配置實名資訊後需要重新登入
  scanQrCodeAddService: 專屬客服
  AudioUpload: 上傳音頻要求-請注意以下要點
  mineDigits: 我的數碼人
  mineServe: 我的商品
  inviteQRCode: 邀請QR碼
  mineInvite: 我的邀請
  downloadQRCode: 邀请二维码下载
  InvitationList: 邀請列表
  rebateList: 反佣列表
  withdrawalList: 提現列表
  username: 用戶名
  orderID: 訂單ID
  userID: 用户ID
  remarks: 備註
  mySuperior: 我的上級：
  mustPicType: 請選擇抠像類型，必填欄位
  mustVideoType: 請上傳訓練影片，必填欄位
  mustFileType: 請上傳授權文件，必填欄位
  uploadNotice: 上傳影片要求-請注意以下要點
  chooseClippinType: 選擇剪裁圖像類型，請注意以下要點
  videoSuccess: 訓練影片提交成功。
  buttonPass: 我們正在對您的影片進行審核和優化，以提升數字人效果。請耐心等待，優化後的影片將很快呈現給您！
  buttonPassAudio: 我們正在對您上傳的音頻進行審核和優化，以提升音頻克隆效果。請耐心等待，優化後的音頻將很快呈現給您!
  awaitingAudio: 您的音頻審核通過，等待後台上傳
  awaitingVideo: 您的視頻審核通過，等待後台上傳
  seeSoon: 您的數字人正在製作中,預計24小時內,會和您見面。
  seeSoonAudio: 您的克隆音頻正在製作中,預計24小時內,會和您見面。
  completeInformation: 待補充個人資訊
  setInformation: 請先設置提現資訊
  warmReminder: 溫馨提示
  orderCompleted: 訂單完成
  aiOrderCompleted: 聯繫客服
  waitingOpen: 等待創建
  waitingOpenDesc: 帳號開通中，預計5～30分鐘開通成功後會有郵件提示
dialog:
  xbTip:
    one: 請留意，一封確認郵件已經在路上了,請注意查收哦
    two: 如果未收到郵件，別忘了檢查垃圾箱，也許在裡面.
    three: 添加並聯絡我們的客服，我們隨時準備協助您!
  buyTip:
    one: 本產品為虛擬商品。
    two: 購買並啟用後將不支援退款
  agentInit:
    exitTips: 點擊確認後將退出本站，並跳轉到您的專屬網站 {website}
  discountCode:
    discountCode: 折扣碼
    apply: 合夥人申請
    suggestInput: 建議填寫，手機號碼、姓名、主要流量平台，方便我們盡快審核
    applySuccess: 申請成功
    getDiscountCode: 賺取佣金,點擊申請折扣碼
    discountforCode: 申請折扣碼
    whatIsDiscountCode: 什麼是折扣碼？
    discountNow: 立即折扣
    baseinfo: 基本資訊
    purl: 數位人影片連結
    urlinfo: (選填)請輸入您的數位人影片連結
    yourName: 您的名字
    phoneNumber: 您的手機號
    yourRequest: 您的訴求
    pleaseRequest: 請輸入您訴求
    weChat: 微信二維碼
  digitalRegisterInfo:
    title: 補充手機號碼和電子郵件
    titleForAi: 補充個人信息
    tips1: 1. 啟動郵件即將送達您的信箱，請注意查收哦~
    tips2: 2. 如果未收到郵件，別忘了檢查垃圾箱，也許在裡面.
    tips3: 3. 添加並聯絡我們的客服，我們隨時準備協助您!
  kindTips: 溫馨提示
  scanWechat: 掃描微信二維碼添加專屬客服
  contactWechat: 微信掃描二維碼
  purchaseSuccessful: 購買成功
  contact: 聯繫我們
  freeDigital: 掃碼領取免費數位人製作時長
  getDigital: 免費領取數字人
  purchaseSuccess: 可透過以下資訊與我們聯繫
  below: 關注公眾號
  freeTime: 選擇免費獲取數字人時長.
  send: 回覆以下專屬申請碼：
  clickCopy: 點擊
  problem: 或發送以下內容：
  problem0: “免費領取數字人，專屬申請碼
  problem2: 到該郵箱
  paySuccess: 付款成功
  points_purchase: 積分購買
  points_balance: 積分餘額
  credits: 積分
  immediateRecharge: 立即充值
  question: 如掃描遇到問題，您可以透過以下方式領取
  clickToView: 點擊查看
  apply: 掃描二維碼，即可免費領取
  addWeChat: 微信：搜索並添加微信號
  sendEmail: 發送專屬申請碼
  to: 至
  emailAddress: 電子郵件地址
  contactUs: 請通過以下任一方式聯繫我們的客服：
  addLine: Line：搜索並添加Line賬號
  support: 選擇方便的聯繫方式，我們的客服團隊將隨時準備協助您！
  sendCode: 我已發送
  compositeTips: 本次合成預計時長:{time}
  discountCodeDesc: 折扣碼可以使用優惠價格購買產品
  inputDiscountCode: 請輸入折扣碼
  buyWarning: 親愛的朋友，虛擬產品一經銷售，不支援退款。請在下單前詳細閱讀<a class="text-primary" href="https://mp.weixin.qq.com/s/0Zc2LbX5x9E5JzRxxeQTDw">使用指南</a>，或與我們的客服聯繫詢問。
  buyWarning2: 親愛的朋友，虛擬產品一經銷售，不支援退款。如有疑問，請與我們的客服聯繫詢問。
  buyWarningline1: 親愛的朋友，虛擬產品一經銷售，不支援退款。
  buyWarningline2: 如有疑問，請與我們的客服聯繫詢問。
  chatai:
    one: 解鎖 CHAT AI 會員,享受順暢提問
    two: 目前充值僅支援 CHAT AI
    wechatPay: 點擊立即購買，掃碼支付
    paidSuccess: 已支付
    chooseMember: 請選擇一個會員
  digitHuman:
    one: 解鎖數位人會員，輕鬆製作數位人影片
    two: 目前充值僅支援製作數位人影片
    minute: 分鐘
  digitalVoice:
    audioDuration: 音頻時長
    effectiveAudioDuration: 有效音頻時長
    lessMinutes: 音頻建議不少於10分鐘
    recordingEnvironmen: 錄音環境
    noise: 安靜時環境底噪
    keepNoEcho: 錄製時保持無迴音、無混響、無噪聲等
    RecordingEquipment: 錄音設備
    recommended: 推薦
    microphone: 降噪麥克風/小蜜蜂
    conditionsAllow: 條件允許建議使用防風棉衣或防噴罩
    recordingPerson: 錄音人
    close: 不宜離麥太近
    tooClose: 防止噴麥和錄入呼吸聲
    coherently: 連貫說完一句話，句與句之間停頓1～2秒
    consistent: 保持語境風格一致，避免多種情緒混雜
    recordingParameters: 錄音和錄音參數
    recommendedToUse: 推薦使用
    recordingAudio: 40kHz採樣率錄製音頻
    losslessAudio: 推薦無損音質格式保存音頻，如
    zipAudio: 不能使用壓縮格式保存音頻，如
    submitAudio: 請提交訓練音頻
    synthesizedAudio: 請提交錄音合成音頻
    requiredField: 請上傳音頻,必填字段
    resSex: 性別不能為空
    submittedSuccessfully: 訓練音頻提交成功
    trainingAudio: 訓練音頻
    deleteSound: 確定刪除嗎?
    supports: 音頻僅支持wav、m4a、flac格式
    notExcee: 每個音頻大小不超過10MB
    submitted: 請勿提交相同的文件
    uptoTenAudio: 最多支援提交{fileLimit}段檔案
    supportedTypes: 支持類型wav/m4a/flac
    drag: 將文件拖動到此處或者點擊
  score:
    one: 解鎖積分會員，享受 AI 繪畫
    two: 目前充值僅支援 AI 繪畫
  serviceDigitHuman:
    one: 您好，製作數位人屬於
    two: VIP會員權益，需要付費使用
    three: 方案1—— 普通用戶合作，直接充值。
    four: 方案2—— 企業合作，聯繫我們的銷售顧問
    five: 將有專業的數位顧問為您詳細介紹數位人定制的流程。
  billRuleDialog:
    function: 功能
    price: 售價
    creditsOnce: 積分 / 次
    creditsMinute: 積分 / 分鐘
    credits100Words: 積分 / 100 字
    extractVideoText: 提取視頻文案
    getVideoDetail: 獲取視頻詳情
    extractWatermarkFreeVideos: 提取無水印視頻
    chatAiToken: 積分/100 字符
    extractAudio: 提取音訊
    yuanMinute: 積分/分鐘
  cropper:
    cropperImage: 裁剪圖片
    freeMode: 自由模式
    imageSize: 圖像大小
    pixel: 像素
    fileSize: 檔案大小
    roundRectCropper: 圓形/矩形裁剪
    roundRect: 圓/矩
    reset: 重設
    backgroundColor: 背景顏色
    customColor: 自定義顏色
    close: 關閉
    flip: 翻轉
    xFlip: 水平翻轉
    yFlip: 垂直翻轉
    rotate: 旋轉
    clockwiseRotate: 順時針旋轉
    AnticlockwiseRotate: 逆時針旋轉
    longClick: 可長按
    zoomIn: 放大
    zoomOut: 縮小
    saveImage: 保存圖片
    insertCanvas: 插入畫布
    view: 查看
    viewAll: 查看全部
welcome:
  one:
    AIPower: AI 賦能
    fast: 更快、更強、更簡單
    combine: Chat AI + 數位人，為 360 行業助力賦能
    createNow: 立即創作
  two:
    youWalk: 讓你走進
    future: 未來
    imagine: 每一天，都有更多人因為AI解放想像力、放飛創造力
  three:
    digitHumanCustomization: 數位人定制
    earnLiving: 讓每個人都擁有一個專屬打工人
    customizeNow: 立即定制
  four:
    isMe: 沒錯，是我，你的效率提升精靈
    youAsk: 你儘管提出問題，總有更專業的回答
    experienceNow: 立即體驗
  five:
    simple: 更高效、更專業、更簡單
    inFutureAI: 來 FUTURE AI
    propeller: 為你的創造力安上螺旋槳
    contactUs: 聯絡我們
    common_problem: 常見問題
    nowsq: 馬上申請專屬折扣碼
    digitSimple: 讓您與數字人的互動如此簡單
    highsend: 最高返傭
    sendinfo: 只需要現在製作一段推廣影片，立即擁有您獨家的折扣碼
    nowdo: 立即申請
    partner: 成為合夥人
  imageAI:
    imageAI: AI 繪畫
    support: 支援 Stable Diffusion、Midjourney 模型
    desc: 簡單提詞，文字妙變精彩畫作
    desc2: 想像躍然眼前
  sixCooperationCase:
    cooperationCase: 輕鬆批量製作數字人，優秀案例
    leiJunSpeech: 雷軍演講給我的感悟
    aiDigitalHumanDo: AI數字人可以做什麼
    howHumanCreated: 我的數字人到底怎麼誕生的
    WhatDigital: 什麼是AI數字人
    illusions: 焦慮害怕都是幻想
    selfIntroduction: 一分鐘自我介紹法寶
    StudyAbroad: 美國、英國和澳洲留學
    notInteractWithDigitalHumans: 沒準備的人別碰數字人
    theCEOofJPMorganChase: 摩根大通CEO說每週只用工作三天
    aiDigitalHuman: AI數字人
    landCases: 土地案件
    advantagesOfYoga: 瑜伽的優勢
  news:
    learnMore: 了解詳情
  footer:
    one: AI賦能 — 更快、更強、更簡單
    contactNumber: 聯絡電話
    contactEmail: 聯絡郵箱
    contactLine: 聯絡Line
    officeAddress: 辦公地址
    officeAddressValue: 深圳市南山區雲谷一期南風樓501室
    companyName: 深圳市敲敲撬撬科技有限公司
    aboutUs: 關於我們
    digitalHumanPlate: 數位人板塊
    understandDigitHuman: 了解數位人
    digitHumanRecharge: 數位人充值
    understandChatAI: 了解 Chat AI
    chatAIMember: Chat AI 會員
    creditsRecharge: 積分充值
    businessCooperation: 商務合作
    record: 粤ICP备2023038424号
  AdvantagesLayout:
    similarity: 相似度
    cloneSimilarity99: 克隆相似度99%
    mostRealisticDigitalHuman: 全網最真實的數字人
    cloneSimilarity60: 克隆相似度60%
    poorEffect: 效果差，能明顯看出來是數字人
    speed: 速度
    rapidCloning: 極速克隆
    oneMinuteRapidClone: 1分鐘極速克隆，3分鐘生成視頻
    slowCloneSpeed: 克隆速度慢
    typicallyTakes: 一般要3～5天
    image: 形象
    multipleDigitalHumanImages: 多套數字人形象
    switchWhenever: 想換就換，不怕犯錯
    singleDigitalHumanImage: 單套數字人形象
    onlyOneSet: 只能1套，多了要收錢
    quality: 品質
    hdAndUhdDoubleModel: 高清超清雙模型
    switchQualityFreely: 兩個品質隨心切換，1080P與4K任你選
    singleModel: 單模型
    onlyOneQualityAvailable: 只有一種品質可以選
    productAdvantageComparison: 產品優勢對比
    digitalHuman: 數字人
    otherHouseDigitalHuman: 其他家數字人
  ProcessLayout:
    stepOne: 第1步：形象克隆
    uploadVideoMaterial: 上傳視頻素材
    quicklyGenerateDigitalAvatar: 快速生成數字分身
    oneMinuteRapidClone: 1分鐘極速克隆
    oneToOneRealHumanReplica: 1:1真人復刻
    ultraHighDefinitionQuality: 超高清畫質
    stepTwo: 第2步：聲音克隆
    recordVoiceSample: 錄製聲音樣本
    generateAiVoiceModel: 生成AI聲音模型
    comparableToRealHumanVoice: 媲美真人聲音
    supportBilingual: 支持中英雙語
    naturalEmotionalExpression: 情感表達自然
    stepThree: 第3步：視頻製作
    inputScript: 輸入文案
    automaticallyGenerateVideoContent: 自動生成視頻內容
    threeMinutesNewVideo: 3分鐘生成新視頻
    support1080P4K: 支持1080P/4K
    oneClickExportShare: 一鍵導出分享
    digitalHumanProductionProcess: 數字人製作流程
    onlyThreeSteps: 僅需3步
    easyForBeginners: 小白也能輕鬆做視頻
    startMaking: 開始製作
  UseCasesLayout:
    shortVideoAcquisition: 短視頻獲客
    noProfessionalEquipment: 無需專業設備，一部手機即可完成高質量短視頻製作。AI數字人幫您輕鬆打造爆款內容，快速獲取精準流量。
    personalIPCreation: 個人IP打造
    breakTimeAndEffortLimits: 突破時間和精力限制，讓AI數字人持續輸出優質內容。智慧化內容創作，幫您快速建立個人品牌。
    brandPromotion: 品牌宣傳
    customDigitalSpokesperson: 為企業定製專屬數字代言人，應用於產品展示、品牌推廣、企業培訓等場景。統一的品牌形象傳播。
    courseProduction: 課程製作
    quicklyTransformCourseContent: 快速將課程內容轉化為生動的視頻講解。AI數字人講師全天候在線，幫您打造專業的在線課程。
    digitalHumanApplicationScenarios: 數字人應用場景
    realHumanAppearanceScenes: 真人出鏡場景，AI數字人全能替代
  CommentWallLayout:
    everyoneIsUsingIt: 他們都在用
    makeCreationEasier: 讓創作更簡單，讓變現更輕鬆
digitHuman:
  choose: 選擇最適合您的數字人套餐
  superior: 超高品質，超高性價比
  exhibit: 展示
  videoCaseStudy: 視頻案例
  product:
    digitalBusinessCard: 互動數位人
    digitaLive: 直播數字人
    digitalVoice: 聲音定制
    guiJi:
      title: 矽基數位人
      desc: 矽基智慧作為<span class='text-primary'>騰訊系人工智慧企業，國家級專精特新小巨人</span>，獲得<span class='text-primary'>招銀國際、國新央企、海松資本、紅衫資本等投資</span>，擁有<span class='text-primary'>近百項授權發明專利</span>，研發人員近千人，矽基數位人是通過先進的數字李生技術構建，一比一真實還原真人形態、動作、表情、聲音的數位人形象，<span class='text-primary'>打造由強算力驅動、量子力學驅動、具備售體進步、開源開放、沒有情緒、無限供給、永不離職、不參與分配的矽基生命。</span>把人類從繁重的重複性腦力勞動中解放出來，讓人回歸人的價值。
    xiaoBing:
      newTitle: 短視頻數字人
      title: 小冰數位人
      desc: 小冰數位人脫胎於<span class='text-primary'>微軟公司</span>，其核心技術和團隊源於<span class='text-primary'>微軟亞洲互聯網工程院</span>。為加快小冰產品線的本土創新步伐，2020年7月13日 小冰從微軟分拆為獨立公司運營。獨立後，由<span class='text-primary'>微軟前全球執行副總裁沈向洋博士為小冰公司董事長</span>，新公司使用及繼續研發完整的小冰技術。
      desc2: 2021 年小冰完成 A 輪融資，成長為 AI 行業最快的獨角獸企業，並於 2022年11月7日 完成新一輪 10 億元戰略融資。在 AI 領域，小冰擁有<span class='text-primary'>計算機視覺、語音、NLP.人工智能創作</span>等全棧核心 Al 能力，是<span class='text-primary'>業內佈局數字員工最早的企業之一</span>，目前<span class='text-primary'>已落地、可體驗、產生業務價值</span>的案例數也穩居第一。
      basicName: 數位人體驗版
      liteName: 數位人lite版
      prodName: 數位人pro版
    tengXun:
      title: 智影數位人
      desc: 騰訊智影數位人是由<span class='text-primary'>騰訊公司全資開發的數位人產品</span>，於 2018年11月 發布全球首個 AI 合成主播。經過 6 年時間已經從最初的簡版送代至如今的超寫實3D數字。並在<span class='text-primary'>廣電、氣象、證券、金融等領域</span>都有廣泛應用<span class='text-primary'>合作客戶包含國家電網、工商銀行、新華社、光大銀行、平安金融、青島地鐵等客戶。</span>
  containsContent: 包含內容
  portraitCustomize: 形象訂製
  proPortraitCustomize: pro形象訂製
  soundClone: 聲音克隆
  digitClone: 形象克隆
  unlimited: 無限制
  basicDesc1: 生成專屬克隆數位人
  basicDesc2: 增送{msg}分鐘短視頻製作時長
  proDesc: 生成專屬聲音克隆
  userInfo: 用戶資訊
  pleaseRead: 請閱讀下方提示語，並打勾
  promotionalPrice: 國春促銷價
  discountedPrice: 折扣價
  customizedRequirements: 定製化需求（其他特殊需求）
  websitePrivateDeployment: 網站私有化部署
  customizedPlans: 定製化計劃
  digitApi: API呼叫
  digitApiDoc: API文檔
  enterpriseContact: 企業聯繫
  editionFeaturesplus: 商務版所有功能外加
chatAICategory:
  beyond: 已經超越90%的人類
  optimize: 你儘管提出問題，它總能給出最優答案
  useGuide: 使用引導
  ask: 提問
  pleaseInput: (您可以选择不输入任何内容，直接进入场景。)
  enterScene: 進入場景
  selectModel: 選擇模型：
  currentModel: 當前模型：
imageAI:
  currentPrompt: 目前提示詞
  historyRecord: 歷史紀錄
  apply: 應用
  describeCost: 圖片生成提示詞 消耗{msg}積分
  uploadImage: 上傳圖片
  senior:
    seedDesc: 隨機噪音種子
    chaosDesc: 低的 chaos 值具有更可靠、可重複的結果。</br>高 chaos 值將產生更多不尋常和意想不到的結果和組合
    stylizeDesc: 低 stylize 值生成的圖像與提示非常匹配，但藝術性較差。</br>高 stylize 值創建的圖像非常藝術，但與提示的聯繫較少。
    styleDesc: cute 創造迷人可愛的角色、道具和場景。<br />expressive 更有精緻的插畫感。<br />original 使用原始 Niji 模型版本 5 <br />scenic 在奇幻環境的背景下製作美麗的背景和電影角色時刻。
    tileDesc: 生成的圖像可用作重複圖塊，為織物、壁紙和紋理創建無縫圖案。
    inputSeed: 請輸入隨機數字
    sampleDesc: 擴散過程中使用哪種取樣器。<br />如果省略此值，我們將自動為您選擇合適的採樣器。
    cfgDesc: 數值越高，圖像越接近提示詞
advancedSetting: 高級設置
positivePrompt: 正向提示詞
negativePrompt: 負向提示詞
describe: 圖片生成提示詞
allowed: 允許商用
tutorial: 使用教程
lawTips: 請遵守法律法規，禁止上傳色情、暴力、反動圖片，違反規則會進行封號處理
warning: 警告
modelSelection: 模型選擇
apiService: API 服務
backSquare: 回到廣場
creditRecharge: 積分充值
styleSelect: 風格選擇
imageDescription: 畫面描述
promptGenerator: 提示詞生成器
promptPlaceholder: 畫面描述以短句、短語為佳，支援中英文等語言輸入
generateNum: 生成數量
generateSpeed: 生成速度
calculateStep: 計算步數
widthHeight: 寬高尺寸
imageToImage: 以圖生圖
imageWeight: 原圖權重
relaxQueue: 慢速 排隊{count}人
fastQueue: 快速 排隊{count}人
fastMode: 快速 模式
predictTime: 預計{time}分鐘內
creationCost: 創作 消耗{totalPrice}積分
doubleScale: 2 倍放大
hdDownload: 高清下載
upscaleCost: 放大消耗{credits}積分
variationCost: 變換消耗{credits}積分
redrawCost: 重繪消耗{credits}積分
zoomCost: 延展消耗{credits}積分
confirmPublish: 確認發布嗎？
variation: 變換
midButtonTypeDesc:
  u1: 左上放大
  u2: 右上放大
  u3: 左下放大
  u4: 右下放大
  r: 重繪這張
  v1: 左上變換
  v2: 右上變換
  v3: 左下變換
  v4: 右下變換
  draw: 原始圖片
  high_variation: 強變換
  low_variation: 弱變換
  out_paint_2: 2 倍延展
  out_paint_1_5: 1.5 倍延展
  pan_left: 左延展
  pan_right: 右延展
  pan_up: 上延展
  pan_down: 下延展
publishSquare: 發布廣場
deleteImage: 刪除圖片
picViolateRule: 圖片涉嫌違規
imageWeightRange:
  low: 低
  default: 預設
  mid: 中
  high: 高
inputPrompt: 請輸入提示詞
inputPromptRule: 提示詞不能少於5個字元
publishSuccess: 發布成功
painting: 繪畫中...
waiting: 正在排隊中...
waitingBack: 任務已提交...
generating: 正在生成中...
publishDesc: 設置為付費，其他用戶需要支付積分才能查看，支付的積分 80% 將會返還給您
publishDescribe: 描述
publishIsFree: 是否免費
publishFree: 免費
publishPaid: 付費
publishTag: 標籤
publishSuccessDetail: 發布成功，請前往廣場查看我的
workbench:
  packageDownload: 客戶端下載
  recentWorks: 最近作品
  video: 影片
  quickCreate: 快速建立
  moreWorks: 更多作品
  digitalAssets: 我的數位資產
  myModel: 我的模特兒
  searchModel: 搜尋
  customize: 自訂
  makeVideo: 前往製作
  downloadClip: 去剪輯
  draft:
    tips: 作品刪除後無法恢復，請確認是否刪除？
  inProduction: 編輯中
  toEdit: 前往編輯
  copyEdit: 複製並編輯
  free: 免費
  public: 公開
  available: 可使用
  all: 全部
  sex: 性別
  male: 男
  female: 女
  maleVoice: 男聲
  femaleVoice: 女聲
  background: 背景顏色
  transparent: 綠幕/透明
  fixed: 固定背景
  racial: 種族
  asia: 亞洲
  america: 歐美
createWork:
  selectModel: 選擇模特兒
  selectBg: 選擇背景
  selectVideo: 選擇影片
  selectImg: 選擇圖片
  selectMaterial: 選擇素材
  selectDraft: 選擇草稿
  freeModel: 可使用模特兒
  customizeModel: 客製化模特兒
  unNamed: 未命名
  pleaseSelectModel: 請選擇模特兒
  createWorkSuccess: 建立影片成功
  inputTextOrAudio: 請輸入解說內容或上傳錄音
  draftEmpty: 草稿內容為空，複製失敗
  switchTips: 切換橫向或縱向螢幕方向將不保留已編輯的資料，是否繼續？
  horizontal: 橫向
  vertical: 縱向
  availTimes: 可使用時長
  composite: 合成影片
  extract: 提取
  translate: 翻譯
  painting: 繪畫
  switchOrientation: 切換比例
  default: 預設
  imageAI: AI 繪畫
  imgDirection: 圖片方向
  goImageAI: 前往繪畫
  durationPackage: 時長套餐
  modelUnavailable: 該模特兒暫不可用
  creditsPerMin: 積分 / 分鐘
  extractDrawer:
    copyLink: 請貼上影片連結
    pleaseGetVideoDetail: 請先取得影片詳情
    pleaseGetText: 請先提取文案
    textCopied: 文案已複製到剪貼簿
    textExtractTitle: 輕鬆 5 步，影片文案秒提取
    textExtractSubTitle: 工作效率直線提升
    pendingGetVideoInfo: 正在提取,請稍等
    1step: 第 1 步
    2step: 第 2 步
    3step: 第 3 步
    4step: 第 4 步
    5step: 第 5 步
    1desc: 貼上連結
    2desc: 取得影片詳情
    3desc: 提取文案
    4desc: 儲存音頻/影片
    5desc: 複製/應用文案
    textExtract: 文案提取
    extract: 請輸入抖音分享鏈接，一鍵提取文案
    inputLinkPlaceholder: 請輸入抖音、小紅書、快手、B 站（口令或連結）
    getVideoDetail: 取得影片詳情
    extractText: 提取文案
    saveAudio: 儲存音頻
    saveVideo: 儲存影片
    copyAudioAddress: 複製音頻位址
    copyVideoAddress: 複製影片位址
    copyText: 複製文案
    applyText: 應用文案
  footer:
    all: 全部
    male: 男
    female: 女
    selectAudioModel: 請選擇音色
    selectAudio: 請選擇音頻
    selectAudioStatus: 目前聲音未完成,請更換聲音
    deviceNotSupport: 目前裝置暫不支援音頻試聽
    inputTextRule: 文字內容不能少於 5 個字元
    inputTranslateText: 請在文字合成中輸入翻譯的文字
    inputOptimizeText: 請在文字合成中輸入待優化的文字
    textComposition: 文字合成
    recordComposition: 錄音合成
    inputTextPlaceholder: 輸入或貼上文字
    addPause: 在光标处调整文字之间的停顿时长
    predictCost: 預計消耗
    audioModel: 音色
    audioSpeed: 語速{times}倍
    audioPitch: 音調
    testAudio: 試聽
    language: 語言
    selectLanguage: 請選擇語言
    systemVoice: 系統聲音
    myVoice: 我的聲音
    style: 風格
    sex: 性別
    role: 角色
    insertPause: 插入停頓
    predictTime: 預計時長
    seconds: 秒
    audioUploadDisable: 積分小於 10，無法上傳
    startConvert: 開始轉換
drawer:
  model: 模特兒
  background: 背景
  video: 影片
  img: 圖片
  material: 素材
  draft: 草稿
modelDrawer:
  noneCustomizeModel: 還沒有客製化模特兒
translateDrawer:
  translating: 翻譯中...
  translate: 翻譯
  originLanguage: 源語言
  targetLanguage: 目標語言
  textUpdated: 文案已更新
optimizeDrawer:
  exception: 出現異常
  optimizeGPT: 🔥 使用 ChatAI 進行文案優化
  inputOrCopy: 輸入或貼上文字
  shorter: 更短
  longer: 更長
  professional: 更專業
  engaging: 更吸引人
  fix: 修正文案
  reWritten: 重寫
  stopGenerate: 停止輸出
digitTwins:
  selectDigitalMan: 請選擇模特兒
  inputModelRule: 請輸入模特兒名稱
  inputModelValue: 請上傳模特兒影片
  inputAuthImage: 請上傳授權圖片
  authDoc: 授權書模板
  modelName: 模特兒名稱
  trainVideo: 訓練影片
  demoVideo: 示範影片
  precautions: 注意事項
  uploadPrompt: 上傳提示
  videTime: 時長應在{videoTimeMin}分鐘至{videoTimeMax}分鐘之間
  tips:
    one: 上傳本地影片，人工審核精選過濾後為您訓練輸出更精美的數位人形形象，並贈送 500 分鐘影片製作時長（有效期 180 天）
    two: 訓練影片僅支持時長大於4分鐘,小於6分鐘,影片尺寸必須為 1920*1080(16:9)、1080*1920(9:16)、3840*2160(16:9)、2160*3840(9:16),
    size: 暫不支援其他尺寸如
    three: 影片要求為正面、五官清晰無遮擋，不要歪頭、側頭
    four: 請保持影片中人臉比例適中，涵蓋啟膀區域，頭部比例不要過大或者過小
    five: 上傳影片將用於數位人形定制，請慎重選擇影片上傳
    errorExample: 不佳示範
  errorPersonalPhoto: 非人物照片
  maskFace: 五官遮擋
  vague: 模糊不清
  improperlyDressed: 衣著不當
  tooBigFace: 面部過大
  multipleFaces: 多張人臉
  reSubmit: 請在當前頁面重新提交
  cancelUpload: 取消上傳
  pleaseUpload: 請上傳正確比例的授權書照片
  videoFormat: 視頻格式必須是以下幾種
  clearPhoto: 請上傳清晰的授權書照片
upload:
  tipsType: 支援類型
  fileSize: 檔案大小不能超過 {maxSize}MB！
  filePixError: 目前資源解析不正確，請上傳正確的解析資源
  video:
    tips1: 將影片拖動到此區域或點擊
    tips2: 支援影片類型：mp4
    rule1: 影片僅支援 mp4 格式！
    rule2: 影片大小不能超過 {videoMaxSize}MB！
    rule3: 影片大小不能超過 {maxSize}G!
  img:
    tips1: 將圖片拖動到此區域或點擊
    tips2: 支援圖片類型：jpg/png/gif
    tips3: 支援圖片類型：jpg/png
    rule1: 圖片僅支援 jpg/png/gif 格式！
    rule2: 圖片大小不能超過 {imgMaxSize}MB！
    rule3: 圖片僅支援 jpg/png 格式！
  audio:
    tips1: 將檔案拖動到此區域或點擊
    tips2: 支援檔案類型：wav/m4a/flac
    rule1: 檔案僅支援 wav/m4a/flac 格式！
    rule2: 檔案大小不能超過 {maxSize}MB！
  image:
    rule2: 圖片大小不能超過 {imgMaxSize}MB！
  application:
    rule2: pdf文件大小不能超過 {maxSize}MB！
  model:
    rule2: 3d文件大小不能超過 {maxSize}MB！
  uploadAvatar: 上傳頭像
  uploadSuccess: 上傳成功
  uploadFailed: 上傳失敗
  maxUploadNum: 最大支援 2 張圖片上傳
  uploadSameImage: 請不要上傳相同圖片
workManage:
  time: 時長
  finish: 已完成
  unFinish: 未完成
  myWork: 我的作品
  myDraft: 我的草稿
  searchWork: 搜尋作品
  compositing: 合成中
  workStatus:
    0: 合成中
    1: 成功
    2: 失敗
  empty: 這裡什麼都沒有喔
  createNow: 立即建立
  workNameReg: 名稱必須為 2-20 個字元之間
account:
  remainTime: 影片時長餘額
  spendTimes: 影片時長已使用
  userID: 用戶 ID
  orderId: 訂單 ID
  workName: 作品名稱
  orientation: 方向
  workTime: 時長
  modelCount: 模特數量
  remark: 備註
  creationDate: 時間
aiTools:
  aiTools: AI 工具
  navigator: 導航
  cleanUp: 整理
  cleanUpHot: 整理 🔥
  cutout: AI 智能剪裁
  cutoutHot: AI 智能剪裁 🔥
  cutoutFace: AI 剪裁頭像
  losslessHD: 無損高清
  photoClearer: 照片變清晰
  photoColoring: 老照片上色
  cartoonAvatar: 照片變卡通
  aiPoster: AI 海報
  textExtract: 文案提取
  textToAudio: 文字轉音頻
  textToAudioSubTitle: 文字語音自由切換、創作無界限
  videoTranslation: 影片翻譯
  avoidBackgroundMusic: 儘量避免使用有背景音樂和噪音的視頻，因為它們會降低輸出視頻的質量。
  translateWork: 翻譯作品
  originalLanguage: 原語言
  targetLanguage: 目標語言
  selectInitialLanguage: 請選擇視頻的初始語言
  translateTo: 翻譯為
  noWorks: 暫無作品
  selectTargetLanguage: 請選擇視頻輸出的目標語言
  videoTranslationinfo: 一鍵將您的講解影片翻譯成其他語言講解，並且還能保留您說話的音色和風格
  videoInitialLanguageNotEmpty: 視頻的初始語言不能为空
  videoTargetLanguageNotEmpty: 視頻的目標語言不能为空
  videoNotEmpty: 視頻不能为空
  audioToText: 音頻轉文本
  audioToTextDesc: 切換無拘束，省心省時省力
  imageDetail: 圖片詳情
  slogan1: 探索未知，開啟無限遊戲
  creativitySlogan: 讓創意照進現實
  allTools: 所有工具
  slogan2: 海量工具軟體，{msg}一站體驗
  midjourneyPaint: Midjourney 繪畫
  bussinessCard: 互動數位人
  bussinessCardDesc: 可即時互動的數位人
  supportMidCommand: 支援 Midjourney 官方全部指令
  sdPaint: Stable Diffusion 繪畫
  sdDesc: 簡易有趣，秒變專業畫手
  chatAIDesc: 你儘管提問，它總能應答如流
  human: 數位人
  humanDesc: 立即客製化
  digitHuman: 數位人影片製作
  digitHumanDesc: 無需現身，3 分鐘製作專業短片
  digitLiveHuman: 數位人直播
  digitLiveHumanDesc: 無須出鏡，23小時日不落直播
  cleanUpDesc: 一鍵抹去雜物，真實無痕
  cutoutDesc: 輕鬆剪裁，細膩自然
  cutoutFaceDesc: 一鍵剪裁頭像
  losslessHDDesc: 一鍵處理劣質圖片生成精美圖片
  photoClearerDesc: 模糊圖變清晰，無損放大
  photoColoringDesc: 黑白老照片上色
  cartoonAvatarDesc: 一鍵切換「漫畫臉」
  textExtractDesc: 一鍵提取視頻文案
  textToAudioDesc: 文本、音頻相互轉換
  comingSoon: 即將上線...
  expect: 敬請期待
  expectDesc: 更多功能，敬請期待
  extractRecord: 提取記錄
  converting: 轉換中...
  convertFail: 轉換失敗，請重試
  textExtractSuccess: 文案提取完成
  convertRecord: 轉換記錄
  audioToTextTips1: 支援 100MB 以內且時長不超過 2 小時的音頻文件識別。
  audioToTextTips2: 1:支援 30+ 方言，50+ 外語
  audioToTextTips3: 2:最快 10 秒內完成 30 分鐘語音
  audioToTextResult: 音頻轉文本結果
  return: 返回
  startProcessing: 開始處理
  tutorial: 教程
  Re-upload: 重新上傳
partnerPlan:
  month: 個月
  silver: 白銀
  gold: 黃金
  diamond: 鑽石
  king: 王者
  exclusiveIndependentWebsite: 專屬獨立網站
  partnerPlan: 合作夥伴計劃
  study: 實訓基地
  partnerPlanDesc: 成為頂級推廣官
  enthusiast: 您是數字人探索者
  humanExplorer: 您是数字人探索者
  understandMore: 需要了解更加詳細的合作方案
  partnerDesc1: <p>親愛的夥伴</p>
    <p>感謝你一直以來的支持</p>
    <p>為了建立更長久的連接和分享</p>
    <div>
    現誠邀你參與我們的
    <div class="relative inline-block">
    <span class="absolute bottom-0 block bg-[#B8DCC9] w-full h-[50%]"></span>
    <span class="relative font-bold">合作夥伴計劃</span>
    </div>
    </div>
    <p>成為頂級推廣官</p>
  partnerDesc2: <p>我們將為你打造一個</p>
    <p>完全專屬於你的 AI 工具平臺</p>
    <p>100% 複刻平臺的功能和頁面</p>
  partnerDesc3: <p>現在，只需輕鬆三步 就能擁有</p>
  partnerDesc4: <p>01 動動手指，選擇您的推廣套餐</p>
    <p>02 邀請更多好友體驗您的專屬網站</p>
    <p>03 立刻享受豐厚返利</p>
  plan: 元
  exclusive: <p class="text-[1rem] mt-2">將享有您專屬網站</p>
    <p class="text-[1rem] mt-2 font-bold">充值收益的</p>
  xiaoBingDigitReward: 小冰數位人返傭
  customizedWebSite: 客製化專屬網站
  otherDigitReward: 矽基/智影數位人返傭
  otherProductReward: 其他產品返傭
  giftDesc: 送{number}個小冰試用版數位人
  giftXbLiteDesc: 送{number}個小冰高级定制版數位人
  giftGjLiteDesc: 送{number}個矽基高級訂製版數位人
  invitation_message: 親愛的夥伴，誠摯邀請你加入我們的合夥人計劃，與我們共同成長，探索AI時代的無限可能
  top_promoter: 成為頂級推廣官
  easy_three_steps: 只需輕鬆三步 就能擁有
  ai_platform_replica: 我們將為你打造一個 完全專屬於你的 AI 工具平台100% 復刻平台的功能和頁面
  move_fingers: 動動手指
  choose_package: 選擇您的推廣套餐
  invite_friends: 邀請更多好友
  experience_website: 體驗您的專屬網站
  enjoy_now: 立刻享受
  generous_rewards: 豐厚返利
  core_advantages: 五大核心優勢
  easy_ai_entrepreneurship: 輕鬆實現AI時代輕創業
  authoritative_certification: 工信標準
  ai_operator_project: 《人工智慧營運師》由工信部教育與考試中心制定標準，分階段系統性構建層層遞進的學習體系，課程內容緊跟AI技術應用趨勢，持續更新
  policy_support: 政策支持
  policy_guidance: 符合國家關於深化大數據、人工智能等研發應用的政策導向，響應“人工智能+”行動
  continuous_update: 持續更新
  updated_training: 我們的培訓根據行業發展和技術進步不斷更新課程內容，確保學員能夠掌握最新的人工智能技術和應用
  ai_tools: AI工具
  cutting_edge_services: 我們為您準備了最前沿的AI數字人服務和AI工具集，助力您的市場推廣和品牌宣傳
  solutions: 解決方案
  partnerships: 我們與微軟小冰、騰訊智影、矽基智能等科技公司緊密合作，不僅提供培訓服務，還能夠提供一站式AI解決方案服務
customImage:
  shareImage: 分享圖片
  posterDesign: 海報設計
  editImage: 編輯圖片
  historyImages: 歷史圖片
  aiTools: AI工具
  shareTips: 分享圖展示資訊
  customTitle: 自訂標題（可選）
  saveImage: 儲存圖片
  imageLink: 圖片連結
aiPoster:
  background: 背景
  element: 元素
  cartoon: 卡通
  layer: 圖層
cleanUp:
  cleanUp: 雜物消除
  cleanUpDesc: 想修哪裡修哪裡、一秒抹去瑕疵、毫無痕跡
  tryOtherDemo: 或者嘗試以下範例
  imageHistory: 圖片處理歷史
  demo: 舉個例子
  frequentQA: 經常問的問題
  tryThisExample: 嘗試這個例子
  Q1: 什麼是雜物消除？
  A1: 雜物消除是一種特殊技術，用於從照片中刪除不需要的物件。想修哪裡修哪裡、一秒抹去瑕疵、毫無痕跡
  Q2: 為什麼雜物消除比其他修復工具更好？
  A2: 雜物消除是一款基於人工智能的高級編輯工具，比其他克隆圖章工具要好得多。像Adobe Photoshop Fix這樣的克隆工具需要背景參考，而我們的AI只需點擊幾下即可真正猜測出不需要的文字、不需要的人、不需要的物件。
  Q3: 雜物消除可以處理什麼圖像解析度？
  A3: 您可以在雜物消除中匯入任何尺寸的圖像。
  Q4: 雜物消除費用是多少？
  A4: 價格可在頂部選單的計費規則中查看。
  chooseUs: 為什麼選擇我們
  fiveGood: 五大核心優勢,提供可定制化選擇
  choose: 超低門檻
  onTime: 實時監督
  fit: 量身訂製
  aiBox: AI工具
  oneChoose: 一站式服務
  QQ1: 加入我們成為合夥人的門檻極低，僅需999元起
  QQ2: 我們的在線系統賦予合夥人直接管理權限，您可親自審核客戶的克隆視頻和聲音素材，確保服務交付的高品質
  QQ3: 我們為您量身打造可定制化的網站，您可以根據需要自由調整價格體系、配色方案、聯繫信息等
  QQ4: 除了提供數字人服務，我們還為您準備了最前沿的AI工具集，助力您的市場推廣和品牌宣傳
  QQ5: 我們提供全方位的數字人代理服務，包括小冰、矽基、智影等知名產品，助您一站式解決方案
  removePassersby: 移除路人
  removePassersbyDesc: 快速移除照片中突然闖入的行人或遊客
  removeClutterFromPhoto: 清除照片中的雜物
  removeClutterFromPhotoDesc: 清除照片中的雜物，例如垃圾桶、建築物或電源線
  removeText: 刪除圖片中的文字、標誌等
  removeTextDesc: 告別複雜繁瑣操作。智能AI能夠準確地識別不需要的物件
  startClean: 開始消除
  smearClean: 涂抹
  rectClean: 框選
  selectClean: 選區
  brushSize: 筆刷大小
  smear: 涂抹
  move: 移動
cutout:
  smartCutout: AI 智慧剪裁
  smartCutoutDesc: 一鍵上傳圖片，即可快速剪裁。智慧AI經過數百萬張圖像的訓練，能夠精確識別需要保留的主體並移除多餘的背景，讓剪裁更加簡單、便捷。
  product: 商品
  productDesc: 去除商品背景
  portrait: 人像
  portraitDesc: 去除人像背景
  car: 汽車
  carDesc: 去除汽車背景
  animal: 動物
  animalDesc: 去除動物背景
  graphics: 圖形
  graphicsDesc: 去除圖形背景
cutoutFace:
  cutoutFace: 頭像剪裁
  cutoutFaceDesc: 身份證、簽證、卡通身體、放大頭照，POD 最喜愛
imgBeautify:
  imgBeautify: 一鍵美化
  imgBeautifyDesc: 一鍵處理劣質圖片生成精美圖片
  autoExposure: 自動曝光
imgFaceBecomesClear:
  imgFaceBecomesClear: 模糊人臉變清晰，舊照片一鍵增強
  imgFaceBecomesClearDesc: 沒對好焦、低解析度、模糊、像素化、舊照片、損壞的，這些低品質照片都能智能修復為成像銳利且焦點清晰的高品質照片。
  demo1: 人臉變清晰
  demo1Desc: 使用人工智能深度學習技術，識別模糊的人臉，將面部細節進行高清修復，哪怕是因光線太暗、曝光不足拍攝的夜間照片，也能輕鬆一鍵點亮你的美。
  demo2: 專業修復舊照片
  demo2Desc: 將原本模糊的照片，通過 AI 技術補充細節，還原出高清晰度的照片，使舊照片焕發出新的活力。無論是發黃的舊照片，舊設備拍攝的陳年舊照，都能一鍵變清晰，幫你找回兒時的記憶。
  demo3: 不只人像變清晰
  demo3Desc: 失焦、低解析度、像素化、損壞的舊照片，都能智能修復，自動處理圖片雜點和鋸齒部分，輕鬆還原真實高清的照片。除了人像智能修復，我們還支持一鍵無損放大圖片尺寸，高清放大局部細節！
  demo4: 智能修復模糊圖片
  demo4Desc: 使用人工智能 AI 學習技術，一鍵精準智能識別，輕鬆修復模糊產品圖、圖標、動漫素材等，無損還原真實高清的圖片。
imgPhotoColoring:
  imgPhotoColoring: 黑白照片自動轉換成彩色照片
  imgPhotoColoringDesc: 黑白舊照片上色，讓照片重新焕發光彩，展示出不一樣的魅力。讓您和家人的記憶更加栩栩如生，仿佛昨日重現。體會到不一樣的心情與感動。
  demo1: 賦予舊照片新生
  demo1Desc: 為家裡的黑白舊照片重新上色，用鮮豔的色彩將過去帶回你的眼前，使回憶變得鮮活。
  demo2: 還原真實自然的顏色
  demo2Desc: 與普通著色器不同，我們基於智能 AI 著色算法，經過大量數據的深度學習，輕鬆為黑白照片還原更真實自然的顏色。
imgCartoonAvatar:
  imgCartoonAvatar: 迪士尼特效卡通頭像
  imgCartoonAvatarDesc: 這款一鍵切換「漫畫臉」的 AI 人工智能，上傳一張頭像就可以秒變迪士尼在逃公主。
  demo1: 案例 1
  demo2: 案例 2
  demo3: 案例 3
  demo4: 案例 4
messageTips:
  fileUploaded: 檔案已上傳
  updateSuccess: 更新成功
  deleteSuccess: 刪除成功
  publishSuccess: 發布成功
  putTopSuccess: 置頂成功
  copySuccess: 複製成功
  createSuccess: 新增成功
  convertSuccess: 轉換完成
  convertJobSubmit: 轉換任務已提交
  imgProcessSuccess: 圖片處理完成
  smearTips: 請塗抹待修復的區域
  rectTips: 請框選待修復的區域
  clearSuccess: 清空成功
  chatAIHistoryMode: 當前模式下，發送訊息會攜帶之前的聊天記錄
  chatAISingleMode: 當前模式下，發送訊息不會攜帶之前的聊天記錄
  submitSuccess: 提交成功
  updateWithdrawPWDSuccess: 修改提現密碼成功，請重新登入！
  setWithdrawInfoSuccess: 設置提現資訊成功，請重新登入！
  selectPrompt: 請選擇提示詞
  promptRule: 提示詞不能超過 1000 個字元
  loginFirst: 請先登入
  validateCodeFirst: 請先獲取驗證碼！
  compositeWorkName: 名稱必須為 2-20 個字元之間
  withdrawSuccess: 提現成功
  configSuccess: 配置成功
audioClone:
  title: 在线克隆
  voliceUpload: 本地上傳
  subTitle: 個性化人聲定制服務
  browserNoSupport: 瀏覽器不兼容
  browserNoSupportInfoA: 抱歉，您的瀏覽器無法使用麥克風
  browserNoSupportInfoD: 目前僅支援電腦端
  browserNoSupportInfoB: 請打開瀏覽器掃一掃或者點擊複製網址
  browserNoSupportInfoC: 建議使用 Chrome 谷歌、Firefox 火狐、Safari 等能夠支持錄音的瀏覽器進行錄製
  copyUrl: 複製網址
  copySuccess: 地址複製成功
  copyError: 該瀏覽器不支持自動複製
  langsInfo: 您的聲音克隆可支持的國家語言
  langs: 英文 日文 印地文 德文 法文 西班牙文 韓文 葡萄牙文 意大利文 印尼文 荷蘭文 土耳其文 菲律賓文 波蘭文 瑞典文 保加利亞文 阿拉伯文 捷克文 羅馬尼亞文 芬蘭文 馬來文 希臘文 克羅地亞文 斯洛伐克文 丹麥文 泰米爾文 烏克蘭文
  iknow: 我知道了
  stepOneTitle: 個性化聲音克隆流程
  stepOneInfoAtitle: 環境檢測
  stepOneInfoAinfo: 請同意授權網站使用麥克風，並確保環境安靜，比如少回聲、少雜音的錄音棚、臥室。
  stepOneInfoBtitle: 錄製 5 句高質量音頻
  stepOneInfoBinfo1: 1、建議使用手機自帶麥克風10cm左右距離，盡量保證錄製的每一條都清晰無比。
  stepOneInfoBinfo2: 2、想象自己是配音演員，在錄製時需要保證發音清晰，聲音飽滿富有情感，減少嚥口水等噪音。
  stepOneInfoCtitle: 等待聲音訓練
  stepOneInfoCinfo1: 請您耐心等待，約 1 分鐘後，即可克隆你的聲音。
  stepOneInfoDtitle: 試聽並使用
  stepOneInfoDinfo: 在個人中心 - 個性化人聲定制查看和試聽聲音。
  iread: 我已閱讀並同意
  iservece: 《個性化人聲定制服務協議》
  iokkonw: 點擊確認閱讀並同意
  ilangs: 《多國語言支持》
  irules: 《積分計費規則》
  readyClone: 開始克隆
  dagreeTitle: 個性化人聲定制服務協議
  not: 注意
  notice: 注意：該次聲音克隆將會消耗您
  credits: 積分！
  notiread: 請先確認閱讀並同意《個性化人聲定制服務協議》
  iok: 確認
  stepTwoTitle: 聲音命名
  stepTwoSound: 聲音
  stepTwoIDempty: 聲音 ID 不能為空
  stepempty: 声音名稱不能为空
  stepTwoInputInfo: 請輸入 15 位以內的中文、數字、英文字符
  stepTwoInput: 請輸入15位內的中文、數字、英文字符
  stepTwoInputInfo2: 可以使用中文、數字、英文字符，最多 15 字
  uploadSound: 上傳聲音
  uploadLimit: 4:最多支持提交{maxAuido}段音頻,每個音頻大小不超過{maxAuidoSize}MB
  stepTwoSex: 選擇性別
  stepTwoSex1: 男
  stepTwoSex2: 女
  stepTwoSence: 選擇使用場景
  stepTwoSence1: 科技知識
  stepTwoSence2: 新聞播報
  stepTwoSence3: 童話故事
  stepTwoSence4: 知識分享
  stepTwoSence5: 產品介紹
  stepTwoOpenMic: 如何開啓麥克風權限
  stepTwoMic: 開始錄製
  stepTwoMic1: 請允許獲取麥克風權限
  stepTwoMic2: 沒有麥克風，無法進行聲音錄製哦 ~
  stepTwoMic3: 設置方法
  stepTwoMic4: Safari用戶
  stepTwoMic5: 刷新頁面 > 點擊「開始錄製」>
  stepTwoMic6: 允許
  stepTwoMic7: Chrome 用戶
  stepTwoMic8: 點擊地址欄 / 菜單欄的【更多】
  stepTwoMic9: 或
  stepTwoMic10: 點擊【網站信息】
  stepTwoMic11: 點擊「網站設置」>
  stepTwoMic12: 開啓麥克風權限
  stepThreeDialog: 貼心提示
  stepThreeDialog1: 請務必使用【英語】錄音，語速適中
  stepThreeDialog2: 與耳機 / 手機保存 1-2 個拳頭距離以內
  stepThreeDialog3: 使用耳麥
  stepThreeDialog4: 效果更好哦
  stepThreeDialog5: 務必保持周圍環境安靜
  stepThreeDialog6: 點完錄音按鈕後，等 2 秒鐘再開始錄音，效果會更好哦
  strpThreeBack: 退出錄製
  strpThree: 聲音錄製
  strpThree1: 錄製進度：
  strpThree2: 等待錄製
  strpThree3: 正在錄製
  strpThree4: 錄製完成
  strpThree5: 試聽
  strpThree6: 開始錄製
  newstrpThree6: 點擊錄製
  strpThree7: 點擊停止
  strpThree8: 重新錄製
  strpThree9: 下一個
  strpThree10: 請完成所有錄製後確認
  strpThree11: 點擊確認提交所有錄音
  strpThree12: 您的錄音正在上傳，請耐心等待大約 1-5 分鐘，爲確保上傳聲音完整性，上傳期間請勿關閉或者刷新當前頁面！謝謝！
  strpThree13: 恭喜您！您的克隆聲音已完成，請前往【個人中心 - 我的定制】欄目查閱！
  strpThree14: 錄音上傳失敗，請關閉後再次點擊【確認】按鈕嘗試，或者聯繫系統管理員，非常抱歉給您帶來不便！謝謝！
digitalHumanPage:
  nobuy: 您還未購買數位人，請購買後，再進行操作！
  downloading: 數位人授權書下載中...
  chosehuman: 請選擇數位人
  imgtype: 摳像類型
  imgtype1: 保留拍攝背景
  imgtype2: 僅摳人像
  imgtype3: 人工扣像
  videohuman: 訓練視頻
  egvideo: 示例視頻
  faceuse: 形象授權
  pclick: 請點擊
  downUse: 《下載授權書模板》
  upface: 簽名拍照後上傳
Withdrawal:
  withdrawal: 提現
  withdrawalInformation: 提現資訊
  name: 姓名
  callyou: 請填寫您的訴求，提交後我們將儘快聯繫您
  alipayAccount: 支付寶帳號
  withdrawalAmount: 提現金額
  withdrawalPassword: 提現密碼
future:
  one:
    intelligentCabin: 智能數位人全息艙
    interactiveDigitalHuman: 智能互動數字人
    intelligent: 智能
    cabin: 全息艙
    professional: 數字人&大語言模型&互動一體機大屏
    listening: 我在聽，請說話
    clickBelow: 點擊下方，停止錄音
    tapMe: 拍拍我
    conversation: 開啟對話
    consult: 立即諮詢
  two:
    core: 核心技術全維度攻克研發難題
    hardware: 智能硬件
    standard_solution: 數智員工一體機
    standard_solution_line2: 標準解決方案
    holographic: 業內領先的智能全息舱
    virtual: 虛擬人製作
    Metaverse: 快速構建虛擬人應用產品
    analysis: 用戶行為分析
    camera: 攝像頭採集教據,實時查春用戶行為數據分析
    interaction: AI多模態交互
    variousForms: 真人與數字人多種形式互動,模擬真人對話
  three:
    scenarios: 使用場景
    methods: 製作方式
    issues: 問題諮詢
    processing: 業務辦理
    guide: 職能導購
    weakUp: 通過融合喚醒、語音識別、自然語言理解、虛擬人合成等AI核心技術為用戶提供多模態互動、業務辦理、問題諮詢、資訊播放、服務導覽等服務。
  four:
    activities: 線下品牌活動
    projection: 全息投屏
    idols: 偶像合影
    guide: 職能導購
    live: 實時拍攝傳輸全息影像功能，可以讓用戶體驗實時換臉、與偶像合影等娛樂玩法，加速品牌傳播
    contact: 聯絡我們
  five:
    smart: 商場智能櫥窗
    advertising: 全息廣告
    analytics: 數據統計
    delivery: 智能投放
    holographic: 全息廣告能大幅度提升用戶注意力，無需佩戴3D眼鏡即可身臨其境，給用戶帶來沉浸式體驗。同時，感應器獲取用戶行為，全息艙能整合廣告投放、用戶行為多種數據，提供智能投放解決方案。
  six:
    plan: 學習計劃
    answering: 互動回答
    streaming: 全息直播
  seven:
    stores: 零售商品品牌店
    cultural: 文旅景區
    useScenarios: 使用場景
    service: 服務型機構：銀行/醫院/酒店
    public: 政府、事業單位
    learningPlans: 通過學習計劃定制、互動問答、全息直播講解，使黨建工作突破時空限制，讓黨建工作更加智能化、便捷化、數字化。以沉浸式、互動式的體驗學習方式，讓黨員更加深切感悟到黨的艱辛歷程，凝心聚力，錘煉黨性，提高素養
intelligent:
  oneScreen:
    examinationCenter: 工信人才培養
    examinationCenter_line2: 《人工智慧營運師》
    BringingTogether: 集合了工信部教育與考試中心專家、國內知名高校老師、AIGC實踐專家、領先AI企業應用人才等進行課程設計與持續性更新
    project: 項目以應用為導向，由工信部教育與考試中心制定標準，與人工智能領域的領先企業以及各大院校建立緊密合作
    people: 旨在培養具備人工智能運營技能的專業人才，為企業或個人創造價值。打造產教融合、校企聯合的人才培養生態圈
    generative_ai_engineer: 《人工智慧營運師》
    future_start: 智啟时代
    ai_skills_upgrade: AI技能升級，企業轉型加速
    talent_gap: 人才缺口
    ai_era: AI時代，人工智慧應用人才需求旺盛
    authoritative_certification: 工信標準
    mii_certification: 執行工信人才培養工程職業技術標準
    skills_upgrade: 技能升級
    systematic_training: 系統化實訓，從AI基礎到高級應用
    enterprise_transformation: 企業轉型
    ai_empowerment: AI賦能數智化轉型，企業新動力
  twoScreen:
    course: 課程模塊
    course_line2: 職業技能
    artificialIntelligenceOperator: 《人工智慧營運師》
    gettingStarted: 入門
    comprehensive: 全面的人工智能應用認知
    Professional: 專業
    comprehensiveTools: 全面的人工智能工具使用及應用部署
    advanced: 高階
    advancedDeployment: 高階的人工智能應用部署及數智化項目管理
    gettingStarteItem: 人工智能與大模型基礎認知及應用，熟練操作AI文本、圖像、音視頻多模態生成能力，提示詞工程入門，AI短視頻運營，AI數字人運營以及企業及個人如何迎接AI時代
    professionaItem: 人工智能模型高階認知、提示詞編寫與優化、多模態提示詞技巧與應用、企業級AI應用：AI+新媒體、AI+電商、AI+金融、AI+教育等企業級AI智能項目實施
    advancedItem: 人工智能數據分析、模型定製與訓練、企業AI化生產與轉型、AI智能辦公、AI智能行銷、AI行業深度應用與項目管理
  threeScreen:
    teaching_objectives: 教學目標
    ai_training_overview: 學員系統掌握人工智能運營的核心知識和技能，以實踐操作為導向，學員需實操至少兩種業務場景
    ai_training: 以就業為目標，聯合科技大廠與企業，打造交流中心，實現精準人才輸送
    ai_ecommerce_operations: AI電商運營
    digital_human_application: 數字人運用
    ai_live_stream_operations: AI直播運營
    ai_intelligent_interaction: AI智能交互
    ai_graphic_design: AI圖形設計
    ai_smart_customer_service: AI智能客服
    customized_customer_service: 定制化模型
    others: 其他
  fiveScreen:
    smart_future_line2: AI賦能
    talent_training_program_all: 工信人才培養工程，助力AI運營師騰飛！
    talent_training_program: 工信人才培養工程
    talent_training_program_line2: 助力AI運營師騰飛！
    ai_operator_certification: 《人工智慧營運師》考試報名入口
    exam_name: 姓名：
    contact_information: 聯繫方式：
    sign_up_now: 立即報名
    enter_exam_name: 請輸入您的姓名
    enter_phone_number: 請輸入您的手機號
    disclaimer: 注意:本網站非官方報名網站,只提供報名查詢預約服務,一切以工信官網公布為準。
firstPage:
  oneScreen:
    industrial: 工信教考
    certification: 工信《人工智慧營運師》
    certificationLine2: 職業技術證書
    cultivating: 旨在培養具備人工智慧運營技能的專業人才，使其能夠熟練運用人工智慧技術解決實際工作中的問題，為企業或個人創造價值
    practicePriented: 實踐導向
    national: 透過全國實訓基地，學員進行AI技術實操，深入案例分析，以專案問題驅動學習，確保技能與實際工作需求緊密結合
    integration: 產教融合
    universities: 與高校合作，定期舉辦行業專家講座，課程內容與企業需求同步，持續更新，培養與市場同步的AI專業人才
    school: 校企聯動
    technology: 與科技企業合作，邀請企業專家參與教學，提供實習機會，根據企業需求定制人才培養計劃，促進學員就業與企業發展
    solution: 解決方案
    provideIntegrated: 為企業提供數智化轉型升級的產品服務人才輸送一體化解決方案，推動企業升級的同時增加學員就業機會
  twoScreen:
    applicable_people: 《人工智慧營運師》適用人群
    ai_impact: AI的廣泛應用正在革新教育教學的全流程，重塑社會職場生態，更為企業商業模式和業務形態的創新升級提供了廣闊空間
    national_university_students: 全國大學生群體
    leading_edge: 掌握前沿
    ai_skills_for_students: 為大學生、大專生、研究生等提供前沿AI應用技能
    employment_advantage: 就業優勢
    practical_training: 通过实践向导的培训，显著提升学生的就业竞争力
    professional_group: 社會從業者群體
    skill_update: 技能更新
    ai_skill_enhancement: 幫助各行各業人士掌握最新的AI技能，提升個人能力，實現職業升級
    efficiency_improvement: 效率提升
    ai_integration: 將AI技術與現有業務結合，極大提升工作效率和職場競爭力
    innovation_upgrade: 創新升級
    managerial_support: 助力管理者建立新質生產力思維，推動業務模式的創新升級
    capacity_optimization: 產能優化
    corporate_customized_training: 企業定制化培訓
    employee_ai_skills: 通過提高公司員工的AI應用能力，提升工作產能，優化工作流程
    school_enterprise_course_development: 校企联合课程开发
    industry_education_integration: 產教融合
    ai_course_development: 響應國家“人工智能+”行動，將AI應用能力融入專業，打造精品課程
    job_adaptability: 就業適應性
    market_trend_courses: 課程緊貼市場前沿趨勢，顯著提升學生就業適應性和職場競爭力
  threeScreen:
    short_video_marketing: 短視頻營銷時代
    ai_digital_human_content: AI數字人助力高效內容產出
    digital_human_customization: 1分鐘即可定制專屬數字人形象及聲音克隆
    efficient_voice_over: 隨時隨地高效完成口播視頻輸出
  fourScreen:
    interactive_digital_human: 交互式數字人
    ai_digital_human_platform: 專業的AI數字人平台
    custom_digital_human_ip: 定制數字人專屬IP
    create_business_value: 創造無限商業價值
interactive:
  twoScreen:
    user_behavior_analysis: 用戶行為分析
    camera_data_collection: 攝像頭采集數據,實時查看用戶行為數據分析
    ai_multimodal_interaction: AI多模態交互
    human_digital_interaction: 真人與數字人多種形式互動,模擬真人對話
  fourScreen:
    product_advantages: 產品優勢
    stability: 穩定性：
    stabilityItem: 24小時不間斷工作，服務質量始終如一
    accuracy: 準確性：
    accuracyItem: 減少人為錯誤，確保業務處理的準確性
    intelligence: 智能性：
    intelligenceItem: 具備多模態交互能力，會思考、能交流、有感情
    flexibility: 靈活性：
    flexibilityItem: 應不同業務場景，快速部署，易於擴展
    professionalism: 專業性：
    professionalismItem: 深度結合內部知識庫體系，實現專屬大語言模型
    ip_creation: IP打造：
    ip_creationItem: 符合宣傳需要的形象、更好的傳遞IP價值
  fiveScreen:
    popular_topics: 熱門話題
    business_qa: 業務問答
    business_explanation: 業務講解
    fallback_phrases: 兜底話術
    current_events_interpretation: 時事解讀
    multi_turn_conversations: 多輪會話
    voice_activation: 語音喚醒
    greeting: 迎賓問候
    product_features: 產品功能
  sixScreen:
    diversified_scenarios: 多樣化場景
    interactive_digital_human: 交互式數字人憑借其高度的智能化和個性化能力，在智能教育、客戶服務、虛擬助理、娛樂互動、業務導覽及醫療咨詢、法律咨詢等多個場景中發揮著重要作用。它們能夠理解並響應用戶需求，提供定制化服務，同時通過持續學習優化交互體驗，為各行各業帶來創新動力和效率提升
    4s_store_customer_service: 4S店客服
    digital_immortality: 數字永生
    smart_reading_guide: 智慧導讀
    digital_lawyer: 數字律師
    virtual_shopping_assistant: 虛擬導購
outstandingCase:
  oneScreen:
    personal_ip: 個人IP
    blogger_entrepreneur_merchant: 博主、企業主、商家等，可以打造自己的數字人形象，快速批量更新視頻，做觀點表達，讓用戶持續看到、信任並付費
    corporate_application: 企業應用
    brand_ambassador: 融合企業基因，打造品牌代言人、形象代言人、數字員工、數字專家等，可用於企業內外宣物料製作、營銷Campaign創新、RPA數字人化等
    live_broadcasting_application: 直播應用
    live_streaming_support: 支持直播推流，數字人主播代替真人主播出鏡，輕鬆滿足電商帶貨、賽事解說、新聞資訊、娛樂播報等場景
    digital_interaction: 數字交互
    emotional_companion: 數字交互可以用於情感陪伴、客戶服務、業務處理、虛擬顧問、智能陪練等多種場景，提供直觀、生動的交互體驗
  twoScreen:
    Short_Video: 短視頻
    Interactive_Digital_Human: 交互數字人
    Live_Digital_Human: 直播數字人
    Voice_Customization: 聲音定製
    Fast_Digital_Human: 極速數字人
    digital_human_applications: 數位人百行百業應用
    short_video_broadcasting: AI數字人目前已經廣泛應用在短視頻領域，滿足各類用戶對短視頻播報的場景需求，在互聯網、商業、教育、醫療、政務、法律、文旅等領域都有著廣泛的應用前景
  threeScreen:
    digital_human_live_streaming: 數字人直播應用
    standardized_processes: 數字人直播更適合有標準流程的標品直播間。通過數字人，降低直播間對主播的依賴，放大直播間優勢，降低直播運營成本，讓直播常態化。
  fourScreen:
    interactive_digital_human_application: 交互數字人應用
    high_frequency_services: AI交互數字人通常用在系統化、重複性、高併發的服務場景中。如文旅服務中，將數字人導遊/虛擬代言人等虛擬形象內置於交互大屏中，與遊客深度聯動；搭載AI大模型，讓交互更智能、更效率，還能提供個性化的服務體驗
aboutUs:
  ai_initiative_response: 響應國家人工智能+行動 推動市場創新應用
  talent_enterprise_link: 鏈接人才與企業，引入智能化解決方案 助力企業數智化轉型
  project_overview: 項目概覽
  services_provided: 我們提供智能數字人應用服務以及定制化AI模型開發服務，包括智能交互式數字人、數字人形象定制、聲音克隆、視頻合成、數字人直播、行業垂直小模型等解決方案；
  training_certification: 同時提供專業的AIGC商業應用培訓、工信教育與考試中心《人工智慧營運師》培訓考證等核心服務，並為企業輸送人工智能專業運營人才，推動和創新人工智能應用落地的實現。
  ai_operator_training: 《人工智慧營運師》
  training_certificat: 培訓及考證
  training_program: 通過系統化、分階段的實訓課程，培養專業的人工智能應用型人才。培訓執行工業和信息化人才培養工程標準，通過實訓及考試的學員可獲得工信部教育與考試中心《人工智慧營運師》職業技術證書
  learn_more: 了解更多
  ai_digital_human_products: AI數字人產品
  solutions: 解決方案
  business_solutions: 根據企業的具體業務需求場景提供智能數字人應用方案，包括數字人定制、聲音克隆、視頻合成、數字人直播、AI視頻翻譯、智能交互式數字人等。助力企業在內容製作、流量獲取、品牌宣傳等方面的智能化升級
  ai_customization: AI定制化
  model_services: 模型服務
  training_services: 從企業業務需求端出發定向訓練任務模型，並制定工作流，提高工作效率，包括AI客服模型訓練、AI電商模型訓練、AI設計模型訓練等
offtenQuersion:
  image_customization_change: 形象自訂是否可以更換形象？
  image_customization_changevA: 抱歉，影像影片在審核通過並上傳訓練後無法更換，因為訓練是我們客製化過程中最重要的一環。為了確保數位人形象的克隆質量，建議您在拍完影片後一定要發給客服人員審核後上傳。
  action_required: 為何要在15秒到54秒中間必須要做動作？
  action_requiredA: 因為系統的動作捕捉期是影片的第1分鐘（14秒-54秒），所以您在這個時間段的動作需要多樣化一些。同時，為了相容於未來想表達內容，建議多做一些通用型動作（例如雙手握手、雙手攤手，往前展開等），不要做有特別指向性的動作（例如舉3根或1根手指之類） 。
  video_duration: 影片拍攝時長應該是多少？
  imageVersionSuggestedDuration: 形象版：建議時長1~30分鐘。
  durationVersionSuggestedDurationAndQuality: 時長版：建議時長3~5分鐘，越接近5分鐘越好。樣本視頻質量和長度，決定數字人的最終效果，因此請一定要特別重視樣本視頻的拍攝。
  imageVersionNoReview: 形象版：無需審核，一般1~3分鐘即可訓練完成。
  durationVersionAfterReview: 時長版：審核通過後，一般3~5個工作日即可訓練完成。
  video_durationA: 建議時長是3-5分鐘，越接近5分鐘越好。樣本視頻質量和長度，決定著數字人的最終效果，因此請一定要特別重視樣本視頻的拍攝。
  audio_duration: 音頻錄製時長應該多少？
  audio_durationA: 建議時長是8-12分鐘，越接近12分鐘越好，訓練數據量越多，生成效果越好。
  production_time: 數字人製作一般要多久？
  production_timeA: 審核通過後，一般3-5個工作日即可生成完成。
  refund_policy: 數字人購買之後是否可以退款？
  refund_policyA: 數字人作為虛擬產品，因此在購買之後無法退款，所以建議您在購買前，必須要看清包含的產品內容，可以在咨詢客服後確認無誤再進行購買。
  review_time: 審核時間一般多久？
  review_timeA: 在工作日內，工作人員會先查看原視頻，如果原視頻符合上傳要求，在當天上傳視頻。如果原視頻不符合要求，工作人員將對視頻和音頻進行二次優化處理，完成優化後再做上傳。
  discount_code: 什麼是折扣碼？
  discount_codeA: 如果您申請了折扣碼後並通過，您的朋友或客戶使用該折扣碼就可以獲得一定的折扣。例如您獲得了九折折扣碼，您可以在基礎價格的九折價格，同時還可以獲得相對應的返傭。您必須要注意的是，申請折扣碼之前您必須要發布一條推廣影片才能獲得。
  interaction_paths: 交互路數一路是什麼意思？
  interaction_pathsA: 互動路數越多，可以同時服務的顧客數就越多。範例： 一路交互路數就代表一個客服，當該客服在與客戶通話的時候,第二個客戶再打進來就必須等待第一個客戶結束後才可接入，如果您有兩路,就可以同時服務兩個客戶,此時第三個客戶就會等待，以此類推。
  card_count: 名片張數是什麼意思？
  card_countA: 假設您擁有三張名片,那麼您可以同時發布三張名片。每張名片可以配置不同的形象、不同的職位介紹。
  live_duration: 直播時間是什麼意思？
  live_durationA: 直播時長代表您在有效期間內可以直播的時間長度。例如您在有效期限3個月內有360小時的直播時長，如果您直播超過360個小時,即使在有效期內也不能開播。在有效期限內，您使用完直播時間後可以聯絡工作人員，購買直播時長。注意了，有效期限內直播時長未使用完的，超過有效期限後剩餘的直播時長將會歸零。
bannerCard:
  digital_human_live: 數位人直播
  digital_human_customization: 數位人定制
  customized_models: 客製化模型
  interactive_digitHuman: 互動式數位人
  artificial_intelligence_certification: 人工智能認證
customizedModels:
  customized_model_service: 定制化模型服務
  ai_art_beauty: 你所見之境
  ai_art_beauty_line2: 盡是AI藝術之美
  technical_advantages: 技術優勢
  solve_large_model_issues: 解決了大模型“無法精準落地”的痛點
  solve_large_model_issues_line2: 從具體需求端真正幫助客戶大幅提高生產力
  application_layer: 應用層
  customized_model: 定制化模型
  large_model: 大模型
  precise_efficient_solution: 精準、高效的落地方案
  customized_workflow: 定制化工作流
  demand_oriented: 從需求端出發
  targeted_training_model: 定向訓練模型
  wig_style_effect_images: 指定假發款式效果圖生成4A介面效果圖生成
  wig_materials: 假發素材
  train_model: 訓練模型
  batch_image_generation: 批量跑圖
  customer_selection: 客戶挑選
  customized_model_images_output: 根據產品圖片
  customized_model_images: 輸出定制化模型圖
digital-human-workbench:
  emotionalVoiceLanguage: 情感版聲音可選擇語言
  emotionalVoiceEmotion: 情感版聲音可選擇情感
  professionalRemainingCloneCount: 克隆剩余次数{count}次
  minimalRemainingCloneCount: 预克隆剩余次数{count}次
  setInfo: 參數設定
  previewCloneAudio: 預克隆音頻
  confirmClone: 確定克隆
  preClone: 預克隆
  remaining: 剩餘
  times: 次
  emotionalVersion: 情感版
  emotionalVer: 情感版
  basicVersion: 基礎版
  seats: 席位
  userCommitmentNotice: 使用者承諾須知
  statement: 本聲明將幫助您更好地在平台使用相關工具上傳和管理您的作品。您若上傳作品，即視為您已充分知悉並接受以下內容：平台使用相關工具上傳和管理您的作品。您若上傳作品，即視為您已充分知悉並接受以下內容：
  intellectualProperty: 您作為使用者在本平台上傳、發布的作品，應具有獨立、完整的知識產權，不得侵犯他人知識產權等任何權利。
  compliance: 您在使用本平台及上傳、發布作品時，應當自覺遵守國家法律、法規，維護公共秩序，尊重社會公德、社會主義制度、國家利益、公民合法權益、道德風尚及信息真實性等要求。如有違反，一經本平台發現，將根據違規程度採取包括但不限於刪除、下架、禁止發布內容、封禁賬號等處理方式。如造成惡劣影響或涉嫌違法犯罪的，本平台有權向有關管理機關或公安機關提交相關內容，並配合進行調查。
  complianceUser: 您作為使用者在本平台上傳、發布的作品，應具有獨立、完整的知識產權，不得侵犯他人知識產權等任何權利。
  infringement: 若您上傳的作品及作品中的素材（包括但不限於創意、文本、肖像、音頻、圖片、視頻等）侵犯了任何第三方權利，本平台均有權在收到相關侵權投訴後對被投訴的作品或用戶賬號依據相應規則，採取包括但不限於下架、警告、封禁賬號等處理方式。"
  acknowledged: 我已知曉，並同意
  digitalAvatar: 數字分身
  uploadVideo: 上傳視頻
  rapidCloneDigitalAvatar: 3分鐘極速克隆數位分身
  quickReplication: 快速復刻
  uploadAudio: 上傳音頻
  createPersonalVoiceModel: 1分鐘極速複刻聲音
  rapidClone: 快速克隆
  myDigitalAvatar: 我的數字分身
  myDigital: 我的定制
  goCreate: 去創作
  noDigitalAvatarYet: -還沒有數字分身-
  clickForCustomDigitalPersona: 點擊“快速復刻”定制專屬數字人分身
  myVoiceClone: 我的聲音克隆
  noVoiceCloneYet: -還沒有聲音克隆-
  clickForPersonalVoiceModel: 點擊上方“快速克隆”上傳音頻，打造專屬個人聲音模型
  replicateDigitalAvatar: 複刻數位分身
  digitalAvatarName: 數位分身名稱
  enterDigitalAvatarName: 請輸入數位分身名稱
  upVideo: 上傳視頻
  videoOrientation: ①視頻方向：
  orientation: 橫向或縱向
  fileFormat: ②檔案格式：
  videoDuration: ③視頻時長：
  time: 30秒~5分鐘
  resolution: ④分辯率：
  fileSize: ⑤文件大小：
  less: 小於500MB
  notes: 注意事項
  errorExample: 錯誤示例
  doNotUseMultiplePeopleVideo: 不要使用有多个人的視頻
  ensureFullFaceInScreen: 人臉不要太大，確保整張人臉都在荧幕區域內，人臉不要出荧幕
  ensureFaceUnobstructed: 確保面部特徵沒有被遮擋，並努力讓面部清晰可見
  videoResolutionGuidance: 上傳的視頻分辯率不應低於360p，也不應超過3840p，建議分辯率為720p或1080p
  translateVideo: 分辨率必須介於480x480和2560x2560之間
  translateVideoDur: 介於480x480和2560x2560之间
  videoLengthRange: 視頻長度在30秒～5分鐘範圍內
  multipleFaces: 多張人臉
  faceTooLarge: 人臉太大
  faceObstructed: 臉部遮擋
  faceNotDetected: 檢測不到人臉
  iHaveReadAndAgree: 我已閱讀並同意
  canCloneMultipleTimes: 可多次克隆,找到最佳效果
  confirmCloneAfter: 確認後不可更新
  continueCreate: 繼續制作
  youHave: 您擁有
  preCloneChances: 次聲音預克隆測試機會
  preCloneDescription: 。每次預克隆將消耗一次機會，您可以通過試聽功能確認聲音效果。當效果滿意時，請點擊"確認克隆"完成聲音訓練。請注意：
  preCloneWarning: 僅點擊"確認克隆"的聲音可用於數字分身，且確認後將無法修改或重新訓練。
  confirmEffectPrompt: 請確認聲音效果是否滿意。確認後,
  confirmWarning: 該聲音將完成訓練並可用於數字分身，並且無法進行預克隆,
  continuePrompt: 是否繼續？
  pleasePreCloneFirst: 請先預克隆
  notice: 《使用者承諾需知》
  preCloneSuccess: 預克隆成功
  generating: 生成中
  readAndAgreeUserCommitment: 請先閱讀並同意《使用者承諾須知》
  enter: 請輸入數位分身名稱
  digitalAvatarNameCharRange: 數位分身名稱字元數應在2到20之間
  submitVideoFile: 請提交視頻檔案
  submitting: 提交中
  enterVoiceName: 請輸入聲音名稱，且字元數在2到20之間
  voiceNameCharRange: 聲音名稱字元數應在2到20之間
  submitVoiceFile: 請提交聲音檔案
  submitAudioFileFormat: 請提交mp3、m4a、wav格式的音訊檔案，文件大小不超過10MB
  basicVoiceClone: 聲音克隆
  highFidelityVoiceClone: 聲音克隆—高保真
  enterName: 請輸入聲音名稱
  languageSelection: 語言選擇
  selectLanguage: 選擇語言
  selectSex: 请选择性别
  audioRequirements: 音訊要求
  myfileFormat: ①檔案格式
  audioDuration: ②音訊時長：
  mytime: 10秒～3分鐘
  audioSize: ③音訊大小：
  myless: 小於10MB
  kindReminder: 溫馨提示
  suggestedAudioDuration: 音訊時長建議音訊時長限制在10秒～3分鐘
  audioQuality: 音訊質量
  oneSpeakerOnly: a.只有一個在說話，避免音訊中出現多個人的聲音；
  ensureLowBackgroundNoise: b.保證低底噪。 說話人說話越清晰，最終克隆效果越好，音訊底噪會嚴重影響克隆效果
  maintainStableVolumeAndPace: c.保持音量大小、語速穩定、注意斷句、避免口腔譟音（如口水聲）、雜音、混響等情况
  avoidManualNoises: d.音訊中不要有桌椅響聲、鍵盤滑鼠敲擊聲、衣服摩擦聲等人為雜訊；
  pauseForMistakes: e.音訊中可以存在口誤。 口誤時無需終止錄音，可停頓1~2秒後，繼續錄製即可。
  audioContent: 音訊內容
  decideVoiceStyleFirst: 在錄製音訊前建議先確定好聲音風格，在錄音時儘量去貼近風格，避免錄製的音訊情緒韻律趨於平淡。
  emotionalRhythm: 如果希望克隆出的聲音情緒飽滿、韻律有起伏，請儘量上傳表現力强的音訊來克隆。
  supportedAudioFormats: 音訊格式
  selectEmotion: 選擇情緒
  supportedFormatsAndSize: 支持mp3、wav、m4a檔案格式，音訊文件大小不超過10M。
  uploadVoiceOrEnterText: 請上傳聲音或者輸入文本
  selectVoiceFirst: 請先選擇聲音
  estimatedVideoLength: 預估合成視頻時長為{time}秒
  confirmSynthesis: 確定合成
  newDigitalPersonaVideoGenerating: 新的數字人驅動視頻生成中，可在作品管理查看
  myWorks: 查看我的作品
  continueCreating: 繼續創作
  workbench: 工作台
  public_model: 公共模型
  massive_models: 使用公共模特創作視頻
  audioDriven: 音頻驅動
  textDriven: 文本驅動
  enterTextHere: 請在此輸入文字
  audition: 試聽
  selectTextToAudition: 滑鼠滑動選取文字內容, 進行逐句試聽, 最多選取300字
  extract: 文案提取
  textInput: 文本輸入
  currentSelection: 當前選中：
  previewSynthesizing: 預覽合成中
  speedSpeech: 語速
  adjustRate: 點擊調整
  currentSpeed: 當前語速
  rename: 重命名
  cloneAgain: 再次克隆
  name: 請輸入名稱
  renameSuccess: 修改命名成功
  renameFailure: 修改命名失敗
  myAssest: 我的權益
  usedAlready: 已用
  number: 個
  speedAdjustment: 語速調整
  myAvailable: 可用
  usedfor: Used for
  available: Available
  myused: Used
  already: already
  viewLine: 查看台詞
  recreate: 再次創作
  driveMode: 驅動模式
  selectDigitalPersonaVideoMode: 選擇數字人視頻在驅動時的模式：
  sequentialDrive: 順序驅動：使用數字人生成作品時會從你上傳的原始視頻第一幀開始順序驅動
  randomMode: 隨機模式：隨機挑選片段來進行驅動（生成的音頻時長超過原視頻長度時，按照順序驅動，不會走隨機模式）
  sampleAudio: 示例音频
  noAudioMaterials: 暫時沒有音頻素材？使用我們的示例素材來感受效果吧
  random: 隨機模式
  sequentia: 順序驅動
  persevereSilently: 堅強隱忍
  beYourselfComfortably: 安心做自己
  giveYourselfTime: 給自己時間
  selectOption: 選用
  myOption: 我的
  publicOption: 公用
  enterKeywordsToSearch: 請輸入關鍵詞進行搜索
  noVoiceModelAvailable: 暫無語音模型，請選擇其他
  generatingWait: 生成中,請稍等
  generationFailed: 生成失敗
  waitingToStart: 等待開始
  computingPower: 算力
  dontRemindAgain: 不再提醒
  enable: 啟用
  professionalMode: 超清版本
  professionMode: 超清
  synthesizingVideoWill: 合成視頻將會
  doubleComputingPowerConsumption: 消耗2倍的視頻合成時長，即原來1視頻合成時長的超清版本=2視頻合成時長的高清版本
  confirmContinue: 請確認是否繼續？
  normalMode: 高清版本
  normal: 高清
  generationMode: 生成模式
  description: 說明：
  normalModeDescription: 高清版本：消耗算力，合成1分鐘視頻消耗1算力
  professionalModeDescription: 超清版本：使用超清版本將消耗2倍的視頻合成算力，即原來1算力的超清版本=2算力的高清版本
  noSoundDetected: 檢測不到聲音，請確認麥克風處於錄製中，並且保障足夠的說話時長
  reasonForFailure: 失敗原因
  voiceCloneProcess: 聲音克隆流程
  uploadReferenceAudio: 上傳參考音頻
  uploadAudioDescription: 上傳一段音頻用於克隆聲音。音頻的質量會直接影響到最後克隆聲音的效果，因此請您盡量保證用於克隆的音頻符合以下要求：
  audioDurationTitle: (1)音頻時長：
  audioDurationRange: 建議音頻時長限制在1分鐘～3分鐘;
  audioQualityTitle: (2)音頻質量：
  audioContentTitle: (3)音頻內容：
  audioFormatTitle: (4)音頻格式
  previewEffect: 預覽效果
  previewAudioTip: 音頻上傳後，請先預覽試聽聲音效果。
  editChancesWarning: 如果對聲音效果不滿意，您可重新上傳新的音頻進行效果試聽，
  editChancesLimit: 每個聲音共有2次修改編輯的機會。
  avoidWastingChances: 建議您保證音頻符合上述要求，避免浪費修改編輯的次數。
  pointsConsumption: 積分消耗
  highFidelityPointsCost: 聲音克隆-高保真 積分消耗：
  pointsPerVoice: 10000積分/個
  freeTrialsExhausted: 如果您的免費次數為0
  purchaseDisclaimer: 我們會扣除10000積分，用於購買聲音經購買，概不退貨
  emotion:
    happy: 高興
    sad: 悲傷
    angry: 憤怒
    fearful: 害怕
    disgusted: 厭惡
    surprised: 驚訝
    neutral: 中性
  language:
    zhCN: 中文(普通話)
    zhHK: 中文(粵語)
    enUS: 英語
    jaJP: 日語
    koKR: 韓語
    esES: 西班牙語
    frFR: 法語
    ruRU: 俄語
    itIT: 義大利語
    ptPT: 葡萄牙語
    deDE: 德語
    idID: 印尼語
audioModeSelectionDialog:
  recommended: 推薦版本
  title: 簡單易用，超乎您的期望
  subtitle: 領先的技術體驗聲音克隆，超過2萬用戶的選擇
  advanced:
    title: 高級版
    description: 輕鬆體驗聲音克隆技術，快速還原你的獨特音色
    button: 註冊免費體驗
    features:
      fastClone: 極速克隆
      fastCloneDescription: 輕鬆體驗聲音克隆技術，快速還原你的獨特音色
      zeroCost: 0成本體驗
      zeroCostDescription: 無需任何費用，即可開始你的聲音克隆之旅
      simpleProcess: 簡潔流程
      simpleProcessDescription: 簡單易用的界面設計，讓你輕鬆上手，快速完成聲音克隆
  emotional:
    title: 情感版
    description: 輕鬆體驗聲音克隆技術，快速還原你的獨特音色
    button: 立即使用
    features:
      emotionalClone: 聲音情感克隆
      emotionalCloneDescription: 不僅還原你的音色，更精準克隆原語料的情感表達，讓聲音更加生動真實
      instantPreview: 效果及時預覽
      instantPreviewDescription: 實時預覽克隆效果，隨時調整至滿意狀態，讓聲音克隆更加精準貼合
      resetOpportunity: 享{times}次預克隆重置機會
      resetOpportunityDescription: 隨時特享{times}次預克隆機會，預覽效果不滿意可享有{times}次重置時長的機會，首至點擊確認克隆後，才消耗一次情感版聲音授權額度
      coreAdvantagesLabel: "核心優勢："
      coreAdvantagesValue: "多情感多語言"
      voiceSimilarityLabel: "聲音相似度："
      voiceSimilarityValue: "80-95%"
      emotionalModesLabel: "聲音情感："
      emotionalModesValue: "高興、悲傷、憤怒等7種情感模式"
      languageSupportLabel: "語言支持："
      languageSupportValue: "中文、粵語、英文等12種語言"
      previewSystemLabel: "試聽機制："
      previewSystemValue: "克隆前，{times}次試聽(克隆前充分驗證）"
  highFidelity:
    title: 专业版
    description: 輕鬆體驗聲音克隆技術，快速還原你的獨特音色
    button: 立即使用
    features:
      highRestore: 高度還原音色與節奏
      highRestoreDescription: 採用先進技術，最高程度還原語料的音色、語調及說話節奏
      extremeFidelity: 極致高保真
      extremeFidelityDescription: 一次克隆，即可享受高保真聲音效果，滿足你對聲音品質的極致追求
      retrainOpportunity: 享{highTimes}次重新訓練的機會
      retrainOpportunityDescription: 克隆完成後，在有效期內可享受{highTimes}次聲音替換與重新訓練的機會
      coreAdvantagesLabel: "核心優勢："
      coreAdvantagesValue: "高精度靈活調整"
      emotionalModesLabel: "情感模式："
      emotionalModesValue: "暂不支持"
      voiceSimilarityLabel: "聲音相似度："
      voiceSimilarityValue: "80-95%"
      languageSupportLabel: "語言支持："
      languageSupportValue: "中文、英文"
      previewSystemLabel: "試聽機制："
      previewSystemValue: "克隆後，{highTimes}次重新克隆機會(降低調整成本）"
