<template>
  <!-- Config Provider is used for providing global configurations, 
    which enables your entire application to access these configurations everywhere. -->
  <el-config-provider :locale="currentLocale">
    <router-view />
  </el-config-provider>
  <!-- 选择声音弹窗，这个是在全屏页面和非全屏页面都能弹出的弹窗 -->
  <!-- <SelectVoiceCloneDialog
    ref="selectVoiceCloneDialogRef"
    @gotoClone="handleGotoClone"
  /> -->
  <GlobalEmitter />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, provide, ref } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import zhTw from "element-plus/dist/locale/zh-tw.mjs";
import en from "element-plus/dist/locale/en.mjs";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { Lang } from "./config";
import { initlang } from "./utils/responsive";
import { useDataThemeChange } from "./layout/hooks/useDataThemeChange";
import { useEpThemeStoreHook } from "./store/modules/epTheme";
import { useRoute } from "vue-router";
import GlobalEmitter from "./global.emitter.vue";

// 提供全局属性
provide("seniorChat", true);

// 检查childDomain，不存在时抛出404错误
if (!useSystemConfigStoreHook().childDomain) {
  throw new Error("/error/404");
}

// 计算当前语言环境对应的Element Plus语言包
const currentLocale = computed(() => {
  const lang = initlang();
  switch (lang) {
    case Lang.LAN_CN:
      return zhCn;
    case Lang.LAN_EN:
      return en;
    case Lang.LAN_TW:
      return zhTw;
    default:
      return zhCn;
  }
});
// 获取并设置主题逻辑
const { setLayoutThemeColor } = useDataThemeChange();
const epThemeStore = useEpThemeStoreHook();
const route = useRoute();
// 在组件初始化（onBeforeMount）时根据当前路由或全局主题设置主题色
onBeforeMount(() => {
  const defaultTheme = (route.meta.theme as string) || epThemeStore.epTheme;
  setLayoutThemeColor(defaultTheme);
  console.log(
    "useSystemConfigStoreHook().childDomain:",
    useSystemConfigStoreHook().childDomain
  );
});
</script>
