<script setup lang="ts">
import { Motion, emitter } from "@/utils";
import { ref, onMounted, onBeforeUnmount, nextTick, computed } from "vue";
import { useI18n } from "vue-i18n";
import { EmitterTypeEnum, PayButtonTypeEnum } from "@/config";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { ElMessage } from "element-plus";
import ContactForFreeDialog from "@/views/components/dialog/ContactForFreeDialog.vue";
import { formatUtils } from "@/utils";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import { Constants } from "@/config";
import ToRight from "@/assets/svg/torRight.svg?component";
import ToLeft from "@/assets/svg/toLeft.svg?component";
import elementResizeDetectorMaker, { Erd } from "element-resize-detector";
import { deviceDetection } from "@pureadmin/utils";
import { hasLogin } from "@/router/utils";
import { ItemScoreRechargeType } from "@/api/types";
import DigitalHumanItem from "./digital-human-item.vue";
const isMobile = deviceDetection();
const { t } = useI18n();
const discountCodeRef = ref();
const currentProduct = ref<Array<ItemScoreRechargeType>>();
// 滚动条ref
const scrollbarRef = ref();
const scrollbarRefWidth = ref();
const isicon = ref(false);
const bigWidthRef = ref();
const bigWidth = ref();

const confirmToWatch = ref(false);
const showShakeAnimation = ref(false);
// 客服弹窗ref
const serviceDialogRef = ref();
//免费领取数字人弹窗
const serviceForFreeDialogRef = ref();
const erd = ref<Erd>();
const { locale } = useTranslationLang();
const openPayRef = ref(useSystemConfigStoreHook().openPay);
const selectItemRef = ref<ItemScoreRechargeType>();
const logoIsEmpty = ref<Boolean>(false);

const props = defineProps({
  //是否显示立即购买的按钮
  unShowBottom: {
    default: false,
    type: Boolean
  },
  //是否显示联系我们框
  unshowContactUs: {
    default: true,
    type: Boolean
  }
});
/**
 * @param rechargeId 具体产品id
 * @param type 充值类型
 */
const showDialog = async (tCurrentProduct: Array<ItemScoreRechargeType>) => {
  currentProduct.value = tCurrentProduct;
  if (
    currentProduct.value.findIndex(
      item => item.id == Constants.Common.TEMP_ID
    ) < 0 &&
    props.unshowContactUs
  ) {
    currentProduct.value.push({
      id: Constants.Common.TEMP_ID,
      logo: "digitEnterprise",
      desc: t("digitHuman.enterpriseContact"),
      price: t("welcome.five.contactUs"),
      discountPrice: t("welcome.five.contactUs"),
      descList: formatUtils.contactUs()
    } as any);
  }

  currentProduct.value.forEach(element => {
    if (element.id != Constants.Common.TEMP_ID) {
      element.payButtonType =
        element.discountPrice == Constants.Common.freeDigital
          ? PayButtonTypeEnum.ZERO_HUMAN
          : PayButtonTypeEnum.BUY;
    } else {
      element.payButtonType = PayButtonTypeEnum.CONTACT;
    }
  });
  selectItemRef.value = tCurrentProduct[0];
  // 数字人卡片宽度大于它的展示空间出现滚动条，和向左向右的箭头
  nextTick(() => {
    if (scrollbarRef.value.wrapRef) {
      scrollbarRefWidth.value = scrollbarRef.value.wrapRef.scrollWidth;
      bigWidth.value = bigWidthRef.value.offsetWidth;
      if (scrollbarRefWidth.value > bigWidth.value) {
        isicon.value = true;
      }
      if (scrollbarRefWidth.value == bigWidth.value) {
        isicon.value = false;
      }
    }
  });
};

const isShowDiscountCode = () => {
  if (
    // 总的开关关闭
    !useSystemConfigStoreHook().openDiscount ||
    // 没有选中任何有效的
    !selectItemRef.value ||
    // 当前的选中的是关闭
    !selectItemRef.value.enableDiscount ||
    // 关闭支付,肯定不显示折扣码
    !openPayRef.value
  ) {
    return false;
  }
  return true;
};
const handleBuyRightNow = () => {
  hasLogin();
  if (!confirmToWatch.value) {
    showShakeAnimation.value = true;
    // 可以设置一个延时来隐藏提示
    setTimeout(() => {
      showShakeAnimation.value = false;
    }, 2000);
    ElMessage({
      type: "warning",
      message: t("digitHuman.pleaseRead")
    });
  } else {
    const data: MittSpace.PCAndMobilePayData = {
      id: selectItemRef.value.id,
      discountCode: isShowDiscountCode()
        ? discountCodeRef.value.discountCode
        : null,
      payBusinessType: selectItemRef.value.type,
      brandType: selectItemRef.value.brandType
    };
    confirmToWatch.value = true;
    emitter.emit(EmitterTypeEnum.ShowPCAndMobilePayDialog, data);
  }
};
// 点击联系我们按钮
const handleShowServiceDialog = () => {
  serviceDialogRef.value.showServiceDialog();
};

//打开免费领取数字人弹窗
const handleGetFreeDigital = () => {
  //需要登录，获取到用户信息才能获得用户id进行领取免费数字人
  hasLogin();
  if (!confirmToWatch.value) {
    showShakeAnimation.value = true;
    // 可以设置一个延时来隐藏提示
    setTimeout(() => {
      showShakeAnimation.value = false;
    }, 2000);
    ElMessage({
      type: "warning",
      message: t("digitHuman.pleaseRead")
    });
  } else {
    serviceForFreeDialogRef.value.showServiceDialog();
  }
};
//向左滑动
const scrollLeft = () => {
  if (scrollbarRef.value) {
    const scrollContainer = scrollbarRef.value.wrapRef; // 获取滚动容器
    //平滑的滚动过去
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft - 250,
      behavior: "smooth"
    });
  }
};
//向右滑动
const scrollRight = () => {
  if (scrollbarRef.value) {
    const scrollContainer = scrollbarRef.value.wrapRef; // 获取滚动容器
    //平滑的滚动过去
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft + 250,
      behavior: "smooth"
    });
  }
};
erd.value = elementResizeDetectorMaker({
  strategy: "scroll"
});
const onResize = () => {
  //当窗口大小改变时候，时刻更新联系我们卡片高度赋值
  erd.value.listenTo(
    bigWidthRef.value, // 获取滚动容器大盒子
    function (element) {
      scrollbarRefWidth.value = scrollbarRef.value.wrapRef.scrollWidth;
      bigWidth.value = element.offsetWidth;
      if (scrollbarRefWidth.value > bigWidth.value) {
        isicon.value = true;
      }
      if (scrollbarRefWidth.value == bigWidth.value) {
        isicon.value = false;
      }
    }
  );
};

onMounted(() => {
  window.addEventListener("resize", onResize);
});
onBeforeUnmount(() => {
  //取消监听
  erd.value.uninstall(bigWidthRef.value);
  window.removeEventListener("resize", onResize);
});

defineExpose({ showDialog });
</script>

<template>
  <div>
    <div class="text-[2rem] mt-[18px]">
      <div class="text-[2rem] text-primary font-[OPPOSansH]">
        {{ t("digitHuman.choose") }}
      </div>
      <div class="text-[1.2rem] mt-[2rem]">
        {{ t("digitHuman.superior") }}
      </div>
    </div>
    <Motion class="lg:relative lg:w-full h-full flex-c">
      <!-- 左右滑动的按钮 -->
      <div
        v-if="isicon"
        class="absolute z-[9999] top-[50%] -translate-y-1/2 left-[-5rem]"
        @click="scrollLeft"
      >
        <div
          class="bg-[#7a7979] ani-scale hover:bg-opacity-[20%] active:bg-opacity-0 bg-opacity-[10%] rounded-[50%]"
        >
          <ToLeft
            class="w-[2.5rem] h-[2.5rem] lg:w-[3rem] lg:h-[3rem] rounded-[50%] p-[5px]"
          />
        </div>
      </div>
      <div
        v-if="isicon"
        class="absolute z-[9999] top-[50%] -translate-y-1/2 right-[-5rem]"
        @click="scrollRight"
      >
        <div
          class="bg-[#7a7979] ani-scale hover:bg-opacity-[20%] active:bg-opacity-0 bg-opacity-[10%] rounded-[50%]"
        >
          <ToRight
            class="w-[2.5rem] h-[2.5rem] lg:w-[3rem] lg:h-[3rem] rounded-[50%] p-[5px]"
          />
        </div>
      </div>
      <div
        ref="bigWidthRef"
        class="w-full relative rounded-[10px] overflow-x-auto"
      >
        <el-scrollbar always ref="scrollbarRef">
          <div
            :class="{ 'justify-evenly': !isMobile }"
            class="flex flex-row min-w-max"
          >
            <!--数字人商品卡片 -->
            <div
              v-for="item in currentProduct"
              :key="item.id"
              @click="selectItemRef = item"
              :class="{
                cardActiveBg: selectItemRef?.id === item.id,
                cardBg: selectItemRef?.id !== item.id
              }"
              class="ani-scale mr-[20px] my-[30px] rounded-[10px] pt-[2rem] pb-[4rem] w-[17.5rem] overflow-hidden text-center hover:cursor-pointer"
            >
              <DigitalHumanItem
                :logoIsEmpty="!currentProduct[0].logo"
                :item="item"
                :selectItem="selectItemRef"
              />
            </div>
          </div>
        </el-scrollbar>
      </div>
    </Motion>
    <div v-if="!unShowBottom" class="flex flex-col justify-center mt-[20px]">
      <DiscountCode v-if="isShowDiscountCode()" ref="discountCodeRef" />
      <div
        v-if="
          selectItemRef?.payButtonType == PayButtonTypeEnum.BUY && openPayRef
        "
        class="mt-[30px] mx-auto font-bold ani-scale flex-c my-gradient-green-left text-[1.5rem] lg:text-[21px] text-center text-white rounded-[2rem] lg:rounded-[2.6rem] px-[2.6rem] py-[1rem] lg:px-[5.3rem] lg:py-[1.25rem] hover:cursor-pointer w-[20rem] lg:w-[28rem] h-[4rem]"
        @click="handleBuyRightNow"
      >
        {{ t("common.buyRightNow") }}
      </div>
      <div
        :class="{
          'lg:text-[20px] lg:!p-0': locale == Constants.Lang.en
        }"
        v-else-if="selectItemRef?.payButtonType == PayButtonTypeEnum.ZERO_HUMAN"
        class="mt-[30px] mx-auto font-bold ani-scale flex-c my-gradient-green-left text-[1.5rem] lg:text-[21px] text-center text-white rounded-[2rem] lg:rounded-[2.6rem] px-[2.6rem] py-[1rem] lg:px-[5.3rem] lg:py-[1.25rem] hover:cursor-pointer w-[20rem] lg:w-[28rem] h-[4rem]"
        @click="handleGetFreeDigital"
      >
        {{ t("dialog.getDigital") }}
      </div>
      <div
        v-else
        class="mt-[30px] mx-auto font-bold ani-scale flex-c my-gradient-green-left text-[1.5rem] lg:text-[21px] text-center text-white rounded-[2rem] lg:rounded-[2.6rem] px-[2.6rem] py-[1rem] lg:px-[5.3rem] lg:py-[1.25rem] hover:cursor-pointer w-[20rem] lg:w-[28rem] h-[4rem]"
        @click="handleShowServiceDialog"
      >
        {{ t("welcome.five.contactUs") }}
      </div>
      <div
        class="flex h-[1rem] text-left leading-[1rem] pt-[1rem] text-main-black text-[12px]"
        :class="{
          shakeanimation: showShakeAnimation,
          'items-center pt-[2rem] justify-center': !isMobile
        }"
      >
        <el-checkbox
          v-model="confirmToWatch"
          size="large"
          style="margin-right: 0.5rem"
          class="ani-scale"
        />
        <div v-html="t('dialog.buyWarning2')" />
      </div>
    </div>
  </div>
  <!-- 客服对话框 -->
  <ContactDialog ref="serviceDialogRef" />
  <ContactForFreeDialog ref="serviceForFreeDialogRef" />
</template>

<style scoped>
.shakeanimation {
  /* 颤抖动画 */
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  75% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(0);
  }
}
/* 使用深度选择器来覆盖el-checkbox的默认样式 */
::v-deep .el-checkbox__inner {
  border: 1px solid;
  border-color: var(--el-color-primary);
}

.textborder {
  border: 0.5px solid;
  padding: 0.5px 0px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  box-sizing: border-box;
  border: 0.5px solid;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.borderactive {
  color: white;
  border: 0.5px solid #fff;
  border-radius: 6px;
}
.cardBg {
  background: linear-gradient(180deg, #ffffff 0%, #dff0ff 100%);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.cardActiveBg {
  box-sizing: border-box;
  background-clip: padding-box; /* 确保背景裁剪到内边距框 */
  border: 2px solid;
  border-color: var(--el-color-primary);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* 设置圆角 */
  overflow: hidden; /* 确保内容也有圆角 */
}
.el-checkbox.el-checkbox--large {
  height: 1.125rem;
}
</style>
