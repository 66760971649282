import { defineStore } from "pinia";
import { ref } from "vue";
import {
  LocalStorageKeyEnum,
  PinnedFixKeyEnum,
} from "@/config";
import { CommonUtils } from "@/utils/commonUtils";


export const useCommonStore = defineStore('common-work', () => {
  const pinnedFixMap = ref<Map<PinnedFixKeyEnum, any[]>>(
    CommonUtils.getLocalFromKey(LocalStorageKeyEnum.PINNED_FIX_MAP) ?? new Map()
  );

  function getPinnedFixMap(key: PinnedFixKeyEnum) {
    return pinnedFixMap.value?.get(key);
  }

  function updateFixedDataList(
    key: PinnedFixKeyEnum,
    itemKey: string,
    dataList: Array<any>
  ) {
    if (!dataList?.length || !pinnedFixMap.value?.size) {
      return dataList;
    }

    const fixList = pinnedFixMap.value.get(key);
    if (!fixList?.length) {
      return dataList;
    }

    // 提取置顶项
    const pinnedItems = dataList
      .filter(item => fixList.includes(item[itemKey]))
      .reverse();

    // 合并未置顶项
    return [
      ...pinnedItems,
      ...dataList.filter(item => !fixList.includes(item[itemKey]))
    ];
  }

  function pinItemOrUnpinItem(key: PinnedFixKeyEnum, item: string) {
    let fixList = pinnedFixMap.value.get(key) || [];

    fixList = fixList.includes(item)
      ? fixList.filter(innerItem => item !== innerItem) // 取消置顶
      : [item, ...fixList]; // 置顶

    pinnedFixMap.value.set(key, fixList);

    // 保存到本地存储
    localStorage.setItem(
      LocalStorageKeyEnum.PINNED_FIX_MAP,
      CommonUtils.getJSON(Array.from(pinnedFixMap.value.entries()))
    );

    return fixList;
  }

  return {
    getPinnedFixMap,
    updateFixedDataList,
    pinItemOrUnpinItem
  };
}); 