<script setup lang="ts">
import CloseBold from "@iconify-icons/ep/close-bold";
import { Motion } from "@/utils";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { Clipboard } from "v-clipboard";
import { message } from "@/utils";
import contactLineUrl from "@/assets/digit-human/line.jpg";
import { useUserStoreHook } from "@/store/modules/user";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import downArrow from "@/assets/digit-human/digit-img/down-arrow.png";
import lookEmailArrow from "@/assets/svg/ai-tools/look-email-arrow.svg?component";
import { useRouter } from "vue-router";
import { Constants, QrType, RouterNameEnum } from "@/config";
import { deviceDetection } from "@pureadmin/utils";
import { QrCode } from "@/components/Qrcode/index";
import { DeviceUtils } from "@/utils/DeviceUtils";
import { userApi } from "@/api/user-api";
const isMobile = deviceDetection();
const { locale } = useTranslationLang();
const { t } = useI18n();
const gzApplyQrUrl = ref(useSystemConfigStoreHook().config.sourceImg?.gzQrUrl);
const logoUrl = computed(() => {
  return useSystemConfigStoreHook().config.sourceImg?.logoUrl;
});
const sendMessage = ref(`33${useUserStoreHook().id}`);
// 获取路由参数
const router = useRouter();
const qrWh = ref();
const lookEmailVisible = ref(false);
qrWh.value =
  (DeviceUtils.isMobile() ? window.innerWidth * 0.8 : window.innerWidth * 0.3) /
  2;
// 客服对话框
const serviceDialogVisible = ref(false);
const showServiceDialog = () => {
  serviceDialogVisible.value = true;
  init();
};
const contactUrl = useSystemConfigStoreHook().config.sourceImg
  ?.digitalServiceQrUrl
  ? useSystemConfigStoreHook().config.sourceImg?.digitalServiceQrUrl
  : useSystemConfigStoreHook().config.sysConfig.contactEmail;
const contactLine = useSystemConfigStoreHook().config.sysConfig.contactLine;

const copyValue = value => {
  Clipboard.copy(value);
  message(t("common.copySuccess") + " " + value, { type: "success" });
};
const gotoCenter = () => {
  router.push({ name: RouterNameEnum.UserCenter });
};
const init = async () => {
  const gzQrData = await userApi.getLoginQr(QrType.APPLY);
  gzApplyQrUrl.value = gzQrData.data.url;
};
const lookEmail = () => {
  lookEmailVisible.value = !lookEmailVisible.value;
};
// onMounted(() => {
//   init();
// });
defineExpose({ showServiceDialog });
</script>

<template>
  <div v-if="serviceDialogVisible" class="modal-overlay">
    <Motion class="w-full h-full flex-c">
      <div
        :class="{
          'w-[95%] lg:w-[40rem] !py-[1.5rem]':
            isMobile || locale == Constants.Lang.en
        }"
        class="bg-[#fff] text-white w-[65%] lg:w-[34.375rem] relative flex flex-col items-center py-[2.5rem]"
      >
        <IconifyIconOffline
          :icon="CloseBold"
          @click="serviceDialogVisible = false"
          class="absolute right-[10px] top-[1.2rem] text-primary text-[2rem] transition duration-300 hover:cursor-pointer hover:-rotate-45 hover:text-primary"
        />
        <div
          :class="{
            '!text-[1.5rem]': locale == Constants.Lang.en
          }"
          class="mb-[1.44rem] textColor w-[90%] text-[1.875rem] font-[OPPOSansB]"
        >
          {{ t("dialog.freeDigital") }}
        </div>
        <div
          :class="{
            'w-[80%] lg:w-[36rem]': isMobile || locale == Constants.Lang.en
          }"
          class="relative w-[65%] lg:w-[28.56rem] bg-[#f6f6f6] flex flex-col items-center"
        >
          <div
            class="flex flex-col w-full mt-[1.2rem] items-center justify-center"
          >
            <QrCode :value="gzApplyQrUrl" :logo="logoUrl" ref="qrRef" />
            <div
              :class="{ 'p-0': locale == Constants.Lang.en }"
              class="z-[9999] text-[1.2rem] font-[OPPOSansB] text-black"
            >
              {{ t("dialog.apply") }}
            </div>
          </div>
        </div>
        <!-- 向下箭头 -->
        <div class="w-full h-[3rem]">
          <img
            class="w-[1.5rem] h-[1.5rem] object-cover my-[1rem]"
            :src="downArrow"
          />
        </div>
        <div
          :class="{
            'w-[80%]  lg:w-[36rem]': isMobile || locale == Constants.Lang.en
          }"
          class="w-[65%] lg:w-[28.56rem] flex flex-col bg-[#f6f6f6]"
        >
          <div
            :class="{ 'flex-col': locale == Constants.Lang.en }"
            class="flex justify-center items-center text-[#FF1C1C] mt-[0.5rem] font-[OPPOSansB] text-[0.875rem]"
          >
            <span>{{ t("dialog.question") }}</span>
            <div class="flex justify-center items-center">
              <span
                class="whitespace-nowrap text-primary text-[0.8rem] cursor-pointer active:text-[#848383]"
                @click="lookEmail"
              >
                {{ t("dialog.clickToView") }}
              </span>
              <span
                :class="{
                  ' transform duration-300 rotate-90': lookEmailVisible
                }"
              >
                <lookEmailArrow />
              </span>
            </div>
          </div>
          <div
            class="text-black flex flex-col text-[0.875rem] font-[OPPOSansB]"
          >
            <transition>
              <div
                v-if="lookEmailVisible"
                class="flex justify-center flex-wrap"
              >
                {{ t("dialog.sendEmail") }}
                <span class="textColor mx-[4px]">“{{ sendMessage }}”</span>
                <span class="text-black mr-[4px]">{{ t("dialog.to") }}</span>
                <span class="ttextColor"> contact@iiii.com</span>
                <span class="text-black ml-[4px]">{{
                  t("dialog.clickCopy")
                }}</span>
                <span
                  @click="copyValue('contact@iiii.com')"
                  class="textColor cursor-pointer active:text-white"
                >
                  {{ t("common.copy") }}
                </span>
                {{ t("dialog.emailAddress") }}
              </div>
            </transition>
            <div class="textColor text-center">
              {{ t("dialog.contactUs") }}
            </div>
            <div class="flex w-full justify-center">
              <div class="flex flex-col items-center">
                <img
                  alt="Future AI"
                  :src="contactUrl"
                  class="w-[10rem] h-[10rem] object-cover"
                />
                <div
                  :class="{ '!text-[0.8rem]': locale == Constants.Lang.en }"
                  class="text-[1rem] text-[#3D3D3]"
                >
                  {{ t("dialog.addWeChat") }}
                </div>
              </div>
              <div
                :class="{
                  ' ml-[3rem] lg:ml-[1rem]': locale == Constants.Lang.en
                }"
                class="flex flex-col items-center ml-[3rem] lg:ml-[3rem]"
                v-if="contactLine"
              >
                <img
                  alt="Line"
                  :src="contactLineUrl"
                  class="w-[10rem] h-[10rem] object-cover"
                />
                <div
                  :class="{ '!text-[0.8rem]': locale == Constants.Lang.en }"
                  class="text-[1rem] text-main-black"
                >
                  <span>
                    {{ t("dialog.addLine") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mx-auto mt-[1.125rem] font-[OPPOSansB] my-press-button-no-shadow flex-c my-gradient-green-left text-[15px] text-center text-white rounded-[2.6rem] hover:cursor-pointer w-[11.25rem] h-[2rem]"
          @click="gotoCenter"
        >
          {{ t("dialog.sendCode") }}
        </div>
        <div class="flex justify-center text-black text-[8px]">
          {{ t("dialog.support") }}
        </div>
      </div>
    </Motion>
  </div>
</template>

<style scoped>
.textColor {
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  background: linear-gradient(
      209deg,
      var(--el-color-primary-stop) 21%,
      var(--el-color-primary) 79%
    ),
    #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.boxbg {
  background: linear-gradient(
      184deg,
      var(--el-color-primary-stop) -64%,
      var(--el-color-primary) 170%
    ),
    #ffffff;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
