<script setup lang="ts">
import CommentWall from "./components/CommentWall/index.vue";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
<template>
  <div class="relative app-container">
    <div
      class="absolute text-[1.5rem] lg:text-[2.3rem] top-[4rem] w-full text-center"
    >
      <h1 class="title">
        {{ t("welcome.CommentWallLayout.everyoneIsUsingIt")
        }}{{ useSystemConfigStoreHook().config.sysConfig.appName
        }}{{ t("welcome.AdvantagesLayout.digitalHuman") }}
      </h1>
      <p class="subtitle">
        {{ useSystemConfigStoreHook().config.sysConfig.appName }}，{{
          t("welcome.CommentWallLayout.makeCreationEasier")
        }}
      </p>
    </div>
    <div class="w-full h-full flex flex-row justify-center items-center">
      <CommentWall />
    </div>
  </div>
</template>

<style>
.app-container {
  width: 100%;
  height: 95vh;
  background: linear-gradient(
    180deg,
    var(--el-color-primary) 0%,
    var(--el-color-primary-stop) 100%
  );
}

.title {
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 16px;
}

.subtitle {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.85);
}
</style>
