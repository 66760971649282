import { RouterOption } from "@/api/admin-router-api/admin-router-tyeps";
import { AdminSearchType } from "@/api/types";
import { BusinessTypeEnum, LocalStorageKeyEnum, RouterNameEnum, UploadFileTypeEnum } from "@/config";
import { Constants } from "@/config/constants";
import { DataInfo, sessionKey } from "@/utils";
import { isAllEmpty, storageLocal } from "@pureadmin/utils";
import { useIntervalFn } from "@vueuse/core";

export const CommonUtils = {
  getUserInfo() {
    return storageLocal().getItem<DataInfo<any>>(sessionKey);
  },
  getLocalFromKey(key: LocalStorageKeyEnum) {
    const result = CommonUtils.getJSONObj(localStorage.getItem(key));
    if (result) {
      return new Map(result);
    }
    return new Map();
  },
  cleanText(text: string) {
    return text
      .replace(/^#+\s/gm, "")
      .replace(/[*_]{1,3}([^*_]+)[*_]{1,3}/g, "$1")
      .replace(/```[\s\S]*?```/g, "")
      .replace(/`([^`]+)`/g, "$1")
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, "$1")
      .replace(/<[^>]*>/g, "")
      .replace(/\s+/g, " ")
      .trim();
  },
  findBusinessFileType(businessType: number) {
    if (businessType === BusinessTypeEnum.USER_AUDIO) {
      return UploadFileTypeEnum.AUDIO;
    } else if (
      // 视频
      [
        BusinessTypeEnum.USER_VIDEO,
        BusinessTypeEnum.SYSTEM_VIDEO,
        BusinessTypeEnum.USER_WEB_VIDEO
      ].includes(businessType)
    ) {
      return UploadFileTypeEnum.VIDEO;
    } else {
      // 图片
      return UploadFileTypeEnum.IMG;
    }
  },
  delay(ms: number) {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  },
  isObject(value: any): value is Object {
    return value !== null && typeof value === "object" && !Array.isArray(value);
  },
  listIsEmpty(list: Array<any>) {
    if (!list || list?.length <= 0) {
      return true;
    }
    for (let count = 0; count < list.length; count++) {
      if (!isAllEmpty(list[count])) {
        return false;
      }
    }
    return true;
  },
  getTranslateText(value: string, tLang) {
    let lang = Constants.Lang.zh;
    if (!isAllEmpty(tLang)) {
      lang = tLang;
    }
    if (CommonUtils.isJsonString(value)) {
      try {
        const jsonObj = CommonUtils.getJSONObj(value);
        return jsonObj?.[lang]?.[0] || value;
      } catch (error) {
        console.error("JSON parsing error:", error);
        return value;
      }
    }
    return value;
  },
  isTranslateTextEmpty(...obj: any[]): boolean {
    // 如果 obj 是 null 或者是 undefined，或者 obj 为空数组
    if (obj == undefined || obj == null || obj.length == 0) {
      return true;
    }

    // 遍历 obj 数组
    for (let count = 0; count < obj.length; count++) {
      const value = obj[count];
      // 判断每个 value 是否为 null、undefined 或 空字符串
      if (value == undefined || value == null || value == "" || value == '""') {
        return true;
      }
    }

    return false;
  },
  generateRouterOptions(menuData) {
    const options: RouterOption[] = [];
    const traverseMenu = (menuItems: any[], parentLabel = "") => {
      for (const item of menuItems) {
        // Parse the title assuming it's a JSON string
        let translatedTitle;
        try {
          translatedTitle = CommonUtils.getJSONObj(item.title);
        } catch (error) {
          console.error(`Invalid JSON in title for item: ${item}`, error);
          continue; // Skip this item if title is not valid JSON
        }

        // Create label in Chinese
        const label = `${parentLabel}${parentLabel ? "/" : ""}${translatedTitle.zh[0]
          }`;

        // Retrieve the router name if it exists
        const routerName = item.name ? item.name : "";

        // Only proceed if routerName is not empty
        if (routerName) {
          const mappedName =
            RouterNameEnum[routerName as keyof typeof RouterNameEnum] ||
            routerName;

          options.push({
            label: label,
            name: mappedName,
            component: item.component,
            path: item.path
          });

          // If the current item has children, traverse them recursively
          if (item.children && item.children.length > 0) {
            traverseMenu(item.children, label);
          }
        } else {
          // Optionally, you can choose to traverse children even if the current item's name is empty
          if (item.children && item.children.length > 0) {
            traverseMenu(item.children, label);
          }
        }
      }
    };

    traverseMenu(menuData);
    return options;
  },

  findMenuByName(menuData: any[], targetName: string): any | null {
    let result: any | null = null;
    // 定义递归函数
    const traverseMenu = (menuItems: any[]) => {
      for (const item of menuItems) {
        // 如果 name 匹配目标，直接返回该项
        if (item.name === targetName) {
          result = item;
          return;
        }

        // 如果有子菜单，则递归处理
        if (item.children && item.children.length > 0) {
          traverseMenu(item.children);
        }
      }
    };

    // 开始递归查找
    traverseMenu(menuData);
    return result;
  },
  dealListToLaelList(list: Array<any>, key: string, noNeedAll?: boolean) {
    const digitList = [];
    if (!noNeedAll) {
      digitList.push({
        label: "全部",
        value: list
      });
    }
    list.forEach(item => {
      let tempObj: Array<any> = digitList.find(
        innerItem => innerItem.label == item[key]
      )?.value;
      if (!tempObj) {
        tempObj = [];
        digitList.push({
          label: item[key],
          value: tempObj
        });
      }
      tempObj.push(item);
    });
    return digitList;
  },
  getJSONObj(str: string) {
    if (isAllEmpty(str)) {
      return null;
    }
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  },
  getJSON(str) {
    try {
      return JSON.stringify(str);
    } catch (e) {
      return str;
    }
  },
  generateRandomNumber(length: number) {
    let result = "";
    const characters = "0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  },
  // 检查手机号是否符合国家代码
  checkPhone(content: string, code: string): boolean {
    for (let count = 0; count < Constants.PhoneRegexMap.length; count++) {
      const regex = new RegExp(Constants.PhoneRegexMap[count].regex);
      if (regex.test(code + content)) {
        return true;
      }
    }
    return false;
  },
  isIntegerGreaterThanZero(value): boolean {
    if (!value) {
      return false;
    }
    return typeof value === "number" && Number.isInteger(value) && value > 0;
  },
  isDigitalType(businessType): boolean {
    return businessType > 100 && businessType < 200;
  },
  needAddInfoFromType(businessType): boolean {
    if (businessType > 100 && businessType < 200) {
      return true;
    }
    return false;
  },
  isJsonStringArray(str: string): boolean {
    try {
      const obj = CommonUtils.getJSONObj(str);
      return Array.isArray(obj);
    } catch (e) {
      return false; // 如果解析出错，说明不是有效的JSON数组字符串
    }
  },
  isPhone(content: string): boolean {
    const reg = /^(?:(?:\+|00)86)?(?:1[3-9]\d{9}|852\d{8}|853\d{8}|886\d{9})$/;
    return reg.test(content);
  },
  isJsonString(str: string): boolean {
    try {
      CommonUtils.getJSONObj(str);
      return true;
    } catch (e) {
      return false; // 如果解析出错，说明不是有效的JSON数组字符串
    }
  },
  formatString(localName: string, size: number): string {
    if (localName.length > size) {
      // 使用`size`参数动态生成正则表达式
      const regex = new RegExp(`.{1,${size}}`, "g");
      return localName.match(regex).join("<br>");
    }
    return localName;
  },
  getVociceSpeedInfo() {
    return {
      min: 0.5,
      default: 1,
      max: 1.5
    };
  },
  createNormalRecharge(adminSearchType: AdminSearchType, otherData?: any): any {
    const normalData = {
      userId: parseInt(adminSearchType?.userId),
      start: adminSearchType?.start,
      end: adminSearchType?.end,
      username: adminSearchType?.username
    };
    if (otherData && Object.keys(otherData)?.length > 0) {
      Object.assign(normalData, otherData);
    }
    return normalData;
  },
  getAcceptDesc(accept: string) {
    const acceptList = accept.split(",");
    console.log(acceptList);
    let result = "";
    for (let count = 0; count < acceptList.length; count++) {
      const entry = acceptList[count];
      const parts = entry.split("/");
      let type = parts.length > 1 ? parts[1] : entry; // 使用整个entry如果没有'/'分割
      if (type.includes("x-m4a")) {
        type = "m4a";
      } else if (type.includes("mpeg")) {
        type = "mp3";
      } else if (type.includes("jpeg")) {
        type = "jpg";
      } else if (type.includes("vnd.android.package-archive")) {
        type = "apk";
      } else if (type.includes("obj") || type.includes("fbx")) {
        // 如果输入为文件扩展名，直接使用
        type = type.replace(".", ""); // 移除点，例如".obj"变为"obj"
      }
      result = result + type + "/";
    }
    if (result.endsWith("/")) {
      result = result.substring(0, result.length - 1);
    }
    return result;
  },
  isURL(str: string) {
    const pattern = /^https?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&*_=]*)?$/;
    return pattern.test(str);
  },
  getLabelByValue(data: Array<any>, value: any) {
    const option = data.find(option => option.value === value);
    return option?.label;
  },
  getItemByValue(data: Array<any>, value: Number) {
    const option = data.find(option => option.value === value);
    return option;
  },
  getItemByLable(data: Array<any>, lable) {
    const option = data.find(option => option.label === lable);
    return option;
  },
  getItemByObjValue(data: Array<any>, value: string) {
    const option = data.find(
      option => CommonUtils.getJSON(option.value) === value
    );
    return option;
  },
  // 判断是否为图片 URL
  isImageURL(url) {
    // 图片扩展名列表
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const extension = pathname.split(".").pop().toLowerCase();
      return imageExtensions.includes(extension);
    } catch (e) {
      return false; // 如果 URL 无效，返回 false
    }
  },
  // 默认6秒轮询
  createPollingTask(taskFn, interval = 6000, options = { immediate: true }) {
    return useIntervalFn(taskFn, interval, options);
  }
};
