<script setup lang="ts">
import { ref } from "vue";
import { emitter } from "@/utils";
import { EmitterTypeEnum, RouterNameEnum, VoicesEnum } from "./config";
import SelectVoiceCloneDialog from "@/views/components/dialog/SelectVoiceCloneDialog.vue";
import router from "./router";

const selectVoiceCloneDialogRef = ref();

// 打开选择声音弹窗
emitter.on(EmitterTypeEnum.ShowSelectVoiceCloneDialog, (data: VoicesEnum) => {
  selectVoiceCloneDialogRef.value?.handleOpen(data);
});
// TODO跳转到克隆声音页面
const handleGotoClone = () => {
  router.push({
    name: RouterNameEnum.MenuMyCustom
  });
};
</script>
<template>
  <!-- 我的声音，克隆声音弹窗 -->
  <SelectVoiceCloneDialog
    ref="selectVoiceCloneDialogRef"
    @gotoClone="handleGotoClone"
  />
</template>
