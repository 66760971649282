<script setup lang="ts">
import { CommonDialogTypeEnum } from "@/config";
import { ref, defineEmits, defineProps } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "对话框"
  },
  visible: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: "80%"
  }
});

const dialogVisible = ref(props.visible);
const dialogData = ref();
const dialogType = ref<CommonDialogTypeEnum>();
const emit = defineEmits(["commonDialogSubmit"]);

const showDialog = (type?: CommonDialogTypeEnum, mData?: any) => {
  dialogData.value = mData;
  dialogType.value = type;
  dialogVisible.value = true;
};
const commonDialogClose = () => {
  dialogVisible.value = false;
  dialogData.value = null; // 重置 dialogData
};

const commonDialogSubmit = () => {
  // dialogVisible.value = false;
  emit("commonDialogSubmit", dialogType.value, dialogData.value);
  dialogData.value = null; // 重置 dialogData
};
const isShow = () => {
  return dialogVisible.value;
};
defineExpose({ showDialog, isShow, commonDialogClose });
</script>

<template>
  <el-dialog
    v-model="dialogVisible"
    :align-center="true"
    :width="width"
    :title="title"
    @close="commonDialogClose"
  >
    <slot name="content" :dialogData="dialogData" />
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="commonDialogClose">取消</el-button>
        <el-button type="primary" @click="commonDialogSubmit">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
