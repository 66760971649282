import { LocalStorageKeyEnum } from "@/config";
import { CommonUtils } from "./commonUtils";
import { CommonLocalStorageType } from "types/type";

export const LocalStrogeUtils = {
  // 点击不同模型进行跳转
  set(key: LocalStorageKeyEnum, value, needToJson = true) {
    localStorage.setItem(key, needToJson ? CommonUtils.getJSON(value) : value);
  },
  // 点击不同模型进行跳转
  get(key: LocalStorageKeyEnum, needToObj = true) {
    const value = localStorage.getItem(key);
    if (value) {
      return needToObj ? CommonUtils.getJSONObj(value) : value;
    }
    return null;
  },
  // 点击不同模型进行跳转
  remove(key: LocalStorageKeyEnum) {
    localStorage.removeItem(key);
  },
  setCommonLocalStorage(key: LocalStorageKeyEnum, value: any) {
    let commonLocalStorage = this.get(LocalStorageKeyEnum.COMMON_LOCAL_STORAGE);
    if (!commonLocalStorage) {
      commonLocalStorage = {};
    } 
    commonLocalStorage[key] = value;
    this.set(LocalStorageKeyEnum.COMMON_LOCAL_STORAGE, commonLocalStorage);
  },
  clearCommonLocalStorage(key: LocalStorageKeyEnum) {
    let commonLocalStorage = this.get(LocalStorageKeyEnum.COMMON_LOCAL_STORAGE);
    if (commonLocalStorage) {
      delete commonLocalStorage[key];
    } 
    this.set(LocalStorageKeyEnum.COMMON_LOCAL_STORAGE, commonLocalStorage);
  },
  getCommonLocalStorage(key: LocalStorageKeyEnum) {
    const commonLocalStorage = this.get(LocalStorageKeyEnum.COMMON_LOCAL_STORAGE);
    return commonLocalStorage ? commonLocalStorage[key] : null;
  }
};
