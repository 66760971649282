import { CSSProperties, computed } from "vue";
import { storeToRefs } from "pinia";
import { getConfig, LocalStorageKeyEnum } from "@/config";
import { useRoute, useRouter } from "vue-router";
import { emitter, LocalStrogeUtils } from "@/utils";
import { routeMetaType } from "../types";
import { useGlobal } from "@pureadmin/utils";
import { router, remainingPaths } from "@/router";
import { useAppStoreHook } from "@/store/modules/app";
import { useUserStoreHook } from "@/store/modules/user";
import { useEpThemeStoreHook } from "@/store/modules/epTheme";
import { usePermissionStoreHook } from "@/store/modules/permission";
import { Constants } from "@/config";
import { CommonUtils } from "@/utils/commonUtils";
const errorInfo = "当前路由配置不正确，请检查配置";

export function useNav() {
  const route = useRoute();
  const pureApp = useAppStoreHook();
  const routers = useRouter()?.options?.routes;
  const { wholeMenus } = storeToRefs(usePermissionStoreHook());
  /** 平台`layout`中所有`el-tooltip`的`effect`配置，默认`light` */
  const tooltipEffect = getConfig()?.TooltipEffect ?? "light";
  const getDivStyle = computed((): CSSProperties => {
    return {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden"
    };
  });
  /** 时长 */
  const digitHumanAccount = computed(() => {
    return useUserStoreHook()?.digitHumanAccount;
  });
  /** 用户名 */
  const username = computed(() => {
    return useUserStoreHook()?.username;
  });
  /** 姓名 */
  const realName = computed(() => {
    return useUserStoreHook()?.realName;
  });
  /** nickname */
  const nickname = computed(() => {
    return useUserStoreHook()?.nickname;
  });
  /**角色 */
  const roles = computed(() => {
    return useUserStoreHook()?.roles;
  });

  /** 上级用户名 */
  const parentUsername = computed(() => {
    return useUserStoreHook()?.parentUsername;
  });
  /** 支付宝账户 */
  const payAccount = computed(() => {
    return useUserStoreHook()?.payAccount;
  });
  /** 代理商 */
  const isAgent = computed(() => {
    return useUserStoreHook()?.isAgent;
  });
  // 积分
  const scoreDetail = computed(() => {
    return useUserStoreHook()?.scoreDetail;
  });

  /** 设置国际化选中后的样式 */
  const getDropdownItemStyle = computed(() => {
    return (locale, t) => {
      return {
        background: locale === t ? useEpThemeStoreHook().epThemeColor : "",
        color: locale === t ? "#f4f4f5" : "#000"
      };
    };
  });
  const getDropdownItemClass = computed(() => {
    return (locale, t) => {
      return locale === t ? "" : "dark:hover:!text-primary";
    };
  });

  const avatarsStyle = computed(() => {
    return username.value ? { marginRight: "10px" } : "";
  });

  const isCollapse = computed(() => {
    return !pureApp.getSidebarStatus;
  });

  const device = computed(() => {
    return pureApp.getDevice;
  });

  const { $storage, $config } = useGlobal<GlobalPropertiesApi>();
  const layout = computed(() => {
    return $storage?.layout?.layout;
  });

  const title = computed(() => {
    return $config.Title;
  });
  const subtitle = computed(() => {
    return $config.SubTitle;
  });

  /** 动态title */
  function changeTitle(meta: routeMetaType) {
    const Title = getConfig().Title;
    if (Title)
      document.title = `${CommonUtils.getTranslateText(
        meta.title,
        Constants.Lang.zh
      )}`;
    document.title = CommonUtils.getTranslateText(
      meta.title,
      Constants.Lang.zh
    );
  }

  /** 退出登录 */
  function logout() {
    // 将出现新闻字段变成出现
    LocalStrogeUtils.clearCommonLocalStorage(LocalStorageKeyEnum.NewsTime);
    useUserStoreHook().logOut();
    // 记住当前路径
    router.push({ path: "/login", query: { redirect: route.path } });
  }

  /** 清除用户信息 */
  function clearUserInfo() {
    useUserStoreHook().clearUserInfo();
  }

  function backHome() {
    router.push("/welcome");
  }

  function onPanel() {
    emitter.emit("openPanel");
  }

  function toggleSideBar() {
    pureApp.toggleSideBar();
  }

  function handleResize(menuRef) {
    menuRef?.handleResize();
  }

  function resolvePath(route) {
    if (!route.children) return console.error(errorInfo);
    const httpReg = /^http(s?):\/\//;
    const routeChildPath = route.children[0]?.path;
    if (httpReg.test(routeChildPath)) {
      return route.path + "/" + routeChildPath;
    } else {
      return routeChildPath;
    }
  }

  function menuSelect(indexPath: string, menuRouters): void {
    if (
      wholeMenus.value.length === 0 ||
      route.meta.notFlat ||
      isRemaining(indexPath)
    )
      return;
    let parentPath = "";
    const parentPathIndex = indexPath.lastIndexOf("/");
    if (parentPathIndex > 0) {
      parentPath = indexPath.slice(0, parentPathIndex);
    }
    /** 找到当前路由的信息 */
    function findCurrentRoute(indexPath: string, routes) {
      if (!routes) return console.error(errorInfo);
      return routes.map(item => {
        if (item.path === indexPath) {
          if (item.redirect) {
            findCurrentRoute(item.redirect, item.children);
          } else {
            /** 切换左侧菜单 通知标签页 */
            emitter.emit("changLayoutRoute", {
              indexPath,
              parentPath
            });
          }
        } else {
          if (item.children) findCurrentRoute(indexPath, item.children);
        }
      });
    }
    findCurrentRoute(indexPath, menuRouters);
  }

  /** 判断路径是否参与菜单 */
  function isRemaining(path: string): boolean {
    return remainingPaths.includes(path);
  }
  function goToUsercenter() {
    router.push({ name: "UserCenter" });
  }

  return {
    title,
    subtitle,
    device,
    layout,
    logout,
    clearUserInfo,
    routers,
    $storage,
    backHome,
    onPanel,
    nickname,
    getDivStyle,
    changeTitle,
    toggleSideBar,
    menuSelect,
    handleResize,
    resolvePath,
    isCollapse,
    pureApp,
    username,
    realName,
    parentUsername,
    payAccount,
    avatarsStyle,
    tooltipEffect,
    getDropdownItemStyle,
    getDropdownItemClass,
    goToUsercenter,
    scoreDetail,
    isAgent,
    digitHumanAccount,
    roles
  };
}
