import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { TrainingModel, VoiceCloneType, createWorkApi, postModelListType, userApi } from "@/api";
import {
  getRandomSpeakerAvatar,
  OriginEnum,
  PinnedFixKeyEnum,
  RacialEnum,
  SexEnum,
  VoicesEnum,
  VideoTypeEnum,
  SkipRandomEnum,
  CreateModeEnum,
  ModelTypeEnum,
  MinimaxEmotionEnum,
  CountryLanguageCodeEnum,
  LocalStorageKeyEnum
} from "@/config";
import avatar from "@/assets/usercenter/avatar.png";
import { useUserStore } from "./user";
import { useCommonStore } from "./commonStore";
import { isAllEmpty } from "@pureadmin/utils";
import { CommonUtils } from "@/utils/commonUtils";
import { LocalStrogeUtils } from "@/utils";
import { OperatePlantWorkStoreType } from "./types";


export const useOperatePlantWorkStore = defineStore('operate-plant-work', () => {
  // 获取本地存储的数据
  const storageData:OperatePlantWorkStoreType = LocalStrogeUtils.get(LocalStorageKeyEnum.OPERATE_PLANT_WORK_STORE);
  
  // 初始化 state，优先使用本地存储的数据
  const selectedVoice = ref<VoiceCloneType | null>(storageData?.selectedVoice ?? null);
  const audioUrl = ref<string>(storageData?.audioUrl ?? '');
  const activeType = ref<VideoTypeEnum>(storageData?.activeType ?? VideoTypeEnum.TEXT);
  const content = ref<string>(storageData?.content ?? '');
  const selectedContent = ref<string>(storageData?.selectedContent ?? '');
  const speed = ref( CommonUtils.getVociceSpeedInfo().default);
  const skipRandom = ref<SkipRandomEnum>( SkipRandomEnum.RANDOM);
  const createMode = ref<CreateModeEnum>( CreateModeEnum.LITE);
  const emotion = ref<MinimaxEmotionEnum>( MinimaxEmotionEnum.HAPPY);
  const countryLanguageCode = ref<CountryLanguageCodeEnum>( CountryLanguageCodeEnum.zhCN);
  const activedDigit = ref<TrainingModel>(storageData?.activedDigit ?? null);
  const videoName = ref<string>(storageData?.videoName ?? "");
  const activeDigitType = ref<ModelTypeEnum>(storageData?.activeDigitType ?? ModelTypeEnum.MY_DIGITAL_HUMAN);
  const parseContent = ref<string>(storageData?.parseContent ?? '');

  // 监听数据变化并保存到本地存储
  watch(
    [
      selectedVoice,
      audioUrl,
      activeType,
      content,
      selectedContent,
      activedDigit,
      videoName,
      activeDigitType,
      parseContent
    ],
    () => {
      const storeData = {
        selectedVoice: selectedVoice.value,
        audioUrl: audioUrl.value,
        activeType: activeType.value,
        content: content.value,
        selectedContent: selectedContent.value,
        activedDigit: activedDigit.value,
        videoName: videoName.value,
        activeDigitType: activeDigitType.value,
        parseContent: parseContent.value
      } as OperatePlantWorkStoreType;
      LocalStrogeUtils.set(LocalStorageKeyEnum.OPERATE_PLANT_WORK_STORE, storeData);
    },
    { deep: true }
  );

  // 设置文本内容
  function setContent(text: string) {
    content.value = text;
  }

    // 设置文本内容
  function clearContent() {
    content.value = "";
    parseContent.value = "";
    selectedContent.value = "";
    activeDigitType.value = ModelTypeEnum.MY_DIGITAL_HUMAN;
    audioUrl.value = "";
  }

  function setParseContent(text: string) {
    parseContent.value = text;
  }
  // 设置选中的文本
  function setSelectedContent(text: string) {
    selectedContent.value = text;
  }

  // 设置选中的声音和音频URL
  function setSelectedVoice(voice: VoiceCloneType | null) {
    selectedVoice.value = voice;
  }

  // 设置音频URL
  function setAudioUrl(url: string) {
    audioUrl.value = url;
  }

  // 设置当前类型
  function setActiveType(type: VideoTypeEnum) {
    activeType.value = type;
  }

  // 设置语速
  function setSpeed(value: number) {
    speed.value = value;
  }

  // 设置跳过随机模式
  function setSkipRandom(value: SkipRandomEnum) {
    skipRandom.value = value;
  }

  // 设置创建模式
  function setCreateMode(mode: CreateModeEnum) {
    createMode.value = mode;
  }
  // 设置情绪
  function setEmotion(myEmotion: MinimaxEmotionEnum) {
    emotion.value = myEmotion;
  }
  // 设置国家语言代码
  function setCountryLanguageCode(code: CountryLanguageCodeEnum) {
    countryLanguageCode.value = code;
  }

  function setActivedDigit(digit: TrainingModel) {
    activedDigit.value = digit;
  }
  // 设置视频名称
  function setVideoName(name: string) {
    videoName.value = name;
  }

  // 设置数字人类型
  function setActiveDigitType(type: ModelTypeEnum) {
    activeDigitType.value = type;
  }

  /**获得公共模特和自己的数字人*/
  async function postModelListPage(reqData: postModelListType) {
    const { data } = await createWorkApi.postModelList(reqData);
    return data;
  }

  /**获得公共模特和自己的数字人*/
  async function postUserModelListPage(params?: {
    page?: number,
    name?: string,
    sex?: SexEnum,
    isAll?: boolean // 默认分页
  }) {
    const { name, sex, isAll = false, page = 1 } = params;
    const reqpage = {
      page: page,
      size: isAll ? 200 : 8,
      extra: {
        origin: OriginEnum.USER
      }
    };
    if (sex > 0) {
      reqpage.extra["sex"] = sex;
    }
    if (!isAllEmpty(name)) {
      reqpage.extra["name"] = name;
    }
    const { data } = await createWorkApi.postModelList(reqpage);
    return data;
  }

  /**获得公共模特和自己的数字人*/
  async function postSystemModelListPage(params?: {
    page?: number,
    name?: string,
    sex?: SexEnum,
    racial?: RacialEnum,
  }) {
    const { name, sex, racial, page = 1 } = params;
    const reqpage = {
      page: page,
      size: 6,
      extra: {
        origin: OriginEnum.SYSTEM,
      }
    };
    if (sex > 0) {
      reqpage.extra["sex"] = sex;
    }
    if (racial > 0) {
      reqpage.extra["racial"] = racial;
    }
    if (!isAllEmpty(name)) {
      reqpage.extra["name"] = name;
    }
    const { data } = await createWorkApi.postModelList(reqpage);
    return data;
  }

  /**
   * 获取语音列表
   * @param voicesEnum 
   * @param countryCode 
   * @param sex 
   * @param localName 
   * @returns 
   */
  async function getSysVoiceList(
    voicesEnum: VoicesEnum,
    countryCode?: string,
    sex?: SexEnum,
    localName?: string
  ): Promise<Array<VoiceCloneType>> {
    try {
      const { data } = await userApi.getSysVoiceList({ countryCode, sex, localName });
      if (data?.length > 0) {
        const userStore = useUserStore();
        data.forEach(item => {
          item.avatar = voicesEnum === VoicesEnum.MY_VOICES
            ? (userStore.avatar || avatar)
            : getRandomSpeakerAvatar();
        });
      }
      const list = [...data].reverse();
      return useCommonStore().updateFixedDataList(PinnedFixKeyEnum.VOICE, "voiceId", list);
    } catch (error) {
      console.error('获取语音列表失败:', error);
      return [];
    }
  }

  /**
   * 获取语音列表
   * @param voicesEnum 
   * @param countryCode 
   * @param sex 
   * @param localName 
   * @returns 
   */
  async function getMyVoiceList(
    isSuccess = false
  ): Promise<Array<VoiceCloneType>> {
    const { data } = await userApi.getMyVoiceList(isSuccess);
    if (data?.length > 0) {
      const userStore = useUserStore();
      data.forEach(item => {
        item.avatar = getRandomSpeakerAvatar()
      });
    }
    const list = [...data].reverse();
    return useCommonStore().updateFixedDataList(PinnedFixKeyEnum.VOICE, "voiceId", list);
  }

  return {
    selectedVoice, // 当前选中的声音
    audioUrl, // 当前音频URL
    activeType, // 当前选中的类型
    content, // 文本内容
    parseContent, // 文本内容
    speed, // 语速
    skipRandom, // 跳过随机模式
    createMode, // 创建模式,
    emotion, // 情绪
    countryLanguageCode, // 国家语言代码
    activedDigit, // 当前选中的数字人
    videoName, // 视频名称
    activeDigitType, // 数字人类型
    setActivedDigit,
    setVideoName, // 设置视频名称
    setActiveDigitType, // 设置数字人类型
    setSkipRandom, // 设置跳过随机模式
    setCreateMode, // 设置创建模式
    setSpeed, // 修改语速
    setEmotion, // 修改情绪
    setCountryLanguageCode, // 修改国家语言代码
    selectedContent, // 选中的文本内容
    setSelectedVoice, // 修改选中的声音
    setAudioUrl, // 修改音频URL
    setActiveType, // 修改当前类型
    setContent, // 修改文本内容
    setParseContent, // 修改文本内容
    setSelectedContent, // 修改选中的文本内容
    clearContent, // 清除文本内容
    postModelListPage,
    postUserModelListPage,
    postSystemModelListPage,
    getSysVoiceList,
    getMyVoiceList

  };
}); 