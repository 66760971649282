<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, reactive } from "vue";
import { message } from "@/utils";
import { discountCodeRemarkRules } from "@/views/login/utils/rule";
import type { FormInstance } from "element-plus";
import { ReqDiscountCode, agentApi } from "@/api";
import { deviceDetection } from "@pureadmin/utils";
import CloseBold from "@iconify-icons/ep/close-bold";
import { Motion } from "@/utils";
import { useRenderIcon } from "@/components";
import User from "@iconify-icons/ri/user-3-fill";
import Email from "@iconify-icons/ri/mail-open-fill";
import ChatAI from "@iconify-icons/ri/message-2-fill";

const { t } = useI18n();

const loading = ref(false);
const ruleForm = reactive({
  name: "",
  contactMail: "",
  adminNumber: "",
  yourRequest: ""
});
const props = defineProps(["applycode"]);
const ruleFormRef = ref<FormInstance>();
const serviceDialogRef = ref();
const dialogVisible = ref(false);
const isMobile = deviceDetection();
const postApplyDiscountCode = async () => {
  const reqData: ReqDiscountCode = {
    remark: `姓名:${ruleForm.name},邮箱:${ruleForm.contactMail},手机号:${ruleForm.adminNumber},诉求:${ruleForm.yourRequest}`
  };
  const { data } = await agentApi.postDiscountCode(reqData);
  console.log("data", data);
  loading.value = false;
  message(t("dialog.discountCode.applySuccess"), { type: "success" });
  dialogVisible.value = false;
  //清空数据
  ruleForm.name = "";
  ruleForm.contactMail = "";
  ruleForm.adminNumber = "";
  ruleForm.yourRequest = "";
};
const onUpdate = async (formEl: FormInstance | undefined) => {
  loading.value = true;
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      postApplyDiscountCode();
    } else {
      loading.value = false;
      return fields;
    }
  });
};
const showDialog = () => {
  dialogVisible.value = true;
};

const handleClose = () => {
  ruleFormRef.value.resetFields();
  dialogVisible.value = false;
  //清空数据
  ruleForm.name = "";
  ruleForm.contactMail = "";
  ruleForm.adminNumber = "";
  ruleForm.yourRequest = "";
};
// 立即咨询
const gotoAsk = () => {
  serviceDialogRef.value.showServiceDialog();
};
defineExpose({
  showDialog
});
</script>

<template>
  <div
    v-if="dialogVisible"
    class="w-full h-full flex justify-center items-center modal-overlay"
  >
    <div
      class="relative pt-[5rem] w-[40vw] rounded-lg px-[3rem] h-[70vh] bg-[#fff]"
    >
      <IconifyIconOffline
        :icon="CloseBold"
        @click="handleClose"
        class="absolute text-white right-[-70px] top-[-10px] text-[3rem] transition duration-300 hover:cursor-pointer hover:-rotate-45 hover:text-primary z-10"
      />
      <div class="text-center textColor flex items-center justify-center">
        <span class="mx-[10px] font-bold text-[2.82rem] font-[OPPOSansM]">
          {{
            props?.applycode
              ? t("dialog.discountCode.apply")
              : t("dialog.discountCode.discountforCode")
          }}</span
        >
      </div>
      <div
        class="w-full mt-[1rem] mb-[2.5rem] text-center text-[0.94rem] text-main-black"
      >
        {{ t("Withdrawal.callyou") }}
      </div>
      <div class="flex flex-col">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="discountCodeRemarkRules"
          status-icon
        >
          <el-form-item prop="name">
            <div class="flex w-full">
              <span
                class="mb-[10px] text-main-black text-[0.94rem] w-[5rem] text-end mr-[0.5rem]"
              >
                {{ t("Withdrawal.name") }}:
              </span>
              <el-input
                class="border-0 rounded-[0.22rem]"
                v-model="ruleForm.name"
                resize="none"
                :placeholder="t('login.verifyNameReg')"
                :prefix-icon="useRenderIcon(User)"
              />
            </div>
          </el-form-item>
          <el-form-item prop="contactMail">
            <div class="flex w-full">
              <span
                class="mb-[10px] text-main-black text-[0.94rem] w-[5rem] text-end mr-[0.5rem]"
              >
                {{ t("table.email") }}:
              </span>
              <el-input
                class="border-0 rounded-[0.22rem]"
                v-model="ruleForm.contactMail"
                resize="none"
                :placeholder="t('login.emailReg')"
                :prefix-icon="useRenderIcon(Email)"
              />
            </div>
          </el-form-item>
          <el-form-item prop="adminNumber">
            <div class="flex w-full">
              <span
                class="mb-[10px] text-main-black text-[0.94rem] w-[5rem] text-end mr-[0.5rem]"
              >
                {{ t("login.phone") }}:
              </span>
              <el-input
                class="border-0 rounded-[0.22rem]"
                v-model="ruleForm.adminNumber"
                resize="none"
                :placeholder="t('login.inputEmailOrPhoneReg')"
                :prefix-icon="useRenderIcon(ChatAI)"
              />
            </div>
          </el-form-item>
          <el-form-item prop="yourRequest">
            <div class="flex w-full">
              <span
                class="mb-[10px] text-main-black text-[0.94rem] w-[5rem] text-end mr-[0.5rem]"
              >
                {{ t("dialog.discountCode.yourRequest") }}:
              </span>
              <el-input
                class="border-0 rounded-[0.22rem]"
                v-model="ruleForm.yourRequest"
                :rows="6"
                type="textarea"
                resize="none"
                :placeholder="t('login.pleaseRequest')"
              />
            </div>
          </el-form-item>
        </el-form>
        <span
          class="flex ml-[5.5rem] mt-[1rem] mb-[1rem] flex-row justify-center gap-[1rem]"
        >
          <el-button
            class="!w-[50%] ani-scale !h-[1.82rem] !border-0 my-gradient-green-left !font-[OPPOSansM] !text-white !rounded-[0]"
            :loading="loading"
            @click="onUpdate(ruleFormRef)"
          >
            {{ t("common.submit") }}
          </el-button>
          <el-button
            class="!w-[50%] ani-scale !text-main-black !h-[1.82rem] border-[0.2px] !rounded-[0] !font-[OPPOSansM] hover:!border-[#fff] hover:!bg-[#f4f4f4]"
            @click="gotoAsk"
            >{{ t("future.one.consult") }}</el-button
          >
        </span>
      </div>
    </div>
    <!-- 客服对话框 -->
  </div>
  <ContactDialog ref="serviceDialogRef" />
</template>
<style scoped lang="scss">
:deep(.el-input__wrapper) {
  height: 2rem !important;
  border-radius: 3px !important;
  border: #000000 !important;
}
:deep(.el-dialog) {
  background: #fff;
}
:deep(.el-overlay) {
  background-color: rgba($color: #000000, $alpha: 0.85);
}
.textColor {
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  background: linear-gradient(
    194deg,
    var(--el-color-primary-stop) -15%,
    var(--el-color-primary) 118%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
:deep(.el-form-item__error) {
  left: 5rem;
}
</style>
