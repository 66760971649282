<script setup lang="ts">
import { ref, nextTick, watch, onMounted } from "vue";
import playAudioSVG from "@/assets/svg/digital-human-workbench/playAudio.svg";
import playBtnSVG from "@/assets/svg/digital-human-workbench/playBtn.svg";
import nofileImg from "@/assets/digital-human-workbench/no-file.png";
import playBtnGraySVG from "@/assets/svg/digital-human-workbench/playBtnGray.svg";
import topSVG from "@/assets/svg/digital-human-workbench/top.svg";
import topGarySVG from "@/assets/svg/digital-human-workbench/top-gary.svg";
import {
  audioModelEnum,
  VoicesEnum,
  SexEnum,
  PinnedFixKeyEnum
} from "@/config";
import { useI18n } from "vue-i18n";
import { VoiceCloneType } from "@/api";
import { useOperatePlantWorkStore } from "@/store/modules/operatePlantWorkStore";
import { useCommonStore } from "@/store/modules/commonStore";
import { VoiceTypeEnum } from "@/config/constantsEnum";
const { t } = useI18n();
// 定义传入的 props，并进行类型验证
// 使用 defineProps 定义 props
const props = defineProps<{
  mediaList: VoiceCloneType[];
  mediaType?: VoicesEnum;
  isLocal?: boolean;
}>();

// 获取当前 URL
const currentMediaRef = ref(null);
const currentMediaSrcRef = ref("");
const activeMediaItemRef = ref<VoiceCloneType>(null);
const activeMediaItemAboutStyle = ref<VoiceCloneType>(null);

watch(
  () => props.mediaList,
  newList => {
    if (newList.length > 0) {
      console.log("数据改变:", newList);
      let tempObj = useOperatePlantWorkStore().selectedVoice;
      if (!tempObj) {
        tempObj = newList[0]; // 默认选择第一个音频
        useOperatePlantWorkStore().setSelectedVoice(tempObj);
      } // 不调用selectItem的原因是为了避免多次调用,会播放音频,因为如果两次选中的url一样,会导致播放音频
      activeMediaItemRef.value = tempObj; // 设置当前选中
    }
  },
  {
    immediate: !props.isLocal
  }
);

const selectItem = async (item: VoiceCloneType) => {
  console.log("select item", item);
  // 只有生成成功的状态才能播放
  // if (item.status == GlobalStateEnum.Success) {
  // 删除默认行为
  if (item.url == activeMediaItemRef.value?.url) {
    await playCurrentMedia(item); //控制播放一个声音
  } else {
    await stopCurrentMedia(); //控制播放一个声音
  }
  activeMediaItemRef.value = item; // 设置当前选中
  useOperatePlantWorkStore().setSelectedVoice(item);
  // }
};

// 关闭列表项音频
const stopCurrentMedia = async () => {
  if (currentMediaRef.value && !currentMediaRef.value.paused) {
    try {
      await currentMediaRef.value.pause(); // 播放音频
    } catch (error) {
      console.error("Error pausing audio:", error);
    }
    await nextTick();
    currentMediaRef.value.src = null; // 清空当前音频源，帮助中断当前加载
    currentMediaSrcRef.value = null; // 同步更新响应式源
  }
};

const playCurrentMedia = async (item: VoiceCloneType) => {
  //切换的时候进行停止播放别的声音
  if (item.url === currentMediaSrcRef.value) {
    stopCurrentMedia();
    return;
  } else {
    currentMediaSrcRef.value = item.url; // 同步更新响应式源
    currentMediaRef.value.src = item.url; // 清空当前音频源，帮助中断当前加载
    console.log("播放:", currentMediaSrcRef.value);
    await nextTick();
    try {
      await currentMediaRef.value.load(); // 播放音频
      await currentMediaRef.value.play(); // 播放音频
    } catch (error) {
      console.error("Error play audio:", error);
    }
  }
};

// 监听音频播放结束事件
const onAudioEnded = async () => {
  console.log("音频播放结束");
  // 在这里处理音频播放结束后的逻辑
  await nextTick();
  currentMediaRef.value.src = null; // 清空当前音频源，帮助中断当前加载
  currentMediaSrcRef.value = null; // 同步更新响应式源
};

onMounted(() => {
  if (currentMediaRef.value) {
    currentMediaRef.value.addEventListener("ended", onAudioEnded);
  }
});
// 保存到本地存储
const pinnedVoiceIdList = ref(
  useCommonStore().getPinnedFixMap(PinnedFixKeyEnum.VOICE)
);
// 置顶和取消置顶
const pinItemOrUnpinItem = item => {
  pinnedVoiceIdList.value = useCommonStore().pinItemOrUnpinItem(
    PinnedFixKeyEnum.VOICE,
    item
  );
  emit("update:refreshMediaList", props.mediaList);
};
//没有文件时候快速复刻
const gotoCloneNofile = type => {
  emit("goto:cloneFile", type);
};

const emit = defineEmits([
  "goto:cloneFile",
  "pinItem",
  "update:refreshMediaList"
]);
</script>
<template>
  <div>
    <audio
      ref="currentMediaRef"
      :src="currentMediaSrcRef"
      @ended="stopCurrentMedia()"
    />
    <!-- 当列表非空时显示列表 -->
    <div v-if="mediaList?.length > 0" class="flex w-full p-[1rem] flex-wrap">
      <div
        v-for="(item, index) in mediaList"
        :key="index"
        :class="{
          'bgcolorActive   border-primary':
            activeMediaItemRef?.url === item.url ||
            activeMediaItemAboutStyle?.url === item.url
        }"
        @mouseenter="activeMediaItemAboutStyle = item"
        @mouseleave="activeMediaItemAboutStyle = null"
        @click="selectItem(item)"
        class="cursor-pointer bg-[#f4f5f7] ani-scale overflow-hidden hover:border-primary border-[#F4F5F7] !rounded-[0.5rem] border-[1px] transform duration-300 mb-[1.3rem] relative flex items-center h-[5.5rem] w-[24%] ml-[1%]"
      >
        <div
          class="w-[4rem] h-[4rem] flex justify-center items-center rounded-full overflow-hidden border-1 mx-[1.2rem]"
        >
          <!-- 未完成的状态 -->
          <slot name="unfinish" :item="item" />
          <!-- 提供插槽，允许自定义内容 -->
          <slot
            name="default"
            :item="item"
            :play="() => playCurrentMedia(item)"
          >
            <div class="relative mx-[2rem]">
              <!-- 头像容器 -->
              <div
                class="relative w-[2.9rem] border-2 border-primary rounded-full h-[2.9rem] group"
              >
                <!-- 头像 -->
                <img
                  :src="item.avatar"
                  class="absolute w-full h-full rounded-full object-cover"
                />
                <!-- 遮罩层 - 始终显示当正在播放，否则只在hover时显示 -->
                <div
                  class="absolute inset-0 bg-black rounded-full transition-opacity duration-300"
                  :class="[
                    currentMediaSrcRef == item.url
                      ? 'opacity-50'
                      : 'opacity-0 group-hover:opacity-50'
                  ]"
                ></div>
                <!-- 播放按钮 - 始终显示当正在播放，否则只在hover时显示 -->
                <div
                  class="absolute inset-0 flex items-center justify-center transition-opacity duration-300 z-[9]"
                  :class="[
                    currentMediaSrcRef == item.url
                      ? 'opacity-100'
                      : 'opacity-0 group-hover:opacity-100'
                  ]"
                >
                  <!-- 选中状态 -->
                  <div v-if="activeMediaItemRef?.url == item.url">
                    <!-- 播放声音选中 -->
                    <div v-if="currentMediaSrcRef == item.url">
                      <playAudioSVG @click.stop="stopCurrentMedia()" />
                    </div>
                    <!-- 没有播放声音 -->
                    <div v-else>
                      <playBtnSVG @click.stop="playCurrentMedia(item)" />
                    </div>
                  </div>
                  <!-- 没有选中状态 -->
                  <div v-else>
                    <!-- 播放声音选中 -->
                    <div v-if="currentMediaSrcRef == item.url">
                      <playAudioSVG @click.stop="stopCurrentMedia()" />
                    </div>
                    <!-- 没有播放声音 -->
                    <div v-else>
                      <playBtnGraySVG @click.stop="playCurrentMedia(item)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div class="flex flex-col w-full items-start">
          <div
            class="text-[#101829] font-[500] font-[opposansm] mb-[0.8rem] text-[1rem]"
          >
            <span
              class="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
              >{{
                item.localName.length > 10
                  ? item.localName.slice(0, 10) + "..."
                  : item.localName
              }}</span
            >
            <!-- 当情感版的声音status为-1时,显示预克隆剩余次数，成功的时候不显示 -->
            <slot name="retryCount" :item="item" />
          </div>
          <div class="flex items-center">
            <div class="flex flex-row items-center mr-[0.4rem]">
              <el-tag
                v-if="
                  item.type == VoiceTypeEnum.MINIMAX ||
                  item.type == VoiceTypeEnum.HS
                "
                size="small"
                class="!text-[12px]"
                :type="item?.type === VoiceTypeEnum.MINIMAX ? '' : 'warning'"
                >{{
                  item && item.type === VoiceTypeEnum.MINIMAX
                    ? t("digital-human-workbench.emotionalVer")
                    : t("audioModeSelectionDialog.highFidelity.title")
                }}</el-tag
              >
            </div>
            <div class="flex flex-row items-center">
              <el-tag
                size="small"
                class="!text-[12px]"
                :type="item?.sex === SexEnum.MALE ? '' : 'warning'"
                >{{
                  item?.sex === SexEnum.MALE
                    ? t("workbench.maleVoice")
                    : t("workbench.femaleVoice")
                }}</el-tag
              >
            </div>
            <div class="text-[#AAAAAA] ml-[0.4rem] text-[12px]">
              {{ item.localDesc }}
            </div>
          </div>
        </div>
        <!--置顶 -->
        <div
          @click.stop="pinItemOrUnpinItem(item.voiceId)"
          class="absolute z-[9] top-[0.2rem] right-[0.3rem]"
        >
          <div
            v-if="pinnedVoiceIdList?.includes(item?.voiceId)"
            class="rounded-[50%] hover:border-primary hover:bg-[#fff] p-[0.3rem]"
          >
            <topSVG class="w-[0.95rem] h-[0.95rem] transform duration-300" />
          </div>
          <div
            v-else
            class="rotate-45 rounded-[50%] hover:border-primary hover:bg-[#fff] p-[0.3rem]"
          >
            <topGarySVG class="hover:bg-[#fff] transform duration-300" />
          </div>
        </div>
        <!-- 重命名，和去创作下拉的菜单 -->
        <slot name="openMenu" :item="item" />
      </div>
    </div>
    <!-- 当列表为空时显示备用内容 -->
    <div v-else>
      <slot name="empty">
        <div
          class="text-[#535252] h-[13rem] text-[0.9rem] leading-[1.2rem] flex flex-col justify-center items-center"
        >
          <div
            class="flex flex-col justify-center items-center"
            v-if="mediaType === VoicesEnum.MY_VOICES"
          >
            <img :src="nofileImg" class="w-[6.4rem] h-[6.4rem]" />
            <div class="w-full text-center">
              {{ t("digital-human-workbench.noVoiceCloneYet") }}
            </div>
            <div class="w-full text-center">
              {{ t("digital-human-workbench.clickForPersonalVoiceModel") }}
            </div>
            <span
              @click="gotoCloneNofile(audioModelEnum.HIGH)"
              class="hover:opacity-60 ani-scale mt-[0.5rem] cursor-pointer flex text-[0.8rem] font-[500] text-white buttoncolor px-[0.6rem] py-[0.3rem] rounded-[0.8rem]"
            >
              {{ t("digital-human-workbench.rapidClone") }}
            </span>
          </div>
          <div v-else>
            <div class="w-full flex justify-center flex-col items-center">
              <img :src="nofileImg" class="w-[6.4rem] h-[6.4rem]" />
              <div class="text-center">
                {{ t("digital-human-workbench.noVoiceModelAvailable") }}
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<style scoped>
.bgcolorActive {
  background: linear-gradient(96deg, #f1f8ff 52%, #cbeefc 112%), #d8d8d8;
}

:deep(.el-tag--small) {
  font-size: 1rem;
}

.svgBoxShadow {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.buttoncolor {
  background: linear-gradient(
      196deg,
      var(--el-color-primary-stop) -8%,
      var(--el-color-primary) 112%
    ),
    var(--el-color-main-transform);
}
</style>
