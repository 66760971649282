import { defineStore } from "pinia";
import { store } from "@/store";
import { SystemConfig, WebsiteConfig } from "./types";
import { routesApi } from "@/api";
import { useUserStoreHook } from "./user";
import {
  Code,
  CurrencysEnum,
  FirstLoginEnum,
  IsThirdServiceEnum,
  Lang,
  OnLineServiceStatusEnum
} from "@/config";
import { isJSON, storageLocal } from "@pureadmin/utils";
import { CommonUtils } from "@/utils/commonUtils";
// import router from "@/router";

export const useSystemConfigStore = defineStore({
  id: "pure-systemConfig",
  state: (): SystemConfig => ({
    config: {
      sourceImg: {
        logoUrl: "",
        loginUrl: "",
        serviceQrUrl: "",
        digitalServiceQrUrl: "",
        gzQrUrl: ""
      },
      wxMiniProgramForm: {
        logoUrl: "",
      },
      sysConfig: {
        appName: "",
        slogan: "",
        contactPhone: "",
        contactEmail: "",
        contactAddress: "",
        contactLine: "",
        digits: [],
        homePages: [],
        notifyEmail: "",
        payChannel: [],
        smallBanner: [],
        swiperSorting: [],
        childDomain: "",
        onLineServiceStatus: OnLineServiceStatusEnum.OPEN,
        isCloseDigitalDoc: true,
        textColor: "",
        bgColor: "",
        theme: "",
        firstLoginType: FirstLoginEnum.PHONE,
        lang: [],
        gzServiceUrl: "",
        record: "",
        currencys: "",
        digitalHumanHelpUrl: ""
      }
    },
    id: 0,
    domain: "",
    userId: 0,
    childDomain: "",
    openPay: true,
    price: 0,
    isThirdService: IsThirdServiceEnum.ThirdService,
    openDealer: false,
    openDiscount: false,
    wxLogin: false,
    role: 0,
    dealerType: 0,
    name: "",
    status: 0,
    lang: Lang.LAN_CN // 通过config获取
  }),
  getters: {
    dollarSign: state => {
      const itemCurrencys = state?.config?.sysConfig?.currencys;
      if (itemCurrencys === CurrencysEnum.CNY) {
        return "\u00A5";
      } else if (itemCurrencys === CurrencysEnum.USD) {
        return "$";
      } else if (itemCurrencys === CurrencysEnum.TWD) {
        return "NT$";
      }
    }
  },
  actions: {
    async getSystemConfig() {
      const res = await routesApi.getSystemConfig();
      // res.statusCode = 404;
      if (res.statusCode === Code.SUCCESS.statusCode) {
        const { data } = res;
        console.log("全局配置信息", data);
        console.log("定制化全局配置信息", CommonUtils.getJSONObj(data.config));
        dealGlobalService(this, data);
      } else if (
        [
          Code.CHILD_DOMAIN_ERROR.statusCode,
          Code.MAIN_KEY_ERROR.statusCode,
          Code.SECOND_CONFIG_ERROR.statusCode,
          Code.SECOND_KEY_ERROR.statusCode,
          Code.MAIN_KEY_NOT_INCLUDE_SPACE.statusCode
        ].includes(res.statusCode)
      ) {
        useUserStoreHook().clearUserInfo();
        console.log("常规失效 清除用户缓存");
      } else if (res.statusCode === Code.MAIN_KEY_ACCESS_DENIED.statusCode) {
        useUserStoreHook().clearUserInfo();
        console.log("清除用户缓存并跳转 ", res.message.split("::")[1]);
        window.open(res.message.split("::")[1], "_self");
      } else if (res.statusCode === Code.REQUEST_PATH_ERROR.statusCode) {
        //throw new Error("跳转404界面");
      }
    }
  }
});

export function useSystemConfigStoreHook() {
  return useSystemConfigStore(store);
}
function dealGlobalService(store, data) {
  const keys = Object.keys(data);
  keys.forEach(item => {
    if (item == "config") {
      // 获取config中的语言,首先设置语言
      const config: WebsiteConfig = CommonUtils.getJSONObj(data[item]);
      const langs = config.sysConfig.lang;
      if (langs?.length > 0) {
        const localLang =
          storageLocal().getItem<StorageConfigs>("responsive-locale")?.locale;
        if (localLang && langs.includes(localLang)) {
          store["lang"] = localLang;
        } else {
          store["lang"] = langs[0];
        }
      }
    }
    if (item != "lang") {
      store[item] = isJSON(data[item])
        ? CommonUtils.getJSONObj(data[item])
        : data[item];
    }
  });
}
