import { audioModelEnum, VoicesEnum } from "@/config";
import type { Emitter } from "mitt";
import mitt from "mitt";

type Events = {
  resize: {
    detail: {
      width: number;
      height: number;
    };
  };
  openPanel: string;
  tagViewsChange: string;
  tagViewsShowModel: string;
  logoChange: boolean;
  changLayoutRoute: {
    indexPath: string;
    parentPath: string;
  };
  changeLayoutMode: string;
  // bg|model|material
  openDrawer: any;
  // changeModelDrawer: boolean;
  // changeMaterialDrawer: boolean;
  //切换选中模特后显示顶部工具栏 bg|model
  changeSelectedTool: {
    drawerName: string;
    open: boolean;
  };
  //切换选中背景后显示顶部工具栏
  // changeSelectedBgTool: boolean;
  //清除图片 bg|model
  clearResource: any;

  emitterUploadSuccess: string;
  geetestSuccess: object;
  scrollTop: true;
  // 打开充值对话框
  openPayBusinessTypeDialog: number;
  // 关闭充值对话框
  closePayBusinessTypeDialog: string;
  // 经过提示
  warningTips: {
    isShow: boolean;
    type: number;
  };
  // 通知充值对话框购买成功事件
  refreshPayBusinessTypeDialog: MittSpace.PayBusinessData;
  // 滚动到首屏
  swiperSlideToFirst: string;
  // 修改字幕样式
  changeSrtStyle: object;
  // 文案提取
  extractText: string;
  // 显示价格对话框
  showPriceDialog: boolean;
  // 刷新个人中心数字人列表
  refreshDigitalHumanList: string;
  // 跳转404
  goto404: string;
  // 全局弹出PC和手机支付二维码
  showPCAndMobilePayDialog: MittSpace.PCAndMobilePayData;
  showLoadingDialog: boolean;
  // 打开选择声音弹窗，包含我的声音和系统声音
  ShowSelectVoiceCloneDialog: VoicesEnum;
  // 打开用户需知弹窗
  ShowUserCommitmentInstructionsDialog: audioModelEnum;
};

export const emitter: Emitter<Events> = mitt<Events>();
