import { ref } from "vue";
import { getConfig } from "@/config";
import { useLayout } from "./useLayout";
import { themeColorsType } from "../types";
import { useGlobal } from "@pureadmin/utils";
import { useEpThemeStoreHook } from "@/store/modules/epTheme";
import {
  darken,
  lighten,
  toggleTheme
} from "@pureadmin/theme/dist/browser-utils";

export function useDataThemeChange() {
  const { layoutTheme, layout } = useLayout();
  const themeColors = ref<Array<themeColorsType>>([
    {
      // 绿宝石(默认) #13c2c2
      // 原色较明亮的青色，stop选择更浅更亮的青绿色，增强对比
      color: "#13c2c2",
      themeColor: "mingQing",
      colorStop: "#81DAFF",
      name: "绿宝石（默认）"
    },
    {
      // 天之蓝 #2A7CE3
      // 使用更浅、更亮的蓝色来突出对比
      color: "#2A7CE3",
      themeColor: "skyBlue",
      colorStop: "#d2f1ff",
      name: "天之蓝"
    },
    {
      // 道奇蓝 #1b2a47
      // 深色蓝紫，stop使用更浅、更偏灰的淡蓝，形成明显过渡
      color: "#1b2a47",
      themeColor: "default",
      colorStop: "#d0ddf2",
      name: "道奇蓝"
    },
    {
      // 亮白色 #ffffff
      // 白色本身已非常浅，此处选择稍稍加深的灰白提高层次感
      color: "#ffffff",
      themeColor: "light",
      colorStop: "#dddddd",
      name: "亮白色"
    },
    {
      // 猩红色 #f5222d
      // 从深红色渐变到白色
      color: "#f5222d",
      themeColor: "dusk",
      colorStop: "#F5F5F5",
      name: "猩红色"
    },
    {
      // 橙红色 #bc1f1a
      // 从红过渡到浅粉红，过渡明显
      color: "#bc1f1a",
      themeColor: "volcano",
      colorStop: "#bc1f1a",
      name: "橙红色"
    },
    {
      // 金色 #fadb14
      // 使用非常浅亮的淡黄色，从浅黄到金黄渐变
      color: "#fadb14",
      themeColor: "yellow",
      colorStop: "#ffffbd",
      name: "金色"
    },
    {
      // 酸橙绿 #52c41a
      // 使用极浅绿，从淡绿到明亮绿渐变清晰
      color: "#52c41a",
      themeColor: "auroraGreen",
      colorStop: "#d7ffc7",
      name: "酸橙绿"
    },
    {
      // 深粉色 #eb2f96
      // 使用非常浅的粉色，从浅粉到亮粉红色渐变
      color: "#eb2f96",
      themeColor: "pink",
      colorStop: "#ffe2f1",
      name: "深粉色"
    },
    {
      // 深紫罗兰色 #722ed1
      // 使用浅淡的薰衣草紫，从浅紫到深紫渐变明确
      color: "#722ed1",
      themeColor: "saucePurple",
      colorStop: "#e5d9ff",
      name: "深紫罗兰色"
    }
  ]);

  const { $storage } = useGlobal<GlobalPropertiesApi>();
  const dataTheme = ref<boolean>($storage?.layout?.darkMode);
  const body = document.documentElement as HTMLElement;

  /** 设置导航主题色 */
  function setLayoutThemeColor(theme = "default") {
    layoutTheme.value.theme = theme;
    toggleTheme({
      scopeName: `layout-theme-${theme}`
    });
    $storage.layout = {
      layout: layout.value,
      theme,
      darkMode: dataTheme.value,
      sidebarStatus: $storage.layout?.sidebarStatus,
      epThemeColor: $storage.layout?.epThemeColor
    };

    if (theme === "default" || theme === "light") {
      setEpThemeColor(getConfig().EpThemeColor);
    } else {
      const colors = themeColors.value.find(v => v.themeColor === theme);


      setEpThemeColor(colors.color, colors.colorStop);
    }
  }

  function setPropertyPrimary(mode: string, i: number, color: string) {
    document.documentElement.style.setProperty(
      `--el-color-primary-${mode}-${i}`,
      dataTheme.value ? darken(color, i / 10) : lighten(color, i / 10)
    );
  }
  
  /** 设置 `element-plus` 主题色 */
  const setEpThemeColor = (color: string, colorStop?: string) => {
    useEpThemeStoreHook().setEpThemeColor(color);
    document.documentElement.style.setProperty("--el-color-primary", color);
    console.log("--el-color-primary-stop", colorStop);
    if (colorStop) {
      document.documentElement.style.setProperty("--el-color-primary-stop", colorStop);
    }
    for (let i = 1; i <= 2; i++) {
      setPropertyPrimary("dark", i, color);
    }
    for (let i = 1; i <= 9; i++) {
      setPropertyPrimary("light", i, color);
    }
  }; 

  /** 日间、夜间主题切换 */
  function dataThemeChange() {
    /* 如果当前是light夜间主题，默认切换到default主题 */
    if (useEpThemeStoreHook().epTheme === "light" && dataTheme.value) {
      setLayoutThemeColor("default");
    } else {
      setLayoutThemeColor(useEpThemeStoreHook().epTheme);
    }

    if (dataTheme.value) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }

  return {
    body,
    dataTheme,
    layoutTheme,
    themeColors,
    dataThemeChange,
    setEpThemeColor,
    setLayoutThemeColor
  };
}
