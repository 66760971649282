import { http } from "@/utils/http";
import {
  ResCommon,
  ResPreUploadResult,
  ReqUploadPre,
  ReqMoreResource,
  ReqTestAudio,
  ReqTranslate,
  ReqAudioToText,
  uploadByUrlType,
  uploadHumanType,
  uploaVoiceType,
  postModelListType,
  TrainingModel,
  MiniMaxVoiceClone,
  ReqPlantCompositeVideo
} from "./types";

export const createWorkApi = {
  /**
   * 根据场景id查找模特
   */
  getModelBySceneId: (sceneId: string) => {
    return http.get<any, ResCommon>(`training/find/sceneId/${sceneId}`);
  },
  /**
   * 根据场景id查找模特
   */
  delModel: (id: string) => {
    return http.get<any, ResCommon>(`training/del/${id}`);
  },

  postModelList: (data: postModelListType) => {
    return http.post<any, ResCommon>("training/list", {
      data
    });
  },

  // 飞影提交训练视频
  uploadHuman: (data: uploadHumanType) => {
    return http.post<any, ResCommon>("training/human", {
      data
    });
  },
  uploaVoice: (data: uploaVoiceType) => {
    return http.post<any, ResCommon>("voice-clone/clone/fy/order", {
      data
    });
  },
  getFyAccount: () => {
    return http.get<any, ResCommon>("digital-account");
  },



  /**
   * 获取预上传数据
   */
  postUploadApi: (data: ReqUploadPre) => {
    return http.post<any, ResPreUploadResult>(`oss/pre`, {
      data
    });
  },
  /**我的素材 */
  getMyMaterialList: () => {
    return http.get("getMyMaterialList");
  },
  /**
   * @param businessType 1系统图片 2用户图片 4 用户视频

   */
  getSystemBackgroundGroupList: (businessType: number, orientation: number) => {
    return http.get<any, ResCommon>(
      `oss/system/resource/group/${businessType}/${orientation}`
    );
  },
  /**更多背景列表有缓存
   *
   *  @param businessType 素材类型
   *  @param groupId 分组ID
   * * @param orientation 1竖屏 2横屏
   */
  getSystemResourceList: (
    businessType: number,
    groupId: number,
    orientation: number,
    data: any
  ) => {
    return http.post<any, ResCommon>(
      `oss/system/resource/list/${businessType}/${groupId}/${orientation}`,
      { data }
    );
  },
  // 非缓存 我的素材上传完成后需要立刻拿到最新列表
  getResourceList: (param: {
    businessType: number;
    orientation: number;
    data?: any;
  }) => {
    return http.post<ReqMoreResource, ResCommon>(
      `oss/user/resource/list/${param.businessType}/${param.orientation}`,
      {
        data: param.data
      }
    );
  },
  /**
   * 删除用户素材
   * @param id
   * @returns
   */
  deleteMaterial: (id: number) => {
    return http.delete<any, ResCommon>(`oss/resource/${id}`);
  },
  /**获取发音人 */
  getSpeakersList: () => {
    return http.get<any, ResCommon>("free-speaker");
  },
  /**合成视频 */
  postCompositeVideo: (data?: ReqPlantCompositeVideo) => {
    return http.post<any, ResCommon>(
      "order/composite/video",
      {
        data
      }
    );
  },
  /**获得预估合成视频时长 */
  getCompositeVideoTime: (data?: ReqPlantCompositeVideo) => {
    return http.post<any, ResCommon>(
      "order/calc/video/time",
      {
        data
      }
    );
  },
  /**获取合成视频列表 */
  postCompositeVideoList: (data: object) => {
    return http.post<any, ResCommon>("order/composite/video/list", { data });
  },

  /**获取合成视频列表 */
  getCompositeVideoInfo: () => {
    return http.get<any, ResCommon>("order/composite/video/info");
  },
  /**获取分组背景图片 */
  getBackgroundGroup: () => {
    return http.get("config/background/group");
  },
  /**获取草稿列表 */
  getDraftList: (data: object) => {
    return http.post<any, ResCommon>("draft", { data });
  },
  /**根据Id获取草稿*/
  getDraft: (id: number) => {
    return http.get<any, ResCommon>(`draft/${id}`);
  },
  /**新增草稿 */
  // postDraft: (data: object) => {
  //   return http.post<any, ResCommon>("draft", { data });
  // },
  /**更新草稿 */
  patchDraft: (id: number, data: object) => {
    return http.patch<any, ResCommon>(`draft/${id}`, { data });
  },
  /**删除草稿 */
  deleteDraft: (id: number) => {
    return http.delete<any, ResCommon>(`draft/${id}`);
  },
  /** 获取音频流*/
  postTestTextToAudio: (data: ReqTestAudio) => {
    return http.post<any, any>("microsoft-order/test/text/to/audio", { data });
  },
  /** 获取带轮训的音频地址*/
  postBatchTextToAudio: (data: ReqTestAudio) => {
    return http.post<any, any>("microsoft-order/batch/text/to/audio", { data });
  },
  /** 翻译请求*/
  postTranslate: (data: ReqTranslate) => {
    return http.post<any, any>("microsoft-order/translate", { data });
  },
  /** 根据id获取音频*/
  changeVideoName: (id: number, name: string) => {
    return http.patch<any, any>(`order/change/name/${id}/${name}`);
  },
  /** 根据我的声音克隆名字接口*/
  changevoiceCloneName: (id: number, name: string) => {
    return http.patch<any, any>(`voice-clone/change/name/${id}/${name}`);
  },
  /** 修改我的数字分身接口*/
  changeTrainingName: (id: number, name: string) => {
    return http.patch<any, any>(`training/change/name/${id}/${name}`);
  },
  /** 根据我的声音克隆名字接口*/
  getVoiceByvoiceId: (voiceId: string) => {
    return http.get<any, any>(`voice-clone/${voiceId}`);
  },

  /** 音频转文本*/
  audioToText: (data: ReqAudioToText) => {
    return http.post<ReqAudioToText, any>("microsoft-order/audio/to/text", {
      data
    });
  },
  /** 音频转文本 轮询结果*/
  audioToTextLoopById: (id: number) => {
    return http.get<any, any>(`microsoft-order/${id}`);
  },
  /** 声音克隆数据提交 */
  postAudioCloneInfos: (formdata: any) => {
    return http.request<ResCommon>(
      "post",
      "voice-clone/eleven/labs",
      { data: formdata },
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },

  //本地提交上传音频
  uploadByUrl: (data: uploadByUrlType) => {
    return http.post<any, ResCommon>("voice-clone/eleven/labs/by/url", {
      data
    });
  },
  //获取下载
  getSigned: data => {
    return http.post<any, ResCommon>("oss/signed/url", {
      data: {
        data
      }
    });
  },
  /**
   * 海螺的情感版，根据url获得情感版文件id
   */
  getVoiceCloneFileId: (url: string) => {
    return http.post<any, ResCommon>(`voice-clone/emotion/file/id`, {
      data: { url }
    });
  },
  /**
   * 手动确认成功不在进行重试这个声音
   */
  confirmVoice: (id: number) => {
    return http.patch<any, ResCommon>(`voice-clone/confirm/voice/${id}`);
  },
  /**
   * 根据ID获取声音克隆信息
   */
  getVoiceCloneById: (id: number) => {
    return http.get<any, MiniMaxVoiceClone>(`voice-clone/find/by/id/${id}`);
  },
  /**
   * 删除视频
   * @param id 视频ID
   */
  delOrderVideo: (id: number) => {
    return http.delete<any, ResCommon>(`order/del/${id}`);
  },
};
