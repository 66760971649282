import { defineStore } from "pinia";
import { getLocalState, setLocalState } from "./helper";
import { router } from "@/router";
import { LocalStorageKeyEnum, RouterNameEnum } from "@/config";
import { ChatHelp } from "@/views/chatgpt/utils/ChatHelp";
 
export function createChatStore(key: string) {
  return defineStore(key, {
    state: (): Chat.ChatState => getLocalState(key),

    getters: {
      getChatHistoryByCurrentActive(state: Chat.ChatState) {
        const index = state.history.findIndex(
          item => item.uuid === state.active
        );
        if (index !== -1) return state.history[index];
        return null;
      },

      getChatByUuid(state: Chat.ChatState) {
        return (uuid?: number) => {
          if (uuid)
            return state.chat.find(item => item.uuid === uuid)?.data ?? [];
          return (
            state.chat.find(item => item.uuid === state.active)?.data ?? []
          );
        };
      }
    },

    actions: {
      setUsingContext(context: boolean) {
        this.usingContext = context;
        this.recordState();
      },

      addHistory(history: Chat.History, chatData: Chat.Chat[] = []) {
        this.history.unshift(history);
        this.chat.unshift({ uuid: history.uuid, data: chatData });
        this.active = history.uuid;
        this.reloadRoute(history.uuid);
      },

      updateHistory(uuid: number, edit: Partial<Chat.History>) {
        const index = this.history.findIndex(item => item.uuid === uuid);
        if (index !== -1) {
          this.history[index] = { ...this.history[index], ...edit };
          this.recordState();
        }
      },

      /**
       *  自己加的
       * @param systemId 如果通过systemId找到了,直接就更新当前的,否则重新创建
       * @param edit
       * @returns
       */
      updateHistoryBySystemId(systemId: number, edit: Partial<Chat.History>) {
        const index = this.history.findIndex(
          item => item.systemId === systemId
        );
        if (index !== -1) {
          this.history[index] = { ...this.history[index], ...edit };
          this.recordState();
          return true;
        } else {
          return false;
        }
      },

      /**
       * 自己加的
       * @param systemId 如果通过systemId找到了,直接就更新当前的,否则重新创建
       * @param edit
       * @returns
       */
      findBySystemId(systemId: number): Chat.History {
        const index = this.history.findIndex(
          item => item.systemId === systemId
        );
        if (index !== -1) {
          return this.history[index];
        }
        return null;
      },

      async deleteHistory(index: number) {
        this.history.splice(index, 1);
        this.chat.splice(index, 1);

        if (this.history.length === 0) {
          this.active = null;
          this.reloadRoute();
          return;
        }

        if (index > 0 && index <= this.history.length) {
          const uuid = this.history[index - 1].uuid;
          this.active = uuid;
          this.reloadRoute(uuid);
          return;
        }

        if (index === 0) {
          if (this.history.length > 0) {
            const uuid = this.history[0].uuid;
            this.active = uuid;
            this.reloadRoute(uuid);
          }
        }

        if (index > this.history.length) {
          const uuid = this.history[this.history.length - 1].uuid;
          this.active = uuid;
          this.reloadRoute(uuid);
        }
      },

      async setActive(uuid: number) {
        this.active = uuid;
        return await this.reloadRoute(uuid);
      },
      async setCozeActive(uuid: number) {
        this.active = uuid;
      },

      getChatByUuidAndIndex(uuid: number, index: number) {
        if (!uuid || uuid === 0) {
          if (this.chat.length) return this.chat[0].data[index];
          return null;
        }
        const chatIndex = this.chat.findIndex(item => item.uuid === uuid);
        if (chatIndex !== -1) return this.chat[chatIndex].data[index];
        return null;
      },

      addChatByUuid(uuid: number, chat: Chat.Chat) {
        if (!uuid || uuid === 0) {
          if (this.history.length === 0) {
            const uuid = Date.now();
            this.history.push({ uuid, title: chat.text, isEdit: false });
            this.chat.push({ uuid, data: [chat] });
            this.active = uuid;
            this.recordState();
          } else {
            this.chat[0].data.push(chat);
            if (this.history[0].title === "New Chat")
              this.history[0].title = chat.text;
            this.recordState();
          }
        }

        const index = this.chat.findIndex(item => item.uuid === uuid);
        if (index !== -1) {
          this.chat[index].data.push(chat);
          if (this.history[index].title === "New Chat")
            this.history[index].title = chat.text;
          this.recordState();
        }
      },

      updateChatByUuid(uuid: number, index: number, chat: Chat.Chat) {
        console.log("uuid", uuid);
        if (!uuid || uuid === 0) {
          if (this.chat.length) {
            this.chat[0].data[index] = chat;
            this.recordState();
          }
          return;
        }

        const chatIndex = this.chat.findIndex(item => item.uuid === uuid);
        if (chatIndex !== -1) {
          this.chat[chatIndex].data[index] = chat;
          this.recordState();
        }
      },

      updateChatSomeByUuid(
        uuid: number,
        index: number,
        chat: Partial<Chat.Chat>
      ) {
        if (!uuid || uuid === 0) {
          if (this.chat.length) {
            this.chat[0].data[index] = { ...this.chat[0].data[index], ...chat };
            this.recordState();
          }
          return;
        }

        const chatIndex = this.chat.findIndex(item => item.uuid === uuid);
        if (chatIndex !== -1) {
          this.chat[chatIndex].data[index] = {
            ...this.chat[chatIndex].data[index],
            ...chat
          };
          this.recordState();
        }
      },

      deleteChatByUuid(uuid: number, index: number) {
        if (!uuid || uuid === 0) {
          if (this.chat.length) {
            this.chat[0].data.splice(index, 1);
            this.recordState();
          }
          return;
        }

        const chatIndex = this.chat.findIndex(item => item.uuid === uuid);
        if (chatIndex !== -1) {
          this.chat[chatIndex].data.splice(index, 1);
          this.recordState();
        }
      },

      clearChatByUuid(uuid: number) {
        if (!uuid || uuid === 0) {
          if (this.chat.length) {
            this.chat[0].data = [];
            this.recordState();
          }
          return;
        }

        const index = this.chat.findIndex(item => item.uuid === uuid);
        if (index !== -1) {
          this.chat[index].data = [];
          this.recordState();
        }
      },

      async reloadRoute(uuid?: number) {
        this.recordState();
        if (
          ChatHelp.getAgentOrChatType() !=
          LocalStorageKeyEnum.AGENT_CHOOSE_MODEL
        ) {
          await router.push({ name: RouterNameEnum.Chat, params: { uuid } });
        }
      },

      recordState() {
        setLocalState(key, this.$state);
      }
    }
  });
}

const userChatStoreObj = createChatStore("userChatStore");

// Store for user B
const agentChatStoreObj = createChatStore("agentChatStore");

export const useChatStore = () => {
  const agentChooseModel = ChatHelp.getAgentOrChatType();

  return agentChooseModel == LocalStorageKeyEnum.AGENT_CHOOSE_MODEL
    ? agentChatStoreObj()
    : userChatStoreObj();
};
