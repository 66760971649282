import { transformI18n } from "@/plugins/i18n";
import { home } from "../enums";
import { RouterNameEnum } from "@/config";
const Layout = () => import("@/layout/index.vue");

export default {
  path: "/",
  name: "Home",
  component: Layout,
  redirect: "/welcome",
  meta: {
    title: '{"zh":["首页"],"en":["Home"],"zh-tw":["首頁"]}',
    rank: home
  },
  children: [
    {
      path: "/welcome",
      name: RouterNameEnum.Welcome,
      component: () => import("@/views/welcome/index.vue"),
      meta: {
        title: '{"zh":["首页"],"en":["Home"],"zh-tw":["首頁"]}',
        saveScrollTop: true
      }
    }
  ]
} as RouteConfigsTable;
